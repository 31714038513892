import { useEffect } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/store';
import { getIdUsersImagesThunk, resetVerifyIdImageReducer } from '../../../../redux/slices/components/VerifyId/VerifyIdDataSlice';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import SliderId from '../../../../components/VerifyId/SliderId/SliderId';
import HeaderId from '../../../../components/VerifyId/HeaderId/HeaderId';
import YourStatisticsId from '../../../../components/VerifyId/YourStatisticsId/YourStatisticsId';

const VerifyIdScreen = () => {
  const dispatch = useAppDispatch()
  const { sliderData, lastUserVerified, loadingContainer } = useSelector((state: RootState) => state.verifyIdData);

  useEffect(() => {
      dispatch( getIdUsersImagesThunk() )
      return () => {
        dispatch( resetVerifyIdImageReducer() )
      }
  }, [])
  

  return (
    <div className="flex p-relative height-100">
        <div>
            <HeaderId lastUserVerified={lastUserVerified} />
            
            <div className="p-relative"> 
                { loadingContainer && <LoadingSpinner fullPage={false} /> }
                <SliderId 
                    sliderData={sliderData} 
                />
            </div> 
    
        </div>
        <YourStatisticsId imagesRemaining={sliderData?.length.toString()} />
    </div>
  )
}

export default VerifyIdScreen