import { MutableRefObject } from 'react';

export interface MapViewRef {
	setSearch(selectedPlace: google.maps.places.PlaceResult | null): void;
	returnCurrentPoint(): void
}

export default class MapViewController{
	private static modalRef: MutableRefObject<MapViewRef>;

	static setModalRef = (ref: MutableRefObject<MapViewRef>) => {
		this.modalRef = ref;
	};

	static getCurrentModalRef(): MutableRefObject<MapViewRef> {
		return this.modalRef;
	}

	static setSearch(selectedPlace: google.maps.places.PlaceResult | null): void {
		if (this.modalRef) {
			this.modalRef?.current?.setSearch(selectedPlace)
		}
	}

	static returnCurrentPoint(): void {
		if (this.modalRef) {
			this.modalRef?.current?.returnCurrentPoint()
		}
	}
}
