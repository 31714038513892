import React, { useState } from 'react'
import "./BarSelectRole.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store/store'
import { S3DomainGimwork_country_flags_w80 } from '../../../../../utils/staticVariables/staticVariables'
import { useDispatch } from 'react-redux'
import { updateTableByRoleReducer } from '../../../../../redux/slices/components/ManageRoles/manageRolesSlice'

const BarSelectRole = () => {

    const dispatch = useDispatch()

    const [barSelected, setBarSelected] = useState(false)

    const { roles, roleSelected } = useSelector((state: RootState) => state.manageRoles)

    const existsRole = roleSelected.id ? true : false

    const handleClickOutsideModal = (e: any) => {
        const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickOutsideModal && e.target.id === "darkened-background-modal")
            handleList()
      }

    const selectRole = (idRole: string) => {
        dispatch( updateTableByRoleReducer(idRole) )
        setBarSelected(false)
    }

    const handleList = () => { 
        if(!existsRole)
            return
        setBarSelected(!barSelected) 
    } 

    return (
        <div className="p-relative">

            <div
                onClick={ handleList } 
                className={`con-bar-select-role pointer flex flex-ai-center ${barSelected ? "con-bar-selected-role" : ""}`}
            >
                <p className="flex-1 fs-14 fw-500">
                    {
                        !existsRole ? "No Roles Available" 
                        :
                        (barSelected ? "" : roleSelected.name) 
                    }
                </p>
                <FontAwesomeIcon 
                    icon={solid("triangle")}
                    className={`fs-8 ml-8 ${barSelected ? "color-clear" : "c-black fa-rotate-180"}`}
                />
            </div>
            {
                barSelected && existsRole &&
                <>
                    <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="aux-bar-select" >
                        <div id={"background-aux-modal"}>
                        </div>
                    </div>

                <div className="con-list-roles pt-8 pb-8">
                    {
                        roles.map((role, index) => {
                            if(role.id === roleSelected.id) return (<div key={role.id}></div>)
                            return(
                                <div 
                                    key={role.id}
                                    onClick={() => selectRole(role.id)} 
                                    className="role-listed pt-8 pb-8 pl-10 pr-10 flex flex-jc-sb flex-ai-center pointer"
                                >
                                    <p className="fs-14 fw-500">
                                        { role.name }
                                    </p>
                                    {
                                        role.country !== "All" &&
                                        <div 
                                            className="img-flag-role"
                                            style={{ backgroundImage: `url("${S3DomainGimwork_country_flags_w80}${role.country}.png")` }}>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }
                </div>
                </>
            }

        </div>
    )
}

export default BarSelectRole