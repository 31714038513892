import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import { BugReportTableItem, updateSeenBugReportReducer } from "../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice";
import "./UsersFormRows.css"
import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateBugReportSeenWeb } from "../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions";
import UsersFormRow from "./UsersFormRow/UsersFormRow";
import { UserForm } from "../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice";
import useCheckAccess from "../../hooks/useCheckAccess/useCheckAccess";


const UsersFormRows = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const userFormToShow = useSelector((state: RootState) => state.manageUsersForm.usersToShow);
    const loadingContainer = useSelector((state: RootState) => state.manageUsersForm.loadingContainer);
    const usersGrouped = useSelector((state: RootState) => state.manageUsersForm.usersGrouped)

    const accessDeleteUser = useCheckAccess({ id_Category: "Users_Form", id_Policy: "Users_Form", id_Action: "Delete" })

    const clickOnOffer = useCallback(
      (id: string) => {
          //dispatch( setDetailedBugReportReducer({ bugReport }) )
          navigate(`/manage-user-form/${id}`)
          updateBugReportSeenWeb( id )
          .then(() => {
              dispatch( updateSeenBugReportReducer({ id: id}) )
          }).catch((error) => {
              console.log("error", error)
          })
          //dispatch(setDetailedOfferReducer({bugReport, imgUrl: userImg}))
      },
        [],
    )
    

    return (
      <div className="container-user-form-rows flex flex-1 flex-d-column ff-roboto height-100">
        <div className="aux-scroll-user-form">

            { loadingContainer && <LoadingSpinner fullPage={false} /> }
            
            {
                
                userFormToShow.map((userForm: UserForm) => {
                    const userGrouped = usersGrouped.some((userGrouped: UserForm) => userForm.id === userGrouped.id)
                    return(
                        <UsersFormRow
                            key={userForm.id}
                            userForm={userForm}
                            onPress={clickOnOffer}
                            accessDeleteUser={accessDeleteUser}
                            checkboxValue={userGrouped ? true : false}
                        />
                    )
                })
                
            }

          </div>
      </div>
    )
}

export default React.memo( UsersFormRows )