import { faImagePortrait } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import GenericNotification from '../GenericNotification';
import { Colors } from '../../../../utils/colors/colors';
import { NotificationProps } from '../../FactoryNotification/FactoryNotification';

interface PictureVerificationNotificationProps extends NotificationProps {
	item: {
		id: string;
		notifMsg: string;
		timestamp: number;
		viewed: boolean;
		notifData: {
			approved: string;
			receivedFromType: string;
		};
	};
}

const PictureVerificationNotification = ({
	item,
	clickable,
	onSetNotificationAsView,
}: PictureVerificationNotificationProps) => {
	const { t } = useTranslation()
	const dispatch = useDispatch();
	//const openPictureModal = useSelector((state: STORE_TYPE) => state.modalsState.pictureModal.openPictureModal);
	const {
		id,
		notifMsg,
		timestamp,
		viewed,
		notifData: { approved },
	} = item;
	const description =
		approved === 'true'
			? t("Notifications:index:label_42")
			: t("Notifications:index:label_43");

			/*
	const touchNotification = useCallback(() => {
		if (approved !== 'true') {
			//dispatch(showPictureModalAction(!openPictureModal));
		}

		if (!viewed) {
			onSetNotificationAsView(id);
		}
	}, [openPictureModal]);
*/
	return (
		<GenericNotification
			touchNotification={() => {

			}}
			
			id={id}
			timestamp={timestamp}
			viewed={viewed}
			description={description}
			title={approved === 'true' ? t("Notifications:index:label_11") : t("Notifications:index:label_12")}
			icon={faImagePortrait}
			color={approved === 'true' ? Colors.flagGreen : Colors.secondaryColor}
		/>
	);
};


export default React.memo( PictureVerificationNotification )