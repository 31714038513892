import React, { ChangeEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid';
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import LoadingSpinner from '../../../../LoadingSpinner'
import { useTranslation } from 'react-i18next'
import UploadImageButton from '../../../../UIcomponents/Modals/UploadImageButton/UploadImageButton'
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../../utils/verifications/Forms'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { FileProduct, MarketplaceProduct, setAddMarketplaceProductUserReducer, setEditMarketplaceProductUserReducer } from '../../../../../redux/slices/authSlice'
import TitleInBorderForInput from '../../../../UIcomponents/TitleInBorderForInput/TitleInBorderForInput'
import { EditProductBody, editProductMarketplace, getLinkToPutFileProduct } from '../../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions';
import axios from 'axios';
import { useAppDispatch } from '../../../../../redux/store/store';
import { resetMarketplaceModalsReducer, setEditMarketplaceProductReducer } from '../../../../../redux/slices/components/Marketplace/marketplaceDataSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { getProductDB } from '../../../../../utils/requestsUtils/functionsRequests/products/productsFunctions';
import { ProductDB } from '../../../../../redux/slices/components/Products/ProductsSlice';
import { getProductPrice } from '../../../../../utils/prices/prices';
import GeneralInputTitle from '../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle';

type FormModalEditProduct = {
    product: MarketplaceProduct
}


const FormModalEditProduct = ({ product }: FormModalEditProduct) => {

    const { t,  i18n } = useTranslation()

    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    let initialProductFileState = undefined

    if(product.File)
        initialProductFileState = { name: product.File.name, size: product.File.size }

    const [file, setFile] = useState<File | undefined>(initialProductFileState as File)
    const [fileProduct, setFileProduct] = useState<FileProduct | undefined>(product.File)
    const [fileChanged, setFileChanged] = useState(false)
    const [errorFile, setErrorFile] = useState("")
    const [productDB, setProductDB] = useState<ProductDB>()
    const [taxesDataProduct, setTaxesDataProduct] = useState({
        IVA_GIMMER:  0,
        IVA_GIMWORK: 0,
        ISR:         0,
        RMPC:        0
    })

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: product.productName, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString()},
        description: { error: "", value: product.description, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString()},
        price: { error: "", value: product.price.toString(), touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0)},
    });  

    useEffect(() => {

        const getData = () => {
            getProductDB(product.productId)
            .then((product) => {
                setProductDB(product)
                setTaxesDataProduct({
                    IVA_GIMMER: product.IVA_GIMMER,
                    IVA_GIMWORK: product.IVA_GIMWORK,
                    ISR: product.ISR,
                    RMPC: product.RMPC
                })
            }).catch(() => {

            }).finally(() => {
                setLoading(false)
            })
        }

        getData()
    }, [])
    


    const onLoadFile = async() => {
        if(errorFile)
            setErrorFile("")

        const fileLoaded = (document.getElementById("new-add-product-file") as HTMLInputElement)?.files
    
        if(fileLoaded?.length)
        {
            const file = fileLoaded[0]

            const words = file.name.split(".").reverse()
            setFile(file)
            setFileChanged(true)
            setFileProduct({
                name: file.name,
                size: file.size,
                type: words[0] ?? "unknown"
            })

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    //setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }


    const handleSubmit = async(e: any) => {
        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput, inputValues))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        const options = { headers: { 'Content-Type': '*' } };

        try{
            
            setLoading(true)

            const body: EditProductBody = {
                id: product.id,
                description: inputValues["description"].value,
                price: Number( inputValues["price"].value ),
                productId: product.productId,
            }

            if(product.typeProduct === "DIGITAL"){
                if(fileChanged && file){
                    // get links
                    const signedLink = await getLinkToPutFileProduct(product.id, file.name);
    
                    // save images
                    await Promise.all([
                        axios.put(signedLink, file, options),
                    ])
    
                    body["File"] = fileProduct
                }else{
                    body["File"] = product.File
                }
            }

            await editProductMarketplace(body)

            const newProduct: MarketplaceProduct = {
                ...body,
                date: new Date().toISOString(),
                jobId: product.jobId,
                productName: product.productName,
                typeProduct: product.typeProduct
            }

            dispatch( setEditMarketplaceProductReducer(newProduct) )
            dispatch( setEditMarketplaceProductUserReducer(newProduct) )

            dispatch(resetMarketplaceModalsReducer())

            setLoading(false)
            //dispatch(getAllJobsCategoriesChunk())
            //dispatch(resetValueFromState())
        }catch(error){
            console.log("error", error)
            setLoading(false)
            alert("General:Error Creating the job")
        }


    }

    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }

    const { product_GimmerIVA, product_GimmePaid } = getProductPrice({
        IVA_GIMMER: taxesDataProduct.IVA_GIMMER,
        IVA_GIMWORK: taxesDataProduct.IVA_GIMWORK,
        ISR:    taxesDataProduct.ISR,
        RMPC: taxesDataProduct.RMPC,
        timeTransaction_ISR: 1,
        price: Number(inputValues["price"].value)
    })

    return (
        <form className="mt-10" onSubmit={handleSubmit}>

            <div className="flex height-100">


                <div style={{ width: "60%" }}>
                    <GeneralInputTitle 
                        title={t("ManageProducts:modals:label_03")}
                        name={"productName"}
                        value={inputValues["productName"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={t("ManageProducts:modals:label_04")}
                        error={inputValues["productName"].error}
                        width="100%"
                        readOnly={true}
                    />

                    <GeneralInputTitle 
                        title={t("ManageProducts:modals:label_05")}
                        name={"description"}
                        value={inputValues["description"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={t("ManageProducts:modals:label_06")}
                        error={inputValues["description"].error}
                        width="100%"
                        textArea={{ height: "7vh" }}
                    />



                    <div className="flex flex-ai-center" style={{ }}>
                        <p className="fs-13 fw-500 mb-12 mr-14">{`${t("Marketplace:label_14")} ($)`}</p>
                        <GeneralInputTitle 
                            title={t("ManageProducts:modals:label_09")}
                            name={"price"}
                            value={inputValues["price"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={""}
                            error={inputValues["price"].error}
                            width="100%"
                            type="number"
                        />
                    </div>

                </div>

                <div className="ml-20 mr-20 silver-bg" style={{ width: "1px", height: "20rem"}}></div>

                <div className="flex-1">

                    {
                        productDB?.typeProduct === "DIGITAL" &&
                        <>
                            <p className="fs-13 mb-6 mt-6">{t("Marketplace:label_15")}</p>
                            <input 
                                id="new-add-product-file"  
                                className="display-none" 
                                type="file" 
                                accept={productDB?.fileType.map(file => `application/${file}`).join(",")}
                                onChange={onLoadFile}
                            />

                            <div className="con-upload-btns">
                                <div className="con-input add-job-upload-btn">
                                    <UploadImageButton 
                                        idInputFile="new-add-product-file"  
                                        text={t("Marketplace:label_16")}
                                        iconName="folder-image" 
                                    />
                                    { errorFile && <p className="error-input-file">{errorFile}</p> }
                                </div>
                            </div>

                            <div className="flex">
                                {
                                    fileProduct &&
                                        <div className="flex flex-ai-center">
                                            <div className="mercury-bg border-manatee p-6 mr-8 border-radius-3">
                                                <p className="fs-13">{`${fileProduct.name} (${(fileProduct.size / 1024 / 1024).toFixed(2)} Mb)`}</p>
                                            </div>
                                            <FontAwesomeIcon onClick={() => setFileProduct(undefined)} icon={solid("times")} className="pointer fs-16 c-manatee" />
                                        </div>
                                }

                            </div>
                        </>
                    }

                    <div className="flex-1 center flex-d-column">
                        <div className="flex flex-jc-sb flex-ai-center mt-10 width-100">
                            <p className="fs-18-vh fw-500 color-text">{t("ManageProducts:modals:label_14")}</p>
                            <p className="fs-16-vh fw-500 color-text">{`${product_GimmerIVA} MXN$`}</p>
                        </div>

                        <div className="flex flex-jc-sb flex-ai-center mt-12 width-100">
                            <p className="fs-18-vh fw-500 color-text">{t("ManageProducts:modals:label_15")}</p>
                            <p className="fs-16-vh fw-500 color-text">{`${product_GimmePaid} MXN$`}</p>
                        </div>
                    </div>

                </div>

            </div>


            <div className="flex flex-d-column mt-20">
                <ConfirmButton text={t("General:label_016")} width="100%" />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default FormModalEditProduct