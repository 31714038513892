import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { StyleProp, Text, TextStyle } from 'react-native'
import DateTextReload from './DateTextReload'
import 'moment/locale/es';
import 'moment/locale/fr';

type DateReloadProps = {
    date: string | number
    type: "NOTIFICATION" | "MESSAGES_CARD"
    style: StyleProp<TextStyle>
}

const DateReload = ({
    date,
    type,
    style
}: DateReloadProps) => {

    let text = moment.utc(date).fromNow()

    switch (type) {
        case "NOTIFICATION":
            text = moment.utc(date).fromNow()
        break;
        case "MESSAGES_CARD":
            text = moment.utc(date).fromNow()
        break;
    }

    const auxDate = useRef(text)

    const [dateState, setDateState] = useState(date)
    const [updateComponent, setUpdateComponent] = useState(false)

    /*
    const checkAndSetDate = () => {
        let text = moment.utc(date).fromNow()
        switch (type) {
            case "NOTIFICATION":
                text = moment.utc(date).fromNow()
            break;
        }
        setDateState(dateState)
    }
*/

    useEffect(() => {
        //console.log(" ")
        //console.log("useEffect")
        const timer = setTimeout(() => {
            //console.log("timer")
            //checkAndSetDate()
            setUpdateComponent(!updateComponent)
        },60000);

        return () => {
            //console.log("destroy", Math.random())
            clearTimeout(timer);
        };
    }, [updateComponent])
    

    /*
    useEffect(() => {
        console.log(" ")
        console.log("use effect interval")
        const interval = setInterval(() => {
            console.log("interval")
            setUpdateComponent(!updateComponent)
        }, 3000);
        return () => {
            console.log("clear interval", Math.random())
            clearInterval(interval)
        };
    }, [updateComponent])
    */
    
    //console.log("component")
    
    return (
        <DateTextReload 
            text={text}
            style={style}
        />
    )
}

export default React.memo( DateReload );