import React from 'react'

type TextPasswordRuleProps = {
    approved: boolean
    text: string
}

const TextPasswordRule = ({ approved, text }: TextPasswordRuleProps) => {
    return (
        <p className={`${approved ? "color-approved" : "color-text"} fs-13 mt-6`}>
            {`${approved ? "✓" : "✗"} ${text}`}
        </p>    
    )
}

export default React.memo( TextPasswordRule )