import { GetGimPriceProps, GetGimmerPriceProps, GetGimworkPriceProps, GimPriceData, GimmerPriceData, GimworkPriceData } from "./typesNumbers"

export const getStars = (n: number) => {
    if(!n) return 0
    return Number(n.toFixed(1))
}

export const twoDecimals = (n: number) => {
    return Number(n.toFixed(2))
}

export const twoDecimalsToPercentage = (n?: number) => {
    if(!n)
        return 0
    return Number((n/100).toFixed(2))
}

export const getGimmerPriceData = ({
    price,
    malusGim,
    malusGimmer,
    gimStars,
    gimmerStars,
    malusShareGim,
    tax,
    timeHours,
    timeAdded,
    productPrice,
    rmpcProduct
}: GetGimmerPriceProps): GimmerPriceData => {
    const priceByHours = twoDecimals(price * timeHours)
    const calculationMalusGim = twoDecimals(( malusShareGim / 100 ) * priceByHours * (malusGim / 100) * (5 - gimStars))
    const calculationMalusGimmer = twoDecimals(priceByHours * (malusGimmer / 100) * (5 -gimmerStars))
    const taxTotal = twoDecimals((priceByHours - calculationMalusGim + calculationMalusGimmer) * tax / 100)
    const totalOutcome = twoDecimals(priceByHours - calculationMalusGim + calculationMalusGimmer + taxTotal)
    const stripeComission = twoDecimals( 3 + totalOutcome * 0.05 )
    const total = twoDecimals(totalOutcome + stripeComission)

    const priceByHoursTimeAdded = twoDecimals(price * timeAdded)
    const calculationMalusGimTimeAdded = twoDecimals(( malusShareGim / 100 ) * priceByHoursTimeAdded * (malusGim / 100) * (5 - gimStars))
    const calculationMalusGimmerTimeAdded = twoDecimals(priceByHoursTimeAdded * (malusGimmer / 100) * (5 -gimmerStars))
    const taxTotalTimeAdded = twoDecimals((priceByHoursTimeAdded - calculationMalusGimTimeAdded + calculationMalusGimmerTimeAdded) * tax / 100)
    const totalOutcomeTimeAdded = twoDecimals(priceByHoursTimeAdded - calculationMalusGimTimeAdded + calculationMalusGimmerTimeAdded + taxTotalTimeAdded)
    const stripeComissionTimeAdded = twoDecimals( 3 + totalOutcomeTimeAdded * 0.05 )
    const totalTimeAdded = twoDecimals(totalOutcomeTimeAdded + stripeComissionTimeAdded)

    const rmpcTotalProduct = twoDecimals(productPrice * (rmpcProduct/100))
    const taxTotalProduct = twoDecimals(productPrice * (tax/100))
    const totalOutcomeProduct = twoDecimals(productPrice + rmpcTotalProduct + taxTotalProduct)
    const stripeComissionProduct = twoDecimals( 3 + (totalOutcomeProduct * 0.05) + (productPrice * 0.0025))
    const totalProduct = twoDecimals(totalOutcomeProduct + stripeComissionProduct)

    return {
        calculationMalusGim,
        calculationMalusGimmer,
        taxTotal,
        totalOutcome,
        stripeComission,
        priceByHours,
        total,

        calculationMalusGimTimeAdded,
        calculationMalusGimmerTimeAdded,
        taxTotalTimeAdded,
        stripeComissionTimeAdded,
        priceByHoursTimeAdded,
        totalOutcomeTimeAdded,
        totalTimeAdded,

        rmpcTotalProduct,
        taxTotalProduct,
        totalOutcomeProduct,
        stripeComissionProduct,
        totalProduct
    }
}


export const getGimPriceData = ({
    price,
    malusGim,
    malusGimmer,
    gimStars,
    gimmerStars,
    malusShareGimmer,
    RMP,
    timeHours,
    timeAdded, 
    productPrice,
}: GetGimPriceProps): GimPriceData => {
    const priceByHours = twoDecimals(price * timeHours)
    const calculationMalusGim = twoDecimals(priceByHours * (malusGim / 100) * (5 - gimStars ) )
    const calculationMalusGimmer = twoDecimals((malusShareGimmer / 100) * priceByHours * (malusGimmer / 100) * (5 - gimmerStars))
    const rmpTotal = twoDecimals(priceByHours * RMP / 100 )
    const totalOutcome = twoDecimals(priceByHours - calculationMalusGim + calculationMalusGimmer - rmpTotal)
    const stripeComission = twoDecimals( 12 + totalOutcome * 0.0025 )
    const total = twoDecimals(totalOutcome - stripeComission)

    const priceByHoursTimeAdded = twoDecimals(price * timeAdded)
    const calculationMalusGimTimeAdded = twoDecimals(priceByHoursTimeAdded * (malusGim / 100) * (5 - gimStars ) )
    const calculationMalusGimmerTimeAdded = twoDecimals((malusShareGimmer / 100) * priceByHoursTimeAdded * (malusGimmer / 100) * (5 - gimmerStars))
    const rmpTotalTimeAdded = twoDecimals(priceByHoursTimeAdded * RMP / 100 )
    const totalOutcomeTimeAdded = twoDecimals(priceByHoursTimeAdded - calculationMalusGimTimeAdded + calculationMalusGimmerTimeAdded - rmpTotalTimeAdded)
    const stripeComissionTimeAdded = twoDecimals( totalOutcomeTimeAdded * 0.0025 )
    const totalTimeAdded = twoDecimals(totalOutcomeTimeAdded - stripeComissionTimeAdded)
    
    return {
        calculationMalusGim,
        calculationMalusGimmer,
        rmpTotal,
        totalOutcome,
        stripeComission,
        priceByHours,
        total,

        rmpTotalTimeAdded,
        calculationMalusGimTimeAdded,
        calculationMalusGimmerTimeAdded,
        priceByHoursTimeAdded,
        totalOutcomeTimeAdded,
        stripeComissionTimeAdded,
        totalTimeAdded
    }
}


export const getGimworkPriceData = ({
    price,
    malusGim,
    malusGimmer,
    gimStars,
    gimmerStars,
    malusShareGim,
    malusShareGimmer,
    RMP,
    tax,
    timeHours,
    timeAdded, 
    productPrice,
    rmpcProduct
}: GetGimworkPriceProps): GimworkPriceData => {
    
    const priceByHours = twoDecimals(price * timeHours)
    const calculationMalusGim = twoDecimals(( malusShareGim / 100 ) * priceByHours * (malusGim / 100) * (5 - gimStars))
    const calculationMalusGimmer = twoDecimals( priceByHours * (malusGimmer / 100) * (5 - gimmerStars))
    const calculationMalusGimComplement = twoDecimals(( (100 - malusShareGim) / 100 ) * priceByHours * (malusGim / 100) * (5 - gimStars))
    const calculationMalusGimmerComplement = twoDecimals(( (100 - malusShareGimmer) / 100 ) * priceByHours * (malusGimmer / 100) * (5 - gimmerStars))
    const rmpTotal = twoDecimals(priceByHours * RMP / 100)
    const taxTotal = twoDecimals((priceByHours - calculationMalusGim + calculationMalusGimmer) * tax / 100)
    const total = twoDecimals(calculationMalusGimComplement + calculationMalusGimmerComplement + rmpTotal + taxTotal)

    const priceByHoursTimeAdded = twoDecimals(price * timeAdded)
    const calculationMalusGimTimeAdded = twoDecimals(( malusShareGim / 100 ) * priceByHoursTimeAdded * (malusGim / 100) * (5 - gimStars))
    const calculationMalusGimmerTimeAdded = twoDecimals( priceByHoursTimeAdded * (malusGimmer / 100) * (5 - gimmerStars))
    const calculationMalusGimComplementTimeAdded = twoDecimals(( (100 - malusShareGim) / 100 ) * priceByHoursTimeAdded * (malusGim / 100) * (5 - gimStars))
    const calculationMalusGimmerComplementTimeAdded = twoDecimals(( (100 - malusShareGimmer) / 100 ) * priceByHoursTimeAdded * (malusGimmer / 100) * (5 - gimmerStars))
    const rmpTotalTimeAdded = twoDecimals(priceByHoursTimeAdded * RMP / 100)
    const taxTotalTimeAdded = twoDecimals((priceByHoursTimeAdded - calculationMalusGimTimeAdded + calculationMalusGimmerTimeAdded) * tax / 100)
    const totalTimeAdded = twoDecimals(calculationMalusGimComplementTimeAdded + calculationMalusGimmerComplementTimeAdded + rmpTotalTimeAdded + taxTotalTimeAdded)
    
    const rmpTotalProduct = twoDecimals(productPrice * rmpcProduct / 100)
    const taxTotalProduct = twoDecimals(productPrice * tax / 100)
    const totalProduct = twoDecimals(rmpTotalProduct + taxTotalProduct)
    
    return {
        calculationMalusGim,
        calculationMalusGimmer,
        calculationMalusGimComplement,
        calculationMalusGimmerComplement,
        rmpTotal,
        taxTotal,
        priceByHours,
        total,

        calculationMalusGimTimeAdded,
        calculationMalusGimmerTimeAdded,
        calculationMalusGimComplementTimeAdded,
        calculationMalusGimmerComplementTimeAdded,
        rmpTotalTimeAdded,
        taxTotalTimeAdded,
        priceByHoursTimeAdded,
        totalTimeAdded,

        rmpTotalProduct,
        taxTotalProduct,
        totalProduct
    }
}

export const generateRandom10DigitNumber = () =>  {
    // Generate a random number between 1 billion and 9.99 billion
    return Math.floor(Math.random() * 9000000000) + 1000000000;
}

type GetPriceDataProps = {
    transaction_price:              number
    transaction_malusGim:           number
    transaction_malusGimmer:        number
    transaction_malusShareGim:      number
    transaction_malusShareGimmer:   number
    transaction_IVA:                number

    timeTransaction_gimIVA:         number
    timeTransaction_gimmerIVA:      number
    timeTransaction_gimworkIVA:     number
    timeTransaction_ISR:            number
    timeTransaction_RMP:            number

    transport_price:                number
    transport_gimIVA:               number
    transport_gimmerIVA:            number
    transport_gimworkIVA:           number
    transport_ISR:                  number
    transport_RMP:                  number

    fixe_price:                     number              
    fixe_gimIVA:                    number
    fixe_gimmerIVA:                 number          
    fixe_gimworkIVA:                number
    fixe_ISR:                       number
    fixe_RMP:                       number

    tip_price:                      number          
    tip_gimIVA:                     number
    tip_gimmerIVA:                  number
    tip_gimworkIVA:                 number
    tip_ISR:                        number
    tip_RMP:                        number

    product_gimIVA:                 number
    product_gimmerIVA:              number
    product_gimworkIVA:             number
    product_RMP:                    number

    gimStars:                       number
    gimmerStars:                    number
    timeAdded:                      number
    kmCovered:                      number
    tip:                            number 
}

export const getPriceData = ({
    transaction_price,
    transaction_malusGim,
    transaction_malusGimmer,
    transaction_malusShareGim,
    transaction_malusShareGimmer,
    transaction_IVA,

    timeTransaction_gimIVA,
    timeTransaction_gimmerIVA,
    timeTransaction_gimworkIVA,
    timeTransaction_ISR,
    timeTransaction_RMP,

    transport_price,               
    transport_gimIVA,            
    transport_gimmerIVA,           
    transport_gimworkIVA,           
    transport_ISR,                  
    transport_RMP,

    fixe_price,               
    fixe_gimIVA,            
    fixe_gimmerIVA,           
    fixe_gimworkIVA,           
    fixe_ISR,                  
    fixe_RMP,

    tip_price,               
    tip_gimIVA,            
    tip_gimmerIVA,           
    tip_gimworkIVA,           
    tip_ISR,                  
    tip_RMP,

    product_gimIVA,
    product_gimmerIVA,
    product_gimworkIVA,
    product_RMP,

    gimStars,
    gimmerStars,
    timeAdded,
    kmCovered,
    tip 
}: GetPriceDataProps) => {

    const priceTimeAdded = transaction_price * timeAdded
    const priceTransport = kmCovered * transport_price

    const transaction_bonusGim_HT = twoDecimals( transaction_malusShareGimmer * ( (transaction_price * transaction_malusGim) * (5-gimStars) ) )
    const transaction_MalusGimmer_HT = twoDecimals(transaction_price * transaction_malusGimmer * (5-gimmerStars))
    const transaction_GimmerTotal_HT = twoDecimals(transaction_price + transaction_MalusGimmer_HT - transaction_bonusGim_HT)
    const transaction_GimmerIVA = twoDecimals(transaction_GimmerTotal_HT * timeTransaction_gimmerIVA)
    const transaction_GimmerPaid_TTC = twoDecimals(transaction_GimmerTotal_HT + transaction_GimmerIVA)

    const extraTime_bonusGim_HT = twoDecimals( transaction_malusShareGimmer * ( (priceTimeAdded * transaction_malusGim) * (5-gimStars) ) )
    const extraTime_MalusGimmer_HT = twoDecimals(priceTimeAdded * transaction_malusGimmer * (5-gimmerStars)) 
    const extraTime_GimmerTotal_HT = twoDecimals(priceTimeAdded + extraTime_MalusGimmer_HT - extraTime_bonusGim_HT)
    const extraTime_GimmerIVA = twoDecimals(extraTime_GimmerTotal_HT * timeTransaction_gimmerIVA)
    const extraTime_GimmerPaid_TTC = twoDecimals(extraTime_GimmerTotal_HT + extraTime_GimmerIVA)

    const transport_GimmerTotal_HT = twoDecimals(priceTransport)
    const transport_GimmerIVA = twoDecimals(transport_GimmerTotal_HT * transport_gimmerIVA)
    const transport_GimmerPaid_TTC = twoDecimals(transport_GimmerTotal_HT + transport_GimmerIVA)

    const fixe_bonusGim_HT = twoDecimals( transaction_malusShareGimmer * ( (fixe_price * transaction_malusGim) * (5-gimStars) ) )
    const fixe_MalusGimmer_HT = twoDecimals(fixe_price * transaction_malusGimmer * (5-gimmerStars)) 
    const fixe_GimmerTotal_HT = twoDecimals(fixe_price + fixe_MalusGimmer_HT - fixe_bonusGim_HT)
    const fixe_GimmerIVA = twoDecimals(fixe_GimmerTotal_HT * fixe_gimmerIVA)
    const fixe_GimmerPaid_TTC = twoDecimals(fixe_GimmerTotal_HT + fixe_GimmerIVA)

    const tip_GimmerTotal_HT = twoDecimals(tip / (1+tip_gimmerIVA))
    const tip_GimmerIVA = twoDecimals(tip_GimmerTotal_HT * tip_gimmerIVA)
    const tip_GimmerPaid_TTC = twoDecimals(tip_GimmerIVA + tip_GimmerTotal_HT)
   
    const transaction_bonusGim = twoDecimals((1-transaction_malusShareGimmer) * (transaction_price*transaction_malusGimmer) * (5-gimStars))
    const transaction_bonusGimmer = twoDecimals((1-transaction_malusShareGim) * (transaction_price*transaction_malusGimmer) * (5-gimmerStars))
    const transaction_RMP_TTC = twoDecimals(transaction_price * timeTransaction_RMP)
    const transaction_GimworkTotal_TTC = twoDecimals(transaction_bonusGim + transaction_bonusGimmer + transaction_RMP_TTC)
    const transaction_GimworkIVA = twoDecimals(transaction_GimworkTotal_TTC * timeTransaction_gimworkIVA / (1+timeTransaction_gimworkIVA))
    const transaction_GimworkIncome_HT = twoDecimals(transaction_GimworkTotal_TTC - transaction_GimworkIVA)

    const extraTime_bonusGim = twoDecimals((1-transaction_malusShareGimmer) * (priceTimeAdded*transaction_malusGimmer) * (5-gimStars))
    const extraTime_bonusGimmer = twoDecimals((1-transaction_malusShareGim) * (priceTimeAdded*transaction_malusGimmer) * (5-gimmerStars))
    const extraTime_RMP_TTC = twoDecimals(priceTimeAdded * timeTransaction_RMP)
    const extraTime_GimworkTotal_TTC = twoDecimals(extraTime_bonusGim + extraTime_bonusGimmer + extraTime_RMP_TTC)
    const extraTime_GimworkIVA = twoDecimals(extraTime_GimworkTotal_TTC * timeTransaction_gimworkIVA / (1+timeTransaction_gimworkIVA))
    const extraTime_GimworkIncome_HT = twoDecimals(extraTime_GimworkTotal_TTC - extraTime_GimworkIVA)

    const transport_Gimwork_RMP_TTC = twoDecimals(priceTransport * transport_RMP)
    const transport_GimworkTotal_TTC = transport_Gimwork_RMP_TTC
    const transport_GimworkIVA = twoDecimals(transport_GimworkTotal_TTC*transport_gimworkIVA / (1+transport_gimworkIVA))
    const transport_GimworkIncome_HT = twoDecimals(transport_GimworkTotal_TTC-transport_GimworkIVA)

    const fixe_bonusGim = twoDecimals((1-transaction_malusShareGimmer) * (fixe_price*transaction_malusGimmer) * (5-gimStars))
    const fixe_bonusGimmer = twoDecimals((1-transaction_malusShareGim) * (fixe_price*transaction_malusGimmer) * (5-gimmerStars))
    const fixe_RMP_TTC = twoDecimals(fixe_price * fixe_RMP)
    const fixe_GimworkTotal_TTC = twoDecimals(fixe_bonusGim + fixe_bonusGimmer + fixe_RMP_TTC)
    const fixe_GimworkIVA = twoDecimals(fixe_GimworkTotal_TTC * fixe_gimworkIVA / (1+fixe_gimworkIVA))
    const fixe_GimworkIncome_HT = twoDecimals(fixe_GimworkTotal_TTC - fixe_GimworkIVA)

    const tip_RMP_TTC = twoDecimals(tip * tip_RMP)
    const tip_GimworkTotal_TTC = tip_RMP_TTC
    const tip_GimworkIVA = twoDecimals(tip_GimworkTotal_TTC * tip_gimworkIVA / (1+tip_gimworkIVA))
    const tip_GimworkIncome_HT = twoDecimals( tip_GimworkTotal_TTC - tip_GimworkIVA )

    const transaction_MalusGim_TTC = twoDecimals(transaction_price * transaction_malusGim * (5-gimStars))
    const transaction_bonusGimmer_TTC = twoDecimals(transaction_malusShareGim * transaction_price * transaction_malusGimmer * (5-gimmerStars))
    const transaction_GimTotal_HT = transaction_GimmerTotal_HT
    const transaction_GimIVA = twoDecimals(transaction_GimmerIVA / 2)
    const transaction_GimISR = twoDecimals(transaction_GimTotal_HT * timeTransaction_ISR)
    const transaction_GimIncome_TVA = twoDecimals(transaction_GimTotal_HT + transaction_GimmerIVA - transaction_GimIVA - transaction_GimworkTotal_TTC - transaction_GimISR)
    const transaction_ReportGimwork = twoDecimals(100 * transaction_GimworkTotal_TTC / transaction_GimIncome_TVA)

    const extraTime_MalusGim_TTC = twoDecimals(priceTimeAdded * transaction_malusGim * (5-gimStars))
    const extraTime_bonusGimmer_TTC = twoDecimals(transaction_malusShareGim * priceTimeAdded * transaction_malusGimmer * (5-gimmerStars))
    const extraTime_GimTotal_HT = extraTime_GimmerTotal_HT
    const extraTime_GimIVA = twoDecimals(extraTime_GimmerIVA / 2)
    const extraTime_GimISR = twoDecimals(extraTime_GimTotal_HT * timeTransaction_ISR)
    const extraTime_GimIncome_TVA = twoDecimals(extraTime_GimIVA + extraTime_GimTotal_HT - extraTime_GimworkTotal_TTC - extraTime_GimISR)
    const extraTime_ReportGimwork = twoDecimals(100 * extraTime_GimworkTotal_TTC / extraTime_GimIncome_TVA)

    const transport_GimIVA = twoDecimals(transport_GimmerIVA / 2)
    const transport_GimISR = twoDecimals(transport_GimmerTotal_HT * transport_ISR)
    const transport_GimTotal_HT = transport_GimmerTotal_HT
    const transport_GimIncome_TVA = twoDecimals(transport_GimIVA + transport_GimTotal_HT - transport_GimworkTotal_TTC - transport_GimISR)
    const transport_ReportGimwork = twoDecimals(100 * transport_GimworkTotal_TTC / transport_GimIncome_TVA)


    const fixe_MalusGim_TTC = twoDecimals(fixe_price * transaction_malusGim * (5-gimStars))
    const fixe_bonusGimmer_TTC = twoDecimals(transaction_malusShareGim * fixe_price * transaction_malusGimmer * (5-gimmerStars))
    const fixe_GimTotal_HT = fixe_GimmerTotal_HT
    const fixe_GimIVA = twoDecimals(fixe_GimmerIVA / 2)
    const fixe_GimISR = twoDecimals(fixe_GimTotal_HT * fixe_ISR)
    const fixe_GimIncome_TVA = twoDecimals(fixe_GimIVA + fixe_GimTotal_HT - fixe_GimworkTotal_TTC - fixe_GimISR)
    const fixe_ReportGimwork = twoDecimals(100 * fixe_GimworkTotal_TTC / fixe_GimIncome_TVA)

    const tip_GimTotal_HT = tip_GimmerTotal_HT
    const tip_GimIVA = twoDecimals(tip_GimmerIVA/2)
    const tip_GimISR = twoDecimals(tip_GimTotal_HT * tip_ISR)
    const tip_GimIncome_TVA = twoDecimals(tip_GimIVA + tip_GimTotal_HT - tip_GimworkTotal_TTC - tip_GimISR)
    const tip_ReportGimwork= twoDecimals(tip_GimworkTotal_TTC / tip_GimIncome_TVA)

    const transaction_TaxGimmer = twoDecimals(transaction_GimmerIVA - transaction_GimIVA)
    const transaction_TaxGim = twoDecimals(transaction_GimISR)
    const transaction_TaxGimwork = twoDecimals(transaction_GimworkIVA)
    const transaction_TaxTotal = twoDecimals(transaction_TaxGimmer + transaction_TaxGim + transaction_TaxGimwork)

    const extraTime_TaxGimmer = twoDecimals(extraTime_GimmerIVA - extraTime_GimIVA)
    const extraTime_TaxGim = twoDecimals(extraTime_GimISR)
    const extraTime_TaxGimwork = twoDecimals(extraTime_GimworkIVA)
    const extraTime_TaxTotal = twoDecimals(extraTime_TaxGimmer + extraTime_TaxGim + extraTime_TaxGimwork)

    const transport_TaxGimmer = twoDecimals(transport_GimmerIVA - transport_GimIVA)
    const transport_TaxGim = twoDecimals(transport_GimISR)
    const transport_TaxGimwork = twoDecimals(transport_GimworkIVA)
    const transport_TaxTotal = twoDecimals(transport_TaxGimmer + transport_TaxGim + transport_TaxGimwork)

    const fixe_TaxGimmer = twoDecimals(fixe_GimmerIVA - fixe_GimIVA)
    const fixe_TaxGim = twoDecimals(fixe_GimISR)
    const fixe_TaxGimwork = twoDecimals(fixe_GimworkIVA)
    const fixe_TaxTotal = twoDecimals(fixe_TaxGimmer + fixe_TaxGim + fixe_TaxGimwork)

    const tip_TaxGimmer = twoDecimals(tip_GimmerIVA - tip_GimIVA)
    const tip_TaxGim = twoDecimals(tip_GimISR)
    const tip_TaxGimwork = twoDecimals(tip_GimworkIVA)
    const tip_TaxTotal = twoDecimals(tip_TaxGimmer + tip_TaxGim + tip_TaxGimwork)

    const transaction_StripeGim = twoDecimals(12+(transaction_GimTotal_HT*0.0025))
    const transaction_StripeGimmer = twoDecimals(3+(transaction_GimmerPaid_TTC*0.05))
    const transaction_Stripe_TTC = twoDecimals((transaction_StripeGim + transaction_StripeGimmer)*(1+ transaction_IVA))
    const transaction_TVA_Stripe = twoDecimals(transaction_Stripe_TTC * transaction_IVA / (1+ transaction_IVA))
    const transaction_Stripe_HT = twoDecimals(transaction_Stripe_TTC - transaction_TVA_Stripe)
    const transaction_ReportStripe = twoDecimals(100*transaction_Stripe_HT/transaction_GimworkIncome_HT)

    const extraTime_StripeGim = twoDecimals(extraTime_GimTotal_HT*0.0025)
    const extraTime_StripeGimmer = twoDecimals(3+(extraTime_GimmerPaid_TTC*0.05))
    const extraTime_Stripe_TTC = twoDecimals(extraTime_StripeGim + extraTime_StripeGimmer)
    const extraTime_TVA_Stripe = twoDecimals(extraTime_Stripe_TTC * transaction_IVA / (1+ transaction_IVA))
    const extraTime_Stripe_HT = twoDecimals(extraTime_Stripe_TTC - extraTime_TVA_Stripe)
    const extraTime_ReportStripe = twoDecimals(100*extraTime_Stripe_HT/extraTime_GimworkIncome_HT)

    const transport_StripeGim = twoDecimals(transport_GimTotal_HT*0.0025)
    const transport_StripeGimmer = twoDecimals(3+(transport_GimmerPaid_TTC*0.05))
    const transport_Stripe_TTC = twoDecimals(transport_StripeGim + transport_StripeGimmer)
    const transport_TVA_Stripe = twoDecimals(transport_Stripe_TTC * transaction_IVA / (1+ transaction_IVA))
    const transport_Stripe_HT = twoDecimals(transport_Stripe_TTC - transport_TVA_Stripe)
    const transport_ReportStripe = twoDecimals(100*transport_Stripe_HT/transport_GimworkIncome_HT)

    const fixe_StripeGim = twoDecimals(fixe_GimTotal_HT*0.0025)
    const fixe_StripeGimmer = twoDecimals(3+(fixe_GimmerPaid_TTC*0.05))
    const fixe_Stripe_TTC = twoDecimals(fixe_StripeGim + fixe_StripeGimmer)
    const fixe_TVA_Stripe = twoDecimals(fixe_Stripe_TTC * transaction_IVA / (1+ transaction_IVA))
    const fixe_Stripe_HT = twoDecimals(fixe_Stripe_TTC - fixe_TVA_Stripe)
    const fixe_ReportStripe = twoDecimals(100*fixe_Stripe_HT/fixe_GimworkIncome_HT)

    const tip_StripeGim = twoDecimals(tip_GimTotal_HT*0.0025)
    const tip_StripeGimmer = twoDecimals(3+(tip_GimmerPaid_TTC*0.05))
    const tip_Stripe_TTC = twoDecimals(tip_StripeGim + tip_StripeGimmer)
    const tip_TVA_Stripe = twoDecimals(tip_Stripe_TTC * transaction_IVA / (1+ transaction_IVA))
    const tip_Stripe_HT = twoDecimals(tip_Stripe_TTC - tip_TVA_Stripe)
    const tip_ReportStripe = twoDecimals(100*tip_Stripe_HT/tip_GimworkIncome_HT)

 

    return {
        transaction_bonusGim_HT,
        transaction_MalusGimmer_HT,
        transaction_GimmerTotal_HT,     
        transaction_GimmerIVA,
        transaction_GimmerPaid_TTC,

        extraTime_bonusGim_HT,
        extraTime_MalusGimmer_HT,
        extraTime_GimmerIVA,
        extraTime_GimmerTotal_HT,
        extraTime_GimmerPaid_TTC,

        transport_GimmerTotal_HT,
        transport_GimmerIVA,
        transport_GimmerPaid_TTC,

        fixe_bonusGim_HT,
        fixe_MalusGimmer_HT,
        fixe_GimmerTotal_HT,
        fixe_GimmerIVA,
        fixe_GimmerPaid_TTC,

        tip_GimmerTotal_HT,
        tip_GimmerIVA,
        tip_GimmerPaid_TTC,

        transaction_bonusGim,
        transaction_bonusGimmer,
        transaction_RMP_TTC,
        transaction_GimworkTotal_TTC,
        transaction_GimworkIVA,
        transaction_GimworkIncome_HT,

        extraTime_bonusGim,
        extraTime_bonusGimmer,
        extraTime_RMP_TTC,
        extraTime_GimworkTotal_TTC,
        extraTime_GimworkIVA,
        extraTime_GimworkIncome_HT,

        transport_Gimwork_RMP_TTC,
        transport_GimworkTotal_TTC,
        transport_GimworkIVA,
        transport_GimworkIncome_HT,

        fixe_bonusGim,
        fixe_bonusGimmer,
        fixe_RMP_TTC,
        fixe_GimworkTotal_TTC,
        fixe_GimworkIVA,
        fixe_GimworkIncome_HT,

        tip_RMP_TTC,
        tip_GimworkTotal_TTC,
        tip_GimworkIVA,
        tip_GimworkIncome_HT,

        transaction_MalusGim_TTC,
        transaction_bonusGimmer_TTC,
        transaction_GimTotal_HT,
        transaction_GimIVA,
        transaction_GimISR,
        transaction_GimIncome_TVA,
        transaction_ReportGimwork,

        extraTime_MalusGim_TTC,
        extraTime_bonusGimmer_TTC,
        extraTime_GimTotal_HT,
        extraTime_GimIVA,
        extraTime_GimISR,
        extraTime_GimIncome_TVA,
        extraTime_ReportGimwork,

        transport_GimIVA,
        transport_GimISR,
        transport_GimTotal_HT,
        transport_GimIncome_TVA,
        transport_ReportGimwork,

        fixe_MalusGim_TTC,
        fixe_bonusGimmer_TTC,
        fixe_GimTotal_HT,
        fixe_GimIVA,
        fixe_GimISR,
        fixe_GimIncome_TVA,
        fixe_ReportGimwork,

        tip_GimTotal_HT,
        tip_GimIVA,
        tip_GimISR,
        tip_GimIncome_TVA,
        tip_ReportGimwork,

        transaction_TaxGimmer,
        transaction_TaxGim,
        transaction_TaxGimwork,
        transaction_TaxTotal,
    
        extraTime_TaxGimmer,
        extraTime_TaxGim,
        extraTime_TaxGimwork,
        extraTime_TaxTotal,
    
        transport_TaxGimmer,
        transport_TaxGim,
        transport_TaxGimwork,
        transport_TaxTotal,
    
        fixe_TaxGimmer,
        fixe_TaxGim,
        fixe_TaxGimwork,
        fixe_TaxTotal,
    
        tip_TaxGimmer,
        tip_TaxGim,
        tip_TaxGimwork,
        tip_TaxTotal,

        transaction_StripeGim,
        transaction_StripeGimmer,
        transaction_Stripe_TTC,
        transaction_TVA_Stripe,
        transaction_Stripe_HT,
        transaction_ReportStripe,
    
        extraTime_StripeGim,
        extraTime_StripeGimmer,
        extraTime_Stripe_TTC,
        extraTime_TVA_Stripe,
        extraTime_Stripe_HT,
        extraTime_ReportStripe,
    
        transport_StripeGim,
        transport_StripeGimmer,
        transport_Stripe_TTC,
        transport_TVA_Stripe,
        transport_Stripe_HT,
        transport_ReportStripe,
    
        fixe_StripeGim,
        fixe_StripeGimmer,
        fixe_Stripe_TTC,
        fixe_TVA_Stripe,
        fixe_Stripe_HT,
        fixe_ReportStripe,
    
        tip_StripeGim,
        tip_StripeGimmer,
        tip_Stripe_TTC,
        tip_TVA_Stripe,
        tip_Stripe_HT,
        tip_ReportStripe,
/*
        product_GimmerTotal_HT,
        product_GimmerIVA,
        product_GimmePaid,
        product_Gimwork_RMP,
        product_GimworkTotal_TTC,
    
        product_GimworkIVA,
        product_GimworkIncome_HT,
    
        product_GimTotal_HT,
        product_GimIVA,
        product_GimISR,
        product_GimIncome_TVA,
        report_ReportGimwork,
    
        product_TaxGimmer,
        prodcut_TaxGim,
        product_TaxGimwork,
        product_TaxTotal,
    
        product_StripeGim,
        product_StripeGimmer,
        product_Stripe_TTC,
        product_TVA_Stripe,
        product_Stripe_HT,
        product_ReportStripe,
        */

    }
}