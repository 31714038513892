import { ChangeEvent } from "react"
import { MyYup } from "./myYup"

export type InputData = {
    error: string,
    value: string,
    touched: boolean,
    checkValue: (x: string | number, dv?: boolean) => MyYup

    group?: string 
}

export type ValuesForm = {
   [key: string]: InputData
}


export const checkErrors = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputValues: ValuesForm, setInputValues: React.Dispatch<React.SetStateAction<ValuesForm>>, checkDependentValue?: boolean) => {
    const value = e.target.value
    const result = inputValues[e.target.name].checkValue(value, checkDependentValue)
    if(result.errorMsg && (checkDependentValue || checkDependentValue === undefined ))
        setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
    else
        setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
}

export const checkErrorsWhenSubmit = (nameInput: string, inputValues: ValuesForm, checkDependentValue?: boolean): boolean => {
    const value = inputValues[nameInput].value
    const result = inputValues[nameInput].checkValue(value, checkDependentValue)
    if(result.errorMsg && (checkDependentValue || checkDependentValue === undefined )){
        inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
        return true
    }else{
        inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
        return false
    }
}

