import React, { useEffect, useState } from 'react'
import { Coords, getLocalitation, requestUserPosition } from '../../../utils/maps/locations'

const useGetAddressInfo = () => {

    const [address, setAddress] = useState("")

    useEffect(() => {

        const getData = () => {

            requestUserPosition()
            .then((coords: Coords) => {
                getLocalitation(coords.latitude, coords.longitude)
                .then((data) => {
                    setAddress( data.results[0]?.formatted_address )
                }).catch(() => {

                })
            }).catch((error) => {
                console.log("Error: ", error)

            })
        }

        getData()
      
    }, [])

    return [address]
}

export default useGetAddressInfo