import React from 'react'

type MessageBugReportProps = {
    text: string
    onPress: () => void
}

const MessageBugReport = ({ text, onPress }: MessageBugReportProps) => {
    return (
        <div className="p-absolute width-100 height-100 white-bg center flex-d-column" style={{ zIndex: 2 }}>
            <p className="fs-20 fw-600">{text}</p>

            <button
                onClick={onPress}
                className="dark-gray-bg border-radius-3 fw-600 c-white mt-20 fs-22 pl-20 pr-20 pt-10 pb-10 center pointer"
            >
                Go Back
            </button>
        </div>
    )
}

export default React.memo( MessageBugReport )