import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Language } from '../../../utils/staticVariables/staticVariables';
import "./PublicNavBar.css"
import ModalLanguageCurrency from '../../Language/ModalLanguageCurrency/ModalLanguageCurrency';
import { setShowModalLanguageCurrency } from '../../../redux/slices/authSlice';
import { useAppDispatch } from '../../../redux/store/store';

const PublicNavBar = () => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const openLanguageCurrencyModal = () => {
        dispatch( setShowModalLanguageCurrency(true) )
    }
    

    return (
        <header className="navbar-login center width-100">

            <div className="flex flex-ai-center cotainer-login height-100 width-100">
                
                <Link to="/" className="img-navbar-logo-big">
                    <img className="navbar-logo" src={"./assets/images/LOGO.svg"} alt="Logo" />
                </Link>


                <div className="flex flex-1 flex-jc-end flex-ai-center height-100">

                    
                    <button onClick={openLanguageCurrencyModal}>
                        <img 
                            id="globe-btn"
                            src="/assets/svg/globe.svg" 
                            alt="globe" 
                            className="globe-img mr-24 pointer" 
                        />
                    </button>
                </div>

            </div>
            <ModalLanguageCurrency isLogged={false} />
        </header>
    )
}

export default React.memo( PublicNavBar )