import { Hub } from 'aws-amplify/utils';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import "./SignInProvidersListener.css"
import { useTranslation } from 'react-i18next';

const SignInProvidersListener = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [searchParams] = useSearchParams();
    const [showMessageError, setShowMessageError] = useState(false)

    //https://localhost:3000/?error_description=PreSignUp+failed+with+error+Email+already+registered.+&state=qi6cktUYxXq63hgI8ZA8bScVQWX7NnBD&error=invalid_request
    useEffect(() => {
        const errorDescription = searchParams.get("error_description")
        if(errorDescription){
                //navigate("/stage-react")
                //window.location.href= `${process.env.REACT_APP_DOMAIN_URL}/stage-react`;
                //alert("This email was already registered, please sign in with it or try with another email")
                setShowMessageError(true)
        }
        //notify()
        // Perform actions based on the new URL
    }, [searchParams.get("error_description")]);

    useEffect(() => {
        
        const unsubscribe = Hub.listen("auth", ({ payload }) => {
            switch (payload.event) {
              case "signInWithRedirect":
                    console.log("signInWithRedirect")
                break;
              case "signInWithRedirect_failure":
                console.log("signInWithRedirect_failure")
                // handle sign in failure
                break;
              case "customOAuthState":
                const state = payload.data; // this will be customState provided on signInWithRedirect function
                console.log("customOAuthState case: ", state);
                break;
            }
        });
    
        return unsubscribe
    }, [])
    
    const onClick = () => {
        setShowMessageError(false)
        navigate("/login")
    }

    return (
        <>
            {
                showMessageError &&
                <div className="background-gray center">
                    <div className="signin-provider-modal border-radius-6">
                        <p className="fs-18 fs-18-lh fw-500 color-text text-justify">{t("SignUp:label_01")}</p>
                        <div className="center mt-40">
                            <button
                                onClick={onClick}
                                className="dark-gray-bg border-radius-3 pl-20 pr-20 pt-13 pb-13 pointer"
                            >
                                <p className="fs-18 fw-500 c-white">{t("SignUp:label_02")}</p>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default React.memo( SignInProvidersListener )