import React from 'react'

type BarProgressSignUpProps = {
    percentageBar: number
}

const BarProgressSignUp = ({ percentageBar }: BarProgressSignUpProps) => {

    const heightBar = 1

    return (
        <div
            style={{
                borderRadius: `${heightBar}rem`,
                height: `${heightBar}rem`,
                backgroundColor: "#E6E6E6",
                width: "100%"
            }}
        >
            <div 
                style={{
                    borderRadius: `${heightBar}rem`,
                    height: `${heightBar}rem`,
                    backgroundColor: "#3A4153",
                    width: `${percentageBar}%`
                }}
            >
            </div>
        </div>
    )
}

export default React.memo( BarProgressSignUp )