import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { BsFillTriangleFill } from 'react-icons/bs';
import "./YourStatistics.css"

interface YourStatisticsProps {
    imagesRemaining: string
}

const YourStatistics = ({ imagesRemaining }:YourStatisticsProps ) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-d-column flex-ai-center">
        <p className="your-statistics-profile-title fw-500">{t("General:label_058")}</p>
        <button className="remaining-profiles-imgs-btn flex flex-d-column flex-ai-center">
            <span className="fs-28 fw-500 mb-4">{ imagesRemaining }</span>
            <span className="fs-14">{t("General:label_059")}</span>
        </button>

        <button className="btn-keys-verification mt-30">
            <FontAwesomeIcon icon={solid("triangle")} className="fs-10 c-white triangle-right" />
        </button>
        <p className="fs-14 mt-8">{t("General:label_056")}</p>

        <button className="btn-keys-verification mt-30">
            <FontAwesomeIcon icon={solid("triangle")} className="fs-10 c-white triangle-down" />
        </button>
        <p className="fs-14 mt-8">{t("General:label_057")}</p>

        <button className="btn-keys-verification mt-30">
            <FontAwesomeIcon icon={solid("triangle")} className="fs-10 c-white triangle-left" />
        </button>
        <p className="fs-14 mt-8">{t("General:label_061")}</p>

    </div>
  )
}

export default YourStatistics