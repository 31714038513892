import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Offer } from './manageOffersDataSlice';


const initialState = {
  offer: {} as Offer,
  imgUrl: "",
  show: false
}

export const manageDetailedOfferSlice = createSlice({
  name: 'manageDetailedOffer',
  initialState,
  reducers: {
    setDetailedOfferReducer: (state, action: PayloadAction<{ offer: Offer, imgUrl: string }>) => {
        state.offer = action.payload.offer
        state.imgUrl = action.payload.imgUrl
        state.show = true    
        return state
    },
    resetDetailedOfferReducer: (state) => {
        return initialState
    }
  },
});

// Action creators are generated for each case reducer function
export const {  
    setDetailedOfferReducer, 
    resetDetailedOfferReducer
} = manageDetailedOfferSlice.actions

export default manageDetailedOfferSlice.reducer