import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import HistoryRow from "./HistoryRow/HistoryRow";
import { HistoryExtraData } from "../../../redux/slices/components/MyHistory/myHistoryDataSlice";
import React from "react";

const HistoryRows = () => {
  const historyToShow = useSelector((state: RootState) => state.myHistoryData.historyToShow);
  const loadingContainer = useSelector((state: RootState) => state.myHistoryData.loadingContainer);

  return (
    <div className="p-relative flex flex-1 flex-d-column ff-roboto height-100">
      <div className="aux-scroll-offers">

            { loadingContainer && <LoadingSpinner fullPage={false} /> }
            {
                historyToShow.map((history: HistoryExtraData, index: number) => {
                    return(
                        <HistoryRow
                            key={history.id}
                            history={history}
                        />
                    )
                })
            }
        </div>
    </div>
  )
}

export default React.memo( HistoryRows )