import React, { useEffect, useMemo, useRef } from 'react'
import "./MessageUserReport.css"
import { useSelector } from 'react-redux'
import { UserReportType, setMessageAsViewedReducer } from '../../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { Message } from '../../../../../../../utils/dbModels'
import { RootState, useAppDispatch } from '../../../../../../../redux/store/store'
import { UpdateChatMessage } from '../../../../../../../utils/chats/ChatFunctions'
import { GimGimmer } from '../../../../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import moment from 'moment'


type MessageUserReportProps = {
    message: Message
    type: UserReportType
    checkView: boolean
    gimGimmer: GimGimmer
    userId: string 
    isFirstGroupMessage?: boolean
    requestImgUrl: string
    personInvolvedUrl: string
    supportImgUri: string
}

const MessageUserReport = ({
    message,
    type,
    checkView,
    gimGimmer,
    userId,
    isFirstGroupMessage,
    requestImgUrl,
    personInvolvedUrl,
    supportImgUri,
}: MessageUserReportProps) => {

    const dispatch = useAppDispatch()

    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if(checkView){
            UpdateMessageState()
        }
    }, [checkView])
    

    const UpdateMessageState = async () => {
        //console.log(" ")
        //console.log("mes", message.content, message.id)
        //console.log("ccc", message.viewed, message.userID, userId)
        // message.userID !== userId : user who sent the message === other user of tab chat (not support)
        if (message.viewed || message.userID !== userId) {
            return;
        }
        UpdateChatMessage(message.id).then(() => {
            //reduceNewMessage();
            //setViewed(true);
            dispatch( setMessageAsViewedReducer({ messageId: message.id, type }) )
            setTimeout(() => {
                
                
            }, 3000);
        }).catch(() => {

        })
    };

    //console.log("message.content", message.content)
    return (
        <div ref={ref} className={`aux-con-message-report mt-10 flex ${message.userID === userId ? "message-left" : "message-right"}`}>
                
            <div className="con-message-img">
                {
                    isFirstGroupMessage && message.userID === userId &&
                    <img 
                        src={(type === "RequestBy" ? requestImgUrl : personInvolvedUrl) ?? "/user.svg"} 
                        className="img-message-user circle" 
                        onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }}    
                    />
                }
            </div>
            
            <div className="con-message-date">
                <div className={`message-user-report border-radius-6 pr-20 pl-20 center ${message.userID === userId ? (gimGimmer === "Gim" ? "gim-bg" : "gimmer-bg" ) : "white-bg"  }`} >
                    <p className={`fs-13 fw-500 ${message.userID === userId ? "c-white" : "color-text" }`} >{message.content}</p>
                </div>
                <div className="flex flex-ai-center mt-2">
                    <p className={`flex-1 fs-11 color-text ${message.userID === userId ? "text-align-left" : "text-align-right" }` }>
                        {moment(message.createdAt).fromNow()}
                    </p>
                    {
                        message.userID !== userId && message.viewed &&
                        <img 
                            src={type === "RequestBy" ? requestImgUrl : personInvolvedUrl ?? "/user.svg"} 
                            className="img-message-user-seen circle ml-6" 
                            onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }}    
                        />
                    }
                </div>
            </div>

        
            
            <div className="con-message-img flex flex-jc-end">
                {
                    isFirstGroupMessage && message.userID !== userId &&
                    <img 
                        src={"/icon.ico"} 
                        className="img-message-user circle" 
                        onError={(e) => { 
                        //(e.target as HTMLImageElement).src = "/user.svg" 
                            }
                        }    
                    />
                }

            </div>
        
        </div>
    )
}

export default React.memo( MessageUserReport )