import axios, { AxiosRequestConfig } from "axios"
import {
    adminApiUrl,
    getUserCredentials
} from "../../axiosUtils"

export type NotifEmailUserBody ={
    sendEmail: boolean,
    message: string,
    userId: string,
    titleNotification: string,
    descripNotification: string,
    data: {
        receivedFromType: string
    },
    emailFields?: {
      subject: string,
      emails: string[]
    }
}

export const notifEmailUser = (body: NotifEmailUserBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.post(`${adminApiUrl}/v1/notify/notif-email-user`, body, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/job-category`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



