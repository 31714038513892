import React from 'react'
import "../buttonsLogin.css"
import { useTranslation } from 'react-i18next'

type ButtonEmailProps = {
    onPress: () => void
}

const ButtonEmail = ({ onPress }: ButtonEmailProps) => {

    const { t } = useTranslation()

    return (
        <button type="button" onClick={onPress} className="login-button flex flex-ai-center flex-jc-c">
            <p className="fs-18 c-text">
                {t("SignIn:label_05")}
            </p>
        </button>
    )
}

export default React.memo( ButtonEmail )