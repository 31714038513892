import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react'
import "./GeneralInputTitle.css"

type GeneralInputTitleProps = {
    title: string
    name: string
    value: string
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void

    readOnly?: boolean
    labelBgColor?: string
    type?: HTMLInputTypeAttribute
    step?: number | string
    width?: string
    error?: string
    placeholder: string | null
    textArea?: { height: string }
}

const GeneralInputTitle = ({ title, name, value, onChange, onFocus, labelBgColor, step, width, error, placeholder, type, textArea, readOnly }: GeneralInputTitleProps) => {
    return (
        <div className="flex flex-d-column" style={{ width: width ? width : "auto" }}>
            <div className="p-relative padding-to-show-error">
                <label 
                    style={{
                        position: "absolute",
                        left: ".8rem",
                        top: "-.1rem",
                        backgroundColor: labelBgColor ?? "white"
                    }} 
                    htmlFor={name}
                    className="c-oslo-gray fs-15-vh white-bg pl-6 pr-6"
                >
                    {title}
                </label>
                {
                    textArea ?
                    <textarea
                        id={name}
                        name={name}
                        placeholder={placeholder ?? ""}
                        style={{ height: textArea.height, paddingTop: "1vh" }}
                        className="input-modal width-100 resize-none fs-15-vh"
                        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                onChange(e)
                        }}
                        value={value}
                        onFocus={(e:any) => { onFocus(name) }}
                        readOnly={readOnly}
                    />
                    :
                    <input
                        id={name}
                        name={name}
                        type={type ?? "text"}
                        step={step} // for 24 hours
                        placeholder={placeholder ?? ""}
                        className="input-modal width-100 fs-15-vh"
                        onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                onChange(e)
                        }}
                        value={value}
                        onFocus={(e:any) => { onFocus(name) }}
                        readOnly={readOnly}
                    />
                }
                {error && (
                    <p className="error-input-text">{error  || ""}</p>
                )}
            </div>
        </div>
    )
}

export default React.memo( GeneralInputTitle )