import { faCheckCircle, faCircleX, faHandHoldingUsd, faHandshake } from '@fortawesome/pro-solid-svg-icons';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GenericNotification from '../../GenericNotification';
import moment from 'moment';
import { Colors } from '../../../../../utils/colors/colors';
import { useNavigate } from 'react-router-dom';
import { capitalize } from '../../../../../utils/strings/strings';
import { GimGimmer } from '../../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions';
import useGetUserInfo from '../../../../hooks/useGetUserInfo/useGetUserInfo';

interface RequestNotificationProps {
    item: {
        id: string;
        notifMsg: string;
        timestamp: number;
        viewed: boolean;
        iso_timestamp: string | number
        notifData: {
            receivedFromType: string;
            startTime: string
            date: string
            fromUserId: string
            type: GimGimmer
            jobName: string
        };
    };
    onSetNotificationAsView: (id: string) => void;
}

const RequestNotification = ({
    item,
    onSetNotificationAsView,
}: RequestNotificationProps) => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate()

    const { name } = useGetUserInfo(item.notifData.fromUserId)

    const {
        id,
        notifData,
        timestamp,
        viewed,
    } = item;

    const touchNotification = useCallback(() => {
        navigate('OpportunityInfo', {
			//requestId: item.notifData.requestId,
			//notifId: item.id,
        });
        if (!viewed) {
            onSetNotificationAsView(id);
        }
    }, []);

    
    const getTime = () => {
        const date = notifData?.startTime ?? item.notifData?.date
        const month = capitalize(moment(date).format("MMMM"))
        const day = capitalize(moment(date).format("DD"))
        let text = ""
        if (i18n.language === "es") {
            text = `${day} de ${month}`
        } else {
            text = `${day} ${month}`
        }
        return text;
    }

    const description = item.notifData.type === 'Gimmer'
    ? `${name} ${t('Notifications:index:label_4',)} ${t(`Jobs:${item.notifData.jobName.replace(/\s/g,'',)}`,)} ${t('Notifications:index:label_5',)} ${getTime()}!`
    : `${name} ${t('Notifications:index:label_6',)} ${t(`Jobs:${item.notifData.jobName.replace(/\s/g,'',)}`,)} ${t('Notifications:index:label_5',)} ${getTime()}!`

    return (
        <GenericNotification
            touchNotification={touchNotification}
            userIdImg={notifData.fromUserId}
            id={id}
            timestamp={moment().valueOf()}
            viewed={viewed}
            description={description}
            title={notifData.type === 'Gimmer' ? t('Notifications:index:label_50') : t('Notifications:index:label_3')}
            icon={notifData.type === 'Gimmer' ? faHandshake : faHandHoldingUsd}
            color={notifData.type === 'Gimmer' ? Colors.secondaryColor : Colors.mainColor}
        />
    );
};

export default React.memo( RequestNotification );
