
export type OrderStateProps = {
    orderDirection: boolean, 
    orderColumn: string, 
    array: any[]
  }
  
  export const getOrderedState = ({ orderDirection, orderColumn, array }: OrderStateProps) =>{
      if(array.length === 0)
          return array
        let counter = 0;
      const newArray = array.toSorted((a: any, b: any) => {
          //console.log("a", a, orderColumn, orderDirection)
          //console.log("counter", counter)
          counter++

          const A = a[orderColumn] ?? ""
          const B = b[orderColumn] ?? ""
          //console.log("A B", A[0], B[0])
          if(Array.isArray( a[orderColumn])){
            //console.log("hi")
                return A[0] > B[0] ? 1 : -1
          }else{
              return A > B ? 1 : -1
          }
      })
      if(!orderDirection)
          newArray.reverse()
      //console.log("OK")
      return newArray
  } 
  