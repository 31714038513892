import React, { useState } from 'react'
import { useAppDispatch } from '../../../../../redux/store/store';
import { orderByCategoryNameReducer, SortEvent } from '../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import Icon from '../../../../../utils/icons/Icon'
import { S3DomainGimwork_country_flags_w80 } from '../../../../../utils/staticVariables/staticVariables'
import "./HeaderJobDropdowns.css"

interface HeaderJobDropdownsProps {
  countryName: string,
  orderJob?: SortEvent
}

const HeaderJobDropdowns = ({ countryName, orderJob }: HeaderJobDropdownsProps) => {

    const dispatch = useAppDispatch()

    const [animationArrow, setAnimationArrow] = useState<string>("")

    const handleSortCategoryName = () => {
        if(orderJob?.sortByCategoryName === "asc"){
            dispatch(orderByCategoryNameReducer({ countryName, sortByCategoryName: "des"}))
        }else{
            dispatch(orderByCategoryNameReducer({ countryName, sortByCategoryName: "asc"}))
        }
    }

    return (
        <div className="container-header-job-dropdown">
            <div 
                className="img-flag-dropdown"
                style={{ backgroundImage: `url("${S3DomainGimwork_country_flags_w80}${countryName}.png")` }}>
            </div>
            <p className="fw-600 fs-20 ml-16 ff-roboto">{countryName}</p>
            
            <div 
                className="con-A-Z-icon"
                onMouseEnter={() => {
                    setAnimationArrow("out")
                }}

                onMouseLeave={() => {
                    setAnimationArrow("in")
                }}
            >
                <div className={`A-Z fs-16 
                    ${animationArrow === "out" ? "c-black" : "c-manatee"}`}
                >
                    A-Z
                </div>
                <div 
                    className={`con-arrow-sort pointer
                        ${animationArrow === "in" && "animation-in"}
                          ${animationArrow === "out" && "animation-out"}
                    `}
                    onClick={handleSortCategoryName}
                >
                    <Icon 
                        name={orderJob?.sortByCategoryName === "asc" ? "AiOutlineArrowDown" : "AiOutlineArrowUp"} 
                        cssClass={`little-sort-header
                          ${animationArrow === "out" ? "c-black" : "c-manatee"}
                        `}
                    />
                </div>
            </div>

        </div>
    )
}

export default HeaderJobDropdowns