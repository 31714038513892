import { useTranslation } from 'react-i18next';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { useAppDispatch } from '../../../../redux/store/store';
import "./CancelButton.css"

type CancelButtonProps = {
   onPress: () => void
}

const CancelButton = ({ onPress }: CancelButtonProps) => {
  const { t } = useTranslation();
  return (
    <button type="button" onClick={onPress} className="cancel-btn-modal pointer">
        {t("General:label_015")}
    </button>
  )
}

export default CancelButton