import { ActionReport } from "../../redux/slices/components/ManageReports/manageReportsDataSlice"
import { MessageType } from "../chats/ChatFunctions"
import { GimGimmer } from "../requestsUtils/functionsRequests/offers/offersFunctions"

export type StatusReport = "OPENED" | "CLOSED" | "PENDING" | "ONPROGRESS"
export type StatusReportBug = "UPLOADED" | "OPENED" | "CLOSED" | "PENDING" | "TESTING"

export const statusReportValues: StatusReport[] = ["OPENED", "CLOSED", "PENDING"]
export const statusReportBugValues: StatusReportBug[] = ["UPLOADED", "OPENED", "CLOSED", "PENDING", "TESTING" ]

export type PriorityReport = "HIGH" | "MEDIUM" | "LOW"
export type Support = "Support_1" | "Support_2" | "Support_3" | "Support_Admin"
export type SupportHierarchy = 1 | 2 | 3 | 4

export type MovementSupport = {
    start: string
    end?: string
    actions: ActionReport[]
    userId: string,
    supportRole: Support
}
export interface UserReport {
    id: string
    actions: any
    timestamp: string
    description: string
    personsInvolved: string[]
    reason: string
    requestBy: string
    status: StatusReport
    userAssigned?: string
    support: SupportHierarchy
    seen: boolean
    openIncident: boolean
    movements?: MovementSupport[]
    requestByUserType: GimGimmer
    transactionId: string
}

export type DatesIncident = {
    startTime: string
    endTime?: string
}

export type MessageBugReport = {
    id: string
    messageText: string
    createdAt: string
    viewed: boolean
    userId: string 
    isFirstGroupMessage?: boolean
}

export type HistoryEvent = "ACCEPT" | "CLOSED" | "FIX_REJECTED" | "READY_FOR_TESTING" | "ASSIGNED"

export type HistoryBugReport = {
    userId: string 
    date: string 
    event: HistoryEvent
}

export interface BugReport {
    id: string
    timestamp: string
    description: string
    priority: PriorityReport
    subject: string
    requestBy: string
    status: StatusReportBug
    userAssigned: string
    testerAssigned?: string
    messages?: MessageBugReport[]
    history?: HistoryBugReport[]
    title: string
    datesIncident?: DatesIncident
}

//export type MessageType = "Message"

// DB MODEL dynamo
export type Message = {
    id: string
    __typename: MessageType
    chatRoomID: string
    content: string
    information: any
    createdAt: string
    updatedAt: string
    userID: string
    viewed: boolean
    isFirstGroupMessage?: boolean // just for web, ot in model
    isLastMessageFromOtherUser?: boolean
}