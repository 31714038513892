import axios, { AxiosError, AxiosRequestConfig } from "axios"
import {
    adminApiUrl,
    getUserCredentials
} from "../../axiosUtils"
import { UserForm } from "../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice"

type GetUsersBody = {
    LastEvaluatedKey?: string,
    limit?: number,
    bringTotal: boolean
}

export const getUsersForm = (params: GetUsersBody) => {
    return new Promise((resolve: (res: { results: UserForm[], total: number, LastEvaluatedKey?: string }) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params
            };
            axios.get(`${adminApiUrl}/v1/user/form`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/form`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteUserForm = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.delete(`${adminApiUrl}/v1/user/form/${id}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/form/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteUsersForm = (ids: string[]) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                data: { ids }
            };
            axios.delete(`${adminApiUrl}/v1/user/form`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/user/form`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
