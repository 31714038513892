import { useEffect } from 'react'
import { useAppDispatch } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import HeaderPicture from '../../../../components/VerifyPicture/HeaderPicture/HeaderPicture'
import SliderPicture from '../../../../components/VerifyPicture/SliderPicture/SliderPicture'
import YourStatistics from '../../../../components/VerifyPicture/YourStatistics/YourStatistics'
import "./VerifyPictureScreen.css"
import { getProfileUsersImagesThunk, resetVerifyPictureImageReducer } from '../../../../redux/slices/components/VerifyPicture/verifyPictureDataSlice'
import LoadingSpinner from '../../../../components/LoadingSpinner'





const VerifyPictureScreen = () => {
  
  const dispatch = useAppDispatch()

  const sliderData = useSelector((state: RootState) => state.verifyPictureData.sliderData);
  const lastUserVerified = useSelector((state: RootState) => state.verifyPictureData.lastUserVerified);
  const loadingContainer = useSelector((state: RootState) => state.verifyPictureData.loadingContainer);

  useEffect(() => {
      dispatch(getProfileUsersImagesThunk())
      return () => {
        dispatch(resetVerifyPictureImageReducer())
      }
  }, [])

  return (
    <div className="flex p-relative height-100">
        <div>
            <HeaderPicture lastUserVerified={lastUserVerified} />

            <div className="p-relative"> 
                { loadingContainer && <LoadingSpinner fullPage={false} /> }
                <SliderPicture 
                    sliderData={sliderData} 
                    lastUserVerified={lastUserVerified}
                />
            </div> 
    
        </div>
        <YourStatistics imagesRemaining={sliderData.length.toString()} />
    </div>
  )
}

export default VerifyPictureScreen