import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs, { DataInputRowJob } from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowPrincipalProps = {
    inputValues: ValuesForm
    isDay: boolean
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}


const RowPrincipal = ({ inputValues, isDay, onChange, onFocus }: RowPrincipalProps) => {

    const { t } = useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "price_Day",                placeholder: t("ManageJobs:label_08"),  width: "13%",   type: "text",   step: undefined },
        { name: "malusGim_Day",             placeholder: "Malus Gim",               width: "13%",   type: "text",   step: undefined },
        { name: "malusGimmer_Day",          placeholder: "Malus Gimmer",            width: "13%",   type: "text",   step: undefined },
        { name: "malusShareGim_Day",        placeholder: "Share Gim",               width: "13%",   type: "text",   step: undefined },
        { name: "malusShareGimmer_Day",     placeholder: "Share Gimmer",            width: "13%",   type: "text",   step: undefined },
        { name: "IVA_Day",                  placeholder: "IVA",                     width: "13%",   type: "text",   step: undefined },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "price_Night",                  placeholder: t("ManageJobs:label_08"),  width: "13%",   type: "text",   step: undefined },
        { name: "malusGim_Night",               placeholder: "Malus Gim",               width: "13%",   type: "text",   step: undefined },
        { name: "malusGimmer_Night",            placeholder: "Malus Gimmer",            width: "13%",   type: "text",   step: undefined },
        { name: "malusShareGim_Night",          placeholder: "Share Gim",               width: "13%",   type: "text",   step: undefined },
        { name: "malusShareGimmer_Night",       placeholder: "Share Gimmer",            width: "13%",   type: "text",   step: undefined },
        { name: "IVA_Night",                    placeholder: "IVA",                     width: "13%",   type: "text",   step: undefined },
    ], []);
    


    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color={"#E6E6E6"}
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_01")}
                color={"#E6E6E6"}
            />
        </RowInputs>
    )
}

export default RowPrincipal