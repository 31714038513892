import React from 'react'

type NextBlockProps = {
    onPress: () => void
}

const NextBlock = ({ onPress }: NextBlockProps) => {
    return (
        <div className="width-100 flex flex-jc-end flex-1" style={{ paddingBottom: "8.7vh" }}>
            <button 
                onClick={onPress}
                className="dark-gray-bg border-radius-3 pl-20 pr-20 c-white fw-500 fs-20-vh"
                style={{ height: "4.2vh" }}
            >
                {"Next"}
            </button>
        </div>
    )
}

export default React.memo( NextBlock )