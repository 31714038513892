import React, { useEffect, useState } from 'react'
import "./StatisticsUser.css"
import MoneyEarnedCard from './MoneyEarnedCard/MoneyEarnedCard'
import TransactionsCard from './TransactionsCard/TransactionsCard'
import { getTransactionHistory } from '../../../utils/requestsUtils/functionsRequests/transactions/history'
import { HistoryTransaction } from '../../../utils/dbModels/HistoryTransaction'
import moment from 'moment-timezone'
import { RootState } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Timezone = moment.tz.guess();

export type MoneyCardsData = {
    moneyEarned: number
    jobsNumber: number
    bestSkill: string,
    topPursued: string
}

export type ChartIncomes = { nameMonth: string, label: string, amount: number }

const StatisticsUser = () => {

    const { t } = useTranslation()

    const userId = useSelector((state: RootState) => state.auth.user.id)

    const [chartIncomes, setChartIncomes] = useState<ChartIncomes[]>([])
    const [lastFourTransactions, setLastFourTransactions] = useState<HistoryTransaction[]>([])
    const [moneyCardsData, setMoneyCardsData] = useState<MoneyCardsData>({
        moneyEarned: 0,
        jobsNumber: 0,
        bestSkill: "",
        topPursued: ""
    })

    const getPreviousMonths = (): ChartIncomes[] => {
        const currentDate = moment.utc().tz(Timezone)
        const dates: ChartIncomes[] = []
        for(let i=0; i < 12; i++){
            dates.push({ 
                nameMonth: (currentDate.format("MMMM")).substring(0,3).toUpperCase(), 
                amount: 0, 
                label: `${currentDate.year()}-${currentDate.month()}`, 
                //amounts: []
            })
            currentDate.subtract(1, 'month').month()
        }
        return dates
    }

    const getOrderedTransactionByMonth = (items: HistoryTransaction[]): ChartIncomes[] => {
        const dates = getPreviousMonths()
        const auxObjCounter: { [key: string]: HistoryTransaction[] } = {}
        dates.forEach((date) => { auxObjCounter[date.label] = [] })

        items.forEach((h) => {
            const dateTransaction = moment(h.schedule.startTime)
            const month = dateTransaction.month()
            const year = dateTransaction.year()
            const strDate = `${year}-${month}`
            if(strDate in auxObjCounter){
                auxObjCounter[strDate].push(h)
            }
        })

        dates.forEach((date) => {
            let counter = 0
            auxObjCounter[date.label].forEach((h) => { 
                if(typeof h.gimProfit === "number")
                    counter += h.gimProfit 
                else
                    console.log(`BAD_VALUE_GIMPROFIT: ${h.gimProfit}`)
            })

            date.amount = Number( counter.toFixed(2) )
        })

        return dates
    }

    useEffect(() => {
        getTransactionHistory("All", 2000)
        .then(({ items }: {items: HistoryTransaction[]}) => {

            const startDayOfMonth = moment.utc().tz(Timezone).startOf("month")
            const endDayOfMonth = moment.utc().tz(Timezone).endOf("month")

            const incomesForMonth = getOrderedTransactionByMonth(items)


            const itemsOfMonth = items.sort((a: HistoryTransaction, b: HistoryTransaction) => {
                if(moment.utc(a.schedule.startTime) > moment.utc(b.schedule.startTime))
                    return -1
                else
                    return 1
            }).filter((h: HistoryTransaction) => {
                const dateTransaction = moment.utc(h.schedule.startTime).tz(Timezone)
                return  dateTransaction >= startDayOfMonth && dateTransaction < endDayOfMonth && userId === h.gimId
            }).filter((h, index) => index < 4)

            const jobsData: MoneyCardsData = {
                moneyEarned: 0,
                jobsNumber: 0,
                bestSkill: "",
                topPursued: ""
            }

            const auxCounterJob: any = {}

            itemsOfMonth.forEach((item: HistoryTransaction) => {
                if(typeof auxCounterJob[item.skill.jobName] === "number")
                    auxCounterJob[item.skill.jobName] = auxCounterJob[item.skill.jobName] + 1
                else
                    auxCounterJob[item.skill.jobName] = 1

                jobsData.moneyEarned = jobsData.moneyEarned + item.gimProfit
            })

            let nameMoreWorkedSkillInMonth = t("Profile:transaction:label_02")
            let counter = 0
            for(const nameJob in auxCounterJob){
                if(counter <= auxCounterJob[nameJob])
                    nameMoreWorkedSkillInMonth = nameJob
            }

            jobsData.jobsNumber = itemsOfMonth.length
            jobsData.bestSkill = nameMoreWorkedSkillInMonth
            setMoneyCardsData(jobsData)

            setLastFourTransactions(items.slice(0,5))

            setChartIncomes(incomesForMonth.reverse())
            /*
            const daysOfMonth = [
                { earnedMoney: 0, date: startDayOfMonth }
            ]
            let currentDate = moment.utc().tz(Timezone).add("days", 1).startOf("month")
            while(currentDate < endDayOfMonth){

            }
            */

        }).catch(() => {

        })
    
    }, [])
    
    return (
        <div className="con-statistics-user">
            <MoneyEarnedCard 
                chartIncomes={chartIncomes}
                moneyEarned={moneyCardsData.moneyEarned}
                bestSkill={moneyCardsData.bestSkill}
                jobsNumber={moneyCardsData.jobsNumber}
                topPursued={moneyCardsData.topPursued}
            />
            <TransactionsCard lastFourTransactions={lastFourTransactions} />
        </div>
    )
}

export default React.memo( StatisticsUser )