
export const getChatRoom = /* GraphQL */ `
  query GetChatRoom($id: ID!) {
    getChatRoom(id: $id) {
      id
      chatRoomUsers {
        items {
          id
          userID
          chatRoomID
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        content
        userID
        viewed
        chatRoomID
        otherUserID
        otherUser {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        createdAt
        user {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageID
          createdAt
          updatedAt
        }
        type
        information
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;


export const createChatRoomUser = /* GraphQL */ `
  mutation CreateChatRoomUser(
    $input: CreateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    createChatRoomUser(input: $input, condition: $condition) {
      id
      userID
      chatRoomID
      user {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const createChatRoom = /* GraphQL */ `
  mutation CreateChatRoom(
    $input: CreateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    createChatRoom(input: $input, condition: $condition) {
      id
      chatRoomUsers {
        items {
          id
          userID
          chatRoomID
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        content
        userID
        viewed
        chatRoomID
        otherUserID
        otherUser {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        createdAt
        user {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageID
          createdAt
          updatedAt
        }
        type
        information
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const messagesByChatRoom = /* GraphQL */ `
  query MessagesByChatRoom(
    $chatRoomID: ID!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    messagesByChatRoom(
      chatRoomID: $chatRoomID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content
        userID
        viewed
        chatRoomID
        otherUserID
        otherUser {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        createdAt
        user {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageID
          createdAt
          updatedAt
        }
        type
        information
        updatedAt
      }
      nextToken
    }
  }
`;

export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      userID
      viewed
      chatRoomID
      otherUserID
      otherUser {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      user {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      information
      updatedAt
    }
  }
`;

export const updateChatRoomUser = /* GraphQL */ `
  mutation UpdateChatRoomUser(
    $input: UpdateChatRoomUserInput!
    $condition: ModelChatRoomUserConditionInput
  ) {
    updateChatRoomUser(input: $input, condition: $condition) {
      id
      userID
      chatRoomID
      user {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const updateChatRoom = /* GraphQL */ `
  mutation UpdateChatRoom(
    $input: UpdateChatRoomInput!
    $condition: ModelChatRoomConditionInput
  ) {
    updateChatRoom(input: $input, condition: $condition) {
      id
      chatRoomUsers {
        items {
          id
          userID
          chatRoomID
          createdAt
          updatedAt
        }
        nextToken
      }
      messages {
        items {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        nextToken
      }
      lastMessageID
      lastMessage {
        id
        content
        userID
        viewed
        chatRoomID
        otherUserID
        otherUser {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        createdAt
        user {
          id
          name
          imageUri
          description
          status
          createdAt
          updatedAt
        }
        chatRoom {
          id
          lastMessageID
          createdAt
          updatedAt
        }
        type
        information
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;

export const onNewMessageOnRoom = /* GraphQL */ `
  subscription OnNewMessageOnRoom($chatRoomID: ID!) {
    onNewMessageOnRoom(chatRoomID: $chatRoomID) {
      id
      content
      userID
      viewed
      chatRoomID
      otherUserID
      otherUser {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      user {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      information
      updatedAt
    }
  }
`;

export const onUpdateMessageState = /* GraphQL */ `
  subscription OnUpdateMessageState($chatRoomID: ID!) {
    onUpdateMessageState(chatRoomID: $chatRoomID) {
      id
      content
      userID
      viewed
      chatRoomID
      otherUserID
      otherUser {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      user {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      information
      updatedAt
    }
  }
`;

export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      userID
      viewed
      chatRoomID
      otherUserID
      otherUser {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      user {
        id
        name
        imageUri
        description
        status
        chatRoomUser {
          nextToken
        }
        createdAt
        updatedAt
      }
      chatRoom {
        id
        chatRoomUsers {
          nextToken
        }
        messages {
          nextToken
        }
        lastMessageID
        lastMessage {
          id
          content
          userID
          viewed
          chatRoomID
          otherUserID
          createdAt
          type
          information
          updatedAt
        }
        createdAt
        updatedAt
      }
      type
      information
      updatedAt
    }
  }
`;