import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import ArrowColumn from '../../UIcomponents/ArrosColumn/ArrowColumn';
import "./HeaderTableManageUsersForm.css"
import { checkAllCheckboxesManageUsersForm, orderUserFormsReducer } from '../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice';
import React, { useEffect, useRef, useState } from 'react';


const HeaderTableManageUsersForm = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const page =useSelector((state:RootState) => state.manageUsersForm.page)
    const orderDirection = useSelector((state: RootState) => state.manageUsersForm.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.manageUsersForm.orderColumn)
    const usersToShow = useSelector((state: RootState) => state.manageUsersForm.usersToShow)
    const checkboxUsersGrouped = useSelector((state: RootState) => state.manageUsersForm.checkboxUsersGrouped)

    const previousPage = useRef(page)


    //const orderColumn = "a"
    //const orderDirection = false

    const orderArray = //useCallback(
        ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => { 
            dispatch(orderUserFormsReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
        }//,[orderColumn, orderDirection],)


    const handleCheckbox = (selectAll: boolean) => {
        if(selectAll)
            dispatch(checkAllCheckboxesManageUsersForm(usersToShow))
        else
            dispatch(checkAllCheckboxesManageUsersForm([]))
    }

    useEffect(() => {

    if(previousPage.current !== page){
        previousPage.current = page
        dispatch(checkAllCheckboxesManageUsersForm([]))
    }
    return () => {
    }
    }, [page])

    return (
        <div className="flex con-header-table-user-form ff-roboto mt-24">

            <div className="user-form-item1 center">
                <input 
                    className="pointer"
                    type="checkbox"  
                    checked={checkboxUsersGrouped}
                    onChange={() => {
                        handleCheckbox(!checkboxUsersGrouped)
                    }}
                />
            </div>

            <div className="user-form-item2 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"Name"}
                    orderColumn={orderColumn} 
                    columnName={"firstName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="user-form-item3 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"Phone"}
                    orderColumn={orderColumn} 
                    columnName={"phoneNumber"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="user-form-item4 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"Email Address"}
                    orderColumn={orderColumn} 
                    columnName={"mail"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="user-form-item5 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"Skill"}
                    orderColumn={orderColumn} 
                    columnName={"skillId"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="user-form-item6 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"State"}
                    orderColumn={orderColumn} 
                    columnName={"state"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="user-form-item7 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"City"}
                    orderColumn={orderColumn} 
                    columnName={"city"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            
            <div className="user-form-item8 con-item-header-user-form">
                <ArrowColumn 
                    columnText={"Date"}
                    orderColumn={orderColumn} 
                    columnName={"timestamp"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>


            <div className="user-form-item9 con-item-header-user-form">

            </div>
            
        
        </div>
    )
}

export default React.memo( HeaderTableManageUsersForm )  