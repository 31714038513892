import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { FaTrash } from 'react-icons/fa'
import { Offer } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import {  UserInfoCache } from '../../../../utils/staticVariables/staticVariables'
import "./OwnerInfo.css"

interface OwnerInfoProps {
    offer: Offer,
    imgUrl: string,
    setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
}

const OwnerInfo = ({ offer, imgUrl, setShowDeleteModal}: OwnerInfoProps) => {
    const { t } = useTranslation();
    const userName =  UserInfoCache.userNameCache.get(offer.userId)?.name ?? "" 
    return (
        <div className="con-info-owner-offer p-8 flex flex-d-column flex-jc-sb">

            <div className="">
                <p className="fs-24 fw-600">{t("General:label_020")}</p>

                <div className="flex flex-ai-center mt-8">
                    <div className="img-user-offer-detailed circle" style={{ backgroundImage: `url("${imgUrl}")` }}></div>
                    <p className="fs-18 fw-500 ml-14">{userName}</p>
                </div>
            </div>

            <div className="flex flex-d-column">
                <p className="fs-22 fw-600">{t("General:label_021")}</p>
                <p className="fs-18 fw-500 mt-4 ml-4 mb-20">{offer?.creationDate ?? ""}</p>
                
                <button onClick={() => setShowDeleteModal(true)} className="btn-detailed-delete center pointer">
                    <FontAwesomeIcon icon={regular("trash")} className="fs-18" />
                    <span className="fs-16 fw-500 ml-8">{t("General:label_022")}</span>
                </button>
            </div>

        </div>
    )
}

export default OwnerInfo