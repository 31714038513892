import { useEffect } from 'react'
import HeaderManageUsers from '../../../../components/ManageUsers/HeaderManageUsers/HeaderManageUsers'
import ManageUsersModals from '../../../../components/ManageUsers/ManageUsersModals/ManageUsersModals'
import TableManageUsers from '../../../../components/ManageUsers/TableManageUsers/TableManageUsers'

const ManageUsersScreen = () => {

  return (
    <div className="flex flex-d-column height-100">
        <HeaderManageUsers />
        <TableManageUsers />
        <ManageUsersModals />
    </div>
  )
}

export default ManageUsersScreen