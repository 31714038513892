import React from 'react'

interface TitleModalProps {
    title: string
}

const TitleModal = ({ title }:TitleModalProps) => {
  return (
    <p className="title-modal">{ title }</p>
  )
}

export default TitleModal