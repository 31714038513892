import  React, { useState } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { RootState } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal';
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import CancelButton from '../../../UIcomponents/Modals/CancelButton/CancelButton';
import LoadingSpinner from '../../../LoadingSpinner';
import { useTranslation } from 'react-i18next';
import { removeDeletedProductReducer, resetProductsDBModalsReducer } from '../../../../redux/slices/components/ManageProducts/manageProductsDataSlice';
import "./DeleteProductDBModal.css"
import { deleteProductDB } from '../../../../utils/requestsUtils/functionsRequests/products/productsFunctions';

const DeleteProductDBModal = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const productDB = useSelector((state: RootState) => state.manageProductsData.DeleteProductDBModal);
    const [loading, setLoading] = useState<boolean>(false);


    const handleSubmit = async(e :any) => {
        e.preventDefault();
        setLoading(true)

        try {
            await deleteProductDB(productDB.id)
            dispatch(removeDeletedProductReducer(productDB.id))
        } catch (error) {
            console.log("error", error)
            alert("General:Error")
        }finally{
            setLoading(false)
            closeModal()
        }
        
    }

    const closeModal = () => {
        dispatch(resetProductsDBModalsReducer())
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>
            <DescriptionModal description={`${t("ManageProducts:modals:label_01")} ${productDB?.productName}?`} 
            />
            <br />
            <br />
            <br />
            <div className="flex flex-jc-end">
                <CancelButton onPress={closeModal} />
                <ConfirmButton text={t("General:label_016")} />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default React.memo( DeleteProductDBModal )