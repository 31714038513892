import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { ProductDB, StatusProduct, TypeProduct } from '../Products/ProductsSlice'
import { getAvailableProducts, getValidatedProducts } from '../../../../utils/requestsUtils/functionsRequests/products/productsFunctions'
import { LastEvaluatedKeyProductsDB } from '../ManageProducts/manageProductsDataSlice'


export type ExtraDataRequestProducts = {
    typeProduct: TypeProduct
    LastEvaluatedKey?: LastEvaluatedKeyProductsDB,
    jobId?: string
    page: number,
    limit: number,
}


export const GetRequestProductsDBThunk = createAsyncThunk(
    'requestProductsDataSlice/GetRequestProductsDBThunk',
    async ({ typeProduct, LastEvaluatedKey, page, limit, jobId }: ExtraDataRequestProducts) => {
      try { 
        const params = {
            jobId,
            typeProduct,
            limit, 
            LastEvaluatedKey
        }

        const res = await getAvailableProducts(params)

        return {
          results: res.items as ProductDB[],
          LastEvaluatedKey: res.LastEvaluatedKey,
          typeProduct,
          page,
          limit,
          total: res.total,
          jobId
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as ProductDB[],
          typeProduct,
          page,
          limit,
          total: 0,
          jobId: undefined
        }
      }
    }
)


export const numberRequestProductsByPage: number[] = [ 10, 25, 50, 100 ]

export type ModalNameRequestProducts =  "RequestProductDBModal" | "AddProductDBModal" | ""

type InitialState = {
    stringToSearch:         string
    total:                  number
    indexTab:               number
    limit:                  number
    page:                   number
    typeProduct:            TypeProduct
    productsIds:            LastEvaluatedKeyProductsDB[]
    productsToShow:         ProductDB[]
    LastEvaluatedKey?:      LastEvaluatedKeyProductsDB
    loadingContainer:       boolean
    orderColumn:            string
    orderDirection:         boolean
    jobId?:                 string
    RequestProductDBModal:  ProductDB
    AddProductDBModal:      ProductDB
    modalName:              ModalNameRequestProducts
}

const initialState: InitialState = {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    limit: numberRequestProductsByPage[0],
    page: 1,
    typeProduct: "DIGITAL",
    productsIds: [],
    productsToShow: [],
    LastEvaluatedKey: undefined,
    loadingContainer: false,
    orderColumn: "",
    orderDirection: false,
    jobId: undefined,
    RequestProductDBModal: {} as ProductDB,
    AddProductDBModal: {} as ProductDB,
    modalName: ""
}

export const requestProductsDataSlice = createSlice({
  name: 'requestProductsData',
  initialState,
  reducers: {
    changeIndexTabRequestProductsReducer: (state, action: PayloadAction<{ indexTab: number }>) => {
      state.productsIds = []
      state.indexTab = action.payload.indexTab
      return state
    },
    showRequestProductDBModalReducer: (state) => {
        state.modalName = "RequestProductDBModal"
        return state
    },
    showAddRequestProductModalReducer: (state, action: PayloadAction<ProductDB>) => {
        state.modalName = "AddProductDBModal"
        state.AddProductDBModal = action.payload
        return state
    },
    resetRequestProductsDBModalsReducer: (state) => {
        state.RequestProductDBModal = {} as ProductDB
        state.AddProductDBModal = {} as ProductDB
        state.modalName = ""
        return state
    },
    orderRequestProductsReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
      const { orderColumn, orderDirection  } = action.payload
      state.productsToShow = getOrderedState({ orderColumn, orderDirection, array: state.productsToShow })
      state.orderColumn = orderColumn
      state.orderDirection = orderDirection
      return state
    },
    resetRequestProductsReducer: () => {
      return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetRequestProductsDBThunk.fulfilled, (state, action: PayloadAction<ExtraDataRequestProducts & { total: number, results: ProductDB[] }>) => {
      const { results, typeProduct, page, LastEvaluatedKey, limit, total,jobId } = action.payload  
 
      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          //state.productsIds[skip+i] = results[i].id
          lastIndex = skip+i
      }
        
        state.productsIds = state.productsIds.slice(0,lastIndex+1)
        state.jobId = jobId
        state.productsToShow = results
        state.typeProduct = typeProduct
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey
        state.limit = limit ?? 0
        state.total = total
        state.stringToSearch = ""
        return state
      }).addCase(GetRequestProductsDBThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetRequestProductsDBThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
      
  },

});

// Action creators are generated for each case reducer function
export const {  
    changeIndexTabRequestProductsReducer,
    showRequestProductDBModalReducer,
    showAddRequestProductModalReducer,
    resetRequestProductsDBModalsReducer,
    orderRequestProductsReducer,
    resetRequestProductsReducer
} = requestProductsDataSlice.actions

export default requestProductsDataSlice.reducer