import { solid } from "@fortawesome/fontawesome-svg-core/import.macro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { resetManageUsersModalsReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice"
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import LoadingSpinner from "../../../LoadingSpinner"
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import "./EditUserRoleModal.css"
import InputList from "../../../UIcomponents/InputList/InputList"
import { Role } from "../../../../redux/slices/components/ManageRoles/manageRolesSlice"
import { updateUserRole } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions"
import { updateEditedUsersReducer, updateRoleUsersReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersDataSlice"

const EditUserRoleModal = () => {
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState<boolean>(false)
    const { id, firstName, lastName, role } = useSelector((state: RootState) => state.manageUsersModals.editUserRoleModal)
    const roles = useSelector((state: RootState) => state.manageUsersModals.roles)
    const [roleValue, setRoleValue] = useState<Role>({} as Role)
    const [error, setError] = useState<string>("")

    const closeModal = () => {
        dispatch(resetManageUsersModalsReducer())
    }

    const selectRole = (role: Role) => {
        if(error)
            setError("")
        setRoleValue(role)
    }

    const handleSubmit = (e: any) => {
        e.preventDefault()

        if(!roleValue.id){
            setError("You must select a role")
            return
        }

        setLoading(true)
        updateUserRole(id, { id_Role: roleValue.id })
        .then(() => {
            dispatch( updateRoleUsersReducer({ userId: id, roleId: roleValue.id}) )
            closeModal()
        })
        .catch(() => {
            alert("General:Error updating role")
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <form onSubmit={handleSubmit} className="blank-wrapper-modals flex flex-d-column p-relative" style={{ height: "55vh" }}>
            <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
                <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
            </button>
            <TitleModal title={`${t("General:label_142")} ${firstName} ${lastName} ${t("General:label_143")}`} />

            <label className="fs-14 black fw-600 mt-10 mb-6" htmlFor="role">{t("General:label_138")} </label>
            <InputList text={roleValue?.name || ""} width={"100%"} error={error} >
                {
                    roles.map((r: Role, index: number) => {
                        return(
                            <div key={index} className="input-list-item" onClick={() => selectRole(r)}>
                                <p className="fs-14">
                                    { r.name }
                                </p>
                            </div>
                        )
                    })
                }
            </InputList>

            <div className="flex flex-1"></div>

            <ConfirmButton text={t("General:label_016")} width="100%" />

            { loading && <LoadingSpinner fullPage={true} /> }
        </form>
    )
}

export default EditUserRoleModal