import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../utils/colors/colors'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import PhoneInputMask from '../../../components/Login/PhoneInputMask/PhoneInputMask'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { endSignUp, verifyUserPhoneNumberExists } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { setPhoneNumberSignUp } from '../../../redux/slices/components/SignUp/SignUpSlice'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useTranslation } from 'react-i18next'

type PhoneScreenProps = {
    isPrivate?: boolean
}

const PhoneScreen = ({ isPrivate }: PhoneScreenProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const firstName= useSelector((state: RootState) => state.signUp.data.firstName)
    const lastName= useSelector((state: RootState) => state.signUp.data.lastName)
    const phoneNumberRedux= useSelector((state: RootState) => state.signUp.data.phoneNumber)

    const [phoneNumber, setPhoneNumber] = useState(phoneNumberRedux)
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState("")

    useEffect(() => {
      
        if(!firstName || !lastName)
            navigate("/login")
      
    }, [])
    

    const onNext = () => {

        const completePhoneNumber = `${phoneNumber.code}${phoneNumber.phoneNumber.replace(/\s/g, "")}`

        setLoading(true)
        verifyUserPhoneNumberExists(completePhoneNumber)
        .then(() => {
            setError(t("SignUp:phoneScreen:label_01") ?? "")
        }).catch((error) => {
            if(error.response?.data === "PHONE_NUMBER_NOT_FOUND"){
                if(isPrivate){
                    endSignUp({ firstName, lastName, phoneNumber: completePhoneNumber })
                    .then(() => {
                        dispatch( setPhoneNumberSignUp({ phoneNumber: phoneNumber.phoneNumber, code: phoneNumber.code }) )
                        navigate("/profile-picture")
                    }).catch(() => {
                        setError(t("SignUp:phoneScreen:label_02") ?? "")
                    })
                }else{
                    dispatch( setPhoneNumberSignUp({ phoneNumber: phoneNumber.phoneNumber, code: phoneNumber.code }) )
                    navigate("/email")
                }
            }else{
                setError(t("SignUp:phoneScreen:label_03") ?? "")
            }

        }).finally(() => {
            setLoading(false)
        })
    }


    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    return (
        <div className="width-100 height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center ml-10" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">{t("SignUp:phoneScreen:label_04")}</p>

                    <PhoneInputMask 
                        phoneNumber={phoneNumber}
                        setPhoneNumber={setPhoneNumber}
                        setError={setError}
                    />

                    <div className="p-relative">
                        <p className="p-absolute color-error fs-12 mt-6">
                            {error}
                        </p>
                    </div>
                </div>

                <div className="mr-30">
                    { ImageSignUp["Phone"] }
                </div>
            </div>

            <div className="flex flex-jc-sb ml-10" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:phoneScreen:label_06")} 
                    disabled={error ? true : false}
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default PhoneScreen