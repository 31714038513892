import React, { useEffect } from 'react'
import HeaderRules from '../../../../components/Rules/HeaderRules/HeaderRules'
import RulesModals from '../../../../components/Rules/RulesModals/RulesModals'
import { useAppDispatch } from '../../../../redux/store/store'
import { getRulesChunk } from '../../../../redux/slices/components/Rules/rulesSlice'
import RowsRules from '../../../../components/Rules/RowsRules/RowsRules'

const RulesScreen = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch( getRulesChunk() )
    }, [])
    

    return (
        <div className="flex flex-d-column height-100">
            <HeaderRules />
            <RowsRules />
            <RulesModals />
        </div>
    )
}

export default RulesScreen