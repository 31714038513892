import React from 'react'

type VerticalSeparatorProps = {
    distance: string
}

const VerticalSeparator = ({
    distance
}: VerticalSeparatorProps) => {
    return (
        <div style={{ "width": "1rem", "height": distance }}></div>
    )
}

export default VerticalSeparator