import React from 'react'
import LoadingSpinner from '../../../LoadingSpinner'

type UserImgNotificationProps = {
    photoUri: string
} 

const UserImgNotification = ({ photoUri }: UserImgNotificationProps) => {

    if(photoUri){
        return(
            <img 
                className="circle" 
                alt="user-notification" 
                src={photoUri ? photoUri : "/user.svg"}
                style={{
                    height: "4vh",
                    width: "4vh",
                }}
                onError={(e) => { 
                    (e.target as HTMLImageElement).src = "/user.svg" }
                }
                //onError={(e) => { (e.target as HTMLImageElement).src = "/user.svg" }} 
            />
        )
    }else{
        return(
            <div
                className="p-relative center"
                style={{
                    height: "4vh",
                    width: "4vh",
                }}>
                <LoadingSpinner fullPage={false} mini={true} />
            </div>
        )
    }
}

export default UserImgNotification