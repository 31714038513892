import React, { ChangeEvent, useCallback, useState } from 'react'
import { checkErrors, checkErrorsWhenSubmit, ValuesForm } from '../../../../../utils/verifications/Forms'
import { MyYup } from '../../../../../utils/verifications/myYup'
import { useTranslation } from 'react-i18next'
import { createProductGimworkDB, ProductCreateGimworkDBBody } from '../../../../../utils/requestsUtils/functionsRequests/products/productsFunctions'
import { v4 as uuidv4 } from 'uuid';
import { TypeProduct } from '../../../../../redux/slices/components/Products/ProductsSlice'
import InputCategoryJobs from '../../../../UIcomponents/InputCategoryJobs/InputCategoryJobs'
import { JobCategoryJob } from '../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import GeneralInputTitle from '../../../../UIcomponents/GeneralInputTitle/GeneralInputTitle'
import MyCheckbox from '../../../../UIcomponents/MyCheckbox/MyCheckbox'
import ButtonsSubmitFormProduct from '../../../../VerifyProduct/SliderProduct/SliderProductCard/ButtonsSubmitFormProduct/ButtonsSubmitFormProduct'
import "./FormAddProductGimworkDBModal.css"
import LoadingSpinner from '../../../../LoadingSpinner'
import { addProductCreatedGimworkReducer } from '../../../../../redux/slices/components/ManageProducts/manageProductsDataSlice'

type FormAddProductGimworkDBModalProps = {
    typeProduct: TypeProduct
    onClose: () => void
}

const FormAddProductGimworkDBModal = ({ typeProduct, onClose }: FormAddProductGimworkDBModalProps) => {

    const { t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const [pdfCheckbox, setPdfCheckbox] = useState(false)
    const [zipCheckbox, setZipCheckbox] = useState(false)
    const [errorCheckbox, setErrorCheckbox] = useState(false)
    const [errorJob, setErrorJob] = useState(false)
    const [optionJobSelected, setOptionJobSelected] = useState<JobCategoryJob>()

    const [loading, setLoading] = useState(false)

    const [inputValues, setInputValues] = useState<ValuesForm>({
        productName: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isString().justValidCharacters()},
        description: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isString().justValidCharacters()},
        RFCcode: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired()},
        ISR: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        IVA_GIMMER: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        IVA_GIMWORK: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
        RMPC: { error: "", value: "", touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isNumber().min(0).max(99)},
    })

    const categoryJobs = useSelector((state: RootState) => state.managerJobData.categoryJobs);

    const getFilesArray = () => {
        const files = []
        if(pdfCheckbox) files.push("pdf")
        if(zipCheckbox) files.push("zip")

        return files
    }

    const onPressSubmitButtons = async() => {
        try {
            let existsError = false;
            for(const nameInput in inputValues){
                if(checkErrorsWhenSubmit(nameInput, inputValues, true))
                    existsError = true
            }
            setInputValues({...inputValues})

            if(typeProduct !== "PHYSICAL" && !pdfCheckbox && !zipCheckbox){
                setErrorCheckbox(true)
                return
            }

            if(!optionJobSelected){
                setErrorJob(true)
                return
            }
    
            if(existsError)
                return

            setLoading(true)
            
            const id = uuidv4()
            if(typeProduct === "DIGITAL"){
                const filesTypes = getFilesArray()
                const body: ProductCreateGimworkDBBody = {
                    id,
                    jobId: optionJobSelected?.id,
                    description: inputValues["productName"].value,
                    productName: inputValues["productName"].value,
                    RFCcode: inputValues["RFCcode"].value,
                    ISR: Number(inputValues["ISR"].value),
                    IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                    IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                    RMPC: Number(inputValues["RMPC"].value),
                    typeProduct: "DIGITAL",
                    fileType: filesTypes
                }
                await createProductGimworkDB(body)
                dispatch( 
                    addProductCreatedGimworkReducer({
                        ...body,
                        status:         "VALIDATED",
                        userId:         "GIMWORK",
                        updatedAt:      new Date().toISOString()
                    }) 
                )
            }else{
                const body: ProductCreateGimworkDBBody = {
                    id,
                    jobId: optionJobSelected?.id,
                    description: inputValues["productName"].value,
                    productName: inputValues["productName"].value,
                    RFCcode: inputValues["RFCcode"].value,
                    ISR: Number(inputValues["ISR"].value),
                    IVA_GIMMER: Number(inputValues["IVA_GIMMER"].value),
                    IVA_GIMWORK: Number(inputValues["IVA_GIMWORK"].value),
                    RMPC: Number(inputValues["RMPC"].value),
                    typeProduct: "PHYSICAL",
                }
                await createProductGimworkDB(body)
                dispatch( 
                    addProductCreatedGimworkReducer({
                        ...body,
                        status:         "VALIDATED",
                        userId:         "GIMWORK",
                        updatedAt:      new Date().toISOString()
                    }) 
                )
            }
            
            onClose()
        } catch (error) {
            
        } finally {
            setLoading(false)
        }
    }

    const onPressJob = useCallback(
        (job: JobCategoryJob) => {
            setOptionJobSelected(job)
            setErrorJob(false)
        },
        [],
    )
    
    const onChangeValueInput = (newEvent: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        checkErrors(newEvent, inputValues, setInputValues)
    }

    const onChangeFocusInput = (name: string) => {
        setInputValues({...inputValues, [name]: {...inputValues[name], touched: true}}) 
    }


    

    return (
        <div className="height-100">
            <div className="p-relative">
                <InputCategoryJobs 
                    idJobSelected={optionJobSelected?.id}
                    categoryJobs={categoryJobs}
                    onPressJob={onPressJob}
                    showError={errorJob}
                />
            </div>

            <div className="flex height-100 mt-20">
                <div className="flex flex-d-column flex-1">
                    <GeneralInputTitle 
                        title={`Product name`}
                        name={"productName"}
                        value={inputValues["productName"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"Product name"}
                        error={inputValues["productName"].error}
                        width="100%"
                    />

                    <GeneralInputTitle 
                        title={`Description`}
                        name={"description"}
                        value={inputValues["description"].value}
                        onChange={onChangeValueInput}
                        onFocus={onChangeFocusInput}
                        placeholder={"Description"}
                        error={inputValues["description"].error}
                        width="100%"
                        textArea={{ height: "18.2rem" }}
                    />
                </div>

                <div className="height-100">
                    <div className="line-height-product-gimwork" />
                </div>

                <div className="flex-1">
                    <div className="flex flex-jc-sb mt-10">
                        <GeneralInputTitle 
                            title={`RFC Code`}
                            name={"RFCcode"}
                            value={inputValues["RFCcode"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={"RFC Code"}
                            error={inputValues["RFCcode"].error}
                            width="70%"
                        />
                        <GeneralInputTitle 
                            title={`ISR`}
                            name={"ISR"}
                            value={inputValues["ISR"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={"ISR"}
                            error={inputValues["ISR"].error}
                            width="26%"
                        />
                    </div>

                    <div className="flex flex-jc-sb mt-10">
                        <GeneralInputTitle 
                            title={`IVA GIMMER`}
                            name={"IVA_GIMMER"}
                            value={inputValues["IVA_GIMMER"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={"IVA GIMMER"}
                            error={inputValues["IVA_GIMMER"].error}
                            width="48%"
                        />
                        <GeneralInputTitle 
                            title={`IVA GIMWORK`}
                            name={"IVA_GIMWORK"}
                            value={inputValues["IVA_GIMWORK"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={"IVA GIMWORK"}
                            error={inputValues["IVA_GIMWORK"].error}
                            width="48%"
                        />
                    </div>

                    <div className="flex flex-jc-sb mt-10">
                        <GeneralInputTitle 
                            title={`RMPC`}
                            name={"RMPC"}
                            value={inputValues["RMPC"].value}
                            onChange={onChangeValueInput}
                            onFocus={onChangeFocusInput}
                            placeholder={"RMPC"}
                            error={inputValues["RMPC"].error}
                            width="48%"
                        />
                    </div>
                    <div className="flex-1 p-relative">
                        {
                            typeProduct === "DIGITAL" &&
                            <div className="flex flex-ai-center width-100 flex-jc-sb con-filt-types">
                                <div className="flex flex-ai-center">
                                    <MyCheckbox size={"1.3rem"} value={pdfCheckbox} onChange={() => { setPdfCheckbox(!pdfCheckbox); setErrorCheckbox(false) }} />
                                    <img 
                                        id="globe-btn"
                                        src="/assets/svg/pdf.svg" 
                                        alt="globe" 
                                        style={{ height: "3.8vh" }}
                                        className="ml-10"
                                    />
                                </div>

                                <div className="flex flex-ai-center">
                                    <MyCheckbox size={"1.3rem"} value={zipCheckbox} onChange={() => {setZipCheckbox(!zipCheckbox); setErrorCheckbox(false)}} />
                                    <img 
                                        id="globe-btn"
                                        src="/assets/svg/zip.svg" 
                                        alt="globe" 
                                        style={{ height: "3.8vh" }}
                                        className="ml-10"
                                    />
                                </div>
                            </div>
                        }
                        {errorCheckbox && <p className="fs-12-vh color-error p-absolute mt-6">{"You must select one or two of these options"}</p>}
                    </div>

                    <div className="flex flex-ai-center width-100 flex-jc-sb mt-20">

                        <button
                            className="white-bg border-color-text color-text fw-600 fs-15-vh pointer button-submit-product border-radius-3"
                            onClick={onClose}
                        >
                            {t("General:label_057")}
                        </button>

                        <button
                            className="dark-gray-bg c-white fw-600 fs-15-vh pointer button-submit-product border-radius-3"
                            onClick={onPressSubmitButtons}
                        >
                            {t("General:label_056")}
                        </button>


                    </div>
                </div>
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default React.memo( FormAddProductGimworkDBModal )