import React, { useEffect, useState } from 'react'
import { getTransactionHistory } from '../../../utils/requestsUtils/functionsRequests/transactions/history'
import ListCardsTransactionHistory, { TransactionDate } from '../ListCardsTransactionHistory/ListCardsTransactionHistory'
import { orderTransactions } from '../../../utils/arrays/transactions'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { setThreeTransactionsReducer } from '../../../redux/slices/components/Help/HelpSlice'
import { useSelector } from 'react-redux'

const CardsTransactionHistoryHelp = () => {

    const dispatch = useAppDispatch()

    const transactions = useSelector((state: RootState) => state.help.threeTransactions)

    useEffect(() => {
      

        const getData = () => {
            getTransactionHistory("All", 3)
            .then(({ items }) => {
                dispatch( setThreeTransactionsReducer(items) )
            }).catch(() => {

            })
        }

        getData()
    
    }, [])

    return (
        <ListCardsTransactionHistory transactions={transactions} />
    )
}

export default React.memo( CardsTransactionHistoryHelp )