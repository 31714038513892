import { useEffect, useState } from 'react'

import { Offer, resetDetailedOfferReducer } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { S3DomainGimwork_categories_logo } from '../../../utils/staticVariables/staticVariables'
import AvailableTimes from './AvailableTimes/AvailableTimes'
import DeleteOfferModal from './DeleteOfferModal/DeleteOfferModal'
import "./DetailedOfferScreen.css"
import HeaderDetailedOffer from './HeaderDetailedOffer/HeaderDetailedOffer'
import OwnerInfo from './OwnerInfo/OwnerInfo'
import WhereMaps from './WhereMaps/WhereMaps'
import { useSelector } from 'react-redux'
import { getProfilePictureByUserId } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import ImagesSkillSlider from './ImagesSkillSlider/ImagesSkillSlider'
import ButtonBack from './ButtonBack/ButtonBack'

interface DetailedOfferScreenProps {

}

const DetailedOfferScreen = ({ }: DetailedOfferScreenProps) => {

  const dispatch = useAppDispatch()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [imgUrl, setImgUrl] = useState<string>("")


  const offer = useSelector((state: RootState) => state.manageOffersData.offer)

  useEffect(() => {
  
    getProfilePictureByUserId(offer.userId, "small")
    .then((imgUrl) => {
      setImgUrl(imgUrl)
    }).catch(() => {})

    return () => {
        dispatch( resetDetailedOfferReducer() )
    }
  }, [])

  if(!offer.id){
    return (
        <div className="center height-100">
            <h1>{"Offer does not exists"}</h1>
        </div>
    )
  }

  return (
    <div className="con-screen-detailed-offer aux-scroll width-100 ff-poppins">
        <div className="con-detailed-offer height-100">
            <HeaderDetailedOffer jobName={offer.jobName} id={offer.id} type={offer.type} />
            <ButtonBack />

            {
              //owner info and imgs
            }
            <div className="flex flex-jc-sb mt-30">
                
                <ImagesSkillSlider 
                    id={offer.userId}
                    type={ offer.type }
                    jobId={offer.jobId}
                    imgKey={offer.imgKey}
                />

                <OwnerInfo offer={offer} imgUrl={imgUrl} setShowDeleteModal={setShowDeleteModal} />

            </div>

            <WhereMaps {...offer.workPreference} />
            <AvailableTimes detailsSchedule={offer.detailsSchedule} type={offer.type} />
        </div>
        { showDeleteModal && <DeleteOfferModal offerId={offer.id} type={offer.type} jobName={offer.jobName} setShowDeleteModal={setShowDeleteModal} /> }
    </div>
  )
}

export default DetailedOfferScreen