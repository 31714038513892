import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TransactionDate } from '../../../../components/Help/ListCardsTransactionHistory/ListCardsTransactionHistory'
import { LanguageLabel } from '../../authSlice'
import { Country } from '../../../../utils/staticVariables/staticVariables'

type NamesJobs = {
    en: string
    es: string
    fr: string
}


type JobsNamesFile = {
    [key: string]: NamesJobs
}

type InitialState = {
    jobsNamesFile?: JobsNamesFile
} 

const initialState: InitialState = {
    jobsNamesFile: undefined,
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setJobsNamesFileReducer: (state, action: PayloadAction<JobsNamesFile>) => {
            state.jobsNamesFile = action.payload
            return state;
        },
        setNameFileReducer: (state, action: PayloadAction<{ id: string, names: NamesJobs }>) => {
            const { id, names } = action.payload

            if(state.jobsNamesFile)
                state.jobsNamesFile[id] = names

            return state;
        },
        deleteJobNameFileReducer: (state, action: PayloadAction<string>) => {
            const id = action.payload

            if(state.jobsNamesFile)
                delete state.jobsNamesFile[id]

            return state;
        },
    },
    extraReducers: (builder) => {

    }
});

// Action creators are generated for each case reducer function
export const { 
    setJobsNamesFileReducer,
    setNameFileReducer,
    deleteJobNameFileReducer
} = globalSlice.actions

export default globalSlice.reducer