import React, { useEffect, useState } from 'react'
import WorkPreferenceIcon from '../../../../../UIcomponents/WorkPreferenceIcon/WorkPreferenceIcon'
import { getHistoryTransaction } from '../../../../../../utils/requestsUtils/functionsRequests/transactions/history'
import { RootState } from '../../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { WorkPreferenceTransaction } from '../../../../../../utils/dbModels/HistoryTransaction'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

type Info = {
    jobName: string
    startTime: string
    endTime: string
    workPreference: WorkPreferenceTransaction
    date: string
}

const TransactionInfo = () => {

    const { t } = useTranslation()

    const [info, setInfo] = useState<Info | null>(null)

    const transactionId = useSelector((state: RootState) => state.manageUserReports.userReport.transactionId)

    useEffect(() => {
      
        const getData = () => {
            getHistoryTransaction(transactionId)
            .then((historyItem) => {
                const startTime = historyItem.schedule.startTime
                const endTime = historyItem.schedule.endTime

                setInfo({
                    jobName: historyItem.skill.jobName,
                    startTime: startTime,
                    endTime: historyItem.schedule.endTime,
                    workPreference: historyItem.workPreference,
                    date: `${moment(startTime).format("DD MMMM YYYY")} - ${moment(startTime).format("HH:mm")} / ${moment(endTime).format("HH:mm")}`
                })
            }).catch(() => {

            })
        }

        getData()

    }, [])


    return (
            <div className="job-info-user-report flex flex-ai-center p-12 mt-24">
                <p className="fs-14">{info?.jobName}</p>
                <div className="ml-16 mr-16">
                    <WorkPreferenceIcon type={info?.workPreference.type ?? "remote"} size={2.6} />
                </div>
                <div className="">
                    <p className="fs-13 italic">{t(`General:${info?.workPreference.type ?? ""}`)}</p>
                    <p className="fs-13 mt-8">{info?.date}</p>
                </div>
            </div>
    )
}

export default React.memo( TransactionInfo )