import React, { useCallback } from 'react'
import { faTimer } from '@fortawesome/pro-solid-svg-icons'
import GenericNotification from '../GenericNotification'
import { Colors } from '../../../../utils/colors/colors'
import { NotificationProps } from '../../FactoryNotification/FactoryNotification'


interface NewOvertimeAcceptedNotificationProps extends NotificationProps{
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			approved: string, 
			receivedFromType: string, 
            userName: string,
            transactionId: string
		},
	}
}


const NewOvertimeAcceptedNotification = ({ item, clickable, onSetNotificationAsView }: NewOvertimeAcceptedNotificationProps) => {
	const { id, notifMsg, timestamp, viewed, notifData: { approved } } = item

	const touchNotification =  useCallback(
	  () => {
        if(!viewed)
            onSetNotificationAsView(id)
	  },
	  [],
	)
	
	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			

			id={id}
			timestamp={timestamp}
			viewed={clickable ? viewed : true}
			description={"User accepted overtime"}
			title={ "Username has accepted add overtime to the meet" }

			icon={ faTimer }
			color={ Colors.flagGreen }
		/>
	)
}

export default React.memo( NewOvertimeAcceptedNotification )