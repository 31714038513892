import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { UserReportType } from '../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./InputChatUserReport.css"
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'
import { useTranslation } from 'react-i18next'

type InputChatUserReportProps = {
    tabUsers: number
    onSendPress: (message: string, type: UserReportType) => void
}

const InputChatUserReport = forwardRef(({ 
    tabUsers, 
    onSendPress,
}: InputChatUserReportProps, ref) => {

    const { t } = useTranslation()

    const requestByUserType = useSelector((state: RootState) => state.manageUserReports.userReport.requestByUserType)
    
    const isInTabRequestBy = tabUsers === 0 

    let colorButton = ""
    if(isInTabRequestBy){
        colorButton = requestByUserType === "Gim" ? "color-gim" : "color-gimmer"
    }else{
        colorButton = requestByUserType !== "Gim" ? "color-gim" : "color-gimmer"
    }

    const [message, setMessage] = useState({ requestBy: "", personInvolved: "" })



    useImperativeHandle(ref, () => ({
        resetInputRequestBy() {
            setMessage({ ...message, requestBy: "" })  
        },
        resetInputPersonInvolved() {
            setMessage({ ...message, personInvolved: "" })
        }
    }));


    return (
        <div className="pl-20 pr-20 ">
            <div className="write-message border-radius-6 white-bg mb-8 mt-8 flex flex-ai-center">

                <input 
                    className="fs-14 input-write-message flex-1"
                    placeholder={t("Reports:user:label_15") ?? ""}
                    value={isInTabRequestBy ? message.requestBy : message.personInvolved}
                    onChange={(e) => {
                        if(isInTabRequestBy)
                            setMessage({...message, requestBy: e.target.value})
                        else
                            setMessage({...message, personInvolved: e.target.value})
                    }}
                />

                <button 
                    className="pointer"
                    onClick={() => onSendPress(isInTabRequestBy ? message.requestBy : message.personInvolved, tabUsers === 0 ? "RequestBy" : "PersonInvolved")}
                >
                    <FontAwesomeIcon icon={solid("paper-plane-top")} className={`fs-20 ${colorButton}`} />
                </button>

            </div>
        </div>
    )
})

export default React.memo( InputChatUserReport )