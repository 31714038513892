import React, { useEffect } from 'react'
import "./ButtonsBugReports.css"
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { GetBugReportsThunk } from '../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import { useSelector } from 'react-redux'
import { StatusReportBug, statusReportBugValues } from '../../../utils/dbModels'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { canBeInteger } from '../../../utils/verifications/strings'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'

const ButtonsBugReports = () => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const limit = useSelector((state: RootState) => state.manageBugReportsData.limit);
    const status = useSelector((state: RootState) => state.manageBugReportsData.status);
    const LastEvaluatedKey = useSelector((state: RootState) => state.manageBugReportsData.LastEvaluatedKey);
    const totalCounterStatus = useSelector((state: RootState) => state.manageBugReportsData.totalCounterStatus);

    const hasAccessDisplayTester = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Display" })
    const hasAccessDisplayDeveloper = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Display" })

    const handleGetReports = (newStatus: StatusReportBug) => {


        navigate(`/manage-bug-reports?status=${newStatus}&limit=${limit}`)
        /*
        dispatch(GetBugReportsThunk({ 
            LastEvaluatedKey: "", 
            page: 1, 
            limit, 
            bringTotal: true,
            status: newStatus
        }))
            */
    }

    useEffect(() => {
        // Get a specific parameter value
        const limitParam = searchParams.get("limit") ?? "10"
        //const pageParam = searchParams.get("General:page") ?? "1"
        const statusParam = searchParams.get("status") ?? ""

        let status: StatusReportBug | undefined = hasAccessDisplayTester ? "UPLOADED" : "OPENED"
        if(statusReportBugValues.includes(statusParam as StatusReportBug ))
            status = statusParam as StatusReportBug

        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
        /*
        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
*/
        //setTab({ index })
        //dispatch(changeIndexTabReducer({ indexTab: index }))
            //console.log("last", LastEvaluatedKey, page)
        dispatch(GetBugReportsThunk({ page: 1, limit, bringTotal: true, status }))

    }, [ searchParams.get("status"), searchParams.get("limit"), hasAccessDisplayTester, hasAccessDisplayDeveloper])

    return (
        <div className="flex mt-24">

            {
                hasAccessDisplayTester && 
                <div className="p-relative">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.uploaded ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("UPLOADED")} className={`${status !== "UPLOADED" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`}>
                        {"Bugs"}
                    </button>
                </div>
            }

            {
                hasAccessDisplayDeveloper &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.opened ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("OPENED")} className={`${status !== "OPENED" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`} >
                        {t("Reports:bug:label_01")}
                    </button>
                </div>
            }

            {
                //hasAccessDisplayDeveloper &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.pending ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("PENDING")} className={`${status !== "PENDING" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`}>
                        {t("Reports:bug:label_02")}
                    </button>
                </div>
            }

            <div className="p-relative ml-16">
                <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                    <p className="c-white fw-600 fs-11" >{totalCounterStatus?.closed ?? 0}</p>
                </div>
                <button onClick={() => handleGetReports("CLOSED")} className={`${status !== "CLOSED" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`}>
                    {t("Reports:bug:label_03")}
                </button>
            </div>

            {
                hasAccessDisplayTester &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterStatus?.testing ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports("TESTING")} className={`${status !== "TESTING" ? "btn-pending" : "btn-pending-clicked"} fs-14 fw-600 pr-16 pl-16 border-radius-6 pointer`}>
                        {t("Reports:bug:label_07")}
                    </button>
                </div>
            }

        </div>
    )
}

export default React.memo( ButtonsBugReports )