import React from 'react'
import "./HeaderManageUsersForm.css"
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/store/store';
import { UserForm } from '../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice';
import useGetJobName from '../../hooks/useGetJobName/useGetJobName';
import useGetAllJobNames from '../../hooks/useGetAllJobNames/useGetJobName';
import moment from 'moment';

const HeaderManageUsersForm = () => {

    const usersToShow = useSelector((state: RootState) => state.manageUsersForm.usersToShow)

    const jobNames = useGetAllJobNames()

    const handleExport = () => {
        // Create a new workbook and worksheet
        const data = orderColumnsExcel(usersToShow)
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Convert the workbook to a binary file and trigger the download
        XLSX.writeFile(workbook, "SampleData.xlsx");
      };


      const handleExport2 = () => {
        // Create a new workbook and worksheet
        const data = orderColumnsExcel(usersToShow)
        const worksheet = XLSX.utils.json_to_sheet(data);
      
        // Define column widths
        worksheet["!cols"] = [
          { wch: 20 }, 
          { wch: 20 }, 
          { wch: 20 }, 

          { wch: 20 }, 
          { wch: 30 }, 

          { wch: 20 }, 
          
          { wch: 20 }, 
          { wch: 30 }, 
          { wch: 20 }, 

          { wch: 20 }, 
        ];
      
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const date = moment().format("YYYY-MMMM-DDD-Hora-HH-mm-SS")
        // Convert the workbook to a binary file and trigger the download
        XLSX.writeFile(workbook, `${date}.xlsx`);
      };

    const orderColumnsExcel = (usersToShow: UserForm[]) => {
        return usersToShow.map((user) => {
            const {
                id,
                firstName,
                lastName, 
                phoneNumber,
                mail,
                skillId,
                skillName,
                state,
                city,
                cityHall,
                timestamp
            } = user
            return({
                id,
                firstName, 
                lastName, 
                phoneNumber,
                mail,
                skillName:      jobNames[skillId],
                state,
                city,
                cityHall,
                timestamp:       moment(timestamp).format("DD MMMM YYYY")
            })
        })
    }

    return (
        <div className="flex flex-jc-sb flex-ai-center">
            <p className="title-screen">{"Form result"}</p>

            <button
                onClick={handleExport2}
                className="c-white fs-12 picton-blue-bg export-form-btn"
            >
                Export
            </button>
        </div>
    )
}

export default React.memo( HeaderManageUsersForm )