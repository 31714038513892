import React, { useState } from 'react'
import "./SelectorBar"
import { Colors } from '../../../../utils/colors/colors'
import { useTranslation } from 'react-i18next'

type SelectorBarProps = {
    onPress: (leftPress: boolean) => void
}

const SelectorBar = ({ onPress }: SelectorBarProps) => {

    const { t } = useTranslation()
    const [leftSelected, setLeftSelected] = useState(true)

    const width = 334

    const onPressBar = (leftPress: boolean) => {
        onPress(leftPress)
        setLeftSelected(leftPress)
    }

    return (
        <div className="flex flex-jc-sb selector-bar" style={{ width: `${width}px`, height: "3.6vh" }}>
            <button 
                onClick={() => onPressBar(true)}
                disabled={leftSelected}
                className="left width-50 height-100 center"
                style={{
                    backgroundColor: leftSelected ? Colors.TEXT : "white",
                    cursor: leftSelected ? "" : "pointer"
                }}
            >
                <p 
                    className="fs-16-vh"
                    style={{
                        color: !leftSelected ? Colors.TEXT : "white",
                    }}
                >
                    {t("ManageProducts:request:label_05")}
                </p>
            </button>
            <button 
                onClick={() => onPressBar(false)}
                disabled={!leftSelected}
                className="left width-50 height-100 center"
                style={{
                    backgroundColor: !leftSelected ? Colors.TEXT : "white",
                    cursor: !leftSelected ? "" : "pointer"
                }}
            >
                <p 
                    className="fs-16-vh"
                    style={{
                        color: leftSelected ? Colors.TEXT : "white",
                    }}
                >
                    {t("ManageProducts:request:label_06")}
                </p>
            </button>
        </div>
    )
}

export default React.memo( SelectorBar )