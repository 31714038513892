import React from 'react'
import MyUserStatus from './MyUserStatus/MyUserStatus'
import { useTranslation } from 'react-i18next'
import SearchPeopleBar from './SearchPeopleBar/SearchPeopleBar'

const ListChatsSide = () => {

    const { t } = useTranslation()

    return (
        <div className="" style={{ width: "27%" }}>
            <MyUserStatus />
            <p className="fs-14 fw-500 mt-22 mb-16">{t("Sessions:label_002")}</p>
            <SearchPeopleBar />
        </div>
    )
}

export default React.memo( ListChatsSide )