import React from 'react'
import { Colors } from '../../../../../utils/colors/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/pro-solid-svg-icons'
import { faTimes } from '@fortawesome/pro-regular-svg-icons'
import { ImgDataSkill } from '../../../../../screens/SignUp/SkillGim/AddPicturesSkillScreen/AddPicturesSkillScreen'
import "./AddPictureRectangle.css"

type AddPictureRectangleProps = {
    onPress: () => void
    onRemove: (index: number, img: ImgDataSkill) => void
    img: ImgDataSkill
    index: number
}

const AddPictureRectangle = ({ onPress, img, onRemove, index }: AddPictureRectangleProps) => {
    return (
        <button
            onClick={onPress}
            className="border-radius-6 center p-relative ml-6 mr-6 mb-12"
            style={{
                width: "32%",
                height: "15vh",
                backgroundColor: Colors.TEXT,
                overflow: "hidden",
                cursor: img?.imgUrl ? "pointer" : "pointer"
            }}
        >
            {
                (img?.imgUrl || img?.localImgUrl) 
                ? 
                    <>
                        <img className=" width-100 img-add-skill" src={img?.imgUrl || img?.localImgUrl} alt={img?.imgUrl || img?.localImgUrl} />
                        <div
                            onClick={() => onRemove(index, img)}
                            className="pointer p-absolute circle center" 
                            style={{ backgroundColor: "#FFFFFF99", height: "3.1vh", width: "3.1vh", top: "5%", right: "5%" }}
                        >
                            <FontAwesomeIcon icon={faTimes} className="color-text fs-22-vh ml-2" />
                        </div>
                    </>
                :
                <div
                    style={{ 
                        height: "3.75vh",
                        width: "3.75vh"
                    }}
                    className="white-bg center circle"
                >
                    <FontAwesomeIcon icon={faPlus} className="color-text fs-22-vh ml-2" />
                </div>
            }
        </button>
    )
}

export default AddPictureRectangle