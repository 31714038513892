import React from 'react'
import "../buttonsLogin.css"
import { useTranslation } from 'react-i18next'

type ButtonGoogleProps = {
    onPress: () => void
}

const ButtonGoogle = ({ onPress }: ButtonGoogleProps) => {
    
    const { t } = useTranslation()

    return (
        <button type="button" onClick={onPress} className="login-button flex flex-ai-center">
            <img className="icon-btn-login" src="./assets/images/Google.png" alt="Google_logo" />
            <p className="fs-18 c-text">
                {t("SignIn:label_04")}
            </p>
        </button>
    )
}

export default React.memo( ButtonGoogle )