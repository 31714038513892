import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import DetailedPriceCard from '../../../../../components/ManagerJobs/SimulatorJobs/DetailedPriceCard/DetailedPriceCard';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../redux/store/store';
import useGetJobName from '../../../../../components/hooks/useGetJobName/useGetJobName';
import { setUpdateStateTablesJobsReducer, StateTableJobs } from '../../../../../redux/slices/components/ManagerJobs/managerJobsTables';
import { useTranslation } from 'react-i18next';

const bc = new BroadcastChannel("test_channel2");

const validTypes = ["Gim", "Gimmer", "Gimwork"]

const TableSimulatorScreen = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const { id }: any = useParams();

    const [error, setError] = useState("Aux")
    const [type, seTtype] = useState<"Gim" | "Gimmer" | "Gimwork" | undefined>()


    const { 
        jobSelected,
        gimStars,
        gimmerStars, 
        price, 
        malusGim,
        malusGimmer, 
        malusShareGim, 
        malusShareGimmer, 
        minRevenue, 
        tax, 
        timeHours, 
        productPrice, 
        timeAdded,
        rmpcProduct,
        kmCovered,
        tip
    } = useSelector((state: RootState) => state.managerJobsTables)


    const jobName = useGetJobName(jobSelected?.id ?? "")

    useEffect(() => {

        bc.postMessage("SEND_INFORMATION");

        //console.log("bc", bc)
        bc.onmessage = (event) => {
            //console.log(event);
            console.log("receive")
            const data: StateTableJobs = event.data

            if(data.jobSelected)
                setError("")

            dispatch( setUpdateStateTablesJobsReducer(data))
        };
        


        return () => {
            
        }
    }, [])
    

    useLayoutEffect(() => {
        
        const type: "Gim" | "Gimmer" | "Gimwork" | undefined = id
        
        if(!type){
            setError("Type table not specified")
            return
        }
            
        if(!validTypes.includes(type)){
            setError("Invalid type table")
            return
        }

        seTtype(type)

        if(!jobSelected){
            setError("You haven't chosen a job")
            return
        }

        // this for avoid inconcistencies in detailed price component if data is missing
        setError("")


        return () => {
        
        }
    }, [])
    
    if(error){
        return(
            <div className="center height-100">
                <p className="fs-20 fw-600 color-text">{error}</p>
            </div>
        )
    }

    return (
        <div className="flex flex-d-column height-100 p-relative">
            <div className="aux-scroll">
                <div className="center mt-20 mb-20">
                    <p className="title-screen">{`${type} ${t("ManageJobs:detailed:label_24")} ${jobName}`}</p>
                </div>
                {
                    type && jobSelected &&
                        <div className="p-relative center" style={{ width: "100vw" }}>
                            <DetailedPriceCard 
                                //price={price}
                                //malusGim={malusGim}
                                //malusGimmer={malusGimmer}
                                gimStars={gimStars}
                                gimmerStars={gimmerStars}
                                RMP={minRevenue}
                                type={type}
                                //malusShareGim={malusShareGim}
                                //malusShareGimmer={malusShareGimmer}
                                tax={tax}
                                timeHours={1}
                                productPrice={productPrice}
                                timeAdded={timeAdded}
                                rmpcProduct={rmpcProduct}
                                isHovered={true}
                                topGimmerBar={"0"}
                                topGimBar={"0"}
                                topGimworkBar={"0"}
                                isInSimulator={false}
                                data={jobSelected.pricingInformation.day}
                                kmCovered={kmCovered}
                                tip={tip}
                            />
                    </div>
                }
            </div>

        </div>
    )
}

export default TableSimulatorScreen