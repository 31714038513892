import React, { useEffect } from 'react'
import HeaderManageUsersForm from '../../../../components/ManageUsersForm/HeaderManageUsersForm/HeaderManageUsersForm'
import { useAppDispatch } from '../../../../redux/store/store'
import { GetUsersFormThunk } from '../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice'
import HeaderTableManageUsersForm from '../../../../components/ManageUsersForm/HeaderTableManageUsersForm/HeaderTableManageUsersForm'
import UsersFormRows from '../../../../components/ManageUsersForm/UsersFormRows/UsersFormRows'
import BottomRowTableUsersForm from '../../../../components/ManageUsersForm/BottomRowTableUsersForm/BottomRowTableUsersForm'
import ManageUsersFormModals from '../../../../components/ManageUsersForm/ManageUsersFormModals/ManageUsersFormModals'

const ManageUsersFormScreen = () => {

    const dispatch = useAppDispatch()

    useEffect(() => {

        dispatch( GetUsersFormThunk({ limit: 10, page: 1, bringTotal: true }) )
    
        return () => {
          
        }
    }, [])
    

    return (
        <div className="flex flex-d-column height-100">
            <HeaderManageUsersForm />
            <HeaderTableManageUsersForm />
            <UsersFormRows />
            <BottomRowTableUsersForm  />
            <ManageUsersFormModals />
        </div>
    )
}

export default ManageUsersFormScreen