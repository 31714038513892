import React from 'react'
import { UserCertificateImage } from '../../../redux/slices/components/VerifyCertificate/VerifyCertificateDataSlice'
import SliderCertificateCard from './SliderCertificateCard/SliderCertificateCard'
import "./SliderCertificate.css"
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'

interface SliderCertificateProps {
  sliderData: UserCertificateImage[]
}

const SliderCertificate = ({ sliderData }: SliderCertificateProps) => {

    const accessEdit = useCheckAccess({ id_Category: "Verify", id_Policy: "Certificate", id_Action: "Edit" })

    return (
        <div className="p-relative">
          <div id="con-gray-slider-profile-picture" className="rec-gray-slider-cer"> 
              {
                  sliderData.map((user: any, index: number) => {
                      return(
                          <SliderCertificateCard
                              key={user.id}
                              user={user}
                              index={index}
                              lengthUsers={sliderData.length}
                              editable={accessEdit}
                          />
                      )
                  })
              }
          </div>
      </div>
    )
}

export default SliderCertificate