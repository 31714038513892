import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Country } from '../../../../utils/staticVariables/staticVariables';
import { data_roles, policies } from '../../../../utils/roles';
import { BodyCreateRole, getAllowedRoles, updateRole } from '../../../../utils/requestsUtils/functionsRequests/roles/roles';
import { RootState } from '../../../store/store';

export type Id_Category_Role = "Access_Management" | "Dashboard" | "Manage_Users" | "Manage_Products"
| "Manage_Jobs" | "Manage_Offers" | "Verify" | "Manage_Reports" | "Manage_Roles" | "Rules" | "General_Information" | "Users_Form"

export type Id_Policy = "My_Profile" | "My_History" | "My_Sessions"
| "My_Marketplace" | "My_Documents" | "Export" | "Indicator" | "Pie_Chart"
| "Message" | "Users" | "Category" | "Task" | "Job" | "Activate_Deactivate" 
| "Link_Jobs" | "Profile_Picture" | "Id" | "Certificate" | "Products" |  "Reports" | "Users_Form"
| "Bug_Reports" | "Roles" | "Contracts" | "Shop_Fees" | "Support_1" | "Support_2" | "Support_3" | "Support_Admin" | "Bug_Reports_Tester" | "Bug_Reports_Developer"

export type Id_Action = "Display" | "Create" | "Edit" | "Delete"

export type Action_Option = { action: Id_Action, selected: boolean }

type Url_Access = { url: string, method: string }

export type Policy = {
    id_Category: Id_Category_Role,
    id_Policy: Id_Policy,
    id_Action: Id_Action,
    url_Access: Array<Url_Access>,
    label: string
}

export type Role = {
    id: string
    hierarchy: number
    name: string
    country: Country | "All"
    policies: Array<Policy>
    permanent: boolean
}

export type Data_Policy = {
    id_Policy: Id_Policy,
    label: string,
    actions: Array<Action_Option>
}

export type Data_Rol_Table = {
    id_Category: Id_Category_Role,
    label: string,
    actions: Array<Id_Action>,
    policies: Array<Data_Policy>
}

type State = {
    loading: boolean
    data: Array<Data_Rol_Table>
    roles: Array<Role>
    roleSelected: Role
    policies: Array<Policy>
    modalRole: ModalRole
}

type ModalRole = "Add" | "Remove" | ""



const initialState: State = {
    loading: false,
    data: data_roles,
    roles: [],
    roleSelected: {} as Role,
    policies: policies,
    modalRole: ""
}



export type AllowedRolesResponse = {
    policies: Array<Policy>
    roles: Array<Role>
}

export const getAllowedRolesChunk = createAsyncThunk(
    'manageRoles/getAllRolesChunk',
    async (thunkAPI) => {
        return getAllowedRoles()
        .then((res: AllowedRolesResponse) => {
            return res
        })
        .catch(() => {
            return { 
                policies: [] as Policy[],
                roles: [] as Role[]
            }
        })
    }
)

type UpdateRoleReducerArg = {
    id_Category: string
    id_Policy: string
    id_Action: string
    checked: boolean
}

export const updateRoleReducerChunk = createAsyncThunk(
    'manageRoles/updateRoleReducerChunk',
    async (args: UpdateRoleReducerArg, thunkAPI: any) => {
        const state: RootState = thunkAPI.getState();
        const id_Role = state.manageRoles.roleSelected.id

        return updateRole(id_Role, args)
        .then((res) => {
            return args
        })
        .catch(() => {
            alert("General:Error: The role wasnt update, report to gimwork team")

            throw({ 
                id_Category: "",
                id_Policy: "",
                id_Action: "",
                checked: false
            })
        })
    }
)



const updateDataTableByRole = (data: Array<Data_Rol_Table>, policies: Array<Policy>) => {
    
    for(let i=0; i<data.length; i++){
        const id_category = data[i].id_Category
        for(let j=0; j<data[i].policies.length; j++){
            const id_policy = data[i].policies[j].id_Policy
            for(let k=0; k<data[i].policies[j].actions.length; k++){
                const action = data[i].policies[j].actions[k].action
                const policy = policies.some(p => 
                    p.id_Category === id_category && 
                    p.id_Policy === id_policy &&
                    p.id_Action === action )
                if(policy)
                    data[i].policies[j].actions[k].selected = true
                else 
                    data[i].policies[j].actions[k].selected = false
            }
        }
    }
}

export const manageRolesSlice = createSlice({
  name: 'manageRoles',
  initialState,
  reducers: {
    updateTableByRoleReducer: (state, action: PayloadAction<string>) => {
        const id = action.payload
        const role = state.roles.find(r => r.id === id)

        if(!role) {
            alert("General:Role does not exists")
            return state
        }

        state.roleSelected = role
        updateDataTableByRole(state.data, role?.policies || [])

        return state
    },
    createRoleReducer: (state, action:PayloadAction<BodyCreateRole>) => {
        
        const { name, hierarchy, country } = action.payload
        const id_Role = name.replace(/\s/g, "_")

        state.roles.push({
            id: id_Role,
            name,
            hierarchy,
            country,
            policies: [],
            permanent: false
        })  

        return state
    },
    deleteRoleReducer: (state, action:PayloadAction<string>) => {
        
        const id_Role = action.payload

        const index = state.roles.findIndex(r => r.id === id_Role)
        if(index === -1)
            return state
            
        const id_RoleRemoved = state.roles[index].id 
        state.roles.splice(index,1)

        if(id_Role === id_RoleRemoved && state.roles.length > 0){
            state.roleSelected = state.roles[0]
            updateDataTableByRole(state.data, state.roleSelected.policies)
        }
        state.roleSelected = {} as Role
        return state
    },
    openCloseModalRoleReducer: (state, action:PayloadAction<ModalRole>) => {
        state.modalRole = action.payload
        return state
    },
    resetManageRolesReducer: (state) => {
        return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(getAllowedRolesChunk.fulfilled, (state, action: PayloadAction<AllowedRolesResponse>) => {
        state.roles = action.payload.roles
        state.policies = action.payload.policies

        if(state.roles.length > 0){
            state.roleSelected = state.roles[0]
            updateDataTableByRole(state.data, state.roleSelected.policies)
        }
        state.loading = false
        return state
    })
    .addCase(getAllowedRolesChunk.pending, (state, action: any) => {
        state.loading = true
        return state
    }).addCase(getAllowedRolesChunk.rejected, (state, action: any) => {
        state.loading = false
        return state
    })

    builder.addCase(updateRoleReducerChunk.fulfilled, (state, action: PayloadAction<UpdateRoleReducerArg>) => {
        const { id_Category, id_Policy, id_Action, checked } = action.payload
        
        if(checked){
            const policy = state.policies.find(p => 
                p.id_Category === id_Category &&
                p.id_Policy === id_Policy &&
                p.id_Action === id_Action
            )
            if(!policy)
                return state
            state.roleSelected.policies.push(policy)
        }else{
            const index = state.roleSelected.policies.findIndex(p => 
                p.id_Category === id_Category &&
                p.id_Policy === id_Policy &&
                p.id_Action === id_Action
            )
            if(index === -1)
                return state
            state.roleSelected.policies.splice(index,1)
        }

        state.roles.forEach(role => {
            if(role.id === state.roleSelected.id)
                role.policies = state.roleSelected.policies
        })

        updateDataTableByRole(state.data, state.roleSelected.policies)
        state.loading = false
        return state
    })
    .addCase(updateRoleReducerChunk.pending, (state, action: any) => {
        state.loading = true
        return state
    }).addCase(updateRoleReducerChunk.rejected, (state, action: any) => {
        state.loading = false
        return state
    })
    
  },
});

// Action creators are generated for each case reducer function
export const {  
    updateTableByRoleReducer,
    resetManageRolesReducer,
    openCloseModalRoleReducer,
    deleteRoleReducer,
    createRoleReducer
} = manageRolesSlice.actions

export default manageRolesSlice.reducer