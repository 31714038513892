import { useTranslation } from 'react-i18next';

const HeaderCertificate = () => {
  const { t } = useTranslation();
  return (
    <div className="con-title-slider-profile">

        <div className="flex pb-10">
          <p className="title-screen">
              {t("General:label_054")}
          </p>
        </div>

    </div>
  )
}

export default HeaderCertificate