import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { getOfferPublic } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import "./OfferCard.css"
import ImagesSkillSlider from '../../ManageAllOffers/DetailedOfferScreen/ImagesSkillSlider/ImagesSkillSlider'
import { Offer } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { getProfilePictureByUserId, getUserDataById, UserValidData } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faStar } from '@fortawesome/pro-solid-svg-icons'
import { getStars } from '../../../utils/numbers/numbers'
import LabelJobDate from './LabelJobDate/LabelJobDate'
import { orderSchedulesByStartDate } from '../../../utils/dates/dates'
import { DefaultPosition, distanceInKmBetweenEarthCoordinates } from '../../../utils/maps/locations'
import WorkPreferenceIcons from './WorkPreferenceIcons/WorkPreferenceIcons'
import Price from '../../UIcomponents/Price/Price'
import { useAppDispatch } from '../../../redux/store/store'
import { setOfferInfoReducer, setOfferPriceReducer, setOfferUserDataInfoReducer } from '../../../redux/slices/components/HomeOffers/homeOffersSlice'
import moment from 'moment'
import UserImage from '../../UIcomponents/UserImage/UserImage'

export type UserCoordinates = {
	latitude: number;
	longitude: number;
};


type OfferCardsProps = {
    id: string
    userPosition?: UserCoordinates
    onPress: (id: string) => void
}

type UserData = {
    firstName: string
    lastName: string
    gimmerScore: number
    gimScore: number
    numberReviews: {
        gim: number,
        gimmer: number
    }
}

const OfferCard = ({ id, userPosition, onPress }: OfferCardsProps) => {

    const dispatch = useAppDispatch()

    const [offer, setOffer] = useState<Offer | null>(null)
    
    const [userData, setUserData] = useState<UserData>({
        firstName: "",
        lastName: "",
        gimmerScore: 0,
        gimScore: 0,
        numberReviews: {
            gim: 0,
            gimmer: 0
        }
    })

    const firstDate = useMemo(() => orderSchedulesByStartDate(offer?.detailsSchedule)[0]?.start ?? offer?.timestamp, [offer?.detailsSchedule[0]?.start])

    useEffect(() => {
      
        const getData = () => {
            getOfferPublic(id)
            .then((offer) => {

                const firstDate = orderSchedulesByStartDate(offer.detailsSchedule)[0]
                const Offer: Offer = {
                    ...offer,
                    type: offer.estimatedTime ? "Task" : "Job",
                    timestampMilliseconds: new Date(offer.timestamp).getTime(),
                    seen: Boolean(offer.seen),
                    soonerDate: moment(firstDate.start).valueOf()
                }
                
                setOffer(Offer)
                dispatch( setOfferInfoReducer(Offer) )


                getUserDataById(offer.userId, { firstName: 1, lastName: 1, gimmerScore: 1, gimScore: 1, numberReviews: 1 })
                .then((userInfo: any) => {
                    setUserData({
                        firstName: userInfo.firstName ?? "",
                        lastName: userInfo.lastName ?? "",
                        gimmerScore: getStars(userInfo.gimmerScore) ?? "",
                        gimScore: getStars(userInfo.gimScore) ?? "",
                        numberReviews: {
                            gim: userInfo.numberReviews.gim,
                            gimmer: userInfo.numberReviews.gimmer
                        }
                    })
                    dispatch( setOfferUserDataInfoReducer({
                        id,
                        user: {
                            gimmerScore: userInfo.gimmerScore,
                            gimScore: userInfo.gimScore,
                            numberReviewsGim: userInfo.numberReviews.gim,
                            numberReviewsGimmer: userInfo.numberReviews.gimmer
                        }
                    }) )
                }).catch(() => {})
                
            }).catch(() => {

            })
        }
    
        getData()

    }, [])

    const onPressImage = useCallback(
        () => {
            onPress(id)
        },
        [],
    )

    const onSetPrice = useCallback(
        (price: number) => {
            dispatch( setOfferPriceReducer({ id, price }) )
        },
        [],
    )
    
    

    const offerType = offer?.estimatedTime ? "Task" : "Job"

    if(!offer)
        return (<></>)

    let distanceKm = 0;
    if (offer.workPreference?.outside?.address) {
        distanceKm = distanceInKmBetweenEarthCoordinates(
            userPosition?.latitude ?? DefaultPosition.latitude,
            userPosition?.longitude ?? DefaultPosition.longitude,
            offer.workPreference.outside.latitude,
            offer.workPreference.outside.longitude,
        );
    }

    return (
        <div className="offer-card flex flex-d-column pointer mb-20">

            <div onClick={() => onPress(id)} className="header-offer-card p-relative flex flex-ai-center pr-6 touchable-effect-disabled">

                <div className="p-absolute con-circle-user-img">
                    <div className="circle-user-img p-absolute circle center">
                        <UserImage 
                            userId={offer.userId} 
                            size="small" 
                            sizeImg={87} 
                            unit="%"
                        />
                    </div>
                </div>

                <p className="fs-20 fw-500 color-text name-text-offerd-card">{userData.firstName}</p>

                <div className="flex flex-ai-center flex-jc-end ml-8 con-stars-review-card">
                    <FontAwesomeIcon icon={faStar} className="color-alert fs-19" />
                    <div className="flex flex-ai-end height-100">
                        <p className="fs-19 color-text fw-600 ml-4 mt-6" style={{ verticalAlign: "bottom" }}>{offerType === "Task" ? userData.gimmerScore : userData.gimScore}</p>
                        <p className="fs-10 color-text fw-500 ml-4 mb-4">({offerType === "Task" ? userData.numberReviews.gimmer : userData.numberReviews.gim})</p>
                    </div>
                </div>

            </div>

            <div className="body-offer-card p-relative">
                <ImagesSkillSlider 
                    id={offer.userId}
                    type={offerType}
                    jobId={offer.jobId}
                    imgKey={offer.imgKey}
                    onPressImage={onPressImage}
                >
                    <LabelJobDate type={offer.type} jobId={offer.jobId} date={firstDate} position='relative' />
                </ImagesSkillSlider>
                
            </div>

            <div onClick={() => onPress(id)} className="footer-offer-card flex pl-10 pr-10 touchable-effect-disabled">
                <WorkPreferenceIcons
					workPreference={offer.workPreference}
					distanceKm={distanceKm}
				/>
                <div className="flex-1"></div>
                {
                    offer.estimatedTime &&
                    <div className="flex flex-jc-end flex-ai-center flex-1">
                        <div className="center flex-d-column">
                            <div className="flex flex-ai-center">
                                <FontAwesomeIcon icon={faClock} className="color-text fs-20"/>
                                <p className="fs-20 color-text fw-600 ml-6">{offer.estimatedTime}</p>
                            </div>
                            <p className="fs-10 color-text mt-4 flex-1" style={{ display: "inline" }}>{"Time needed"}</p>
                        </div>
                    </div>
                }

                <div className="center flex-d-column ml-6">
                    <Price 
                        otherUserID={offer.userId}
                        type={offer.type === "Task" ? "gim" : "gimmer"}
                        jobId={offer.jobId}
                        categoryName={offer.categoryName}
                        estimatedTime={offer.estimatedTime}
                        cssClasses={"fs-18 color-text fw-600"}
                        initText={"$"}
                        getPrice={onSetPrice}
                    />
                    <p className="fs-8-vw color-text">{offer.type === "Task" ? "Earn" : "Cost"}</p>
                </div>
            </div>

        </div>
    )
}

export default React.memo( OfferCard )