import React from 'react'
import "./SearchPeopleBar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next'

const SearchPeopleBar = () => {

    const { t } = useTranslation()

    return (
        <div className="">
            <div className="search-people-bar border-radius-1 fs-14 flex flex-ai-center pr-8 pl-8">
                <input 
                    className="flex-1 input-without-focus outline-none"
                    placeholder={t("Sessions:label_003") ?? ""}
                    style={{ outline: "none" }}
                />
                <FontAwesomeIcon icon={solid("magnifying-glass")} className="c-manatee pr-2 pl-2" />
            </div>
        </div>
    )
}

export default React.memo( SearchPeopleBar )