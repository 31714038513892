import React, { useEffect } from 'react'
import ButtonScreenHelp from '../../../components/Help/ButtonScreenHelp/ButtonScreenHelp'
import { useNavigate } from 'react-router-dom'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { getTransactionHistory } from '../../../utils/requestsUtils/functionsRequests/transactions/history'
import { setTransactionsAssistanceReducer } from '../../../redux/slices/components/Help/HelpSlice'
import CardsTransactionsHistoryAssistance from '../../../components/Help/CardsTransactionsHistoryAssistance/CardsTransactionsHistoryAssistance'
import { useTranslation } from 'react-i18next'

const AssistanceJobTaskScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const transactions = useSelector((state: RootState) => state.help.transactionsAssistance)

    useEffect(() => {
      
        const getData = () => {
            getTransactionHistory("All", 100)
            .then(({ items }) => {
                dispatch( setTransactionsAssistanceReducer(items) )
            }).catch(() => {

            })
        }

        getData()
    
    }, [])

    return (
        <div className="width-100 height-100 center">

            <div className="container-help height-100">
                
                <div className="line-gray-help"></div>

                <ButtonScreenHelp text={t("Help:help:label_05")} onPress={() => navigate("/report-bug")} />

                <div className="line-gray-help"></div>

                <p className="fs-24-vh color-text mt-16 mb-4">{t("Help:help:label_04")}</p>

                <CardsTransactionsHistoryAssistance />

            </div>

        </div>
    )
}

export default AssistanceJobTaskScreen