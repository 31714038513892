import React from 'react'

import moment from 'moment-timezone';
const timezone = moment.tz.guess()

const useGetTimeLeft = (dateMilliseconds: string) => {

    return moment(dateMilliseconds).fromNow()

    /*
    const date = new Date(dateMilliseconds)

    if(isNaN(date.getDay()))
        return null

    const todayDateMilliseconds = new Date().getTime()

    const divideByMinutes = 1000 * 60 
    const divideByHours = divideByMinutes * 60
    const divideByDays = divideByHours * 24

    const difference = todayDateMilliseconds - dateMilliseconds

    if(difference < 0)
        return null

    if( difference / divideByMinutes < 60)
        return `${Math.floor(difference / divideByMinutes)}min ago`
    else if( difference / divideByHours < 24)
        return `${Math.floor(difference / divideByHours)}h ago`
    else if( difference / divideByDays < 8 )
        return `${Math.floor(difference / divideByDays)} days ago`
    else 
        return ""
        */
}

export default useGetTimeLeft