import React, { useEffect, useRef, useState } from 'react'
import { Colors } from '../../../utils/colors/colors'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { GimGimmer } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import "./TwoSelectorBarGimGimmer.css"

type SideBarSelector = "left" | "right"

type TwoSelectorBarProps = {
    initialSide: SideBarSelector
}

const TwoSelectorBarGimGimmer = ({ initialSide }: TwoSelectorBarProps) => {

    const navigate = useNavigate()

    const barRef = useRef<HTMLDivElement>(null);

    const widthOption = 17; // rem
    const heightBar = 4.7

    const [sideSelected, setSideSelected] = useState<SideBarSelector>(initialSide)
    const [leftLine, setLeftLine] = useState<number>(initialSide === "left" ? 0 : widthOption)

    const isLogged = useSelector((state: RootState) => state.auth.isLogged)
    const page = useSelector((state: RootState) => state.homeOffers.page)
    const limit = useSelector((state: RootState) => state.homeOffers.limit)
    const jobId = useSelector((state: RootState) => state.homeOffers.jobId)
    const where = useSelector((state: RootState) => state.homeOffers.where)
    const startTime = useSelector((state: RootState) => state.homeOffers.when?.startTime)
    const endTime = useSelector((state: RootState) => state.homeOffers.when?.endTime)
    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)

    const leftSideSelected = sideSelected === "left" 

    const onChange = (side: SideBarSelector) => {

        const params: any = {
            page: page.toString(),
            limit: limit.toString(),
            type: side === "left" ? "Gim" : "Gimmer",
        }

        if(jobId)
            params["jobId"] = jobId?.toString()

        if( startTime )
            params["startTime"] = startTime?.iso

        if( endTime )
            params["endTime"] = endTime?.iso

        if( where.outside && optionSelectedWhere.includes("outside") )
            params["outside"] = JSON.stringify(where.outside)

        if( where.inside && optionSelectedWhere.includes("inside") )
            params["inside"] = JSON.stringify(where.inside)

        if( optionSelectedWhere.includes("remote"))
            params["remote"] = JSON.stringify(true)

        const searchParams = createSearchParams(params);
        const pathname = isLogged ? "/home" : ""
        setSideSelected(side)
        navigate({
            pathname,
            search: searchParams.toString(),
        });
    }

    const clickBar = (newSide: SideBarSelector) => {
        onChange(newSide)
        setSideSelected(newSide)
        moveBar(newSide)
    }

    const moveBar = (newSide: SideBarSelector) => {
        const mobileDiv = document.getElementById("select-bar") 

        if(mobileDiv){

            let remsToMove = 0
            if(newSide === "right")
                remsToMove = (widthOption);
            else
                remsToMove = (-widthOption); // case after search bar

            mobileDiv.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            setLeftLine(remsToMove + leftLine)
        }
        
    }

    return (
        <div className="flex flex-jc-end fs-14 border-radius-3 p-relative two-selector-bar-home" style={{ width: `${widthOption*2}rem`, height: `${heightBar}rem` }}>
            <div 
                className={`touchable-effect-disabled width-50 gallery-bg center ${leftSideSelected ? "c-white" : "pointer color-gimmer"}`}
                style={{
                    //backgroundColor: leftSideSelected ? Colors.Gim : "white"
                }}
                onClick={() => {
                    if(!leftSideSelected)
                        clickBar("left")
                }}
            >
                Gim
            </div>

            <div 
                className={`touchable-effect-disabled width-50 gallery-bg center ${leftSideSelected ? "pointer color-gim" : "c-white"}`}
                style={{
                    //backgroundColor: !leftSideSelected ? Colors.Gimmer : "white"
                }}
                onClick={() => {
                    if(leftSideSelected)
                        clickBar("right")
                }}
            >
                Gimmer
            </div>

            <div 
                id="select-bar"
                ref={barRef}
                className={`width-50 gallery-bg center pointer p-absolute c-white`}
                style={{
                    backgroundColor: !leftSideSelected ? Colors.Gimmer : Colors.Gim,
                    width: `${widthOption}rem`,
                    height: `${heightBar}rem`,
                    top: 0,
                    left: `${leftLine.toString()}rem`
                }}
            >
                {leftSideSelected ? "Gim" : "Gimmer"}
            </div>
        </div>
    )
}

export default React.memo( TwoSelectorBarGimGimmer )