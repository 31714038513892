import Resizer from "react-image-file-resizer";

export const resizeImage = (file: any, width = 100, height = 100): Promise<string | File | Blob | ProgressEvent<FileReader>> =>
    new Promise((resolve) => {
        const type = file.type.split("General:/")
        const extension = type[type.length - 1]
        Resizer.imageFileResizer(
            file,
            width,
            height,
            extension,
            100,
            0,
            (fileBlob) => {
                resolve(fileBlob);
            },
            "blob"
        ); 
    }
);

export const rotateImage = (file: Blob, width: number, height: number, degrees: number): Promise<string | File | Blob | ProgressEvent<FileReader>> =>
    new Promise((resolve) => {
        const type = file.type.split("General:/")
        const extension = type[type.length - 1]
        Resizer.imageFileResizer(
            file,
            width,
            height,
            extension,
            100,
            degrees,
            (fileBlob) => {
                resolve(fileBlob);
            },
            "blob"
        ); 
    }
);


export const base64ToFile = (base64String: string): File => {
    const base64Data = base64String.split(',')[1]; // Remove the "data:image/png;base64," prefix
    const byteString = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([arrayBuffer], { type: 'image/png' }); // Adjust the type as needed
    const file = new File([blob], 'image.png'); // Adjust the filename as needed

    return file
  };
