import { ImageNotification } from "./ImageNotification";
import { TitleNotification } from "./TitleNotification";
import { MessageNotification } from "./MessageNotification";
import { ReceivedTimeNotification } from "./ReceivedTimeNotification";
import UserImage from "../../../../UIcomponents/UserImage/UserImage";

interface BodyNotificationProps {
	numberTextLines?: number,
	loadingImg?: boolean,
	userIdImg?: string,
	description: string,
	timestamp: number,
	title: string,
}

export const BodyNotification = (props: BodyNotificationProps) => {
	
	const { title, numberTextLines, userIdImg, description, timestamp } = props
	return(

		<div className="flex width-100 pl-10">

			{
				userIdImg &&
				<div className="mr-10">
					<UserImage userId={userIdImg} sizeImg={5.28} unit={"vh"} />
				</div>
			}
			<div className="flex-1 height-100">
				<TitleNotification title={title} />
				<MessageNotification 
					numberTextLines={numberTextLines} 
					message={description} 
				/>
				<ReceivedTimeNotification date={timestamp} />
			</div>

		</div>
	)
}
