
import { FaHouseUser, FaLaptop, FaWalking } from 'react-icons/fa'
import { WorkPreference } from '../../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import "./WhereIcons.css"
import React from 'react'

const WhereIcons = ({ outside, inside, remote }:WorkPreference) => {
    console.log("--------------", outside, inside, remote )
    return (
        <div className="con-where-icons flex">

            {
                remote &&
                <div className="con-where-icon">
                    <div className="where-icon center circle c-white fs-14 where-icon-first"><FaLaptop /></div>
                </div>
            }

            {
                inside.address &&
                <div className="con-where-icon">
                    <div className="where-icon center circle c-white fs-14 where-icon-second"><FaHouseUser /></div>
                </div>
            }

            {
                outside.address &&
                <div className="con-where-icon">
                    <div className="where-icon center circle c-white fs-14 where-icon-third"><FaWalking /></div>
                </div>
            }

        </div>
    )
}

export default React.memo( WhereIcons )