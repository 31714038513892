import { useCallback, useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { orderManageUsersReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersDataSlice"
import { checkAllCheckboxesManageUsers } from "../../../../redux/slices/components/ManageUsers/manageUsersGroupedDataSlice"
import { RootState, useAppDispatch } from "../../../../redux/store/store"
import ArrowColumn from "../../../UIcomponents/ArrosColumn/ArrowColumn"
import "./HeaderTableManageUsers.css"

const HeaderTableManageUsers = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const page =useSelector((state:RootState) => state.manageUsersData.page)
    const orderColumn = useSelector((state: RootState) => state.manageUsersData.orderColumn)
    const orderDirection = useSelector((state: RootState) => state.manageUsersData.orderDirection)
    const usersToShow = useSelector((state: RootState) => state.manageUsersData.usersToShow)
    const checkboxUsersGrouped = useSelector((state: RootState) => state.manageUsersGroupedData.checkboxUsersGrouped)


    const previousPage = useRef(page)

    const orderArray = useCallback(
      ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {
        dispatch(orderManageUsersReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
      },
      [],
    )

    const handleCheckbox = (selectAll: boolean) => {
        if(selectAll)
            dispatch(checkAllCheckboxesManageUsers({ users: usersToShow }))
        else
            dispatch(checkAllCheckboxesManageUsers({ users: [] }))
    }
      

    useEffect(() => {

    if(previousPage.current !== page){
        previousPage.current = page
        dispatch(checkAllCheckboxesManageUsers({ users: [] }))
    }
    return () => {
    }
    }, [page])
      

    return (
        <div className="ff-roboto fs-14 fw-500 con-header-table-users flex" >

            <div className="item1-table-user center">
                <input 
                    className="pointer"
                    type="checkbox"  
                    checked={checkboxUsersGrouped}
                    onChange={() => {
                        handleCheckbox(!checkboxUsersGrouped)
                    }}
                />
            </div>
            
            <div className="item2-table-user center-start">
                <p className="fs-13 fs-600">{t("General:label_134")}</p>
            </div>

            <div className="item3-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_135")}
                    orderColumn={orderColumn} 
                    columnName={"id"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="item4-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_136")}
                    orderColumn={orderColumn} 
                    columnName={"lastName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="item5-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_137")}
                    orderColumn={orderColumn} 
                    columnName={"firstName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="item6-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_138")}
                    orderColumn={orderColumn} 
                    columnName={"roleId"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="item7-table-user center-start">
                <ArrowColumn 
                    columnText={t("General:label_139")}
                    orderColumn={orderColumn} 
                    columnName={"countryName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

        </div>
    )
}

export default HeaderTableManageUsers