import React from 'react'
import "./MoneyEarnedCard.css"
import InforMoneyEarnedCard from './InforMoneyEarnedCard/InforMoneyEarnedCard'
import GraphicEarned from './GraphicEarned/GraphicEarned'
import { useTranslation } from 'react-i18next'
import { ChartIncomes } from '../StatisticsUser'

type MoneyEarnedCardProps = {
    moneyEarned: number
    jobsNumber: number
    bestSkill: string,
    topPursued: string
    chartIncomes: ChartIncomes[]
}

const MoneyEarnedCard = ({ moneyEarned, jobsNumber, bestSkill, topPursued, chartIncomes }: MoneyEarnedCardProps) => {

    const { t } = useTranslation()

    return (
        <div className="main-money-card">
            <GraphicEarned chartIncomes={chartIncomes} />
            <InforMoneyEarnedCard 
                title={`$${moneyEarned.toFixed()}`}
                description={t("Profile:transaction:label_01")}
            />
            <InforMoneyEarnedCard 
                title={bestSkill}
                description={t("Profile:transaction:label_03")}
            />
            <InforMoneyEarnedCard 
                title={jobsNumber.toString()}
                description={t("Profile:transaction:label_04")}
            />
            <InforMoneyEarnedCard 
                title={"Plumber"}
                description={t("Profile:transaction:label_05")}
            />
        </div>
    )
}

export default React.memo( MoneyEarnedCard )