import { useEffect } from "react"
import HeaderManageOffers from "../../../../components/ManageAllOffers/HeaderManageOffers/HeaderManageOffers"
import HeaderTableOffer from "../../../../components/ManageAllOffers/HeaderTableOffer/HeaderTableOffer"
import OfferRows from "../../../../components/ManageAllOffers/OfferRows/OfferRows"
import PaginationRow from "../../../../components/ManageAllOffers/PaginationRow/PaginationRow"
import TabBarManageOffers from "../../../../components/ManageAllOffers/TabBarManageOffers/TabBarManageOffers"
import { resetManageOffersReducer } from "../../../../redux/slices/components/ManageOffers/manageOffersDataSlice"
import { useAppDispatch } from "../../../../redux/store/store"
import "./ManageAllOffers.css"


const ManageAllOffers = () => {

  const dispatch = useAppDispatch()

  useEffect(() => {

      return () => {
        dispatch(resetManageOffersReducer())
      }
  }, [])
  

  return (
    <div id="manager-offers-screen-container" className="flex flex-d-column height-100 p-relative manage-offer-screen" >
        <HeaderManageOffers />
        <TabBarManageOffers />
        <HeaderTableOffer />
        <OfferRows />
        <PaginationRow />
    </div>
  )
}

export default ManageAllOffers