import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

type StatisticInfoProps = {
    value: string
    text: string
    percentage: string
    grow: boolean
}

const StatisticInfo = ({
    value,
    text,
    percentage,
    grow
}: StatisticInfoProps) => {
    return (
        <div className="1">
            <p className="fs-16 fw-600">
                {`${value} $`}
            </p>
            <p className="fs-14 color-oslo">
                {text}
            </p>
            <div className="flex flex-ai-center">
                <p className={`fs-14 mr-6 ${grow ? "color-positive" : "color-error"}`}>
                    {`${percentage}%`}
                </p>
                <FontAwesomeIcon 
                    icon={grow ? solid("arrow-up-right") : solid("arrow-down-right")} 
                    className={`fs-14 ${grow ? "color-positive" : "color-error"}`}
                />
            </div>
        </div>
    )
}

export default StatisticInfo