import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { DetailsSchedule } from '../../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { getWhenDate } from '../../../../utils/dates/dates';
import "./AvailableTimes.css"


type DetailedScheduleUI = {
  start: string,
  end: string,
  id: string,
  timeMilliseconds: number,
  hourRange?: string
}

type datesToShowUI = {
  [key: string]: DetailedScheduleUI[]
}

interface AvailableTimesProps {
  detailsSchedule: DetailsSchedule[],
  type: string
}

const u = [
  {"start": "2023-02-04 04:55:00","end": "2023-02-04 07:35:00","id": "e5b7f672-1bb2-41d2-aee3-96e9b490e419"},
  {"start": "2023-02-04 08:55:00","end": "2023-02-04 10:35:00","id": "e5b7f672-1bb2-41d2-aee3-96e9b490e419"},
  {"start": "2023-02-03 04:55:00","end": "2023-02-04 07:35:00","id": "e5b7f672-1bb2-41d2-aee3-96e9b490e419"}]



const AvailableTimes = ({ detailsSchedule, type }: AvailableTimesProps) => {
  const { t } = useTranslation();
  //detailsSchedule = u
  const [datesToShow, setDatesToShow] = useState<datesToShowUI>({})

  useEffect(() => {
    
    //const groupedDates = _.groupBy(detailsSchedule, (d: DetailsSchedule) => d.start.slice(0,10)) 
    //console.log("Grouped Dates", groupedDates)

    const WholeData: DetailedScheduleUI[] = detailsSchedule.map((d: DetailsSchedule) => ({...d, timeMilliseconds: new Date(d.start).getTime()}))
    const orderedData = WholeData.sort((a: DetailedScheduleUI, b: DetailedScheduleUI) => {
        if(a.timeMilliseconds < b.timeMilliseconds)
          return -1
        return 1
    })

    const initialValue = {} as { [key: string]: DetailedScheduleUI[] }
    const groupedDates = orderedData.reduce((accumulator: { [key: string]: DetailedScheduleUI[] }, currentValue: DetailsSchedule) => {
      const { hour, date } = getWhenDate({ start: currentValue.start, end: currentValue.end })
      if(accumulator[date]?.length > 0)
          accumulator[date].push({ ...currentValue, timeMilliseconds: new Date(currentValue.start).getTime(), hourRange: hour  })
      else
        accumulator[date] = [{ ...currentValue, timeMilliseconds: new Date(currentValue.start).getTime(), hourRange: hour   }]    
      return accumulator
    }, initialValue);

    setDatesToShow(groupedDates)

    return () => {
    }
  }, [])
  
  return (
    <div className="mt-20 mb-80" style={{ paddingBottom: "8rem" }}>
        <p className="fs-24 fw-600 mb-10">{t("General:label_008")}</p>
        {
          Object.keys(datesToShow).map((key: string, index: number) => {
            return(
              <div key={index} className="">

                  <p className="fs-18 fw-500 mt-16">{key}</p>
                  <div className="flex flex-wrap mt-8">
                  {
                    datesToShow[key].map((d: DetailedScheduleUI, indexHour: number) => {
                        return (
                          <div key={indexHour} className={`center border-radius-3 mr-12 pt-8 pb-8 fw-400 fs-16 c-white type-color-${type}`} style={{ width: "15rem" }} >
                              {d.hourRange}
                          </div>
                        )
                    })
                  }
                  </div>

              </div>
            )
          })
        }
    </div>
  )
}

export default AvailableTimes