import React from 'react'
import { FaHouseUser, FaLaptop, FaWalking } from 'react-icons/fa'
import { WorkPreferenceStr } from '../../../utils/dbModels/HistoryTransaction'

type WorkPreferenceIconProps = {
    type: WorkPreferenceStr
    size: number
    unit?: string
}

const WorkPreferenceIcon = ({ type, size, unit = "rem" }: WorkPreferenceIconProps) => {

    const style = { width: `${size}${unit}`, height: `${size}${unit}` }
    const sizeIcon = size * 0.55

    if(type === "remote")
        return( <div className="dark-gray-bg center circle c-white fs-14" style={style} ><FaLaptop style={{ fontSize: `${sizeIcon}${unit}`}} /></div> )

    else if(type === "inside" )
        return( <div className="dark-gray-bg center circle c-white fs-14" style={style} ><FaHouseUser style={{ fontSize: `${sizeIcon}${unit}`}}  /></div> )

    else // outside
        return( <div className="dark-gray-bg center circle c-white fs-14" style={style} ><FaWalking style={{ fontSize: `${sizeIcon}${unit}`}}  /></div> )
}

export default React.memo( WorkPreferenceIcon )