import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type StarsProps = {
    number: number
    size: string
}

const Stars = ({ number, size }: StarsProps) => {
    const stars = [0,0,0,0,0]
    for(let i=0; i< number; i++){stars[i] = 1}
    return (
        <div className="flex">
            {
                stars.map((star, index) => {
                    return(
                        <FontAwesomeIcon key={index} className={star ? "c-star" : "c-unselected-star"} fontSize={size} icon={solid("star")} />
                    )
                })
            }
        </div>
    )
}

export default React.memo( Stars )