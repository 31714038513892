import React from 'react'

type TitleInBorderForInputProps = {
    title: string
    name: string
}

const TitleInBorderForInput = ({ title, name }: TitleInBorderForInputProps) => {
    return (
        <label 
            style={{
                position: "absolute",
                left: ".8rem",
                top: "-.1rem",
            }} 
            htmlFor={name}
            className="c-oslo-gray fs-12-vh white-bg pl-6 pr-6"
        >
            {title}
        </label>
    )
}

export default TitleInBorderForInput