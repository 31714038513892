import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { openModalDeleteUsersFormReducer, resetManageUsersFormGroupedReducer } from '../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice';

const GroupedUsersSide = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
  
    const usersGrouped = useSelector((state: RootState) => state.manageUsersForm.usersGrouped)
    const userSelected = usersGrouped.length

    const handleDeleteMultipleUsers = () => {
        dispatch( openModalDeleteUsersFormReducer() )
    }
  
  
    const handleClear = () => {
        dispatch( resetManageUsersFormGroupedReducer() )
    }
  
  return (
    <div className="flex flex-jc-sb fs-12">
        <div className="flex flex-ai-center">
            <div className="center ml-10 mr-10">
                <input type="checkbox" disabled={true} />
            </div>
            <p className="fw-500">{`${userSelected} ${t("General:label_149").toLowerCase()}`}</p>
            <button
                onClick={handleClear}
                className="color-clear height-100 fw-500 bg-transparent ml-20 mr-28 ml-10 pointer"
            >
                {t("General:label_066")}
            </button>
            <p className="mr-2"><i>{t("General:label_067")}</i></p>
            
  
            <div onClick={handleDeleteMultipleUsers} className={`pointer flex flex-ai-center ml-14 ${userSelected === 0 ? "disable-div" : ""}`}>
                <FontAwesomeIcon icon={solid("trash-can")} className="color-gimmer fs-12 mr-4" />
                <p className="fw-500 mt-2">{t("General:label_069")}</p>
            </div>
            
        </div>
    </div>
  )
}

export default React.memo( GroupedUsersSide )