import React, { useEffect } from 'react'
import "./MyMarketplaceScreen.css"
import HeaderMarketplace from '../../../../components/MyMarketplace/HeaderMarketplace/HeaderMarketplace'
import TabsMarketplace from '../../../../components/MyMarketplace/TabsMarketplace/TabsMarketplace'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { setInitialProducts } from '../../../../redux/slices/components/Marketplace/marketplaceDataSlice'
import { useSelector } from 'react-redux'
import TableMarketplace from '../../../../components/MyMarketplace/TableMarketplace/TableMarketplace'
import ManagerMarketplaceModals from '../../../../components/MyMarketplace/ManagerModalsMarketplace/ManagerMarketplaceModals'
import PaginatedMarketplaceTableRow from '../../../../components/MyMarketplace/TableMarketplace/PaginatedMarketplaceTableRow/PaginatedMarketplaceTableRow'

const MyMarketplaceScreen = () => {

    const dispatch = useAppDispatch()

    const MyProducts = useSelector((state: RootState) => state.auth.user.MyProducts)
    const MarketPlace = useSelector((state: RootState) => state.auth.user.MarketPlace)

    useEffect(() => {
        
        dispatch( setInitialProducts({ productsBought: MyProducts ?? [], productsPublished: MarketPlace ?? [] }) )
 
    }, [])
    

    return (
        <div className="flex flex-d-column height-100">
            <HeaderMarketplace />
            <TabsMarketplace />
            <TableMarketplace />
            <ManagerMarketplaceModals />
        </div>
    )
}

export default MyMarketplaceScreen