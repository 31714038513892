import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { UserInfoCache } from '../../../../utils/staticVariables/staticVariables'
import { HistoryTransaction } from '../../../../utils/dbModels/HistoryTransaction'
import moment from 'moment'
import { TransactionType, getHistoryTransaction, getTransactionHistory } from '../../../../utils/requestsUtils/functionsRequests/transactions/history'

export type TypeOffers = "Task" | "Job"
export interface ExtraDataOffer {
    type: TypeOffers,
    //LastEvaluatedKey?: string,
    page: number,
    limit: number,
    bringTotal: boolean
}


export interface DetailsSchedule {
    id: string,
    start: string,
    end: string
}

export type HistoryExtraData = HistoryTransaction & {
    type: TypeOffers
    userName?: string
    startTime: number
    costTransaction: number
}


  
const addRemainingFieldsOffer = (item: HistoryTransaction, userId: string): HistoryExtraData => {
    const user = UserInfoCache.userNameCache.get(userId)
    //console.log("--- moment",item.schedule.startTime, moment.utc(item.schedule.startTime).toISOString() )
    return {
        ...item,
        userName: user?.name,
        type: userId === item.gimId ? "Job" : "Task",
        startTime: moment.utc(item.schedule.startTime).valueOf(),
        costTransaction: userId === item.gimId ? item.gimProfit : item.cost ?? 0   
    }
}


export const GetHistoryThunk = createAsyncThunk(
    'myHistoryData/GetHistoryThunk',
    async ({ type }: { type: TransactionType }, { getState }: any) => {
      try { 
          //const { request } = getState() as { request: REQUEST_STORE };
          const userId = getState().auth.user.id

          const res = await getTransactionHistory(type)
        
          const results: HistoryExtraData[] = res.items.map((item: HistoryTransaction) => {
              //changeNameToUppercase(offer)
              return addRemainingFieldsOffer(item, userId)
          })
          return {
            results: results,
            type,
          }
      } catch (error) {
          console.log("error", error)
          return {
            results: [] as HistoryExtraData[],
            type,
            total: 0
          }
      }
    }
)


export const GetHistoryTransactionThunk = createAsyncThunk(
    'myHistoryData/GetHistoryTransactionThunk',
    async ({ id, limit }: { id: string, limit?: number }, { getState }: any) => {
        try { 
            //const { request } = getState() as { request: REQUEST_STORE };
            const userId = getState().auth.user.id

            const historyTransaction = await getHistoryTransaction(id)

            return historyTransaction
        } catch (error) {
            console.log("error", error)
            return {} as HistoryTransaction
        }
    }
)


const initialState = {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    type: "Job" as TypeOffers,
    allHistoryItems: [] as HistoryExtraData[],
    historyToShow: [] as HistoryExtraData[],
    LastEvaluatedKey: "",
    loadingContainer: true,
    orderColumn: "",
    orderDirection: false,

    historyTransaction: {} as HistoryTransaction
}

export const myHistoryDataSlice = createSlice({
  name: 'myHistoryData',
  initialState,
  reducers: {

    changeIndexTabReducerHistory: (state, action: PayloadAction<{ indexTab: number }>) => {
        state.indexTab = action.payload.indexTab
        state.historyToShow = state.allHistoryItems.filter(h => state.indexTab === 1 ? h.type === "Job" : h.type === "Task")
        return state
    },
    orderHistoryReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
        const { orderColumn, orderDirection  } = action.payload
        /*
        state.historyToShow = state.historyToShow.map((item: HistoryExtraData) => {
          //const user = UserInfoCache.userNameCache.get(item.userId)
          //if(user?.name)
          //  offer.userName = user.name
          //return offer
        })
        */
        state.historyToShow = getOrderedState({ orderColumn, orderDirection, array: state.historyToShow })
        state.orderColumn = orderColumn
        state.orderDirection = orderDirection
        return state
    },
    resetManageOffersReducer: () => {
        return initialState
    }
  },

    extraReducers: (builder) => {
        builder.addCase(GetHistoryThunk.fulfilled, (state, action: PayloadAction<{ results: HistoryExtraData[] }>) => {
            const { results } = action.payload  
            const resultsOrdered = getOrderedState({ orderColumn: "startTime", orderDirection: false, array: results })
            state.allHistoryItems = resultsOrdered
            state.historyToShow = resultsOrdered.filter(h => state.indexTab === 1 ? h.type === "Job" : h.type === "Task")
            state.loadingContainer = false
            return state
        }).addCase(GetHistoryThunk.pending, (state, action: any) => {
            state.loadingContainer = true
            return state     
        }).addCase(GetHistoryThunk.rejected, (state, action: any) => {
            return initialState
        }).addCase(GetHistoryTransactionThunk.fulfilled, (state, action: PayloadAction<HistoryTransaction>) => {
            state.historyTransaction = action.payload
            state.loadingContainer = false
            return state
        }).addCase(GetHistoryTransactionThunk.pending, (state, action: any) => {
            state.loadingContainer = true
            return state     
        }).addCase(GetHistoryTransactionThunk.rejected, (state, action: any) => {
            initialState.loadingContainer = false
            return initialState
        })
    },

});

// Action creators are generated for each case reducer function
export const {  
  changeIndexTabReducerHistory,
  resetManageOffersReducer,
  orderHistoryReducer,
} = myHistoryDataSlice.actions

export default myHistoryDataSlice.reducer