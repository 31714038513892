import React from 'react'
import "./DashboardHeaderTable.css"
import InputList from '../../../UIcomponents/InputList/InputList'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { useTranslation } from 'react-i18next'
import HorizontalSeparator from '../../../UIcomponents/HorizontalSeparator/HorizontalSeparator'

const DashboardHeaderTable = () => {
    const { t } = useTranslation()
    return (
        <div className="segment-dashboard mb-6 flex flex-d-column flex-jc-sb" style={{ height: "14rem" }}>
            <div className="flex flex-jc-sb pl-18 pr-18 pt-13">
                <InputList width="22rem" height="3.2rem" >
                <p></p>
                </InputList>
                <button type="submit" className="export-btn pointer fs-14">
                    { t("General:label_253")}
                </button>
            </div>

            <div className="flex flex-jc-sb pl-18 pr-18">
                <div className="">
                    <p className="fs-16 fw-500">
                        The essential of world
                    </p>
                    <p className="fs-14">
                        Data from 
                    </p>
                </div>

                <div className="flex flex-jc-sb">
                    <InputList width="26rem" height="3.2rem" >
                    <p></p>
                    </InputList>
                    <HorizontalSeparator distance={"5rem"} />
                    <InputList width="26rem" height="3.2rem" >
                    <p></p>
                    </InputList>
                    <HorizontalSeparator distance={"5rem"} />
                    <InputList width="26rem" height="3.2rem" >
                    <p></p>
                    </InputList>
                </div>
            </div>
        </div>
    )
}

export default DashboardHeaderTable