import React from 'react'

type ButtonSubmitFormINEProps = {
    onPress: (e: any) => Promise<void>
}

const ButtonSubmitFormINE = ({ onPress }: ButtonSubmitFormINEProps) => {
    return (
        <div className="center">
            <button onClick={onPress} className="dark-gray-bg border-radius-6 pl-20 pr-20 pt-10 pb-10 mt-10 pointer">
                <p className="c-white fs-15 fw-500">Submit</p>
            </button>
        </div>
    )
}

export default React.memo( ButtonSubmitFormINE )