import React, { useCallback, useEffect, useRef, useState } from 'react'
import "./SimulatorGraphic.css"
import Stars from '../../../UIcomponents/Stars/Stars'
import ReactangleDotted from '../../../UIcomponents/ReactangleDotted/ReactangleDotted'
import BarSimulator from '../../../UIcomponents/BarSimulator/BarSimulator'
import DetailedPriceCard from '../DetailedPriceCard/DetailedPriceCard'
import { getGimPriceData, getGimmerPriceData, getGimworkPriceData } from '../../../../utils/numbers/numbers'
import { JobCategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { useAppDispatch } from '../../../../redux/store/store'
import { setUpdateStateTablesJobsReducer } from '../../../../redux/slices/components/ManagerJobs/managerJobsTables'
import { useNavigate } from 'react-router-dom'
const bc = new BroadcastChannel("test_channel2");
export type TypePrice = "Gim" | "Gimmer" | "Gimwork"

type SimulatorGraphicProps = {
    gimStars: number
    gimmerStars: number
    error: boolean
    price: number
    malusGim: number
    malusGimmer: number
    malusShareGim: number
    malusShareGimmer: number
    minRevenue: number // RMP
    tax: number
    timeHours: number
    productPrice: number
    timeAdded: number
    kmCovered:  number
    tip:        number
    jobSelected: JobCategoryJob
}

const SimulatorGraphic = ({ 
    gimStars, 
    gimmerStars, 
    error,
    price,
    malusGim,
    malusGimmer,
    malusShareGim,
    malusShareGimmer,
    minRevenue,
    tax,
    timeHours,
    productPrice,
    timeAdded,
    kmCovered,
    tip,
    jobSelected,
}: SimulatorGraphicProps) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const divRef = useRef<HTMLDivElement>(null);

    const [width, setWidth] = useState(0);


    const [hoveredInfo, setHoveredInfo] = useState<{ isHovered: boolean, type: TypePrice }>({
        isHovered: false,
        type: "Gimmer"
    })

    const [gimmerPrice, setGimmerPrice] = useState({
        price: 0,
        leftGimmerRectangle: 0,
        rightGimmerRectangle: 0,
        segmentBarGimmerStars: {
            start: 0, 
            end: 0,
            color: "#6290C3",
        },
        segmentBarTAX: {
            start: 0, 
            end: 0,
            color: "#6290C3",
        }
    })

    const [gimPrice, setGimPrice] = useState({
        price: 0,
        widthRMP: 0,
        widthJobPrice: 0,
        segmentBarGimmerStars: {
            start: 0, 
            end: 0,
            color: "#6290C3",
        },
        segmentBarJobPrice: {
            start: 0, 
            end: 0,
            color: "#6290C3",
        }
    })


    const [gimworkPrice, setGimworkPrice] = useState({
        price: 0,
        segmentBarGimmerStars: {
            start: 0, 
            end: 0,
            color: "#000",
        },
        segmentBarRightStars: {
            start: 0, 
            end: 0,
            color: "#000",
        },
        segmentBarTAX: {
            start: 0, 
            end: 0,
            color: "#000",
        }
    })

    const heightSimulator = "11.8vh"
    const topGimmerBar = "5.16vh"
    const topGimBar = "7.37vh"
    const topGimworkBar = "9.54vh"

    const widthDottedRectangle = 24
    const gapDottedRectangle = 6

    const widthSpaceBetweenSimulatorAndTAX = 7
    const widthTax = 74 

    const widthDottedRectangles = 5 * widthDottedRectangle + 4 * gapDottedRectangle

    const middleWidth = width - ( widthDottedRectangles * 2 ) - widthTax - widthSpaceBetweenSimulatorAndTAX // space between stars

    const limitLeftStarsRight = width - ( widthDottedRectangles ) - widthTax
    const limitRightStarsLeft = widthDottedRectangles

    const widthBars = width - gimmerPrice.leftGimmerRectangle
    
    const rmpcProduct = 5

    useEffect(() => {

        if (divRef.current) {
          setWidth(divRef.current.clientWidth);
        }

    }, [divRef.current]); // Run only when ref changes}


    useEffect(() => {

        if(error)
            return 

        const portionGainStarsLeft = (gimmerStars / 5) * widthDottedRectangles 

        const portionLostStarsLeft = widthDottedRectangles - portionGainStarsLeft
        
        /*
        if(gimmerStars >= 1)
            setLeftGimmerRectangle(portionLostStarsLeft + gapDottedRectangle / 2)
        else
            setLeftGimmerRectangle(portionLostStarsLeft)
    */
        const portionGainStarsRight = (gimStars / 5) * widthDottedRectangles 

        const portionLostStarsRight = widthDottedRectangles - portionGainStarsRight


        //setRightGimmerRectangle(limitLeftStarsRight + portionGainStarsRight)

        const malusShareGimDecimal = Number( malusShareGim ) / 100
        
        const malusShareGimmerDecimal = Number(malusShareGimmer) / 100

        const { total: totalGimwork } = getGimworkPriceData({ 
                malusGim,
                malusGimmer,
                price,
                gimmerStars,
                gimStars,
                tax, 
                RMP: minRevenue,
                malusShareGim,
                malusShareGimmer,
                timeHours,
                timeAdded,
                productPrice,
                rmpcProduct
            })

        const { total: totalGim } = getGimPriceData({ 
                malusGim: malusGim, 
                malusGimmer, 
                price: price, 
                gimmerStars,
                gimStars,
                RMP: minRevenue,
                malusShareGimmer,
                timeHours,
                timeAdded,
                productPrice
            })

        const { total: totalGimmer } = getGimmerPriceData({ 
                malusGim: malusGim , 
                malusGimmer, 
                price: price, 
                gimmerStars,
                gimStars: gimStars,
                tax: tax, 
                malusShareGim,
                timeHours,
                timeAdded,
                productPrice,
                rmpcProduct
            })

        const totalWidthRMP_JobPrice = middleWidth + portionGainStarsRight
        const widthRMP = totalWidthRMP_JobPrice * Number(minRevenue) / 100
        const widthPrice = totalWidthRMP_JobPrice - widthRMP

        const startGimmerLeft = portionGainStarsLeft
        const barPriceGimmerWidth = portionLostStarsLeft + middleWidth + portionGainStarsRight + (portionLostStarsRight * (1 - malusShareGimDecimal))


        setGimmerPrice({
            price: totalGimmer, 
            leftGimmerRectangle: startGimmerLeft,
            //rightGimmerRectangle: limitLeftStarsRight +  portionGainStarsRight , // limit with blue stars,
            rightGimmerRectangle: limitLeftStarsRight +  widthDottedRectangles,
            segmentBarGimmerStars: {
                start: 0, 
                end: barPriceGimmerWidth, // barPriceGimmerWidth - widthSpaceBetweenSimulatorAndTAX
                color: "#E54B48",
            },
            segmentBarTAX: {
                start: widthBars - widthTax, 
                end: widthBars,
                color: "#E54B48",
            }
        })

        setGimPrice({
            price: totalGim,
            widthRMP: widthRMP,
            widthJobPrice: widthPrice,
            segmentBarGimmerStars: {
                start: 0, 
                end: portionLostStarsLeft * malusShareGimmerDecimal,
                color: "#6290C3",
            },
            segmentBarJobPrice: {
                start: portionLostStarsLeft + widthRMP, 
                end: portionLostStarsLeft + widthRMP + widthPrice,
                color: "#6290C3",
            }
        })

        setGimworkPrice({
            price: totalGimwork,
            segmentBarGimmerStars: {
                start: portionLostStarsLeft * malusShareGimmerDecimal,   // measures relative to star left bar
                end: portionLostStarsLeft + widthRMP,
                color: "#363843",
            },
            segmentBarRightStars: {
                start: barPriceGimmerWidth, 
                end: barPriceGimmerWidth + (portionLostStarsRight * malusShareGimDecimal) ,
                color: "#363843",
            },
            segmentBarTAX: {
                start: widthBars - widthTax, 
                end: widthBars,
                color: "#363843",
            }
        })

        const state = {
            jobSelected,
            gimStars,
            gimmerStars, 
            price, 
            malusGim,
            malusGimmer, 
            malusShareGim, 
            malusShareGimmer, 
            minRevenue, 
            tax, 
            timeHours, 
            productPrice, 
            timeAdded,
            rmpcProduct,
            kmCovered,
            tip
        }
        dispatch( setUpdateStateTablesJobsReducer(state))

        bc.postMessage(state);
        bc.onmessage = (event) => {
            //console.log(event);
            if(event.data === "SEND_INFORMATION"){
                bc.postMessage(state)
            }
        };

    }, [gimStars, gimmerStars, price, malusGim, malusGimmer, malusShareGim, malusShareGimmer, minRevenue, tax, timeHours, productPrice, timeAdded, kmCovered, tip, jobSelected.id, width, widthBars]) 
    // width: to ensure that the new value of width will be used

    useEffect(() => {
      
        // By an unknow reason, if i set this bc.close in the same use effect that the methods of bc for send and receive messages, the app crashes
        return () => {
            console.log("DESTROYED")
            //bc.close();
        }
    }, [])
    
    
    const onMouseOver = useCallback(
        (type: TypePrice) => {
            setHoveredInfo({
                isHovered: true,
                type
            })
        },
        [],
    )
      
      
      const onMouseOut = useCallback(
        (type: TypePrice) => {
            setHoveredInfo({
                isHovered: false,
                type
            })
        },
        [],
    )

      const onPress = useCallback(
        (type: TypePrice) => {
            //navigate(`/table-simulator/${type}`)
            window.open(`${process.env.REACT_APP_DOMAIN_URL}/table-simulator/${type}`, '_blank');
        },
        [],
    )

    return (
        <div ref={divRef} style={{ minHeight: "2rem", backgroundColor: "white", marginTop: "2.5vh" }}>

            <div className="flex flex-jc-sb" style={{ paddingRight: `${widthTax + widthSpaceBetweenSimulatorAndTAX}px` }}>
                <Stars number={gimmerStars > 5 || gimmerStars < 0 ? 5 : gimmerStars} size="2.4vh" />
                <Stars number={gimStars > 5 || gimStars < 0 ? 5 : gimStars} size="2.4vh" />
            </div>

            <div className="p-relative mt-8">

                {
                    [0,0,0,0,0].map((m, index) => {
                        return(
                            <div key={index} className="p-absolute" style={{ left: `${index * widthDottedRectangle + index * gapDottedRectangle}px`, zIndex: 1 }}>
                                <ReactangleDotted width={`${widthDottedRectangle}px`} height={heightSimulator} />
                            </div>
                        )
                    })
                }
                {
                    [0,0,0,0,0].map((m, index) => {
                        return(
                            <div key={index} className="p-absolute" style={{ right: `${(index * widthDottedRectangle + index * gapDottedRectangle + 74 + widthSpaceBetweenSimulatorAndTAX )}px`, zIndex: 1 }}>
                                <ReactangleDotted width={`${widthDottedRectangle}px`} height={heightSimulator} />
                            </div>
                        )
                    })
                }

                <div className="" style={{ backgroundColor: "white", height: heightSimulator }}></div>







                <div 
                    className="p-absolute orange-simulator-bg" 
                    style={{ 
                        top: "0", 
                        width: `${gimmerPrice.rightGimmerRectangle - gimmerPrice.leftGimmerRectangle}px`, 
                        height: heightSimulator, 
                        left: `${gimmerPrice.leftGimmerRectangle}px`, 
                        //transform: "translateX(-50%)" 
                    }}
                ></div>







                <div 
                    className="p-absolute job-price-bg" 
                    style={{ 
                        top: "0", 
                        width: `${gimPrice.widthJobPrice}px`, 
                        height: heightSimulator, 
                        left: `${limitRightStarsLeft + gimPrice.widthRMP}px`, 
                        //transform: "translateX(-50%)" 
                    }}
                >
                    <p className="fs-14 fw-700 text-align-center mt-12">JOB PRICE</p>
                </div>

                <div 
                    className="p-absolute blue-RMP-bg" 
                    style={{ 
                        top: "0", 
                        width: `${gimPrice.widthRMP}px`, 
                        height: heightSimulator, 
                        left: `${limitRightStarsLeft}px`, 
                        //transform: "translateX(-50%)" 
                    }}
                >
                    <p className="fs-14 fw-700 text-align-center mt-12">RMP</p>
                </div>



                {
                    /*
                        <div 
                            className="p-absolute" 
                            style={{ 
                                top: "0", 
                                width: `${middleWidth}px`, 
                                height: heightSimulator, 
                                left: `${gimmerPrice.leftGimmerRectangle}px`, 
                                //transform: "translateX(-50%)" 
                                backgroundColor: "green"
                            }}
                        ></div>
                    */
                }



                <div 
                    className="p-absolute violet-tax" 
                    style={{ 
                        top: "0", 
                        width: `${widthTax}px`, 
                        height: heightSimulator, 
                        right: 0,
                        //transform: "translateX(-50%)" 
                    }}
                >
                    <p className="fs-14 fw-700 text-align-center mt-10">TAX</p>
                </div>

                <BarSimulator 
                    width={widthBars} 
                    segments={[gimmerPrice.segmentBarGimmerStars, gimmerPrice.segmentBarTAX]} 
                    start={gimmerPrice.leftGimmerRectangle} 
                    top={"5.16vh"} 
                    type={"Gimmer"}
                    cssClass="bar-simulator-Gimmer"
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onPress={onPress}
                />
                <BarSimulator 
                    width={widthBars} 
                    segments={[gimPrice.segmentBarGimmerStars, gimPrice.segmentBarJobPrice]} 
                    start={gimmerPrice.leftGimmerRectangle} 
                    top={"7.37vh"} 
                    type={"Gim"}
                    cssClass="Gim-bar"
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onPress={onPress}
                />
                <BarSimulator 
                    width={widthBars} 
                    segments={[gimworkPrice.segmentBarGimmerStars, gimworkPrice.segmentBarRightStars, gimworkPrice.segmentBarTAX]} 
                    start={gimmerPrice.leftGimmerRectangle} 
                    top={"9.54vh"} 
                    type={"Gimwork"}
                    cssClass="Gimwork-bar"
                    onMouseOver={onMouseOver}
                    onMouseOut={onMouseOut}
                    onPress={onPress}
                />
                <div 
                    className="p-absolute" 
                    style={{ 
                        backgroundColor: "white", 
                        height: heightSimulator, 
                        width: `${widthSpaceBetweenSimulatorAndTAX}px`,
                        right: widthTax,
                        top: 0 
                    }}
                >
                </div>


                {
                    //hoveredInfo.isHovered &&
                    /*
                    <DetailedPriceCard 
                        //price={price}
                        //malusGim={malusGim}
                        //malusGimmer={malusGimmer}
                        gimStars={gimStars}
                        gimmerStars={gimmerStars}
                        RMP={minRevenue}
                        type={hoveredInfo.type}
                        //malusShareGim={malusShareGim}
                        //malusShareGimmer={malusShareGimmer}
                        tax={tax}
                        timeHours={timeHours}
                        productPrice={productPrice}
                        timeAdded={timeAdded}
                        rmpcProduct={rmpcProduct}
                        isHovered={hoveredInfo.isHovered }
                        topGimmerBar={topGimmerBar}
                        topGimBar={topGimBar}
                        topGimworkBar={topGimworkBar}
                        isInSimulator={true}
                        kmCovered={kmCovered}
                        tip={tip}
                        data={jobSelected.pricingInformation.day}
                    />
                    */
                }


            </div>

            <div className="flex mt-20 mb-20">
                <p className="fs-14 fw-600">Total</p>
                <p className="fs-14 fw-600 color-gimmer ml-16">{`GIMMER ${gimmerPrice.price.toFixed(2)}$`}</p>
                <p className="fs-14 fw-600 color-gim ml-16">{`GIM ${gimPrice.price.toFixed(2)}$`}</p>
                <p className="fs-14 fw-600 ml-16" style={{ color: "#363843" }}>{`GIMWORK ${gimworkPrice.price.toFixed(2)}$`}</p>
            </div>

        </div>
    )
}

export default React.memo( SimulatorGraphic ) 