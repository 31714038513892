import React from 'react'
import "./HeaderTableRoles.css"
import { useTranslation } from 'react-i18next'
import BarSelectRole from './BarSelectRole/BarSelectRole'

const HeaderTableRoles = React.memo(() => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-jc-sb flex-ai-center con-header-table-roles pl-18 pr-18">
        <p className="color-oslo fs-14">
            {t("General:label_241")}
        </p>

        <BarSelectRole />
    </div>
  )
})

export default HeaderTableRoles