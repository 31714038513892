import React from 'react'

type ImgSignUp = "Name" | "Password" | "Phone" | "Email" | "ProfilePicture" | "Id" | "Select_type_Gimmer" | "Select_type_Gim" | "Welcome"

const ImageSignUp = {
    Name:                   <img className="" src="./assets/svg/name.svg" alt="name" style={{ height: "42vh" }} />,
    Password:               <img className="" src="./assets/svg/password.svg" alt="name" style={{ height: "37vh" }} />,
    Phone:                  <img className="" src="./assets/svg/phone_number.svg" alt="phone number" style={{ height: "44.6vh" }} />,
    Email:                  <img className="" src="./assets/svg/email.svg" alt="email" style={{ height: "47.3vh" }} />,
    ProfilePicture:         <img className="" src="./assets/svg/profile_picture.svg" alt="profile picture" style={{ height: "43.3vh" }} />,
    Id:                     <img className="" src="./assets/svg/id.svg" alt="id" style={{ height: "43.3vh" }} />,
    Select_type_Gimmer:     <img className="" src="./assets/svg/select_type_gimmer.svg" alt="name" style={{ height: "25.5vh" }} />,
    Select_type_Gim:        <img className="" src="./assets/svg/select_type_gim.svg" alt="name" style={{ height: "24.4vh" }} />,
    Welcome:                <img className="" src="./assets/svg/welcome.svg" alt="name" style={{ height: "38vh" }} />,
    ConfirmEmail:           <img className="" src="./assets/svg/confirmEmail.svg" alt="name" style={{ height: "44vh" }} />,
}

export default ImageSignUp