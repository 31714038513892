import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import { Colors } from '../../../utils/colors/colors'
import { useNavigate } from 'react-router-dom'
import { SignUpOutput, autoSignIn, resendSignUpCode, signUp } from 'aws-amplify/auth'
import { verifyEmailExists } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { getUserCredentials } from '../../../utils/requestsUtils/axiosUtils'
import { setEmailSignUp } from '../../../redux/slices/components/SignUp/SignUpSlice'
import { useTranslation } from 'react-i18next'

const EmailScreen = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const firstName = useSelector((state: RootState) => state.signUp.data.firstName)
    const lastName = useSelector((state: RootState) => state.signUp.data.lastName)
    const password = useSelector((state: RootState) => state.signUp.data.password)
    const phoneNumber = useSelector((state: RootState) => state.signUp.data.phoneNumber)
    const code = useSelector((state: RootState) => state.signUp.data.phoneNumber.code)

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState("")

    useEffect(() => {
        if(!firstName || !lastName || !password || !phoneNumber || !code){
            navigate("/login")
            return
        }

    }, [])


    

    const handleNextStep = async(output: SignUpOutput) => {
		const { nextStep } = output;
		switch (nextStep.signUpStep) {
			case 'CONFIRM_SIGN_UP':
				const codeDeliveryDetails = nextStep.codeDeliveryDetails;
				console.log(
					`Confirmation code was sent to ${codeDeliveryDetails?.deliveryMedium}.`
				);
                dispatch( setEmailSignUp({ mail: email }) )
                navigate("/confirm-email")
				//this.props.saveMail(email);
				//this.props.navigation.navigate('MailValidationScreen', { type: "NEW-USER" });
				break;
			case 'COMPLETE_AUTO_SIGN_IN':
				autoSignIn()
                .then((data: any) => {
                    console.log("Then 2", data)
                }).catch((error: any) => {
                    console.log("error then 2", error)
                })
				break
			case 'DONE':
				console.log(`attribute was successfully updated`);

				break;
		}
	}
    

    const onNext = () => {
        setLoading(true)
        checkIfUserCanBeRegistered(email)
        .then((res) => {
            if(res === "EMAIL_REGISTERED"){

            }
            
            const userName = email.replace('@', '_');
            signUp({
                username: userName,
                password,
                options: {
                    autoSignIn: true,
                    userAttributes: {
                        name: `${firstName} ${lastName}`,
                        email: email,
                        phone_number: `${phoneNumber.code}${phoneNumber.phoneNumber.replace(/\s/g, "")}`,
                    }

                }
            }).then((output) => {
                //this.props.saveMail(email);
                //this.props.navigation.navigate('MailValidationScreen', { type: "NEW-USER" });
                console.log("output", output)
                handleNextStep(output)
                //resolve('');
            })
            .catch((error) => {
                console.log("Error cognito sign up",error);
                console.log("---", error.message === 'Email already registered.')
                if (error.message === 'PreSignUp failed with error Email already registered.') {
                    resendSignUpCode({ username: userName })
                    .then((res) => {
                        //this.props.saveMail(email);
                        //this.props.navigation.navigate('MailValidationScreen', { type: 'USER-EXIST' });
                        //resolve('');
                        console.log("res", res)
                        dispatch( setEmailSignUp({ mail: email }) )
                        navigate("/confirm-email", { state: { errorEmailScreen: t("SignUp:emailScreen:label_02") ?? "" } })
                    })
                    .catch((error) => {
                        console.log('Error resendSignUp', error);
                        //this.props.navigation.popToTop();
                    });
                    //reject('USER_ALREADY_REGISTERED');
                    setError(t("SignUp:emailScreen:label_02") ?? "")
                }
            }).finally(() => {
                setLoading(false)
            })
                
        })
        .catch((error) => {
            console.log("Error", error)
            setLoading(false)
        }).finally(() => {
            
        })
    }

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const checkIfUserCanBeRegistered = (email: string) => {
        return new Promise((resolve, reject) => {
            verifyEmailExists(email)
            .then((data: any) => {
                resolve("EMAIL_REGISTERED")
            }).catch((error) => {
                console.log("Error", error.response?.data)
                if(error.response?.data === "MAIL_NOT_FOUND"){
                    resolve("Ok")
                }
                else if(error.response?.data === "USER_REGISTERED_BY_FEDERATIVE"){
                    setError(t("SignUp:emailScreen:label_03") ?? "")
                    reject("Not Ok")
                }
                else{
                    setError(t("SignUp:emailScreen:label_04") ?? "")
                    reject("Not Ok")
                }
            })
        })
    
    }
    

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb">

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="ml-10" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">{t("SignUp:emailScreen:label_05")}</p>

                    <input 
                        style={{
                            width: "100%",
                            borderBottomWidth: "1px",
                            borderBottomColor: Colors.TEXT,
                            borderBottomStyle: "solid",
                            marginTop: "4.4vh"
                        }}
                        className="fs-24-vh outline-none"
                        placeholder={t("SignUp:emailScreen:label_06") ?? ""}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <div className="p-relative">
                        <p className="p-absolute color-error fs-12 mt-6">
                            {error}
                        </p>
                    </div>
                </div>

                <div className="mr-70">
                    { ImageSignUp["Email"] }
                </div>
            </div>

            <div className="flex flex-jc-sb ml-10" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:emailScreen:label_07")} 
                    disabled={!email}
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default EmailScreen