import "./BottomRowTableUsersForm.css"
import GroupedUsersFormSide from "./GroupedUsersFormSide/GroupedUsersFormSide"
import PaginationUsersFormTable from "./PaginationUsersFormTable/PaginationUsersFormTable"

const BottomRowTableUsersForm = () => {

  return(
    <div className="con-bottom-row-table-users flex flex-ai-center flex-jc-sb">
        <GroupedUsersFormSide />
        <PaginationUsersFormTable />
    </div>
  )

}

export default BottomRowTableUsersForm