import moment from "moment"
import { TransactionDate } from "../../components/Help/ListCardsTransactionHistory/ListCardsTransactionHistory"


export const orderTransactions = (transactions: TransactionDate[]) => {
    transactions.sort((a,b) => {
        if(new Date(a.schedule.startTime).getMilliseconds() > new Date(a.schedule.startTime).getMilliseconds())
            return 1
        else
            return -1
    })
    const dates: { [key: string]: boolean } = { }
    const newTransactions = transactions.map((t) => {
        const date = moment(t.schedule.startTime).format("DD-MM-YYYY").toString()

        if(!dates[date])
            t.date = date
        
        dates[ date ] = true

        return t
    })

    return newTransactions
}