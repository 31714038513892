import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import ClickOutside from '../../../../UIcomponents/ClickOutside/ClickOutside'
import { resetModalSignUp } from '../../../../../redux/slices/components/SignUp/SignUpSlice'
import ModalContentSelectJob from './ModalContentSelectJob/ModalContentSelectJob'

const ModalSelectJob = () => {

    const dispatch = useAppDispatch()

    const modalCategoryJobs = useSelector((state: RootState) => state.signUp.modalCategoryJobs)
    const jobsAdded = useSelector((state: RootState) => state.signUp.jobsAdded)

    const handleClickOutsideModal = (e: any) => {
        const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickOutsideModal && e.target.id === "darkened-background-modal")
            dispatch( resetModalSignUp() )
    }
    
    const closeModal = useCallback(
        () => {
                dispatch( resetModalSignUp() ) 
        },
        [],
    )
    

    return (
        <>
            {
            modalCategoryJobs.length > 0 &&
            <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
                <div id={"background-aux-modal"}>
                    <ModalContentSelectJob jobsAdded={jobsAdded} modalCategoryJobs={modalCategoryJobs} closeModal={closeModal} />
                </div>
            </div>
            }
        </>
    )
}

export default React.memo( ModalSelectJob )