import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getRules } from '../../../../utils/requestsUtils/functionsRequests/rules'
import { Countries, Country, getS3GeneralInformationUri, Languages } from '../../../../utils/staticVariables/staticVariables'
import axios from 'axios'
import { LanguageLabel } from '../../authSlice'

//type Country = "Mexico" | "France"
export type ModalNameRules = "AddContractModal" | "DeleteContractModal" | "EditContractModal" | ""


export type ContractInfo = {
    countryName: Country
    contract: {
        es?: string
        en?: string
        fr?: string
    }
}

export const getRulesChunk = createAsyncThunk(
    'rules/getRulesChunk',
    async (thunkAPI) => {

        try {
            /*
            const items = await getRules();
            const generalInformationItems: any = []
            const promises: any = []
            items.forEach((item: any) => {
                const country = item.Key.split("/")[1]
                item.country = country
                item.uri = getS3GeneralInformationUri(item.Key)
                console.log("item", item)
                generalInformationItems.push(item)
                promises.push(axios.get(item.uri))

            })
*/          
            const promises: any[] = []
            const generalInformationItems: any[] = []

            Countries.forEach((country, index) => {
                Languages.forEach((language) => {
                    promises.push( axios.get(getS3GeneralInformationUri(`/${country}/contract_${process.env.REACT_APP_STAGE}_${language}.txt`)) )    
                    generalInformationItems.push( { countryName: country, contract: {}, language } )
                })
            })

            const res = await Promise.allSettled(promises)

            let MexicoObj: ContractInfo = {
                countryName: "Mexico",
                contract: {}
            }

            let FranceObj: ContractInfo = {
                countryName: "France",
                contract: {}
            }

            res.forEach((response, index) => {
                if(response.status === "fulfilled"){
                    if(generalInformationItems[index].countryName as Country === "Mexico"){
                        MexicoObj = {
                            ...MexicoObj, 
                            contract: {
                                ...MexicoObj.contract,
                                [generalInformationItems[index].language]: response.value.data
                            }
                        }
                    }else if(generalInformationItems[index].countryName as Country === "France"){
                        FranceObj = {
                            ...FranceObj, 
                            contract: {
                                ...FranceObj.contract,
                                [generalInformationItems[index].language]: response.value.data
                            }
                        }
                    }
    
                }
            })

            const contracts : ContractInfo[] = []

            if(Object.keys(MexicoObj.contract).length > 0)
                contracts.push(MexicoObj)

            if(Object.keys(FranceObj.contract).length > 0)
                contracts.push(FranceObj)

            console.log("contracts", contracts)
            return contracts
        } catch (error) {
            console.log("error", error)
            return [] as ContractInfo[]
        }

    }
)


const initialState = {
    modalName: "" as ModalNameRules,
    contracts: [] as ContractInfo[],
    AddContractModalState: false,
    DeleteContractModalState: {} as ContractInfo,
    EditContractModalState: {} as ContractInfo,
    loadingContainer: false
};

export const rulesSlice = createSlice({
    name: 'rules',
    initialState,
    reducers: {
        setAddContractModalState: (state) => {
            state.modalName = "AddContractModal"
            return state;
        },
        setDeleteContractModalState: (state, action: PayloadAction<{ countryName: Country }>) => {
            state.modalName = "DeleteContractModal"

            const contractInfo = state.contracts.find((c) => c.countryName === action.payload.countryName)
            if(contractInfo)
                state.DeleteContractModalState = contractInfo

            return state;
        },
        setEditContractModalState: (state, action: PayloadAction<{ countryName: Country }>) => {
            state.modalName = "EditContractModal";

            const contractInfo = state.contracts.find((c) => c.countryName === action.payload.countryName)
            if(contractInfo)
                state.EditContractModalState = contractInfo

            return state;
        },
        setAddContractState: (state, action: PayloadAction<ContractInfo>) => {
            state.contracts.push(action.payload)
            return state;
        },
        setDeleteContractState: (state, action: PayloadAction<string>) => {
            state.contracts = state.contracts.filter(i => i.countryName !== action.payload)
            return state;
        },
        setEditContractState: (state, action: PayloadAction<{ countryName: Country, language: LanguageLabel, contract: string }>) => {
            const { countryName, language, contract } = action.payload
            /*
            state.contracts.forEach((i) => {
                if(i.countryName === countryName){
                    i.contract[language] = contract
                }
            })*/
            for(let i=0; i < state.contracts.length; i++){
                if(state.contracts[i].countryName === countryName)
                    state.contracts[i].contract[language] = contract
            }
            return state;
        },
        resetModalsValueFromRulesState: (state) => {
            state.modalName = ""
            state.DeleteContractModalState = {} as ContractInfo
            state.EditContractModalState = {} as ContractInfo
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRulesChunk.fulfilled, (state, action: PayloadAction<ContractInfo[]>) => {
            state.contracts = action.payload
            state.loadingContainer = false
            return state
        }).addCase(getRulesChunk.pending, (state, action: any) => {
            state.loadingContainer = true
            return state     
        }).addCase(getRulesChunk.rejected, (state, action: any) => {
            state.loadingContainer = false
            return state
        })
    }
});

const inputsBody = {
    nombre: "",
    email: "",
    tel: "",
    direccion: "",
    codigo: "",
    alcaldia: "",
    colonia: "",
    estado: "",
    comentarios: ""   
}

// Action creators are generated for each case reducer function
export const { 
    setAddContractModalState,
    setDeleteContractModalState,
    setEditContractModalState,
    setAddContractState,
    setDeleteContractState,
    setEditContractState,
    resetModalsValueFromRulesState
} = rulesSlice.actions

export default rulesSlice.reducer