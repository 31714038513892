import React from 'react'
import { TypePrice } from '../SimulatorGraphic/SimulatorGraphic'
import { getGimPriceData, getGimmerPriceData, getGimworkPriceData, getPriceData, twoDecimalsToPercentage } from '../../../../utils/numbers/numbers'
import "./DetailedPriceCard.css"
import { useTranslation } from 'react-i18next'
import { getStyles } from '../../../../utils/colors/colors'
import { DayInputs, NightInputs, PricingInformation } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'

type DetailedPriceCardProps = {
    data: DayInputs | NightInputs
    kmCovered:  number
    tip:        number
    gimStars: number
    gimmerStars: number
    RMP: number
    type: TypePrice
    tax: number
    isHovered: boolean
    topGimmerBar: string
    topGimBar: string
    topGimworkBar: string
    timeHours: number
    productPrice: number
    timeAdded: number
    rmpcProduct: number
    isInSimulator: boolean
}


const DetailedPriceCard = ({ 
    gimStars,
    gimmerStars,
    RMP,
    type,
    tax,
    timeHours = 1,
    productPrice,
    timeAdded,
    rmpcProduct,
    isHovered,
    topGimmerBar,
    topGimBar,
    topGimworkBar,
    isInSimulator,
    data,
    kmCovered,
    tip
 }: DetailedPriceCardProps) => {

    const { t } = useTranslation()

    let datA: any[][] = []
    let dataExtraTime: any[][] = []
    let dataTransport: any[][] = []
    let dataFixe: any[][] = []
    let dataTips: any[][] = []
    let dataTimeAdded: any[][] = []
    let dataProduct: any[][] = []
    let totalPaid = ""
    let title = ""

    const { price, malusGim, malusGimmer, malusShareGim, malusShareGimmer, IVA } = data

    const langLabel = "MXN$"
    
    const {
        //Gimmer
        transaction_bonusGim_HT,
        transaction_MalusGimmer_HT,
        transaction_GimmerIVA,
        transaction_GimmerTotal_HT,
        transaction_GimmerPaid_TTC,
        //Gimwork
        transaction_bonusGim,
        transaction_bonusGimmer,
        transaction_RMP_TTC,
        transaction_GimworkTotal_TTC,
        transaction_GimworkIVA,
        transaction_GimworkIncome_HT,
        //Gim
        transaction_MalusGim_TTC,
        transaction_bonusGimmer_TTC,
        transaction_GimTotal_HT,
        transaction_GimIVA,
        transaction_GimISR,
        transaction_GimIncome_TVA,
        transaction_ReportGimwork,
        //Tax
        transaction_TaxGimmer,
        transaction_TaxGim,
        transaction_TaxGimwork,
        transaction_TaxTotal,
        //Stripe 
        transaction_StripeGim,
        transaction_StripeGimmer,
        transaction_Stripe_TTC,
        transaction_TVA_Stripe,
        transaction_Stripe_HT,
        transaction_ReportStripe,

        // Gimmer
        extraTime_bonusGim_HT,
        extraTime_MalusGimmer_HT,
        extraTime_GimmerIVA,
        extraTime_GimmerTotal_HT,
        extraTime_GimmerPaid_TTC,
        //Gimwork
        extraTime_bonusGim,
        extraTime_bonusGimmer,
        extraTime_RMP_TTC,
        extraTime_GimworkTotal_TTC,
        extraTime_GimworkIVA,
        extraTime_GimworkIncome_HT,
        //Gim
        extraTime_MalusGim_TTC,
        extraTime_bonusGimmer_TTC,
        extraTime_GimTotal_HT,
        extraTime_GimIVA,
        extraTime_GimISR,
        extraTime_GimIncome_TVA,
        extraTime_ReportGimwork,
        //Tax
        extraTime_TaxGimmer,
        extraTime_TaxGim,
        extraTime_TaxGimwork,
        extraTime_TaxTotal,
        //Stripe
        extraTime_StripeGim,
        extraTime_StripeGimmer,
        extraTime_Stripe_TTC,
        extraTime_TVA_Stripe,
        extraTime_Stripe_HT,
        extraTime_ReportStripe,

        //Gimmer
        transport_GimmerTotal_HT,
        transport_GimmerIVA,
        transport_GimmerPaid_TTC,
        //Gimwork
        transport_Gimwork_RMP_TTC,
        transport_GimworkTotal_TTC,
        transport_GimworkIVA,
        transport_GimworkIncome_HT,
        //Gim
        transport_GimIVA,
        transport_GimISR,
        transport_GimTotal_HT,
        transport_GimIncome_TVA,
        transport_ReportGimwork,
        //Tax
        transport_TaxGimmer,
        transport_TaxGim,
        transport_TaxGimwork,
        transport_TaxTotal,
        //Stripe
        transport_StripeGim,
        transport_StripeGimmer,
        transport_Stripe_TTC,
        transport_TVA_Stripe,
        transport_Stripe_HT,
        transport_ReportStripe,
        

        //Gimmer
        fixe_bonusGim_HT,
        fixe_MalusGimmer_HT,
        fixe_GimmerTotal_HT,
        fixe_GimmerIVA,
        fixe_GimmerPaid_TTC,
        //Gimwork
        fixe_bonusGim,
        fixe_bonusGimmer,
        fixe_RMP_TTC,
        fixe_GimworkTotal_TTC,
        fixe_GimworkIVA,
        fixe_GimworkIncome_HT,
        //Gim
        fixe_MalusGim_TTC,
        fixe_bonusGimmer_TTC,
        fixe_GimTotal_HT,
        fixe_GimIVA,
        fixe_GimISR,
        fixe_GimIncome_TVA,
        fixe_ReportGimwork,
        //Tax
        fixe_TaxGimmer,
        fixe_TaxGim,
        fixe_TaxGimwork,
        fixe_TaxTotal,
        //Stripe
        fixe_StripeGim,
        fixe_StripeGimmer,
        fixe_Stripe_TTC,
        fixe_TVA_Stripe,
        fixe_Stripe_HT,
        fixe_ReportStripe,

        //Gimmer
        tip_GimmerTotal_HT,
        tip_GimmerIVA,
        tip_GimmerPaid_TTC,
        //Gimwork
        tip_RMP_TTC,
        tip_GimworkTotal_TTC,
        tip_GimworkIVA,
        tip_GimworkIncome_HT,
        //Gim
        tip_GimTotal_HT,
        tip_GimIVA,
        tip_GimISR,
        tip_GimIncome_TVA,
        tip_ReportGimwork,
        //Tax
        tip_TaxGimmer,
        tip_TaxGim,
        tip_TaxGimwork,
        tip_TaxTotal,
        //Stripe
        tip_StripeGim,
        tip_StripeGimmer,
        tip_Stripe_TTC,
        tip_TVA_Stripe,
        tip_Stripe_HT,
        tip_ReportStripe,


    } = getPriceData({
        transaction_price: price,
        transaction_malusGim: twoDecimalsToPercentage(malusGim),
        transaction_malusGimmer: twoDecimalsToPercentage(malusGimmer),
        transaction_malusShareGim: twoDecimalsToPercentage(malusShareGim),
        transaction_malusShareGimmer: twoDecimalsToPercentage(malusShareGimmer),
        transaction_IVA: twoDecimalsToPercentage(IVA),
        

        timeTransaction_gimIVA: twoDecimalsToPercentage(data.timeTransaction.gimIVA),
        timeTransaction_gimmerIVA: twoDecimalsToPercentage(data.timeTransaction.gimmerIVA),
        timeTransaction_gimworkIVA: twoDecimalsToPercentage(data.timeTransaction.gimworkIVA),
        timeTransaction_ISR: twoDecimalsToPercentage(data.timeTransaction.ISR),
        timeTransaction_RMP: twoDecimalsToPercentage(data.timeTransaction.RMP),

        transport_price: data.transportCosts?.price ?? 0,               
        transport_gimIVA: twoDecimalsToPercentage(data.transportCosts?.gimIVA),            
        transport_gimmerIVA: twoDecimalsToPercentage(data.transportCosts?.gimmerIVA),           
        transport_gimworkIVA: twoDecimalsToPercentage(data.transportCosts?.gimworkIVA),           
        transport_ISR: twoDecimalsToPercentage(data.transportCosts?.ISR),                  
        transport_RMP: twoDecimalsToPercentage(data.transportCosts?.RMP),

        fixe_price: data.fixe?.price ?? 0,               
        fixe_gimIVA: twoDecimalsToPercentage(data.fixe?.gimIVA),            
        fixe_gimmerIVA: twoDecimalsToPercentage(data.fixe?.gimmerIVA),           
        fixe_gimworkIVA: twoDecimalsToPercentage(data.fixe?.gimworkIVA), 
        fixe_ISR: twoDecimalsToPercentage(data.fixe?.ISR),
        fixe_RMP: twoDecimalsToPercentage(data.fixe?.RMP),
    
        tip_price: tip,               
        tip_gimIVA: twoDecimalsToPercentage(data.tips?.gimIVA),            
        tip_gimmerIVA: twoDecimalsToPercentage(data.tips?.gimmerIVA),           
        tip_gimworkIVA: twoDecimalsToPercentage(data.tips?.gimworkIVA),         
        tip_ISR: twoDecimalsToPercentage(data.tips?.ISR),          
        tip_RMP: twoDecimalsToPercentage(data.tips?.RMP),
        
        product_gimIVA: twoDecimalsToPercentage(data.product?.gimIVA),
        product_gimmerIVA: twoDecimalsToPercentage(data.product?.gimmerIVA),
        product_gimworkIVA: twoDecimalsToPercentage(data.product?.gimworkIVA),
        product_RMP: twoDecimalsToPercentage(data.product?.RMP),

        gimStars,
        gimmerStars,
        timeAdded,
        kmCovered,
        tip 
    })

    if(type === "Gimmer"){
        title = t("ManageJobs:detailed:label_04")
        const {
            /*
            calculationMalusGim,
            calculationMalusGimmer,
            taxTotal,
            stripeComission,
            totalOutcome,
            priceByHours,
            total,

            calculationMalusGimTimeAdded,
            calculationMalusGimmerTimeAdded,
            priceByHoursTimeAdded,
            totalTimeAdded,
            stripeComissionTimeAdded,
            totalOutcomeTimeAdded,
            taxTotalTimeAdded,

            rmpcTotalProduct,
            taxTotalProduct,
            totalOutcomeProduct,
            stripeComissionProduct,
            totalProduct*/
        } = getGimmerPriceData({ malusGim, malusGimmer, price, gimmerStars, gimStars, tax, malusShareGim, timeHours, timeAdded, productPrice, rmpcProduct })

        const priceTimeAdded = price * timeAdded
        const fixePrice = data.fixe?.price ?? 0
        const transportPrice = data.transportCosts?.price ?? 0
        const tipPrice = data.tips?.tip ?? 0

        datA =[
            [`${t("ManageJobs:table:label_01")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",                                 `${t("ManageJobs:detailed:label_06")}`,     "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,         "Share %",                                          `${t("ManageJobs:detailed:label_11")} /h`,      `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                         `${t("ManageJobs:detailed:label_10")}` ],
            [ `${t("ManageJobs:detailed:label_06")}`,   "-",                                    `${getStyles(`${price} ${langLabel}`, "blue")}`,        "-",                                            "-",                                            "-",                                                `${timeHours} h`,                               `${getStyles(price, "orange")} x ${timeHours}`,                                                                                                                                                                                 `${getStyles(`${price} ${langLabel}`, "blue")}` ],
            [ "Malus GIM",                              "-",                                    ``,                                         `${getStyles(`${malusGim}%`, "gim")}`,          `${getStyles(`${gimStars}`, "orange")}`,        `${getStyles(`${malusShareGimmer}%`, "share")}`,    "-",                                            `${getStyles(`${(malusShareGimmer / 100 )}`, "share")} x (( ${getStyles(`${price}`, "blue")} x ${getStyles(`${malusGim/100}`, "gim")} ) x (${getStyles(`5 - ${gimStars}`, "orange")}) )`,                                       `-${getStyles(`${transaction_bonusGim_HT} ${langLabel}`, "green")}`],
            [ "Malus GIMMER",                           "-",                                    ``,                                         `${getStyles(`${malusGimmer}%`, "gimmer")}`,    `${getStyles(`${gimmerStars}`, "orange")}`,     `-`,                                                "-",                                            `( ${getStyles(`${price}`, "blue")} x ${getStyles(`${malusGimmer/100}`, "gimmer")}) x (${getStyles(`5 - ${gimmerStars}`, "orange")})`,                                                                                          `${getStyles(`${transaction_MalusGimmer_HT} ${langLabel}`, "japonica")}`],
            [ ``,                                       ``,                                     ``,                                         "-",                                            "IVA",                                          "-",                                                `${data.timeTransaction.gimmerIVA}%`,              `( ${getStyles(`${price}`, "blue")} - ${getStyles(transaction_bonusGim_HT, "green")} + ${getStyles(transaction_MalusGimmer_HT, "japonica")} ) x ${getStyles(`${(data.timeTransaction.gimmerIVA/100).toFixed(2)}`, "gray")}`,    `${getStyles(`${transaction_GimmerIVA} ${langLabel}`, "violet")}`],     
            [ "",                                       ``,                                     ``,                                         "",                                             "",                                             "",                                                 "",                                             `${getStyles(`${price}`, "blue")} - ${getStyles(transaction_bonusGim_HT, "green")} + ${getStyles(transaction_MalusGimmer_HT, "japonica")} + ${getStyles(`${transaction_GimmerIVA}`, "violet")}`,                                `${getStyles(`${transaction_GimmerPaid_TTC} ${langLabel}`, "square-result")}` ],   

            [`${t("ManageJobs:table:label_02")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",                                 `${t("ManageJobs:detailed:label_06")}`,     "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,         "Share %",                                          `${t("ManageJobs:detailed:label_11")} /h`,      `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                                 `${t("ManageJobs:detailed:label_10")}` ],
            [ `${t("ManageJobs:detailed:label_06")}`,   "-",                                    `${getStyles(`${price} ${langLabel}`, "blue")}`,        "-",                                            "-",                                            "-",                                                `${priceTimeAdded} h`,                          `${getStyles(price, "orange")} x ${timeAdded}`,                                                                                                                                                                                         `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}` ],
            [ "Malus GIM",                              "-",                                    ``,                                         `${getStyles(`${malusGim}%`, "gim")}`,          `${getStyles(`${gimStars}`, "orange")}`,        `${getStyles(`${malusShareGimmer}%`, "share")}`,    "-",                                            `${getStyles(`${(malusShareGimmer / 100 )}`, "share")} x (( ${getStyles(`${priceTimeAdded}`, "blue")} x ${getStyles(`${malusGim/100}`, "gim")} ) x (${getStyles(`5 - ${gimStars}`, "orange")}) )`,                                      `-${getStyles(`${extraTime_bonusGim_HT} ${langLabel}`, "green")}`],
            [ "Malus GIMMER",                           "-",                                    ``,                                         `${getStyles(`${malusGimmer}%`, "gimmer")}`,    `${getStyles(`${gimmerStars}`, "orange")}`,     `-`,                                                "-",                                            `( ${getStyles(`${priceTimeAdded}`, "blue")} x ${getStyles(`${malusGimmer/100}`, "gimmer")}) x (${getStyles(`5 - ${gimmerStars}`, "orange")})`,                                                                                         `${getStyles(`${extraTime_MalusGimmer_HT} ${langLabel}`, "japonica")}`],
            [ ``,                                       ``,                                     ``,                                         "-",                                            "IVA",                                          "-",                                                `${data.timeTransaction.gimmerIVA}%`,              `( ${getStyles(`${priceTimeAdded}`, "blue")} - ${getStyles(extraTime_bonusGim_HT, "green")} + ${getStyles(extraTime_MalusGimmer_HT, "japonica")} ) x ${getStyles(`${(data.timeTransaction.gimmerIVA/100).toFixed(2)}`, "gray")}`,      `${getStyles(`${extraTime_GimmerIVA} ${langLabel}`, "violet")}`],     
            [ "",                                       ``,                                     ``,                                         "",                                             "",                                             "",                                                 "",                                             `${getStyles(`${priceTimeAdded}`, "blue")} - ${getStyles(extraTime_bonusGim_HT, "green")} + ${getStyles(extraTime_MalusGimmer_HT, "japonica")} + ${getStyles(`${extraTime_GimmerIVA}`, "violet")}`,                                 `${getStyles(`${extraTime_GimmerPaid_TTC} ${langLabel}`, "square-result")}` ],   
        
            [`${t("ManageJobs:table:label_03")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",            "LINE"],
            [ "",                                       `${t("ManageJobs:detailed:label_25")} $/km`,                                           `${t("ManageJobs:detailed:label_26")} km`,          "",     ``,         "",     ``,                                     `${t("ManageJobs:detailed:label_09")}`,                                                                                 `${t("ManageJobs:detailed:label_10")}` ],
            [ `${t("ManageJobs:table:label_03")}`,   `${getStyles(`${transportPrice} ${langLabel}`, "blue")}`,           `${kmCovered}`,        "-",     "-",        "-",    ``,                                     `${getStyles(`${transportPrice}$`, "blue")} x ${kmCovered}`,                                                            `${getStyles(`${transport_GimmerTotal_HT}$`, "blue")}` ],
            [ ``,                                       ``,                                                     ``,                     "-",    "IVA",      "-",    `${data.transportCosts?.gimmerIVA ?? 0}%`,   `${getStyles(`${transport_GimmerTotal_HT}$`, "blue")} x ${((data.transportCosts?.gimmerIVA ?? 0)/100).toFixed(2)}`,     `${getStyles(`${transport_GimmerIVA}$`, "violet")}`],    
            [ "",                                       ``,                                                     ``,                     "",     "",         "",     "",                                     `${getStyles(`${transport_GimmerTotal_HT}$`, "blue")} + ${getStyles(`${transport_GimmerIVA}$`, "violet")}`,             `${getStyles(`${transport_GimmerPaid_TTC} $`, "square-result")}` ], 


            [`${t("ManageJobs:table:label_04")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",                                 `${t("ManageJobs:detailed:label_06")}`,     "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,         "Share %",                                          `${t("ManageJobs:detailed:label_11")} /h`,      `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                         `${t("ManageJobs:detailed:label_10")}` ],
            [ "Malus GIM",                              "-",                                    `${t("ManageJobs:detailed:label_10")}`,     `${getStyles(`${malusGim}%`, "gim")}`,          `${getStyles(`${gimStars}`, "orange")}`,        `${getStyles(`${malusShareGimmer}%`, "share")}`,    "-",                                            `${getStyles(`${(malusShareGimmer / 100 )}`, "share")} x (( ${getStyles(`${fixePrice}`, "blue")} x ${getStyles(`${malusGim/100}`, "gim")} ) x (${getStyles(`5 - ${gimStars}`, "orange")}) )`,                                       `-${getStyles(`${fixe_bonusGim_HT} ${langLabel}`, "green")}`],
            [ "Malus GIMMER",                           "-",                                    `${t("ManageJobs:detailed:label_10")}`,     `${getStyles(`${malusGimmer}%`, "gimmer")}`,    `${getStyles(`${gimmerStars}`, "orange")}`,     `-`,                                                "-",                                            `( ${getStyles(`${fixePrice}`, "blue")} x ${getStyles(`${malusGimmer/100}`, "gimmer")}) x (${getStyles(`5 - ${gimmerStars}`, "orange")})`,                                                                                          `${getStyles(`${fixe_MalusGimmer_HT} ${langLabel}`, "japonica")}`],
            [ ``,                                       ``,                                     ``,                                         "-",                                            "IVA",                                          "-",                                                `${data.fixe?.gimmerIVA ?? 0}%`,                `( ${getStyles(`${fixePrice}`, "blue")} - ${getStyles(fixe_bonusGim_HT, "green")} + ${getStyles(fixe_MalusGimmer_HT, "japonica")} ) x ${getStyles(`${((data.fixe?.gimmerIVA??0)/100).toFixed(2)}`, "gray")}`,       `${getStyles(`${fixe_GimmerIVA} ${langLabel}`, "violet")}`],     
            [ "",                                       ``,                                     ``,                                         "",                                             "",                                             "",                                                 "",                                             `${getStyles(`${fixePrice}`, "blue")} - ${getStyles(fixe_bonusGim_HT, "green")} + ${getStyles(fixe_MalusGimmer_HT, "japonica")} + ${getStyles(`${fixe_GimmerIVA}`, "violet")}`,                                     `${getStyles(`${fixe_GimmerPaid_TTC} ${langLabel}`, "square-result")}` ],   
        
            [`${t("ManageJobs:table:label_05")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",            "LINE"],
            [ "",                                       "Price",                                        ``,        "",     ``,         "",     ``,                           `${t("ManageJobs:detailed:label_09")}`,                                                                  `${t("ManageJobs:detailed:label_10")}` ],
            [ `${t("ManageJobs:table:label_05")}`,   `${getStyles(`${tip} ${langLabel}`, "blue")}`,  ``,        "-",     "-",        "-",    ``,                           ``,                                                                                                    `${getStyles(`${tip_GimmerTotal_HT} ${langLabel}`, "blue")}` ],
            [ ``,                                       ``,                                             ``,        "-",    "IVA",      "-",    `${data.tips?.gimmerIVA ?? 0}%`,   `${getStyles(`${tip_GimmerTotal_HT}`, "blue")} x ${((data.tips?.gimmerIVA ?? 0)/100).toFixed(2)}`,     `${getStyles(`${tip_GimmerIVA} ${langLabel}`, "violet")}`],    
            [ "",                                       ``,                                             ``,         "",     "",         "",     "",                           `${getStyles(`${tip_GimmerTotal_HT}`, "blue")} + ${getStyles(`${tip_GimmerIVA}`, "violet")}`,        `${getStyles(`${tip_GimmerPaid_TTC} ${langLabel}`, "square-result")}` ], 

        
        ]


/*
        dataTimeAdded =[
            [`${t("ManageJobs:detailed:label_12")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",               "",                                     `${t("ManageJobs:detailed:label_06")}`,                            "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,                                        "Share %",                                      `${t("ManageJobs:detailed:label_11")} /h`,          `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                                                      `${t("ManageJobs:detailed:label_10")}` ],
            [ `${t("ManageJobs:detailed:label_06")}`,      "-",                                    `${getStyles(`${price}$`, "blue")}`,    "-",                                            "-",                                            "-",                                            `${timeAdded} h`,   `${getStyles(price, "orange")} x ${timeAdded}`,                                                                                                                                                                                     `${getStyles(`${priceByHoursTimeAdded}$`, "blue")}` ],
            [ "Malus GIM",      "-",                                    ``,                                     `${getStyles(`${malusGim}%`, "gim")}`,          `${getStyles(`${gimStars}`, "orange")}`,        `${getStyles(`${malusShareGim}%`, "share")}`,   "-",                `${getStyles(`${(malusShareGim / 100 )}`, "share")} x (( ${getStyles(`${price}`, "blue")} x ${getStyles(`${malusGim/100}`, "gim")} ) x (${getStyles(`5 - ${gimStars}`, "orange")}) )`,                                              `-${getStyles(`${calculationMalusGimTimeAdded}$`, "green")}`],
            [ "Malus GIMMER",   "-",                                    ``,                                     `${getStyles(`${malusGimmer}%`, "gimmer")}`,    `${getStyles(`${gimmerStars}`, "orange")}`,     `-`,                                            "-",                `( ${getStyles(`${priceByHoursTimeAdded}`, "blue")} x ${getStyles(`${malusGimmer/100}`, "gimmer")}) x (${getStyles(`5 - ${gimmerStars}`, "orange")})`,                                                                              `${getStyles(`${calculationMalusGimmerTimeAdded}$`, "japonica")}`],
            [ `${t("ManageJobs:detailed:label_21")}`,            `${getStyles(`${tax}%`, "gray")}`,      ``,                                     "-",                                            "-",                                            "-",                                            "",                 `( ${getStyles(`${priceByHoursTimeAdded}`, "blue")} - ${getStyles(calculationMalusGimTimeAdded, "green")} + ${getStyles(calculationMalusGimmerTimeAdded, "japonica")} ) x ${getStyles(`${(tax/100).toFixed(2)}`, "gray")}`,         `${getStyles(`${taxTotalTimeAdded}$`, "violet")}`],
            [ "",               ``,                                     ``,                                     "-",                                            "-",                                            "-",                                            "",                 `${t("ManageJobs:detailed:label_17")}: ${getStyles(`${priceByHoursTimeAdded}`, "blue")} - ${getStyles(calculationMalusGimTimeAdded, "green")} + ${getStyles(calculationMalusGimmerTimeAdded, "japonica")} + ${getStyles(`${taxTotalTimeAdded }$`, "violet")}`,    `${getStyles(`${totalOutcomeTimeAdded}$`, "stripe")}`],
            [ "",               ``,                                     ``,                                     "",                                             "",                                             "",                                             "",                 `Fees (Stripe): 3 + (${getStyles(`${totalOutcomeTimeAdded}`, "stripe")} * 0.05)`,                                                                                                                                                        `${getStyles(`${stripeComissionTimeAdded} $`, "gimmer")}` ],    
            [ "",               ``,                                     ``,                                     "",                                             "",                                             "",                                             "",                 `${t("ManageJobs:detailed:label_20")}`,                                                                                                                                                                                                                      `${getStyles(`${totalTimeAdded} $`, "square-result")}` ],       
        ]

        dataProduct =[
            [`${t("ManageJobs:detailed:label_13")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",               "",                                     `${t("ManageJobs:detailed:label_14")}`,                                    "RMP Sales %",                            "",     "Tax %",                                 `${t("ManageJobs:detailed:label_11")} /h`,  `${t("ManageJobs:detailed:label_09")}`,                                                                                                                              `${t("ManageJobs:detailed:label_10")}` ],
            [ "RMPC",           "-",                                    `${getStyles(`${productPrice}$`, "orange")}`,       `${getStyles(`${rmpcProduct}%`, "gim")}`, "",     "-",                                     `   `,      `${getStyles(productPrice, "orange")} x ${getStyles(`${rmpcProduct/100}`, "gim")}`,                                                        `${getStyles(`${rmpcTotalProduct}$`, "blue")}` ],
            [ `${t("ManageJobs:detailed:label_15")}`,    "-",                                    `${getStyles(`${productPrice}$`, "orange")}`,       "-",                                      "",     `${getStyles(`${tax}%`, "gray")}`,       "",         `${getStyles(productPrice, "orange")} x ${getStyles(`${tax/100}`, "gray")}`,                                                               `${getStyles(`${taxTotalProduct}$`, "green")}`],
            [ "",               ``,                                     ``,                                                 "-",                                      "",      "-",                                    "",         `${t("ManageJobs:detailed:label_17")}: ${getStyles(`${productPrice}`, "orange")} - ${getStyles(rmpcTotalProduct, "green")} + ${getStyles(taxTotalProduct, "japonica")}`,  `${getStyles(`${totalOutcomeProduct}$`, "stripe")}`],
            [ "",               ``,                                     ``,                                                 "",                                       "",      "",                                     "",         `Fees (Stripe): 3 + (${getStyles(`${totalOutcomeProduct}`, "stripe")} * 0.05) + (${getStyles(`${productPrice}`, "stripe")} x 0.0025)`,      `${getStyles(`${stripeComissionProduct} $`, "gimmer")}` ],    
            [ "",               ``,                                     ``,                                                 "",                                       "",     "",                                     "",         `${t("ManageJobs:detailed:label_20")}`,                                                                                                                              `${getStyles(`${totalProduct} $`, "square-result")}` ],       
        ]

        totalPaid = `<p style="font-size:12px;textAlignCenter:center"> 
            Total Paid   
            ${getStyles(`${total}$`, "black-bold")} </p>`
            */
    }else if(type === "Gim"){
        title = t("ManageJobs:detailed:label_05")
        const {
            calculationMalusGim,
            calculationMalusGimmer,
            rmpTotal,
            totalOutcome,
            stripeComission,
            priceByHours,
            total,

            rmpTotalTimeAdded,
            calculationMalusGimTimeAdded,
            calculationMalusGimmerTimeAdded,
            priceByHoursTimeAdded,
            totalOutcomeTimeAdded,
            stripeComissionTimeAdded,
            totalTimeAdded
        } = getGimPriceData({ malusGim, malusGimmer, price, gimmerStars, gimStars, RMP, malusShareGimmer, timeHours, timeAdded, productPrice })



        datA =[
            [`${t("ManageJobs:detailed:label_22")}`,    "LINE",                                     "LINE",                                         "LINE",                                         "LINE",                                                                     "LINE",                                                                                                                                                                                                                 "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "GIMMER HT",                                `GIMMER IVA`,                                   "ISR %",                                        `GIMWORK TTC`,                                                              `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                  `${t("ManageJobs:detailed:label_10")}`],
            ["GIM Total HT",                            `${transaction_GimTotal_HT} ${langLabel}`,  "-",                                            "-",                                            "-",                                                                        "-",                                                                                                                                                                                                                     `${getStyles(`${transaction_GimTotal_HT} ${langLabel}`, "blue")}`],
            ["GIM IVA",                                 "-",                                        `${transaction_GimmerIVA} ${langLabel}`,        "-",                                            "-",                                                                        `${transaction_GimmerIVA} / 2`,                                                                                                                                                                                          `${getStyles(`${transaction_GimIVA} ${langLabel}`, "green")}`],
            ["ISR",                                     "-",                                        "-",                                            `${data.timeTransaction.ISR ?? 0}%`,            "-",                                                                        `${getStyles(`${transaction_GimTotal_HT}`, "blue")} x ${(data.timeTransaction.ISR/100).toFixed(2)}`,                                                                                                                     `${getStyles(`-${transaction_GimISR} ${langLabel}`, "japonica")}`],
            ["GIM Income",                              "-",                                        "-",                                            "-",                                            `${getStyles(`${transaction_GimworkTotal_TTC} ${langLabel}`, "orange")}`,   `${getStyles(`${transaction_GimTotal_HT}`, "blue")} + ${getStyles(`${transaction_GimIVA}`, "green")} + ${getStyles(`${transaction_GimISR}`, "japonica")} - ${getStyles(`${transaction_GimworkTotal_TTC}`, "orange")}`,   `${getStyles(`${transaction_GimIncome_TVA} ${langLabel}`, "square-result")}`],

            [`${t("ManageJobs:detailed:label_22")}`,    "LINE",                                     "LINE",                                         "LINE",                                         "LINE",                                                                     "LINE",                                                                                                                                                                                                          "LINE"],
            [ "",                                       "GIMMER HT",                                `GIMMER IVA`,                                   "ISR %",                                        `GIMWORK TTC`,                                                              `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                          `${t("ManageJobs:detailed:label_10")}`],
            ["GIM Total HT",                            `${extraTime_GimTotal_HT} ${langLabel}`,    "-",                                            "-",                                            "-",                                                                        "-",                                                                                                                                                                                                             `${getStyles(`${extraTime_GimTotal_HT} ${langLabel}`, "blue")}`],
            ["GIM IVA",                                 "-",                                        `${extraTime_GimmerIVA} ${langLabel}`,          "-",                                            "-",                                                                        `${extraTime_GimmerIVA} / 2`,                                                                                                                                                                                    `${getStyles(`${extraTime_GimIVA} ${langLabel}`, "green")}`],
            ["ISR",                                     "-",                                        "-",                                            `${data.timeTransaction.ISR ?? 0}%`,            "-",                                                                        `${getStyles(`${extraTime_GimTotal_HT}`, "blue")} x ${(data.timeTransaction.ISR/100).toFixed(2)}`,                                                                                                               `${getStyles(`-${extraTime_GimISR} ${langLabel}`, "japonica")}`],
            ["GIM Income",                              "-",                                        "-",                                            "-",                                            `${getStyles(`${extraTime_GimworkTotal_TTC} ${langLabel}`, "orange")}`,     `${getStyles(`${extraTime_GimTotal_HT}`, "blue")} + ${getStyles(`${extraTime_GimIVA}`, "green")} + ${getStyles(`${extraTime_GimISR}`, "japonica")} - ${getStyles(`${extraTime_GimworkTotal_TTC}`, "orange")}`,   `${getStyles(`${extraTime_GimIncome_TVA} ${langLabel}`, "square-result")}`],

            [`${t("ManageJobs:detailed:label_22")}`,    "LINE",                                     "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "GIMMER HT",                                    `GIMMER IVA`,                                   "ISR %",                                        `GIMWORK TTC`,                                                              `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                                     `${t("ManageJobs:detailed:label_10")}`],
            ["GIM Total HT",                            `${transport_GimmerTotal_HT} ${langLabel}`,     "-",                                            "-",                                             "-",                                                                       "-",                                                                                                                                                                                                                                        `${getStyles(`${transport_GimTotal_HT} ${langLabel}`, "blue")}`],
            ["GIM IVA",                                 "-",                                            `${transport_GimmerIVA} ${langLabel}`,          "-",                                            "-",                                                                        `${transport_GimmerIVA} / 2`,                                                                                                                                                                                                             `${getStyles(`${transport_GimIVA} ${langLabel}`, "green")}`],
            ["ISR",                                     "-",                                            "-",                                            `${data.transportCosts?.ISR ?? 0}%`,                 "-",                                                                        `${getStyles(`${transport_GimTotal_HT}`, "blue")} x ${((data.transportCosts?.ISR ?? 0)/100).toFixed(2)}`,                                                                                                                                       `${getStyles(`-${transport_GimISR} ${langLabel}`, "japonica")}`],
            ["GIM Income",                              "-",                                            "-",                                            "-",                                            `${getStyles(`${transport_GimworkTotal_TTC} ${langLabel}`, "orange")}`,   `${getStyles(`${transport_GimTotal_HT}`, "blue")} + ${getStyles(`${transport_GimIVA}`, "green")} + ${getStyles(`${transport_GimISR}`, "japonica")} - ${getStyles(`${transport_GimworkTotal_TTC}`, "orange")}`,   `${getStyles(`${transport_GimIncome_TVA} ${langLabel}`, "square-result")}`],

            [`${t("ManageJobs:detailed:label_22")}`,    "LINE",                                     "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "GIMMER HT",                                `GIMMER IVA`,                                   "ISR %",                                        `GIMWORK TTC`,                                                              `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                                     `${t("ManageJobs:detailed:label_10")}`],
            ["GIM Total HT",                            `${fixe_GimTotal_HT} ${langLabel}`,  "-",                                            "-",                                             "-",                                                                       "-",                                                                                                                                                                                                                                        `${getStyles(`${fixe_GimTotal_HT}$`, "blue")}`],
            ["GIM IVA",                                 "-",                                        `${fixe_GimmerIVA} ${langLabel}`,        "-",                                            "-",                                                                        `${fixe_GimmerIVA} / 2`,                                                                                                                                                                                                             `${getStyles(`${fixe_GimIVA} ${langLabel}`, "green")}`],
            ["ISR",                                     "-",                                        "-",                                            `${data.timeTransaction.ISR ?? 0}%`,                 "-",                                                                        `${getStyles(`${fixe_GimTotal_HT}`, "blue")} x ${((data.fixe?.ISR ?? 0)/100).toFixed(2)}`,                                                                                                                                       `${getStyles(`-${fixe_GimISR} ${langLabel}`, "japonica")}`],
            ["GIM Income",                              "-",                                        "-",                                            "-",                                            `${getStyles(`${fixe_GimworkTotal_TTC} ${langLabel}`, "orange")}`,   `${getStyles(`${fixe_GimTotal_HT}`, "blue")} + ${getStyles(`${fixe_GimIVA}`, "green")} + ${getStyles(`${fixe_GimISR}`, "japonica")} - ${getStyles(`${fixe_GimworkTotal_TTC}`, "orange")}`,   `${getStyles(`${fixe_GimIncome_TVA} ${langLabel}`, "square-result")}`],

            [`${t("ManageJobs:detailed:label_22")}`,    "LINE",                                     "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "GIMMER HT",                                `GIMMER IVA`,                                   "ISR %",                                        `GIMWORK TTC`,                                                              `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                                     `${t("ManageJobs:detailed:label_10")}`],
            ["GIM Total HT",                            `${tip} ${langLabel}`,  "-",                                            "-",                                             "-",                                                                       "-",                                                                                                                                                                                                                                        `${getStyles(`${tip}$`, "blue")}`],
            ["GIM IVA",                                 "-",                                        `${tip_GimmerIVA} ${langLabel}`,        "-",                                            "-",                                                                        `${tip_GimmerIVA} / 2`,                                                                                                                                                                                                             `${getStyles(`${tip_GimIVA} ${langLabel}`, "green")}`],
            ["ISR",                                     "-",                                        "-",                                            `${data.tips?.ISR ?? 0}%`,                 "-",                                                                        `${getStyles(`${tip_GimTotal_HT}`, "blue")} x ${((data.tips?.ISR ?? 0)/100).toFixed(2)}`,                                                                                                                                       `${getStyles(`-${tip_GimISR} ${langLabel}`, "japonica")}`],
            ["GIM Income",                              "-",                                        "-",                                            "-",                                            `${getStyles(`${tip_GimworkTotal_TTC} ${langLabel}`, "orange")}`,   `${getStyles(`${tip_GimTotal_HT}`, "blue")} + ${getStyles(`${tip_GimIVA}`, "green")} + ${getStyles(`${tip_GimISR}`, "japonica")} - ${getStyles(`${tip_GimworkTotal_TTC}`, "orange")}`,   `${getStyles(`${tip_GimIncome_TVA} ${langLabel}`, "square-result")}`],
        ]

        /*
        dataTimeAdded =[
            [`${t("ManageJobs:detailed:label_12")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",               "RMP%",                             `${t("ManageJobs:detailed:label_06")}`,                                "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,                                        "Share %",                                      `${t("ManageJobs:detailed:label_11")} /h`,                     `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                      `${t("ManageJobs:detailed:label_10")}`],
            [ `${t("ManageJobs:detailed:label_06")}`,      "-",                                `${getStyles(`${price}$`, "blue")}`,       "-",                                            "-",                                            "-",                                             `${timeAdded} h`,                 `${getStyles(price, "orange")} x ${timeAdded}`,                                                                                                                                                                                              `${getStyles(`${priceByHoursTimeAdded}$`, "blue")}`],
            [ "Malus GIM",      "-",                                ``,                                         `${getStyles(`${malusGim}%`, "gim")}`,         `${getStyles(`${gimStars}`, "orange")}`,       `-`,                                              "",                 `( ${getStyles(`${priceByHoursTimeAdded}`, "blue")} x ${getStyles(`${malusGim/100}%`, "gim")} ) x ${getStyles(`(5 - ${gimStars})`, "orange")}) )`,                                                               `-${getStyles(`${calculationMalusGimTimeAdded}$`, "green")}`],
            [ "Malus GIMMER",   "-",                                ``,                                         `${getStyles(`${malusGimmer}%`, "gimmer")}`,   `${getStyles(`${gimmerStars}`, "orange")}`,    `${getStyles(`${malusShareGimmer}%`, "share")}`,  "",                 `${getStyles(`${malusShareGimmer/100}`, "share")} x (( ${getStyles(`${priceByHoursTimeAdded}`, "blue")} x ${getStyles(`${malusGimmer/100}`, "gim")} ) x (${getStyles(`5 - ${gimmerStars}`, "orange")}) ) )`,     `${getStyles(`${calculationMalusGimmerTimeAdded}$`, "japonica")}`],
            [ "RMP",            `${getStyles(`${RMP}%`, "rmp")}`,  ``,                                          "-",                                            "-",                                            "-",                                             "",                    `${getStyles(`${priceByHoursTimeAdded}`, "blue")} x ${getStyles(`${RMP/100}`, "rmp")}`,                                                                                                                           `-${getStyles(`${rmpTotalTimeAdded}$`, "rmp")}`],
            [ "",               ``,                                 ``,                                         "",                                             "",                                             "",                                              "",                    `${t("ManageJobs:detailed:label_23")} `,                                                                                                                                                                                                 `${totalOutcomeTimeAdded} $`],
            [ "",               ``,                                 ``,                                         "",                                             "",                                             "",                                              "",                    `Fees (Stripe): ${getStyles(`${totalOutcomeTimeAdded}`, "stripe")} * 0.0025`,                                                                                                                     `${getStyles(`-${stripeComissionTimeAdded} $`, "gimmer")}`],
            [ "",               ``,                                     ``,                                     "",                                             "",                                             "",                                             "",                     `${t("ManageJobs:detailed:label_20")}`,                                                                                                                                                             `${getStyles(`${totalTimeAdded} $`, "square-result")}` ],   
        ]

        dataProduct =[
            [`${t("ManageJobs:detailed:label_13")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",               ``,                                     `${t("ManageJobs:detailed:label_14")}`,                           "",         ``,      "         ",    "     ",  "             ",                `${t("ManageJobs:detailed:label_10")}` ],
            [ "Product",        "",                                 `${getStyles(`${productPrice}$`, "orange")}`,  "",     ``,      ``,   ``,  ``,                    `${getStyles(`${productPrice}$`, "orange")}`],  
            [ "",               ``,                                     ``,                                        "",         "",      "",    "",  `Total Earn: `,    `${getStyles(`${productPrice} $`, "square-result")}` ],       
        ]
*/
        totalPaid = `<p>Total Earn ${getStyles(`${total}$`, "black-bold")}</p>`
    }else{
        title = t("ManageJobs:detailed:label_06")
        const {
            calculationMalusGim,
            calculationMalusGimmer,
            calculationMalusGimComplement,
            calculationMalusGimmerComplement,
            taxTotal,
            rmpTotal,
            priceByHours,
            total,

            priceByHoursTimeAdded,
            calculationMalusGimTimeAdded,
            calculationMalusGimmerTimeAdded,
            calculationMalusGimComplementTimeAdded,
            calculationMalusGimmerComplementTimeAdded,
            rmpTotalTimeAdded,
            taxTotalTimeAdded,
            totalTimeAdded,

            
            rmpTotalProduct,
            taxTotalProduct,
            totalProduct
        } = getGimworkPriceData({ malusGim, malusGimmer, price, gimmerStars, gimStars, tax, RMP, malusShareGim, malusShareGimmer, timeHours, timeAdded, productPrice, rmpcProduct })
        
        const priceTimeAdded = price * timeAdded
        const transportPrice = data.transportCosts?.price ?? 0
        const fixePrice = data.fixe?.price ?? 0

        datA =[
            [`${t("ManageJobs:detailed:label_22")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",                                                         `${t("ManageJobs:detailed:label_06")}`,                 "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,                    "Share %",                                                   `${t("ManageJobs:detailed:label_11")} /h`,         `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                          `${t("ManageJobs:detailed:label_10")}`],
            [ "Malus GIM",                              "-",                                                            `${getStyles(`${price} ${langLabel}`, "blue")}`,        `${getStyles(`${malusGim}%`, "gim")}`,          `${getStyles(`${gimStars}`, "orange")}`,                   `${getStyles(`${100 - malusShareGimmer}%`, "share")}`,       `${timeHours} h`,                                  `${getStyles(`${(100-malusShareGimmer)/100}`, "share")} x (( (${getStyles(`${price}`, "blue")} x ${timeHours}) x ${getStyles(`${malusGim/100}`, "gim")} ) x ${getStyles(`5 - ${gimStars}`, "orange")})`,         `${getStyles(`${transaction_bonusGim} ${langLabel}`, "green")}`],
            [ "Malus GIMMER",                           "-",                                                            `${getStyles(`${price} ${langLabel}`, "blue")}`,        `${getStyles(`${malusGimmer}%`, "gimmer")}`,    `${getStyles(`${gimmerStars}`, "orange")}`,                `${getStyles(`${100 - malusShareGim}%`, "share")}`,          "-",                                                `${getStyles(`${(100-malusShareGim)/100}`, "share")} (( (${getStyles(`${price}`, "blue")} x ${timeHours}) x ${getStyles(`${malusGimmer/100}`, "gimmer")} ) x ${getStyles(`5 - ${gimmerStars}`, "orange")})`,    `${getStyles(`${transaction_bonusGimmer} ${langLabel}`, "japonica")}`],
            [ "RMP",                                    `${getStyles(`${data.timeTransaction.RMP ?? 0}%`, "rmp")}`,     `${getStyles(`${price} ${langLabel}`, "blue")}`,        "-",                                            "-",                                                       "-",                                                         "-",                                                `${getStyles(`${price}`, "blue")} x ${getStyles(`${(data.timeTransaction.RMP??0)/100}`, "rmp")}`,                                                                                                               `${getStyles(`${transaction_RMP_TTC} ${langLabel}`, "rmp")}`],
            [ ``,                                       ``,                                                             ``,                                                     "-",                                            `${t("ManageJobs:detailed:label_10")} TTC:`,                                              "",                                                          ``,                                                 `${getStyles(`${transaction_bonusGim}`, "green")} + ${getStyles(`${transaction_bonusGimmer}`, "japonica")} + ${getStyles(`${transaction_RMP_TTC}`, "rmp")}`,                                                    `${getStyles(`${transaction_GimworkTotal_TTC} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,                                                             ``,                                                     "-",                                            "IVA",                                                     "",                                                          `${data.timeTransaction.gimworkIVA??0}%`,            `${getStyles(`${transaction_GimworkTotal_TTC}`, "violet")} x ${((data.timeTransaction.gimworkIVA??0)/100).toFixed(2)} / (1 + ${(data.timeTransaction.gimworkIVA??0)/100})`,                                       `${getStyles(`-${transaction_GimworkIVA} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,                                                             ``,                                                     "-",                                            `${t("ManageJobs:detailed:label_12")}`,                                             "",                                                          ``,                                                 `${getStyles(`${transaction_GimworkTotal_TTC}`, "violet")} - ${getStyles(`${transaction_GimworkIVA}`, "violet")}`,                                                                                              `${getStyles(`${transaction_GimworkIncome_HT} ${langLabel}`, "square-result")}`], 

            [`${t("ManageJobs:detailed:label_22")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",                                                         `${t("ManageJobs:detailed:label_06")}`,                         "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,                             "Share %",                                                  `${t("ManageJobs:detailed:label_11")} /h`,         `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                          `${t("ManageJobs:detailed:label_10")}`],
            [`${t("ManageJobs:detailed:label_06")}`,    "-",                                                            `${getStyles(`${priceTimeAdded} ${langLabel}`, "japonica")}`,    "-",                                           "-",                                                                "-",                                                        `${timeAdded} h`,                                   "",                                                                                                                                                                                                             `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}`],
            [ "Malus GIM",                              "-",                                                            `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}`,        `${getStyles(`${malusGim}%`, "gim")}`,         `${getStyles(`${gimStars}`, "orange")}`,                             `${getStyles(`${100 - malusShareGimmer}%`, "share")}`,     ``,                                                 `${getStyles(`${(100-malusShareGimmer)/100}`, "share")} x (( (${getStyles(`${priceTimeAdded}`, "blue")} x ${timeHours}) x ${getStyles(`${malusGim/100}`, "gim")} ) x ${getStyles(`5 - ${gimStars}`, "orange")})`,         `${getStyles(`${extraTime_bonusGim} ${langLabel}`, "green")}`],
            [ "Malus GIMMER",                           "-",                                                            `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}`,        `${getStyles(`${malusGimmer}%`, "gimmer")}`,   `${getStyles(`${gimmerStars}`, "orange")}`,                          `${getStyles(`${100 - malusShareGim}%`, "share")}`,        "-",                                                `${getStyles(`${(100-malusShareGim)/100}`, "share")} (( (${getStyles(`${priceTimeAdded}`, "blue")} x ${timeHours}) x ${getStyles(`${malusGimmer/100}`, "gimmer")} ) x ${getStyles(`5 - ${gimmerStars}`, "orange")})`,     `${getStyles(`${extraTime_bonusGimmer} ${langLabel}`, "japonica")}`],
            [ "RMP",                                    `${getStyles(`${data.timeTransaction.RMP ?? 0}%`, "rmp")}`,     `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}`,        "-",                                            "-",                                                                "-",                                                       "-",                                                `${getStyles(`${priceTimeAdded}`, "blue")} x ${getStyles(`${(data.timeTransaction.RMP??0)/100}`, "rmp")}`,                                                                                                                `${getStyles(`${extraTime_RMP_TTC} ${langLabel}`, "rmp")}`],
            [ ``,                                       ``,                                                             ``,                                                              "-",                                            `${t("ManageJobs:detailed:label_10")} TTC:`,                                                       "",                                                        ``,                                                 `${getStyles(`${extraTime_bonusGim}`, "green")} + ${getStyles(`${extraTime_bonusGimmer}`, "japonica")} + ${getStyles(`${extraTime_RMP_TTC}`, "rmp")}`,                                                    `${getStyles(`${extraTime_GimworkTotal_TTC} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,                                                             ``,                                                              "-",                                            "IVA",                                                              "",                                                        `${data.timeTransaction.gimworkIVA??0}%`,           `${getStyles(`${extraTime_GimworkTotal_TTC}`, "violet")} x ${((data.timeTransaction.gimworkIVA??0)/100).toFixed(2)} / (1 + ${(data.timeTransaction.gimworkIVA??0)/100})`,                               `${getStyles(`-${extraTime_GimworkIVA} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,                                                             ``,                                                              "-",                                            `${t("ManageJobs:detailed:label_12")}`,                                                      "",                                                        ``,                                                 `${getStyles(`${extraTime_GimworkTotal_TTC}`, "violet")} - ${getStyles(`${extraTime_GimworkIVA}`, "violet")}`,                                                                  `${getStyles(`${extraTime_GimworkIncome_HT} ${langLabel}`, "square-result")}`], 
        
            [`${t("ManageJobs:detailed:label_22")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",     `${t("ManageJobs:detailed:label_25")} $/km`,                             `${t("ManageJobs:detailed:label_26")} km`,             `RMP %`,                                                         "",                   ``,                                           `${t("ManageJobs:detailed:label_09")}`,                                                                                                                             `${t("ManageJobs:detailed:label_10")}`],
            [`${t("ManageJobs:detailed:label_06")}`,    "-",        `${getStyles(`${transportPrice} ${langLabel}`, "japonica")}`,       `${kmCovered}`,             "-",                                                            "-",                  "-",                                          `${getStyles(`${transportPrice}`, "japonica")} x ${kmCovered}`,                                                                                        `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}`],
            [ "RMP",                                    `-`,        `${getStyles(`${priceTimeAdded} ${langLabel}`, "blue")}`,           "-",                        `${getStyles(`${data.timeTransaction.RMP ?? 0}%`, "rmp")}`,     "-",                  "-",                                          `${getStyles(`${transportPrice}`, "blue")} x ${getStyles(`${(data.timeTransaction.RMP??0)/100}`, "rmp")}`,                                                          `${getStyles(`${transportPrice} ${langLabel}`, "rmp")}`],
            [ ``,                                       ``,          "",                                                                   ``,                         "IVA",                                                           "",                  `${data.transportCosts?.gimworkIVA??0}%`,     `${getStyles(`${transportPrice}`, "japonica")} x ${((data.timeTransaction.gimworkIVA??0)/100).toFixed(2)} / (1 + ${(data.timeTransaction.gimworkIVA??0)/100})`,     `${getStyles(`-${transport_GimworkIVA} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,          "",                                                                   ``,                         `${t("ManageJobs:detailed:label_20")}`,                                                   "",                  "",                                           `${getStyles(`${transportPrice}`, "japonica")} - ${getStyles(`${transport_GimworkIVA}`, "violet")}`,                                                                `${getStyles(`${transport_GimworkIncome_HT} ${langLabel}`, "square-result")}`], 

            [`${t("ManageJobs:detailed:label_22")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "RMP%",                                                         `${t("ManageJobs:detailed:label_06")}`,                         "Malus %",                                 `${t("ManageJobs:detailed:label_07")}`,                             "Share %",                                                  ``,                                      `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                             `${t("ManageJobs:detailed:label_10")}`],
            [`${t("ManageJobs:detailed:label_06")}`,    "-",                                                            `${getStyles(`${fixePrice} ${langLabel}`, "japonica")}`,    "-",                                           "-",                                                                "-",                                                        "",                                      "",                                                                                                                                                                                                                 `${getStyles(`${fixePrice} ${langLabel}`, "blue")}`],
            [ "Malus GIM",                              "-",                                                            `${getStyles(`${fixePrice} ${langLabel}`, "blue")}`,        `${getStyles(`${malusGim}%`, "gim")}`,         `${getStyles(`${gimStars}`, "orange")}`,                             `${getStyles(`${100 - malusShareGimmer}%`, "share")}`,     ``,                                      `${getStyles(`${(100-malusShareGimmer)/100}`, "share")} x (( (${getStyles(`${fixePrice}`, "blue")} x ${timeHours}) x ${getStyles(`${malusGim/100}`, "gim")} ) x ${getStyles(`5 - ${gimStars}`, "orange")})`,        `${getStyles(`${fixe_bonusGim} ${langLabel}`, "green")}`],
            [ "Malus GIMMER",                           "-",                                                            `${getStyles(`${fixePrice} ${langLabel}`, "blue")}`,        `${getStyles(`${malusGimmer}%`, "gimmer")}`,   `${getStyles(`${gimmerStars}`, "orange")}`,                          `${getStyles(`${100 - malusShareGim}%`, "share")}`,        "",                                      `${getStyles(`${(100-malusShareGim)/100}`, "share")} (( (${getStyles(`${fixePrice}`, "blue")} x ${timeHours}) x ${getStyles(`${malusGimmer/100}`, "gimmer")} ) x ${getStyles(`5 - ${gimmerStars}`, "orange")})`,    `${getStyles(`${fixe_bonusGimmer} ${langLabel}`, "japonica")}`],
            [ "RMP",                                    `${getStyles(`${data.fixe?.RMP ?? 0}%`, "rmp")}`,               `${getStyles(`${fixePrice} ${langLabel}`, "blue")}`,        "-",                                            "-",                                                                "-",                                                       "",                                      `${getStyles(`${fixePrice}`, "blue")} x ${getStyles(`${(data.timeTransaction.RMP??0)/100}`, "rmp")}`,                                                                                                               `${getStyles(`${fixe_RMP_TTC} ${langLabel}`, "rmp")}`],
            [ ``,                                       ``,                                                             ``,                                                         "-",                                            `${t("ManageJobs:detailed:label_10")} TTC:`,                                                       "",                                                        ``,                                      `${getStyles(`${fixePrice}`, "blue")} + ${getStyles(`${fixe_bonusGim}`, "green")} + ${getStyles(`${fixe_bonusGimmer}`, "japonica")} - ${getStyles(`${fixe_RMP_TTC}`, "rmp")}`,                                      `${getStyles(`${fixe_GimworkTotal_TTC} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,                                                             ``,                                                         "-",                                            "IVA",                                                              "",                                                        `${data.fixe?.gimworkIVA??0}%`,          `${getStyles(`${fixe_GimworkTotal_TTC}`, "violet")} x ${((data.timeTransaction.gimworkIVA??0)/100).toFixed(2)} / (1 + ${(data.timeTransaction.gimworkIVA??0)/100})`,                                                `${getStyles(`-${fixe_GimworkIVA} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,                                                             ``,                                                         "-",                                            `${t("ManageJobs:detailed:label_12")}`,                                                      "",                                                        ``,                                      `${getStyles(`${fixe_GimworkTotal_TTC}`, "violet")} - ${getStyles(`${fixe_GimworkIVA}`, "violet")}`,                                                                                                                `${getStyles(`${fixe_GimworkIncome_HT} ${langLabel}`, "square-result")}`], 
        
            [`${t("ManageJobs:detailed:label_22")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",                                       "",         `${t("ManageJobs:detailed:label_06")}`,           "",             `RMP %`,                                              "",                   ``,                                          `${t("ManageJobs:detailed:label_09")}`,                                                                                                                             `${t("ManageJobs:detailed:label_10")}`],
            [ "RMP",                                    `-`,        `${getStyles(`${tip} ${langLabel}`, "blue")}`,    "",             `${getStyles(`${data.tips?.RMP ?? 0}%`, "rmp")}`,     "-",                  "-",                                         `${getStyles(`${tip}`, "blue")} x ${getStyles(`${data.tips?.RMP ?? 0}%`, "rmp")}`,                                                                      `${getStyles(`${tip_GimworkTotal_TTC} ${langLabel}`, "rmp")}`],
            [ ``,                                       ``,          "",                                              ``,             "IVA",                                                "",                  `${data.transportCosts?.gimworkIVA??0}%`,     `${getStyles(`${tip_GimworkTotal_TTC}`, "japonica")} x ${((data.timeTransaction.gimworkIVA??0)/100).toFixed(2)} / (1 + ${(data.timeTransaction.gimworkIVA??0)/100})`,     `${getStyles(`-${tip_GimworkIVA} ${langLabel}`, "violet")}`], 
            [ ``,                                       ``,          "",                                              ``,             `${t("ManageJobs:detailed:label_20")}`,                                        "",                  "",                                           `${getStyles(`${tip_GimworkTotal_TTC}`, "japonica")} - ${getStyles(`${tip_GimworkIVA}`, "violet")}`,                                                                `${getStyles(`${tip_GimworkIncome_HT} ${langLabel}`, "square-result")}`], 

        ]
/*
        dataTimeAdded =[
            [`${t("ManageJobs:detailed:label_12")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",               "RMP%",                                         `${t("ManageJobs:detailed:label_06")}`,                                "Malus %",                                      `${t("ManageJobs:detailed:label_07")}`,                                        "Share %",                                               `${t("ManageJobs:detailed:label_11")} /h`,         `${t("ManageJobs:detailed:label_09")}`,                                                                                                                                                                                              `${t("ManageJobs:detailed:label_10")}`],
            [ "Malus GIM",      "-",                                            `${getStyles(`${price}$`, "blue")}`,        `${getStyles(`${malusGim}%`, "gim")}`,         `${getStyles(`${gimStars}`, "orange")}`,         `${getStyles(`${100 - malusShareGim}%`, "share")}`,      `${timeAdded} h`,  `${getStyles(`${(100-malusShareGim)/100}`, "share")} x (( (${getStyles(`${priceByHours}`, "blue")} x ${timeAdded}) x ${getStyles(`${malusGim/100}`, "gim")} ) x ${getStyles(`5 - ${gimStars}`, "orange")})`,                    `${getStyles(`${calculationMalusGimComplementTimeAdded}$`, "green")}`],
            [ "Malus GIMMER",   "-",                                            `${getStyles(`${price}$`, "blue")}`,        `${getStyles(`${malusGimmer}%`, "gimmer")}`,   `${getStyles(`${gimmerStars}`, "orange")}`,      `${getStyles(`${100 - malusShareGimmer}%`, "share")}`,   "",                `${getStyles(`${(100-malusShareGimmer)/100}`, "share")} (( (${getStyles(`${priceByHours}`, "blue")} x ${timeAdded}) x ${getStyles(`${malusGimmer/100}`, "gimmer")} ) x ${getStyles(`5 - ${gimmerStars}`, "orange")})`,          `${getStyles(`${calculationMalusGimmerComplementTimeAdded}$`, "japonica")}`],
            [ "RMP",            `${getStyles(`${RMP}%`, "rmp")}`,               `${getStyles(`${price}$`, "blue")}`,        "-",                                            "-",                                            "-",                                                     "",                `(${getStyles(`${priceByHours}`, "blue")} x ${timeAdded}) x ${getStyles(`${RMP/100}`, "rmp")}`,                                                                                                                                `${getStyles(`${rmpTotalTimeAdded}$`, "rmp")}`],
            [ "",               `-`,                                            `-`,                                        `-`,                                            "-",                                            `-`,                                                     "",                `${t("ManageJobs:detailed:label_02")}`,                                                                                                                                                                                          `${getStyles(`${calculationMalusGimComplementTimeAdded + calculationMalusGimmerComplementTimeAdded + rmpTotalTimeAdded}`, "black-bold")}$`],
            [ `${t("ManageJobs:detailed:label_21")}`,            `${getStyles(`${tax}%`, "gimmer")}`,            `-`,                                        `-`,                                            "-",                                            `-`,                                                     "",                `(${priceByHoursTimeAdded} - ${getStyles(`${calculationMalusGimTimeAdded}`, "green")} + ${getStyles(`${calculationMalusGimmerTimeAdded}`, "japonica")}) x ${getStyles(`${tax/100}`, "gray")}`,                   `${getStyles(`${taxTotalTimeAdded}$`, "violet")}`],
            [ "",               ``,                                             ``,                                         ``,                                             "",                                             ``,                                                      "",                `${t("ManageJobs:detailed:label_01")} ${getStyles(rmpTotalTimeAdded, "rmp")} + ${getStyles(calculationMalusGimComplement, "green")} + ${getStyles(calculationMalusGimmerComplement, "japonica")} + ${getStyles(taxTotalTimeAdded, "violet")}`,   `${getStyles(`${totalTimeAdded}$`, "square-result")}`]
        ]

        dataProduct =[
            [`${t("ManageJobs:detailed:label_13")}`,                 "LINE",                                 "LINE",                                         "LINE",                                         "LINE",                                         "LINE",             "LINE",                                                                                                                                                                                             "LINE"],
            [ "",               "",                                     `${t("ManageJobs:detailed:label_14")}`,                                    "RMP Sales %",                                "",                                  "",     "",  `${t("ManageJobs:detailed:label_09")}`,                                                                                                                              `${t("ManageJobs:detailed:label_10")}` ],
            [ `${t("ManageJobs:detailed:label_15")}`,   "-",                                    `${getStyles(`${productPrice}$`, "orange")}`,       `${getStyles(`${rmpcProduct}%`, "gim")}`,      "-",                                 "",    ``,      `${getStyles(productPrice, "orange")} x ${getStyles(`${rmpcProduct/100}`, "gim")}`,                                                        `${getStyles(`${rmpTotalProduct}$`, "blue")}` ],
            [ `${t("ManageJobs:detailed:label_21")}`,            "-",                                    `${getStyles(`${productPrice}$`, "orange")}`,       "-",                                           `${getStyles(`${tax}%`, "gray")}`,  "",     "",         `${getStyles(productPrice, "orange")} x ${getStyles(`${tax/100}`, "gray")}`,                                                               `${getStyles(`${taxTotalProduct}$`, "green")}`],   
            [ "",               ``,                                     ``,                                                 "",                                             "",                                "",     "",         `${t("ManageJobs:detailed:label_20")}`,                                                                                                                              `${getStyles(`${totalProduct} $`, "square-result")}` ],       
        ]
*/
        /*
        totalPaid = `<p style="font-size:12px;textAlignCenter:center; minWidth: 6rem;"> 
        ${t("ManageJobs:detailed:label_01")} ${getStyles(rmpTotal, "rmp")} + ${getStyles(calculationMalusGimComplement, "green")} + ${getStyles(calculationMalusGimmerComplement, "japonica")} + ${getStyles(taxTotal, "violet")}   
        ${getStyles(`${total}$`, "square-result")} </p>`
        */
    }

    const heightCell = "2.5vh"

    return (
        <div 
            className={`pr-10 pl-10 ${isHovered ? "hover-bar-detailed-price-card" : ""} detailed-price-card`}
            style={{
                position: isInSimulator ? "absolute" : "relative",
                left: isInSimulator ? 80 : 0,
                top: type === "Gimmer" ? topGimmerBar : ( type === "Gim" ? topGimBar : topGimworkBar ),
                backgroundColor: "white",
                borderRadius: 3,
                zIndex: -1
            }}
        >
            <p className="fs-14-vh fw-600 text-align-center mt-10 mb-6">{title}</p>

            {
                /*
                <div className="width-100 flex flex-ai-center">
                    <div className="line-left dark-gray-bg"></div>
                    <p className="ml-6 mr-6 fs-14-vh mb-2">Principal</p>
                    <div className="line-right dark-gray-bg"></div>
                </div>
                */
            }

            <table>
                <tbody>
                {
                    datA.map((row, index) => {
                        return(
                            <tr key={index}>
                                {
                                    row.map((value, index) => {
                                        
                                        if(value === "LINE")
                                            return (
                                                <td key={index} style={{ height: "15px"}} >
                                                    <div style={{ backgroundColor: "black", height: "1px"  }}></div>
                                                </td>
                                            )

                                        const isTotalCell = index === row.length - 1
                                        return(
                                            <td 
                                                key={index} 
                                                className={`pl-12 pr-12 ${isTotalCell ? "text-align-right" : "text-align-center"} fs-12-vh`} 
                                                style={{ height: heightCell }}
                                                dangerouslySetInnerHTML={{ __html: value }}
                                            >
                                                
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        )
                    })
                }
                </tbody>
            </table>

            {
                /*
                <div dangerouslySetInnerHTML={{ __html: totalPaid }} className="flex flex-jc-end flex-ai-center pr-12" style={{ height: "3rem", }}>
                
            </div>
                */
            }
        </div>
    )
}
// MP300 PRINTER Operatör El Kitabı Manuals M00126700PT.pdf
// Thermal Transfer Printable Nylon Cloth Tape – Specifications Sheet GMC6
/*

        data =[
            [ "",               "TaxA%",     "Job Price",    "Malus %",          "Stars",           "Share %",                  "Calculation",                                                                     "Total"],
            [ "Job Price",      "-",        `${price}$`,     "-",                "-",                "-",                        "-",                                                                                `${price}$`],
            [ "Malus GIM",      "-",        `${price}$`,     `${malusGim}%`,     gimStars,           `${malusShareGim}%`,        `${( malusShareGim / 100 )} x (( ${price} x ${malusGim / 100} ) x (5 - ${gimStars}))`,    `${calculationMalusGim}$`],
            [ "Malus GIMMER",   "-",        `${price}$`,     `${malusGimmer}%`,  gimmerStars,        ``,                         `( ${price} x ${malusGimmer / 100} ) x (5 - ${gimmerStars})`,                             `-${calculationMalusGimmer}$`],
            [ `${t("ManageJobs:detailed:label_21")}`,            `${tax}%`,  `${price}$`,     "-",                "-",                "-",                        `(${price} - ${calculationMalusGim} + ${calculationMalusGimmer}) x ${tax/100}`,     `${taxTotal}$`]
        ]

        data =[
            [ "",               "RMP%",      "Job Price",    "Malus %",         "Stars",            "Share %",                  "Calculation",                                                                              "Total"],
            [ "Job Price",      "-",        `${price}$`,     "-",                "-",                "-",                        "-",                                                                                        `${price}$`],
            [ "Malus GIM",      "-",        `${price}$`,     `${malusGim}%`,     gimStars,           ``,                         `( ${price} x ${malusGim / 100} ) x (5 - ${gimStars}) )`,                                           `-${calculationMalusGim}$`],
            [ "Malus GIMMER",   "-",        `${price}$`,     `${malusGimmer}%`,  gimmerStars,        `${malusShareGimmer}%`,     `${( malusShareGimmer / 100 )} x (( ${price} x ${malusGimmer / 100} ) x (5 - ${gimmerStars}) )`,   `${calculationMalusGimmer}$`],
            [ "RMP",            `${RMP}%`,  `${price}$`,     "-",                "-",                "-",                        `${price} x ${RMP/100}`,                                                                    `-${rmpTotal}$`]
        ]

        data =[
            [ "",               "RMP%",     "Job Price",    "Malus %",                      "Stars",            "Share %",                  "Tax%",     "Calculation",                                                                                  "Total"],
            [ "Malus GIM",      "-",        `${price}$`,     `${malusGim}%`,                 gimStars,       `${malusShareGim}%`,        "-",        `${( malusShareGim / 100 )} x (( ${price} x ${malusGim / 100} ) x ${gimStars})`,            `${calculationMalusGim}$`],
            [ "Malus GIMMER",   "-",        `${price}$`,     `${malusGimmer}%`,              gimmerStars,    `${malusShareGimmer}%`,     "-",        `${( malusShareGimmer / 100 )} (( ${price} x ${malusGimmer / 100} ) x ${gimmerStars})`,     `${calculationMalusGimmer}$`],
            [ "RMP",            `${RMP}%`,  `${price}$`,     "-",                            "-",            "-",                        "-",        `${price} x ${RMP/100}`,                                                                    `${rmpTotal}$`],
            [ "",               ``,         ``,             "",                             "",             "",                         "",         `Total Earn`,                                                                               `${toatlEarn}`],
            [ "LINE", "LINE", "LINE", "LINE", "LINE", "LINE", "LINE", "LINE", "LINE"],
            [ "Tax",            `-`,        `${price}$`,     `${calculationMalusGimmer}`,    "-",                `${calculationMalusGim}`,   `${tax}%`,  `(${price} - ${calculationMalusGim} + ${calculationMalusGimmer}) x ${tax/100}`,         `${taxTotal}$`]
        ]

*/

export default React.memo( DetailedPriceCard )