import React, { useLayoutEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { RootState, useAppDispatch} from '../../redux/store/store';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../components/LoadingSpinner';
import { User, getProfilePicture, login, setLoadingAuth } from '../../redux/slices/authSlice';
import { getMyUser } from '../../utils/requestsUtils/functionsRequests/user/userFunctions';
import PublicNavBar from '../../components/NavBarComponents/PublicNavBar/PublicNavBar';
import PercentageBarWrapper from '../../components/SignUp/UIComponents/PercentageBarWrapper/PercentageBarWrapper';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getUserCurrencyBrowser, getUserLanguageBrowser, localStorageCurrency, localStorageLanguage } from '../../utils/preferences/preferences';
import NavBar from '../../components/NavBarComponents/NavBar/NavBar';
// ReactChild | ReactChildren
type PublicRouteProps = {
    children: React.ReactNode
    percentageBar?: number
    hideLanguage?: boolean
}


const PublicRoute = ({ children, percentageBar, hideLanguage }: PublicRouteProps) => {

    const { i18n } = useTranslation()

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const isLogged = useSelector((state: RootState) => state.auth.isLogged);
    const loading = useSelector((state: RootState) => state.auth.loading);
    
    useLayoutEffect(() => {

        if(isLogged)
            navigate("/my-profile")

        getMyUser()
        .then(({ user }: { user: User }) => {

            const publicLanguage = getUserLanguageBrowser()
            const publicCurrency = getUserCurrencyBrowser()

            const currentLanguage = user.preferences?.language ?? publicLanguage
            const currentCurrency = user.preferences?.currency?.currCode ?? publicCurrency

            i18n.changeLanguage(currentLanguage)
            moment.locale(currentLanguage); 
            localStorage.setItem(localStorageLanguage, currentLanguage)
            localStorage.setItem(localStorageCurrency, currentCurrency)

            dispatch(getProfilePicture(user.id))
            dispatch(login({ isLogged: true, user }))
            dispatch(setLoadingAuth(false))
            //navigate("/my-profile")
        })
        .catch(() => {
            dispatch(setLoadingAuth(false))

            const publicLanguage = getUserLanguageBrowser()

            i18n.changeLanguage(publicLanguage)
            moment.locale(publicLanguage); 
        }).finally(() => {
            
        })

    }, [])


    if(loading)
            return ( <LoadingSpinner fullPage={true} /> )
    else if( isLogged )
        return ( <Navigate to="/my-profile" replace /> )
    else if( percentageBar && percentageBar > -1 )
        return (
            <div style={{ height: "100vh" }}>
                <NavBar hideLanguage={hideLanguage ? true : false} isPublic={true} />

                <PercentageBarWrapper percentageBar={percentageBar} >
                    {children}
                </PercentageBarWrapper>
            </div>
        )
    else
        return (
            <>
                <NavBar hideLanguage={hideLanguage ? true : false} isPublic={true} />
                { children }
            </>
        )

};



export default PublicRoute;
