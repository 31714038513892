import { APIProvider, AdvancedMarker, Map, MapMouseEvent, useAdvancedMarkerRef,  } from "@vis.gl/react-google-maps";
import React, { useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from "react";
import PlaceAutocomplete from "../PlaceAutocomplete/PlaceAutocomplete";
import MapHandler from "../MapHandler/MapHandler";
import { Circle } from "../Geometry/Circle/Circle";
import { Position, getLatLngBounds } from "../../../../utils/maps/locations";
import { Colors } from "../../../../utils/colors/colors";
import MapViewController, { MapViewRef } from "./MapViewController";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? ""

type MapViewProps = {
    initialLongitude: number
    initialLatitude: number
    radius: number
    showRadius: boolean
    showSearchBar: boolean
    address: string
    styleSearchBar?: React.CSSProperties
    onChangePositionMap: (newPosition: Position, radius?: number) => void
}

/*
    zoom 9:     30 km max
    zoom 10:    18 km max
    zoom 11:    9 km max
    zoom 12:    4 km max
    zoom 13:    2 km max
*/

const MapView = ({ initialLongitude, initialLatitude, radius, showRadius, showSearchBar, address, onChangePositionMap, styleSearchBar = { top: "-1.8vh", left: "-1.4rem" } }: MapViewProps) => {

    /*
                    latitude: placeAutocomplete.getPlace().geometry?.location?.lat() ?? 0,
                longitude: placeAutocomplete.getPlace().geometry?.location?.lng()?? 0,
                address: placeAutocomplete.getPlace().formatted_address ?? ""
    */
    
    //const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const modalRef = useRef<MapViewRef>() as React.MutableRefObject<MapViewRef>;

    const [markerRef, marker] = useAdvancedMarkerRef();

    const [mapAlignment, setMapAlignment] = useState<{ 
        bounds: google.maps.LatLngBounds | null,
        location: google.maps.LatLng | null  
        zoom: number | null,
        longitude: number | null,
        latitude: number | null
    }>({ bounds: null, location: null, zoom: null, longitude: null, latitude: null });

    const currentLongitude =  mapAlignment.longitude ?? initialLongitude
    const currentLatitude = mapAlignment.latitude ?? initialLatitude



    const getProportionalZoom = () => {
        
        if(radius > 18)
            return 9
        else if(radius <= 18 && radius > 9)
            return 10
        else if(radius <= 9 && radius > 4)
            return 11
        else if(radius <= 4 && radius > 2)
            return 12
        else if(radius <= 2)
            return 13

        return 14
    }

    

    const onClickMap = async(event: MapMouseEvent) => {
        //console.log("event", event.map.getBounds())
        const latitude = event.detail.latLng?.lat ?? null
        const longitude = event.detail.latLng?.lng ?? null
        if(showRadius){
            onChangePositionMap({
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
                address: ""
            }, radius)
        }else{
            onChangePositionMap({
                latitude: latitude ?? 0,
                longitude: longitude ?? 0,
                address: ""
            }) 
        }

        const bounds = event.map.getBounds()
        const location = event.map.getCenter()
        const zoom = event.map.getZoom()
        const newBounds = getLatLngBounds(latitude, longitude)

        if(bounds && location && zoom){
            setMapAlignment({ bounds: newBounds, location: null, zoom, latitude, longitude })
        }

    }


    const setExternalCoordinates = (latitude: number, longitude: number, address: string = "") => {
        if(showRadius){
            onChangePositionMap({
                latitude,
                longitude,
                address
            }, radius)
        }
        else{
            onChangePositionMap({
                latitude,
                longitude,
                address
            })
        }
    }

    const setNewAutocompleteBarPlace = (selectedPlace: google.maps.places.PlaceResult | null) => {
        if(selectedPlace){

            const latitude = selectedPlace.geometry?.location?.lat() ?? 0
            const longitude = selectedPlace.geometry?.location?.lng() ?? 0

            setExternalCoordinates( latitude, longitude, selectedPlace.formatted_address )
                
            const bounds = selectedPlace.geometry?.viewport
            const location = selectedPlace.geometry?.location
            if(bounds && location){
                setMapAlignment({ bounds, location, zoom: 12, latitude, longitude })
            }
        }
    }

    useLayoutEffect(() => {
        MapViewController.setModalRef(modalRef)
    }, [])

    const set_search = (selectedPlace: google.maps.places.PlaceResult | null) => {
        setNewAutocompleteBarPlace( selectedPlace )
    }

    const return_current_point = ()=> {
        const zoom = getProportionalZoom()
        const newBounds = getLatLngBounds(initialLatitude, initialLongitude)

        setExternalCoordinates(initialLatitude, initialLongitude, address)

        setMapAlignment({ bounds: newBounds, location: null, zoom, latitude: initialLatitude, longitude: initialLongitude })
        
    }

    useImperativeHandle(
        modalRef,
        () => ({
            setSearch: (selectedPlace) => {
                set_search(selectedPlace)
            },
            returnCurrentPoint: () => {
                return_current_point()
            }
        }),
        [set_search, return_current_point]
    );

    useEffect(() => {
        
        if(radius){
            const newZoom = getProportionalZoom()
            setMapAlignment({ ...mapAlignment, zoom: newZoom })
        }
    
    }, [radius])
    


    return (
        <APIProvider
            apiKey={API_KEY}
            solutionChannel='GMP_devsite_samples_v3_rgmautocomplete'
        >
            <Map
                mapId={'bf51a910020fa25a'}
                defaultZoom={getProportionalZoom()}
                defaultCenter={{ lat: initialLatitude, lng: initialLongitude }}
                //center={{ lat: initialLatitude, lng: initialLongitude }}
                gestureHandling={'greedy'}
                disableDefaultUI={true}
                onClick={onClickMap}
            >
                <AdvancedMarker ref={markerRef} position={{ lat: currentLatitude, lng: currentLongitude }} />
                {
                    showRadius &&
                    <Circle
                        radius={radius * 1000}
                        center={{ lat: currentLatitude, lng: currentLongitude }}
                        //onRadiusChanged={onChangeRadius}
                        //onCenterChanged={(e: any) => { console.log("onCenterChanged", e) }}
                        strokeColor={Colors.Gim}
                        strokeOpacity={1}
                        strokeWeight={3}
                        fillColor={Colors.Gim}
                        fillOpacity={0.3}
                        clickable={false}
                        //editable
                        //draggable
                    />
                }
            </Map>

            {
                showSearchBar &&
                <div className="p-absolute" style={styleSearchBar}>
                    <div className="autocomplete-control">
                        <PlaceAutocomplete onPlaceSelect={setNewAutocompleteBarPlace} />
                    </div>
                </div>
            }



            {
                <MapHandler mapAlignment={mapAlignment} marker={marker} />
            }
        </APIProvider>
    );
};

export default React.memo( MapView )