import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getProfilePictureByUserId } from '../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { Policy, Role } from './components/ManageRoles/manageRolesSlice'
import { ProductType } from './components/Marketplace/marketplaceDataSlice'
import { WorkPreference } from './components/ManageOffers/manageOffersDataSlice'
import { TypeProduct } from './components/Products/ProductsSlice'

export type ReviewGimmer = {
  score: number
  comment: string
}

export type ReviewGim = {
  punctuality: number
  attitude: number
  quality: number
  comment: string
}

export type ReviewGimmerUser =  ReviewGimmer & { transactionId: string }
export type ReviewGimUser =  ReviewGim & { transactionId: string }

export type UserReviews = {
	  gimmer?: ReviewGimmerUser[]
    gim?: ReviewGimUser[]
}

export type CurrencyCode = "EUR" | "MXN" | "USD"

export type Currency = {
  currCode:   CurrencyCode
  currName:   string
  currSymbol: string
}

export type LanguageLabel = "en" | "es" | "fr"

export type Preferences = {
    currency?: Currency
    language?: LanguageLabel
}

export type NotifData = {
  action:             string
  categoryName:       string
  disapprovalReason:  string
  jobId:              string
  jobName:            string
  receivedFromType:   string
}

export type NotificationUser = {
  id:         string
  notifData:  any
  notifMsg:   string
  timestamp:  number
  viewed:     boolean
}

export type Job = {
    categoryName:   string
    imgKey:         string
    isCertifReq:    boolean
    isJobSelected:  boolean
    isUploaded:     boolean
    isVerified:     boolean
    jobId:          string
    jobName:        string
    photoKey:       string
    workPreference: WorkPreference
}


export interface GimmerJob {
    categoryName:   string
    imgKey:         string
    isCertifReq:    boolean
    isJobSelected:  boolean
    isUploaded:     boolean
    isVerified:     boolean
    jobId:          string
    jobName:        string
    photoKey:       string
    workPreference: WorkPreference
    estimatedTime:  string
}

export type FileProduct = {
    name: string
    size: number
    type: string
}

export type MarketplaceProduct = {
    id: string
    productId: string
    jobId: string
    productName: string,
    description: string,
    price: number,
    date: string
    typeProduct: TypeProduct
    File?: FileProduct
}

export type MyProductBought = {
  id:             string
  productId:      string
  jobId:          string
  date:           string
  description:    string
  price:          number
  productName:    string
  File?:           FileProduct
  fees:           number
  tax:            number
  transactionId:  string
  typeProduct:    TypeProduct
}

export interface User {
  bannedOffers:           string[]
  countryName:            string
  firstName:              string
  flexibility:            number
  gimScore:               number
  gimmerJobs:             GimmerJob[]
  gimmerScore:            number
  id:                     string
  isIdUploaded:           boolean
  isIdVerified:           boolean
  isProfilePicUploaded:   boolean
  isProfilePicVerified:   boolean
  jobs:                   Job[]
  lastName:               string
  mail:                   string
  notifications:          NotificationUser[]
  phoneNumber:            string
  preferences?:            Preferences
  roleId:                 string
  imgUrl:                 string
  reviews:                UserReviews
  description:            string
  roleData:               Role
  cognito_type:           string
  userName:               string
  MarketPlace:            MarketplaceProduct[]
  MyProducts:             MyProductBought[]
  authorizationToken?:    string
}

export interface Auth {
    isLogged: boolean,
    user: User,
    loading: boolean
}



export const getProfilePicture = createAsyncThunk(
  'auth/getProfilePicture',
  async (userId: string, thunkAPI) => {
    try {
      return await getProfilePictureByUserId(userId, "mid")
    } catch (error) {
      return "";
    }
  }
)

/*
export const authUserThunk = createAsyncThunk(
  'auth/authUserThunk',
  async () => {
    try { 
      const user = await authFirstLoad()
      return user
      
    } catch (error) {
      console.log("error", error)
      return {} as User
      
    }
  }
)
*/

const initialState = {
    isLogged: false,
    user: {
        bannedOffers:           [],
        countryName:            "",
        firstName:              "",
        flexibility:            0,
        gimScore:               0,
        gimmerJobs:             [],
        gimmerScore:            0,
        id:                     "",
        isIdUploaded:           false,
        isIdVerified:           false,
        isProfilePicUploaded:   false,
        isProfilePicVerified:   false,
        jobs:                   [],
        lastName:               "",
        mail:                   "",
        notifications:          [],
        phoneNumber:            "",
        preferences:            {} as Preferences,
        roleId:                 "",
        imgUrl:                 "",
        reviews:                {},
        description:            "",
        roleData: {
          id: "",
          hierarchy: 0,
          name: "",
          country: "Mexico",
          policies: [],
          permanent: false
        },
        cognito_type:           "",
        userName:               "",
        MarketPlace: [],
        MyProducts: [],
        authorizationToken:     ""
    } as User,
    loading: true,
    showModalLanguageCurrency: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,

    reducers: {
        login: (state, action: PayloadAction<{ user: User, isLogged: boolean }>) => {
          const { user, isLogged } = action.payload  
          state.user = user
          state.user.notifications = user.notifications.reverse()
          state.isLogged = isLogged
          return state
        },
        setLoadingAuth: (state, action: PayloadAction<boolean>) => {
          state.loading = action.payload  
          return state
        },
        
        setAddMarketplaceProductUserReducer: (state, action: PayloadAction<MarketplaceProduct>) => {
            state.user.MarketPlace.unshift( action.payload )
            return state
        },
        setEditMarketplaceProductUserReducer: (state, action: PayloadAction<MarketplaceProduct>) => {
            for(let i=0; i<state.user.MarketPlace.length; i++){
              if(state.user.MarketPlace[i].id === action.payload.id)
                  state.user.MarketPlace[i] = action.payload
            }
            return state
        },
        setDeleteMarketplaceProductUserReducer: (state, action: PayloadAction<{ id: string, type: ProductType } >) => {
            const { id, type } = action.payload
            if(type === "Marketplace"){
                const index = state.user.MarketPlace.findIndex((p) => p.id === id)
                state.user.MarketPlace.splice(index, 1)
            }else if(type === "Purchased"){
                const index = state.user.MyProducts.findIndex((p) => p.id === id)
                state.user.MyProducts.splice(index, 1)
            }
            return state
        },
        updateUserReducer: (state, action: PayloadAction<User>) => {

            return { ...state, isLogged: state.isLogged, user: action.payload };
        },
        addJobUserReducer: (state, action: PayloadAction<Job>) => {
            state.user.jobs.push(action.payload)
            return state
        },
        editJobUserReducer: (state, action: PayloadAction<Job>) => {
            const job = action.payload
            state.user.jobs = state.user.jobs.map((Job) => {
                if(Job.jobId === job.jobId)
                  Job = job
                return Job
            })
            return state
        },
        deleteJobUserReducer: (state, action: PayloadAction<string>) => {
            const jobId = action.payload
            state.user.jobs = state.user.jobs.filter((Job) => Job.jobId !== jobId)
            return state
        },
        logout: (state) => {
            return initialState
        },
        setNotificationsReducer: (state, action: PayloadAction<NotificationUser[]>) => {
            state.user.notifications = action.payload.reverse()
            return state
        },
        setNotificationAsViewed: (state, action: PayloadAction<string>) => {
            const notificationId = action.payload
            for(const notification of state.user.notifications){
                if(notificationId === notification.id){
                    notification.viewed = true
                }
            }
            return state
        },
        setAddNotification: (state, action: PayloadAction<NotificationUser>) => {
            state.user.notifications.unshift( action.payload )
            return state
        },
        setShowModalLanguageCurrency: (state, action: PayloadAction<boolean>) => {
          state.showModalLanguageCurrency = action.payload
          return state
        },
        setLanguage: (state, action: PayloadAction<LanguageLabel>) => {
            if(state.user.preferences)
                state.user.preferences = { 
                    ...state.user.preferences,
                    language: action.payload
                }
            return state
        },
        setCurrency: (state, action: PayloadAction<Currency>) => {
          if(state.user.preferences)
              state.user.preferences = { 
                  ...state.user.preferences,
                  currency: action.payload
              }
          return state
      },
    },
    extraReducers: (builder) => {
        builder.addCase(getProfilePicture.fulfilled, (state, action) => {
          state.user.imgUrl = action.payload || ""
          return state
        })
        
        /*
        .addCase(authUserThunk.fulfilled, (state, action: PayloadAction<User>) => {
          state.user = action.payload
          state.isLoading = false
          state.isLogged = true
          return state
        })
        .addCase(authUserThunk.pending, (state, action) => {
          state.isLoading = true
          return state
        })
        .addCase(authUserThunk.rejected, (state, action) => {
          state.isLoading = false
          state.isLogged = false
          return state
        })
        */
    },
});

// Action creators are generated for each case reducer function
export const { 
      login, 
      logout, 
      updateUserReducer,
      setAddMarketplaceProductUserReducer,
      setEditMarketplaceProductUserReducer,
      setDeleteMarketplaceProductUserReducer,
      addJobUserReducer,
      editJobUserReducer,
      deleteJobUserReducer,
      setLoadingAuth,
      setNotificationAsViewed,
      setAddNotification,
      setLanguage,
      setCurrency,
      setShowModalLanguageCurrency,
      setNotificationsReducer
} = authSlice.actions

export default authSlice.reducer