import { useTranslation } from 'react-i18next';
import "./AddProductGimworkDBModal.css"
import React, { useCallback } from 'react';
import FormAddProductGimworkDBModal from './FormAddProductGimworkDBModal/FormAddProductGimworkDBModal';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { resetProductsDBModalsReducer } from '../../../../redux/slices/components/ManageProducts/manageProductsDataSlice';

const AddProductGimworkDBModal = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const typeProduct = useSelector((state: RootState) => state.manageProductsData.typeProduct)
    
    const onClose = useCallback(
        () => {
            dispatch( resetProductsDBModalsReducer() )
        },
        [],
    )
    return (
      <div className="blank-wrapper-modals flex flex-d-column p-relative" style={{ width: "64.4rem" }}>
          <FormAddProductGimworkDBModal
              typeProduct={typeProduct}
              onClose={onClose}
          />
      </div>
    )
}

export default React.memo( AddProductGimworkDBModal )