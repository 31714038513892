import { useState, memo } from 'react'
import "./MapWithSearchBar.css"
import SliderRadius from '../SliderRadius/SliderRadius';
import { Colors } from '../../../utils/colors/colors';
import MapView from '../Maps/MapView/MapView';
import { Position } from '../../../utils/maps/locations';


//import LoadingSpinner from '../../LoadingSpinner'

interface MapWithSearchBarProps {
    radius?: number, 
    longitude: number, 
    latitude: number
    showRadius: boolean
    onChangePositionMap: (newPosition: Position, radius?: number) => void
    height: string
    hideSearchBar?: boolean
    address: string
    styleSearchBar?: React.CSSProperties
    bottomSlider?: boolean
}

const MapWithSearchBar = ({ radius = 0, longitude, latitude, showRadius, onChangePositionMap, height, hideSearchBar, address, styleSearchBar, bottomSlider }: MapWithSearchBarProps) => {

    const [valueSlider, setValueSlider] = useState(radius)

    const onChange = (value: number) => {
        setValueSlider( value )
    }

    let style: React.CSSProperties = { top: ".8vh" }
    if(bottomSlider)
        style = { bottom: ".8vh", width: "100%" }

    style ={ ...style, backgroundColor: "white", zIndex: 1 }
    
    return (
        <div className="p-relative dark-gray-bg" style={{ width: "100%", height }}>
            {
                showRadius &&
                <div className="p-absolute border-radius-3" style={style}>
                    <SliderRadius
                        valueSlider={valueSlider}
                        onChange={onChange}
                        color={Colors.Gim}
                    />
                </div>
            }
            <MapView 
                address={address}
                initialLatitude={latitude}
                initialLongitude={longitude}
                radius={valueSlider}
                showRadius={showRadius}
                showSearchBar={hideSearchBar ? false : true}
                onChangePositionMap={onChangePositionMap}
                styleSearchBar={styleSearchBar}
            />
        </div>
    )
}

// { loadingMap && <LoadingSpinner fullPage={false} /> }  
export default memo(MapWithSearchBar)