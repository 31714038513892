import React from 'react'
import "./AlertMessageRequestProduct.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { useTranslation } from 'react-i18next'

const AlertMessageRequestProduct = () => {
    const { t } = useTranslation()
    return (
        <div className="con-alert-msg-product color-alert flex flex-ai-center">
            <FontAwesomeIcon icon={faMagnifyingGlass} className="color-alert fs-20-vh" />
            <p className="fs-20-vh ml-20">{t("ManageProducts:request:label_04")}</p>
        </div>
    )
}

export default React.memo( AlertMessageRequestProduct )