import { Image } from "react-native"
import { IconDefinition, faIdCard } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./IconNotification.css"

interface IconNotificationProps {
	color: string,
	icon: IconDefinition | string
}

export const IconNotification = ({ color, icon }: IconNotificationProps) => {

	return (
		<div className="center icon-notification">
			{
				typeof icon === "string" ?
				<>
					{
						SvgIcon({icon, color})
					}
				</>
				:
					<div
						className="center height-100 width-100 circle"
						style={{
							borderWidth: 2,
							borderColor: color,
						}}>
						<FontAwesomeIcon
							icon={icon}
							className="fs-30-vh"
							style={{
								color
							}}
						/>
					</div>
			}
		</div>
	)
}

const SvgIcon = ({icon, color}: {icon: string, color?: string}) => {
	switch (icon) {
		case "Wgimwork":
			return(
				<img 
					src={"./assets/svg/logo-W-gimwork.svg"}
					alt="logo"
					height={"30%"}
				/>
			)
		case "IDverified":
			return(
				<div
					style={{
						//height: "6.72vh",
						//width: "6.72vh",
						//borderRadius: "6.72vh",
						borderWidth: "px",
						borderColor: color,
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<img 
						src={"./assets/svg/id_verified.svg"}
						alt="logo"
						height={"36vh"}
					/>
				</div>
			)

		case "IDinvalid":
			return(
				<div
					style={{
						borderColor: color,
						alignItems: 'center',
						justifyContent: 'center',
					}}>
						<FontAwesomeIcon
							icon={faIdCard}
							style={{
								color
							}}
							className="fs-16-vh"
						/>
				</div>
			)
	
		default:
			return(
				<></>
			)
	}
}