import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getCreationDate, getWhenDate } from '../../../../utils/dates/dates'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { GimGimmer, SearchParams, getOffer, getOffers, getOffersByUsers, getOffersPublic } from '../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { UserInfoCache } from '../../../../utils/staticVariables/staticVariables'
import { InsideWorkPreference, Offer, OutsideWorkPreference, WorkPreference } from '../ManageOffers/manageOffersDataSlice'
import moment from 'moment'
import { JobCategoryJob, JobCategoryJobWhole } from '../ManagerJobs/managerJobDataSlice'
import { DefaultPosition, Position } from '../../../../utils/maps/locations'
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction'

export enum StageProcess {
    JOB = 1,
    WHERE = 2,
    WHEN = 3
}

type ExtraDataOffer = {
    type: GimGimmer,
    limit: number,
    page: number
    jobId?:         string
	outside?: { address: string, latitude: number, longitude: number, radius: number }
	inside?: { address: string, latitude: number, longitude: number }
	remote?: boolean
    when?:           { startTime?: string, endTime?: string }
}


const addRemainingFieldsOffer = (offer: Offer) => {
    offer.seen = offer.seen ?? false
    offer.timestampMilliseconds = new Date(offer.timestamp).getTime()
    offer.type = offer.estimatedTime ? "Job" : "Task"
}

const changeNameToUppercase = (offer: Offer) => {
    //@ts-expect-error
    offer.detailsSchedule = offer.detailsschedule
    //@ts-expect-error
    delete offer["detailsschedule"]

    //@ts-expect-error
    offer.workPreference = offer.workpreference
    //@ts-expect-error
    delete offer["workpreference"]

    //@ts-expect-error
    offer.jobName = offer.jobname
    //@ts-expect-error
    delete offer["jobname"]

    //@ts-expect-error
    offer.jobId = offer.jobid
    //@ts-expect-error
    delete offer["jobid"]

    //@ts-expect-error
    offer.userId = offer.userid
    //@ts-expect-error
    delete offer["userid"]
    
}


//const columns = "id,categoryName,detailsSchedule,userId,offerType,jobName,jobId,timestamp,workPreference"
const columns = "id"

export const GetOffersHomeThunk = createAsyncThunk(
    'manageOffersData/GetOffersThunk',
    async (props: ExtraDataOffer, { getState }: any) => {
        try { 
            const { type, page, limit, jobId, inside, outside, remote, when } = props

            const params: SearchParams = {
                offerType: type, 
                limit, 
                page,
                columns,
            }

            // Exists if it is logged
            const userId = getState().auth.user.id
            if(userId)
                params["myUserId"] = userId

            if(jobId){
                params["jobId"] = jobId
            }

            if(outside){
                params["outside"] = JSON.stringify( outside )
            }

            if(inside){
                params["inside"] = JSON.stringify( { ...inside, radius: 30 } )
            }

            if(remote){
                params["remote"] = remote
            }

            if(when?.startTime){
                params["startTime"] = when.startTime
            }

            if(when?.endTime){
                params["endTime"] = when.endTime
            }

            const res = await getOffersPublic(params)

            /*
            res.results = res.results.map((offer: Offer) => {
                changeNameToUppercase(offer)
                addRemainingFieldsOffer(offer)
                return offer
            })*/
            return {
                ...props,
                results: res.results as Offer[],
                total: res.total
            }
        } catch (error) {
            console.log("error", error)
            return {
                ...props,
                results: [] as Offer[],
                total: 0
            }
        }
    }
)

export type SortedPropertiesOffer = {

}

type ExtraDataUser = {
    gimmerScore: number
    gimScore: number
    numberReviewsGim: number 
    numberReviewsGimmer: number 
}

export type WhenSearchDate = { iso: string, milliseconds: number }
export type WhenSearch = { startTime: WhenSearchDate, endTime?: WhenSearchDate }

export const numberOffersByPage: number[] = [ 10, 25, 50, 100 ]

type InitialState = {
    total:                  number
    limit:                  number
    page:                   number
    offersToShow:           Offer[]
    type:                   GimGimmer
    jobId?:                 string
    where:                  { inside: InsideWorkPreference, outside: OutsideWorkPreference, remote: boolean },
    when?:                  WhenSearch
    loadingOffers:          boolean
    optionSelectedWhere:    WorkPreferenceStr[]
    optionDisplayed?:       StageProcess
    searchJobInputText:     string
    orderSelectedOption:    string
}


const initialState: InitialState = {
    total: 0,
    limit: numberOffersByPage[0],
    page: 1,
    offersToShow: [],
    type: "Gim",
    loadingOffers: true,
    where: {
        inside:{
            address: "",
            latitude: DefaultPosition.latitude,
            longitude: DefaultPosition.longitude
        },
        outside: {
            radius: 10,
            address: "",
            latitude: DefaultPosition.latitude,
            longitude: DefaultPosition.longitude
        },
        remote: false
    },
    optionSelectedWhere: [  ],
    searchJobInputText: "",
    orderSelectedOption: ""
}

export const homeOffersSlice = createSlice({
    name: 'homeOffers',
    initialState,
    reducers: {
        setWhenSearch: (state, action: PayloadAction<WhenSearchDate>) => {
            if(state.when?.startTime){
                if(state.when.endTime){

                        state.when = {
                            startTime: action.payload,
                            endTime: undefined
                        }
                    
                }else{
                    //if click the same day, unselect the day
                    if(state.when.startTime.iso === action.payload.iso){
                        state.when = undefined
                    }
                    else if(state.when.startTime.milliseconds > action.payload.milliseconds){
                        const newStartTime = action.payload
                        const newEndtTime = {...state.when.startTime}
                        state.when = {
                            startTime: newStartTime,
                            endTime: newEndtTime
                        }
                    }else{
                        state.when = {
                            ...state.when,
                            endTime: action.payload
                        }
                    }
                }
            }else{
                state.when = {
                    startTime: action.payload,
                    endTime: undefined
                }
            }
            return state
        },
        setJobSearch: (state, action: PayloadAction<string>) => {
            state.jobId = action.payload
            return state
        },
        // set new position, not set selected option
        setWhereInsideSearch: (state, action: PayloadAction<Position>) => {
            state.where = {
                ...state.where,
                inside: action.payload
            }
            return state
        },
        // set new position, not set selected option
        setWhereOutsideSearch: (state, action: PayloadAction<Position & { radius: number }>) => {
            state.where = {
                ...state.where,
                outside: action.payload
            } 
            return state
        },
        setOptionSelectedWhereSearch: (state, action: PayloadAction<WorkPreferenceStr>) => {
            const option = action.payload
            const length = state.optionSelectedWhere.length
            if(state.optionSelectedWhere.includes(option)){
                if(length > 1) {// remove option
                    state.optionSelectedWhere = state.optionSelectedWhere.filter(opt => opt !== option)
                    if(option !== "remote"){
                        state.where[option].address = ""
                    }else{
                        state.where.remote = false
                    }
                }    
            }else{
                state.optionSelectedWhere.push(option) 
                if(option !== "remote"){
                    state.where[option].address = state.where[option].address ? state.where[option].address : DefaultPosition.address
                }else{
                    state.where.remote = true
                }
            }
            return state
        },
        setOfferInfoReducer: (state, action: PayloadAction<Offer>) => {

            for(let i=0; i < state.offersToShow.length; i++){
                if(state.offersToShow[i].id === action.payload.id){
                    state.offersToShow[i] = action.payload
                }
            }
            return state
        },
        setOfferPriceReducer: (state, action: PayloadAction<{id: string, price: number}>) => {

            for(let i=0; i < state.offersToShow.length; i++){
                if(state.offersToShow[i].id === action.payload.id){
                    state.offersToShow[i].price = action.payload.price
                }
            }
            return state
        },
        setOfferUserDataInfoReducer: (state, action: PayloadAction<{id: string, user: ExtraDataUser}>) => {

            for(let i=0; i < state.offersToShow.length; i++){
                if(state.offersToShow[i].id === action.payload.id){
                    state.offersToShow[i] = { ...state.offersToShow[i], ...action.payload.user }
                }
            }
            return state
        },
        setOptionDisplayedSearch: (state, action: PayloadAction<StageProcess | undefined>) => {
            state.optionDisplayed = action.payload
            return state
        },
        setTextInputJobReducer: (state, action: PayloadAction<string>) => {
            state.searchJobInputText = action.payload
            return state
        },
        orderOffersHomeReducer: (state, action: PayloadAction<string>) => {
            if(state.orderSelectedOption === action.payload)
                state.orderSelectedOption = ""
            else
                state.orderSelectedOption = action.payload
            state.offersToShow = getOrderedState({ orderColumn: action.payload, orderDirection: true, array: state.offersToShow })
            return state
        }
    },
    
    extraReducers: (builder) => {
        builder.addCase(GetOffersHomeThunk.fulfilled, (state, action: PayloadAction<{ results: Offer[], total: number } & ExtraDataOffer>) => {
            const { results, type, page, limit, jobId, when, outside, inside, remote } = action.payload  

            if(state.orderSelectedOption)
                state.offersToShow = getOrderedState({ orderColumn: state.orderSelectedOption, orderDirection: true, array: results })
            else
                state.offersToShow = results
            
            state.type = type
            state.loadingOffers = false
            state.page = page
            state.limit = limit ?? 0
            state.jobId = jobId
            if(outside){
                state.optionSelectedWhere.push("outside")
                state.where.outside = outside
            }
            if(inside){
                state.optionSelectedWhere.push("inside")
                state.where.inside = inside
            }
            if(remote) {
                state.optionSelectedWhere.push("remote")
                state.where.remote = remote
            }

            if(when?.startTime){
                const startTime = { iso: when.startTime, milliseconds: moment(when.startTime).valueOf() }
                state.when = { startTime }
                if(when.endTime){
                    const endTime = { iso: when.endTime, milliseconds: moment(when.endTime).valueOf() }
                    state.when = { ...state.when, endTime }
                }
            }

            return state
        }).addCase(GetOffersHomeThunk.pending, (state, action: any) => {
            state.loadingOffers = true
            return state     
        }).addCase(GetOffersHomeThunk.rejected, (state, action: any) => {
            const failState = {...initialState}
            failState.loadingOffers = false
            return failState
        })
    },

});

// Action creators are generated for each case reducer function
export const {  
    setWhenSearch,
    setJobSearch,
    setWhereInsideSearch,
    setWhereOutsideSearch,
    setOptionSelectedWhereSearch,
    setOptionDisplayedSearch,
    setTextInputJobReducer,
    setOfferPriceReducer,
    setOfferUserDataInfoReducer,
    setOfferInfoReducer,
    orderOffersHomeReducer
} = homeOffersSlice.actions

export default homeOffersSlice.reducer