import { useState } from 'react';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { S3DomainGimwork_categories_logo } from '../../../../utils/staticVariables/staticVariables';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import "./EditCategoryModal.css"
import FormEditCategoryModal from './FormEditCategoryModal/FormEditCategoryModal.tsx';

const EditCategoryModal = () => {

  const dispatch = useAppDispatch()
  const { t } = useTranslation();
  const { categoryId, categoryName, country, imgKey, photoKey } = useSelector((state: RootState) => state.managerJobModals.EditCategoryModal);
  const urlCurrentIcon = `${S3DomainGimwork_categories_logo}${photoKey}`
  const [urlPreviewIcon, setUrlPreviewIcon] = useState<string>(urlCurrentIcon)

  const closeModal = () => {
    dispatch(resetValueFromState())
  }

  return (
    <div className="blank-wrapper-modals flex flex-d-column height-job-modal-with-scroll p-relative">
        <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
            <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
        </button>
        <div className="flex flex-jc-sb flex-ai-center pr-10">
            <TitleModal title={`${t("General:label_113")} ${categoryName} ${t("General:label_114")}`} />
            <div className="bg-image-cover preview-icon darken-icon" style={{ backgroundImage: `url("${urlPreviewIcon}")` }} ></div>
        </div>
        <FormEditCategoryModal 
          categoryId={categoryId}
          categoryName={categoryName}
          country={country}
          imgKey={imgKey}
          photoKey={photoKey}
          urlCurrentIcon={urlCurrentIcon}
          urlPreviewIcon={urlPreviewIcon}
          setUrlPreviewIcon={setUrlPreviewIcon}
        />
    </div>
  )
}

export default EditCategoryModal