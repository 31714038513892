import { useAppDispatch } from '../../../redux/store/store';
import { setNewCategoryModalState } from '../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { openCloseModalRoleReducer } from '../../../redux/slices/components/ManageRoles/manageRolesSlice';
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess';

const HeaderManageRoles = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const accessCreateRole = useCheckAccess({ id_Category: "Manage_Roles", id_Policy: "Roles", id_Action: "Create" })
    const accessDeleteRole = useCheckAccess({ id_Category: "Manage_Roles", id_Policy: "Roles", id_Action: "Delete" })

    const handleAddNewRole= () => {
        dispatch( openCloseModalRoleReducer("Add") )
    }

    const handleRemoveRole= () => {
        dispatch( openCloseModalRoleReducer("Remove") )
    }


    return (
        <div className="flex flex-ai-center pb-6">
            <p className="title-screen">{t("General:label_046")}</p>
            {
                accessCreateRole &&
                <button 
                    className="btn-beside-title-screen"
                    onClick={handleAddNewRole}
                >
                    <FontAwesomeIcon icon={regular("plus")} className="fs-18" />
                    <p className="text-btn-title-screen">{t("General:label_239")}</p>
                </button>
            }
            {
                accessDeleteRole &&
                <button 
                    className="btn-beside-title-screen"
                    onClick={handleRemoveRole}
                >
                    <FontAwesomeIcon icon={regular("minus")} className="fs-18" />
                    <p className="text-btn-title-screen">{t("General:label_240")}</p>
                </button>
            }
        </div>
    )
}

export default HeaderManageRoles