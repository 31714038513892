import React, { useState } from 'react'
import { useAppDispatch } from '../../../redux/store/store'
import { signIn, signOut } from 'aws-amplify/auth'
import { getMyUser } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { User, getProfilePicture, login } from '../../../redux/slices/authSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./FormInputsLogin.css"
import LoadingSpinner from '../../LoadingSpinner'
import PhoneInputMask from '../PhoneInputMask/PhoneInputMask'
import { PhoneNumber } from '../../../redux/slices/components/SignUp/SignUpSlice'
import { getTokenFirebase } from '../../../utils/firebase/firebase'
import { getUserCurrencyBrowser, getUserLanguageBrowser, localStorageCurrency, localStorageLanguage } from '../../../utils/preferences/preferences'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

type FormInputsLoginProps = {
    setShowSignMethods: React.Dispatch<React.SetStateAction<boolean>>
    nextStep: (index: number) => void
}

const FormInputsLogin = ({ setShowSignMethods, nextStep }: FormInputsLoginProps) => {

    const{ t, i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>({ phoneNumber: "", code: "" })

    const [identifier, setIdentifier] = useState<"Email" | "PhoneNumber">("Email")

    const [password, setPassword] = useState("")

    const [loading, setLoading] = useState(false)
    const [textError, setTextError] = useState("")
    const [showPassword, setShowPassword] = useState(false)

    const onSubmit = (e: any) => {
        e.preventDefault()
        
        if(loading)
            return
        //handleSignIn(email, password)
        setLoading(true)
        getTokenFirebase()
        .then((token) => {
            signIn({    
                username: identifier === "Email" ? email : `${phoneNumber.code}${phoneNumber.phoneNumber}`.replace(/\s/g, ""), 
                password,
                options: {
                    clientMetadata: {
                        browserToken: token
                    }
                } 
            })
            .then((response) => {
                const { isSignedIn, nextStep } = response
                //console.log("isSignedIn", isSignedIn)
                //console.log("nextStep", nextStep)
                getMyUser()
                .then(({ user }: { user: User }) => {
                    const publicLanguage = getUserLanguageBrowser()
                    const publicCurrency = getUserCurrencyBrowser()
        
                    const currentLanguage = user.preferences?.language ?? publicLanguage
                    const currentCurrency = user.preferences?.currency?.currCode ?? publicCurrency
                    i18n.changeLanguage(currentLanguage)
                    moment.locale(currentLanguage); 
                    localStorage.setItem(localStorageLanguage, currentLanguage)
                    localStorage.setItem(localStorageCurrency, currentCurrency)
                    
                    dispatch(getProfilePicture(user.id))
                    dispatch(login({ isLogged: true, user }))
                    //navigate("/my-profile")
                })
                .catch(() => {
                    setTextError("Error user")
                    signOut()
                    .then(() => {
    
                    })
                    .catch(() => {
    
                    })
                })
                .finally(() => { setLoading(false)})
            }).catch((error) => {
                console.log('error signing in', error);
                setTextError("Incorrect password or email")
                setLoading(false)
    
                signOut()
                .then(() => {
    
                })
                .catch(() => {
    
                })
            })
        }).catch(() => {
            setTextError("Firebase error")
            setLoading(false)
        })

        //handleSignOut()
        //handleResetPassword("diaz.medina.cb84@gmail.com")
        //handleSignIn()
        //currentAuthenticatedUser()
        //currentSession()
    }

    const changeIdentifier = () => {
        if(identifier === "Email")
            setIdentifier("PhoneNumber")
        else
            setIdentifier("Email")
    }
    

    return (
        <form onSubmit={onSubmit}>
            <div className="flex flex-d-column">
                <div className="flex flex-ai-end flex-jc-sb mb-4">
                    <label htmlFor="mail" className="fs-18">Email:</label>
                    <p onClick={changeIdentifier} className="fs-12 c-apple pointer mb-4">
                        {identifier === "Email" ? "Swith to phone number" : "Swith to email"}
                    </p>
                </div>
                {
                    identifier === "Email"
                    ? <input 
                        type="text" 
                        id="login-email" 
                        className="border-none input-login fs-16 p-6"
                        name="mail" 
                        placeholder="example@gmail.com"
                        value={email}
                        onChange={(e) => {
                            if(textError)
                                setTextError("")
                            setEmail(e.target.value)
                        }}
                    />
                    : <PhoneInputMask phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber} />
                }
            </div>



            <div className="flex flex-d-column mt-40">
                <label htmlFor="password" className="fs-18 mb-4">Password: </label>
                <div className="input-login flex">
                    <input 
                        type={showPassword ? "text" : "password"} 
                        id="login-password-2" 
                        name="password" 
                        value={password}
                        onChange={(e) => {
                            if(textError)
                                setTextError("")
                            setPassword(e.target.value)
                        }}
                        className="fs-16 p-6 border-none input-password flex-1"
                    />
                    <button type="button" onClick={() => setShowPassword(!showPassword)}>
                        {
                            showPassword ?
                            <FontAwesomeIcon 
                                icon={ solid("eye-slash") }
                                className="fs-12"
                            />
                            :
                            <FontAwesomeIcon 
                                icon={ solid("eye") }
                                className="fs-12"
                            />
                        }
                    </button>
                </div>
                <div className="flex flex-jc-end">
                    <p onClick={() => nextStep(1)} className="fs-12 p-6 c-apple pointer">
                        {"Forgot password?"}
                    </p>
                </div>
            </div>

            <p className="p-absolute color-error fs-12 mt-6">
                {textError}
            </p>

            <div className="center mt-50">
                {
                    loading ?
                    <div className="center p-relative" style={{ height: "8rem", width: "11rem", }}>
                        <LoadingSpinner fullPage={false} />
                    </div>
                    :
                    <button
                        type="submit"
                        disabled={loading}
                        className=" pointer login-btn"
                    >
                        <p className="c-white fs-16">Login</p>
                    </button>
                }
            </div>

            <div className="center mt-40">
                <button
                    type="button"
                    disabled={loading}
                    className="pointer"
                    onClick={() => setShowSignMethods(true)}
                >
                    <p className="color-text underline fs-13 text-align-center">Select another method</p>
                </button>
            </div>

        </form>
    )
}

export default FormInputsLogin