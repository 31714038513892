import React from "react"
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import "./FileButton.css"


type FileButtonProps = {
    onPress: (arg0: number) => void
    numberFile: string
    type: "video" | "image"
    urlFile: string
    index: number
}

const FileButton = ({ onPress, type, numberFile, urlFile, index }: FileButtonProps) => {

    const { t } = useTranslation()

    return (
        <button onClick={() => onPress(index)} className="file-button fs-14 center pointer mr-14 mt-6">
            {
                type === "video" ?
                <FontAwesomeIcon icon={solid("circle-play")} className="fs-15" />
                : <FontAwesomeIcon icon={solid("image")} className="fs-15" />
            }
            <p className="fw-400 ml-4 fs-14 ml-10" >
                {
                    ( type === "video" ?
                        t("General:label_206")
                        :t("General:label_071") 
                    )
                    + ` ${numberFile}`
                }
            </p>
        </button>
    )
}

export default React.memo( FileButton )