import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import BackButton from '../../../UIcomponents/Buttons/BackButton/BackButton'
import { useTranslation } from 'react-i18next'
import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom'
import FilesInfo from './FilesInfo/FilesInfo'
import CardUserBugReport from './CardUserBugReport/CardUserBugReport'
import PriorityDropdown from '../RightSideBugReport/PriorityDropdown/PriorityDropdown'
import ButtonsBugReport from '../ButtonsBugReport/ButtonsBugReport'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'


const LeftSideBugReport = () => {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const accessEditTester = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Edit" })
    const accessEditDeveloper = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Edit" })

    const bugReport = useSelector((state: RootState) => state.manageBugReportsData.bugReport)


    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="flex flex-d-column" style={{ width: "53%" }}>

            <div className="flex flex-d-column" style={{ marginLeft: "7.6rem" }}>

                <p className="title-screen">{ bugReport.subject }</p>
                <p className="fs-13 mt-8">{moment(bugReport.creationDate).format("DD MMMM YYYY - HH:mm")}</p>

            </div>


            <div className="flex mt-13 mt-8">
                <div className="" >
                    <BackButton goBack={goBack} />
                </div>
                <div className="flex flex-1 flex-ai-center pl-6">
                    <div className="gray-h-line"></div>
                </div>
            </div>
            
            <div className="flex flex-d-column" style={{ marginLeft: "7.6rem"}}>
                {
                    // button
                }

                <div className="center">
                    <CardUserBugReport id={bugReport.requestBy} />
                </div>

                {
                    // attachments
                }
                <p className="fs-13 fw-700 mt-26 mb-6">{t("General:label_196")}</p>
                <FilesInfo id={ bugReport.id } />

                {
                    //description
                }
                <div className="mt-16">

                    <p className="fs-13 fw-700 mt-20 mb-8">{t("General:label_173") + ":"}</p>
                    <textarea rows={3} readOnly className="width-100 fs-13 p-8 border-radius-3 resize-none outline-none" style={{ borderColor: "black", lineHeight: "2rem", height: "18rem"}} value={ bugReport.description }/> 

                </div>

                {
                    <div className="flex flex-jc-sb mt-40">

                        <ButtonsBugReport />

                        {
                            accessEditTester && bugReport.status === "UPLOADED" &&
                            <div className="flex flex-ai-center">
                                <p className="fs-13 mr-20">{t("General:label_204")}</p>
                                <PriorityDropdown />
                            </div>
                        }

                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo( LeftSideBugReport )