import React from 'react'
import "./TransactionItem.css"
import moment from 'moment'
import { GimGimmer } from '../../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { TransactionHistoryState } from '../../../../../utils/dbModels/HistoryTransaction'
import { useTranslation } from 'react-i18next'


type TransactionItemProps = {
    date: string
    money: string
    jobName: string
    imgKey: string
    type: GimGimmer
    state: TransactionHistoryState
}

const TransactionItem = ({ date, money, jobName, imgKey, type, state }: TransactionItemProps) => {

    const { t } = useTranslation()

    let textButton = ""
    let colorButton = ""
    let textColorMoney = type === "Gimmer" ? "color-gimmer" : "color-gim"
    let moneyText = ""
    if(state === "FINISH"){
        colorButton = type === "Gimmer" ? "gimmer-bg" : "gim-bg"
        textButton = t("Profile:transaction:label_07")
        moneyText = `$ ${type === "Gimmer" ? "" : "+"}${money}`
    }else if(state === "CANCELLED"){
        colorButton = "alert-bg"
        textButton = t("Profile:transaction:label_08")
        moneyText = "--"
        textColorMoney = "color-alert"
    }

    return (
        <div className="transaction-card flex flex-d-column flex-ai-center flex-jc-sb">
            <p className="fs-12 fw-700">{moment(date).fromNow()}</p>
            <p className="fs-12 fw-700">{jobName}</p>

            <img src={imgKey} className="transaction-card-img" alt={imgKey}/>

            <p className={`fs-12 fw-700 ${textColorMoney}`}>{`${moneyText}`}</p>

            <button type="button" className={`transaction-card-btn  ${colorButton}`}>
                <p className="fs-9 c-white">{textButton}</p>
            </button>
        </div>
    )
}

export default TransactionItem