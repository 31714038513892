import React, { useCallback } from 'react'
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import FormEditContractModal from './FormEditContractModal/FormEditContractModal';
import { useSelector } from 'react-redux';
import { resetModalsValueFromRulesState } from '../../../../redux/slices/components/Rules/rulesSlice';

const EditContractModal = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();

    const contractInfo = useSelector((state: RootState) => state.rules.EditContractModalState);
  
    const closeModal = useCallback(
      () => {
          dispatch(resetModalsValueFromRulesState())
      },
      [],
    )
  
  
    return (
      <div className="blank-wrapper-modals flex flex-d-column p-relative">
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <div className="center pr-10">
              <TitleModal title={"Edit GIM contract"} />
          </div>
          <FormEditContractModal contractInfo={contractInfo} closeModal={closeModal} />
      </div>
    )
}

export default EditContractModal