import React, { useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'
import { useNavigate } from 'react-router-dom'
import MessageBugReport from './MessageBugReport/MessageBugReport'

const MessageBugReportComponent = () => {

    const navigate = useNavigate()

    const accessDisplayTester = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Display" })
    const accessDisplayDeveloper = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Display" })

    const accessEditTester = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Edit" })
    const accessEditDeveloper = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Edit" })

    const status = useSelector((state: RootState) => state.manageBugReportsData.bugReport.status)

    const onPress = useCallback(
        () => {
            navigate(-1)              
        },
        [],
    )
    

    if(!accessDisplayTester && !accessDisplayDeveloper){
        return (
            <MessageBugReport text={"You can't view this page"} onPress={onPress} />
        )
    } else if(!accessDisplayTester && (status === "UPLOADED" || status === "TESTING")){
        return (
            <MessageBugReport text={"You can't view this page, it is just for testers"} onPress={onPress} />
        )
    }else if(!accessDisplayDeveloper && (status === "OPENED")){
        return (
            <MessageBugReport text={"You can't view this page, it is just for developers"} onPress={onPress} />
        )
    }else {
        <></>
    }
}

export default React.memo( MessageBugReportComponent )