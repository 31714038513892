import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import BackButton from '../../../UIcomponents/Buttons/BackButton/BackButton'
import { useTranslation } from 'react-i18next'

import moment from 'moment-timezone';
import { useNavigate } from 'react-router-dom'
import TabUserReport from './TabUserReport/TabUserReport'
import InformationTab from './InformationTab/InformationTab'
import ChatTab from './ChatTab/ChatTab'

import "./LeftSideUserReport.css"

const timezone = moment.tz.guess()

const LeftSideUserReport = () => {


    const { t } = useTranslation()

    const navigate = useNavigate()

    const userReport= useSelector((state: RootState) => state.manageUserReports.userReport)
    const openIncident = useSelector((state: RootState) => state.manageUserReports.userReport.openIncident)

    const goBack = () => {
        navigate(-1)
    }

    useEffect(() => {
      
        //dispatch(setUserReportReducer())
      
    }, [])

    return (
        <div className="flex flex-d-column pr-20 leftside-user-report" >
            
            <p className="title-screen">{ userReport.reason }</p>
            <p className="fs-13 mt-8">{moment(userReport.creationDate).format("DD MMMM YYYY - HHmm")}</p>

            <div className="flex mt-13 mt-8">
                <BackButton goBack={goBack} />
                <div className="flex flex-1 flex-ai-center pl-10">
                    <div className="gray-h-line"></div>
                </div>
            </div>

            { openIncident && <TabUserReport /> }


            <div className="p-relative height-100">
                <InformationTab />
                { openIncident && <ChatTab />}
            </div>

        </div>
    )
}

export default React.memo( LeftSideUserReport )