import React, { useState, useEffect, useCallback } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { removeVerifiedIdImageReducer, setLoadingVerifyIdReducer, UserIdImage } from '../../../../redux/slices/components/VerifyId/VerifyIdDataSlice'
import { getIdImgByUserId, getProfilePictureByUserId, putIdImgByUserId, verifyUserIdImage } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import "./SliderIdCard.css"
import { useTranslation } from 'react-i18next';
import IdImage from './IdImage/IdImage';
import ExpandedImage from './ExpandedImage/ExpandedImage';
import VerticalSeparator from '../../../UIcomponents/VerticalSeparator/VerticalSeparator';
import axios, { AxiosError, AxiosRequestConfig, AxiosRequestHeaders } from 'axios';
import FormsSliderIdCard from './FormsSliderIdCard/FormsSliderIdCard';
import { Body_INE } from '../../../../utils/INE/INE';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotate, faSave } from '@fortawesome/pro-solid-svg-icons';
import { base64ToFile, rotateImage } from '../../../../utils/images/resizeImages';

interface SliderIdCardProps {
    user: UserIdImage,
    index: number,
    lengthUsers: number
    editable: boolean
}


const SliderIdCard = ({ user, index, editable }: SliderIdCardProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [showExpandedIdFrontImage, setShowExpandedIdFrontImage] = useState<boolean>(false);
    const [showExpandedIdBackImage, setShowExpandedIdBackImage] = useState<boolean>(false);
    const [showExpandedIdVideo, setShowExpandedIdVideo] = useState<boolean>(false);
    const [imgUrl, setImgUrl] = useState<string>("")

    const [base64_front, setBase64_front] = useState("")

    const [idFrontImgUrl, setIdFrontImgUrl] = useState<string>("")
    const [idBackImgUrl, setIdBackImgUrl] = useState<string>("")
    const [idVideoImgUrl, setIdVideoImgUrl] = useState<string>("")

    const [INEwasValidated, setINEwasValidated] = useState<"YES" | "NO" | "">("YES")

    useEffect(() => {
        getProfilePictureByUserId(user.id, "mid")
        .then((url) => {
            setImgUrl(url)
        })

        getIdImgByUserId(user.id, "Front_id")
        .then(({ url, base64 }) => {
            setIdFrontImgUrl(base64)
        }).catch(() => {})

        getIdImgByUserId(user.id, "Back_id")
        .then(({ url, base64 }) => {
            //console.log("back", base64)
            setIdBackImgUrl(base64)
        }).catch(() => {})

        getIdImgByUserId(user.id, "Video_id")
        .then(({ url, base64 }) => {
            setIdVideoImgUrl(url)
        }).catch(() => {})

    }, [])

    const validPicture = () => {
        dispatch( setLoadingVerifyIdReducer(true) )
        verifyUserIdImage(user.id, { newVerifiedState: true })
        .then((res) => {
            dispatch( removeVerifiedIdImageReducer({ id: user.id, user: user}) )
        }).finally(() => { dispatch(setLoadingVerifyIdReducer(false)) })
    }
    
    const inValidPicture = () => {
        dispatch( setLoadingVerifyIdReducer(true) )
        verifyUserIdImage(user.id, { newVerifiedState: false })
        .then((res) => {
            dispatch( removeVerifiedIdImageReducer({ id: user.id, user: user}) )
        }).catch((error: AxiosError) => {
            console.log("error", error)
        }).finally(() => { dispatch(setLoadingVerifyIdReducer(false)) })
    }

    const openFrontImage = useCallback( () => { setShowExpandedIdFrontImage(true) }, [], )
    const openBackImage = useCallback( () => { setShowExpandedIdBackImage(true) }, [], )
    const openVideo = useCallback( () => { setShowExpandedIdVideo(true) }, [], )

    const closeFrontImage = useCallback( () => { setShowExpandedIdFrontImage(false) }, [], )
    const closeBackImage = useCallback( () => { setShowExpandedIdBackImage(false) }, [], )
    const closeVideo = useCallback( () => { setShowExpandedIdVideo(false) }, [], )
    
    const onSuccessINEcheck = useCallback(
        () => {
            setINEwasValidated("YES")
        },
        [],
    )
    
    const onFailedINEcheck = useCallback(
        () => {
            setINEwasValidated("NO")
        },
        [],
    )

    //data:image/jpeg;base64,i
    //data:image/jpeg;base64,/

    const rotateBase64Image = (base64Image: string, rotationAngle: number, callBack: (newBase64: string) => void) => {
        const image = new Image();
        image.src = `${base64Image}`; // Adjust the image format as needed

        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const width = 1300
            const height = 810
            if(!ctx)
                return
        
            canvas.width = width
            canvas.height = height

            ctx.translate(width / 2, height / 2);
            ctx.rotate(rotationAngle * Math.PI / 180);
            ctx.drawImage(image, -width / 2, -height / 2);
        
            const dataURL = canvas.toDataURL();
            // Now you can use the rotated dataURL to display the image

            const url = dataURL.replace(/png/, "jpeg")

            callBack(url)
        }
    }

    const rotate = (srcBase64: string, degrees: number, callback: (base64: string) => void) => {
        const canvas = document.createElement('canvas');
        const ctx    = canvas.getContext('2d');
        const image  = new Image();
      
        image.onload = function () {
            canvas.width  = degrees % 180 === 0 ? image.width : image.height;
            canvas.height = degrees % 180 === 0 ? image.height : image.width;
        
            if(!ctx)
                return

            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate(degrees * Math.PI / 180);
            ctx.drawImage(image, image.width / -2, image.height / -2);
        
            callback(canvas.toDataURL());
        };
      
        image.src = srcBase64;
    }

    const onPressFront = () => {
        rotate(idFrontImgUrl, 90, (newBase64: string) => {
            setIdFrontImgUrl(newBase64)
        })
    }

    const onPressBack = () => {
        rotate(idBackImgUrl, 90, (newBase64: string) => {
            setIdBackImgUrl(newBase64)
        })
    }

    const onPressFrontSave = () => {
        const file = base64ToFile(idFrontImgUrl)
        dispatch(setLoadingVerifyIdReducer(true))
        putIdImgByUserId("Front_id")
        .then((url) => {
            const options = { headers: { 'Content-Type': "image/*, video/*" } };
            axios.put(url, file, options)
            .then(() => {
                
            }).catch(() => {

            }).finally(() => {
                dispatch(setLoadingVerifyIdReducer(false))
            })
        }).catch(() => {
            dispatch(setLoadingVerifyIdReducer(false))
        })
    }

    const onPressBackSave = () => {
        const file = base64ToFile(idBackImgUrl)
        dispatch(setLoadingVerifyIdReducer(true))
        putIdImgByUserId("Back_id")
        .then((url) => {
            const options = { headers: { 'Content-Type': "image/*, video/*" } };
            axios.put(url, file, options)
            .then(() => {
                
            }).catch(() => {

            }).finally(() => {
                dispatch(setLoadingVerifyIdReducer(false))
            })
        }).catch(() => {
            dispatch(setLoadingVerifyIdReducer(false))
        })
    }



    return (
        <div id={`slider-profile-img-${index.toString()}`} className="con-id-img-card p-relative " >
            <div className="card-id-img flex flex-jc-c flex-ai-center pt-20 pb-20">

                <div className="flex flex-d-column flex-jc-c">

                    <div className="p-relative">
                        <IdImage url={idFrontImgUrl} onClick={openFrontImage} />
                        <div className="p-absolute" style={{ top: "10%", right: "2rem" }}>
                            <button onClick={onPressFront} className="pointer">
                                <FontAwesomeIcon icon={faRotate} className="white fs-20" />
                            </button>
                        </div>
                        <div className="p-absolute" style={{ top: "10%", left: "2rem" }}>
                            <button onClick={onPressFrontSave} className="pointer">
                                <FontAwesomeIcon icon={faSave} className="white fs-20" />
                            </button>
                        </div>
                    </div>

                    <div className="mt-20 mb-20 p-relative">
                        <IdImage url={idBackImgUrl} onClick={openBackImage} />
                        <div className="p-absolute" style={{ top: "10%", right: "2rem" }}>
                            <button onClick={onPressBack} className="pointer">
                                <FontAwesomeIcon icon={faRotate} className="white fs-20" />
                            </button>
                        </div>
                        <div className="p-absolute" style={{ top: "10%", left: "2rem" }}>
                            <button onClick={onPressBackSave} className="pointer">
                                <FontAwesomeIcon icon={faSave} className="white fs-20" />
                            </button>
                        </div>
                    </div>

                    <IdImage url={"./assets/images/video_preview.jpg"} onClick={openVideo} />
                    
                </div>

                {
                    showExpandedIdFrontImage &&
                    <ExpandedImage url={idFrontImgUrl} onClick={closeFrontImage} isVideo={false} />
                }

                {
                    showExpandedIdBackImage &&
                    <ExpandedImage url={idBackImgUrl} onClick={closeBackImage} isVideo={false} />
                }

                {
                    showExpandedIdVideo &&
                    <ExpandedImage url={idVideoImgUrl} onClick={closeVideo} isVideo={true} />
                }
                
                <div className="con-data-card-id-img flex-d-column ml-14">

                    <div className="con-img-name-country-id flex flex-ai-center">
                        <div className="img-profile-id-card mr-8" style={{ "backgroundImage": `url(${imgUrl})`}}></div>
                        <div className="flex flex-d-column" >
                            <p className="fw-600 fs-18 mb-4">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="fw-500 mt-4 fs-16">{`${t("General:label_055")}: ${user.countryName}`}</p>
                        </div>
                    </div>
                    {
                        editable &&
                        <div className="" style={{ minHeight: "32rem" }}>
                            <FormsSliderIdCard
                                onSuccessINEcheck={onSuccessINEcheck}
                                onFailedINEcheck={onFailedINEcheck}
                            />
                        </div>
                    }


                    {
                        editable &&
                        <div className="center">
                            {
                                INEwasValidated === "NO" &&
                                <button
                                    className="btn-slider-card-id fw-600  btn-slider-card-invalid-id pointer color-persian-red"
                                    onClick={inValidPicture}
                                >
                                    {t("General:label_057")}
                                </button>
                            }

                            {
                                INEwasValidated === "YES" &&
                                <button
                                    className="btn-slider-card-id fw-600 btn-slider-card-valid-id pointer color-green-pass"
                                    onClick={validPicture}
                                >
                                    {t("General:label_056")}
                                </button>
                            }

                        </div>
                    }
                </div>

            </div>

        </div>
    )
}

export default React.memo( SliderIdCard )