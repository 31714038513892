import React, { useState } from 'react'
import InputList from '../../UIcomponents/InputList/InputList'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { GetUsersReportsThunk } from '../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { useTranslation } from 'react-i18next'


const FiltersManageReports = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [reportFilter, setReportFilter] = useState("")
    const [reason, setReason] = useState("")
    const [filters] = useState([
        { id: "All", text: t("Reports:user:label_05") },
        { id: "New report", text: t("Reports:user:label_06") },
        { id: "New message", text: t("Reports:user:label_07") },
        { id: "Awaiting Response", text: t("Reports:user:label_08") }
    ])

    const limit = useSelector((state: RootState) => state.manageUserReports.limit);
    const openIncident = useSelector((state: RootState) => state.manageUserReports.openIncident);
    const gimGimmerAll = useSelector((state: RootState) => state.manageUserReports.gimGimmerAll);
    const userId = useSelector((state: RootState) => state.manageUserReports.userId);
    const supportSent = useSelector((state: RootState) => state.manageUserReports.supportSent);
    const justSupportLevel = useSelector((state: RootState) => state.manageUserReports.justSupportLevel);

    const handleChangeFilter = (filter: { id: string, text: string }) => {
        setReportFilter(filter.id)
        if(filter.id === "All")
            dispatch(GetUsersReportsThunk({ 
                page: 1, 
                limit, 
                bringTotal: true, 
                bringAllSupportsCounter: true, 
                openIncident: undefined, 
                gimGimmerAll: "All", 
                userId, 
                supportSent: undefined, 
                justSupportLevel: false
            }))
        else if(filter.id === "New message")
                dispatch(GetUsersReportsThunk({ 
                    page: 1, 
                    limit, 
                    bringTotal: true, 
                    bringAllSupportsCounter: true, 
                    gimGimmerAll, 
                    openIncident: true,
                    userId, 
                    supportSent, 
                    justSupportLevel
                }))
        else if(filter.id === "New report")
            dispatch(GetUsersReportsThunk({ 
                page: 1, 
                limit, 
                bringTotal: true, 
                bringAllSupportsCounter: true, 
                gimGimmerAll, 
                openIncident: false,
                userId, 
                supportSent, 
                justSupportLevel
            }))

    }

    return (
        <div className="flex">
            <InputList 
                text={reportFilter}
                width={"20rem"}
                height={"3.6rem"}
                error={""}
                fontSize={"fs-12"}
            >
                {
                    filters.filter((s) => s.id !== reportFilter)
                    .map((c) => {
                        return(
                            <div
                                key={c.id}
                                className="flex flex-ai-center flex-jc-sb pointer pl-14 pr-14 pt-8 pb-8 darks-white-bg"
                                onClick={() => {
                                    handleChangeFilter(c)
                                }}
                            >
                                <p className="fs-15 fw-400 c-black">{c.text}</p>
                            </div>
                        )
                    })
                }
            </InputList>

            <div className="ml-14"></div>

            <InputList 
                text={reason ?? "Reason"}
                width={"20rem"}
                height={"3.6rem"}
                error={""}
                fontSize={"fs-12"}
            >
                {
                    ["All", "Pishing"].filter((s: string) => s !== reportFilter)
                    .map((c: string) => {
                        return(
                            <div
                                key={c}
                                className="flex flex-ai-center flex-jc-sb pointer pl-14 pr-14 pt-8 pb-8 darks-white-bg"
                                onClick={() => {
                                    setReason(c)
                                }}
                            >
                                <p className="fs-15 fw-400 c-black">{c}</p>
                            </div>
                        )
                    })
                }
            </InputList>
        </div>
    )
}

export default React.memo( FiltersManageReports )