import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { WorkPreferenceStr } from '../../../../utils/dbModels/HistoryTransaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faLaptop, faWalking } from '@fortawesome/pro-solid-svg-icons';

type IconTextWorkPreferenceProps = {
    workPreference: WorkPreferenceStr
    size: string
}

const IconTextWorkPreference = React.memo(({
    workPreference,
    size
}: IconTextWorkPreferenceProps) => {

    const { t } = useTranslation()

    let icon: IconProp
    let text: string
    let iconSize: string
    let translateY: string

    switch (workPreference) {
        case "remote":
            icon = faLaptop
            text = t("Home:opportunity:label_37")
            iconSize = "3.9%"
            translateY = ".4%"
            break
        case "inside":
            icon = faHome
            text = t("Home:opportunity:label_38")
            iconSize = "3.5%"
            translateY = ".2%"
            break
        case "outside":
            icon = faWalking
            text = t("Home:TabNavigator:label_40")
            iconSize = "3.3%"
            translateY = ".2%"
            break
    }

    return (
        <div style={{ paddingBottom: ".7%",  justifyContent: "flex-end", alignItems: "center"}}>
            <FontAwesomeIcon 
                icon={icon}
                className="color-text"
                style={{ fontSize: size }}
            />
        </div>
    )
})

export default IconTextWorkPreference