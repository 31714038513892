import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getCertificatesUsersImages } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions';

export interface UserCertificateImage {
    id:             string 
    firstName:      string
    lastName:       string
    countryName:    string
    categoryName:   string
    imgKey:         string
    isCertifReq:    boolean
    isJobSelected:  boolean
    isUploaded:     boolean
    isVerified:     boolean
    jobId:          string
    jobName:        string
    photoKey:       string
}
  
export const getCertificateUsersImagesThunk = createAsyncThunk(
    'verifyCertificateData/getCertificateUsersImagesThunk',
    async () => {
      try {
        const { usersToReturn, LastEvaluatedKey} = await getCertificatesUsersImages()
        return {
            usersToReturn: usersToReturn as UserCertificateImage[],
            LastEvaluatedKey: LastEvaluatedKey
        }
        
      }catch (error) {
        console.log("error", error)
        return {
          usersToReturn: [] as UserCertificateImage[],
          LastEvaluatedKey: null
      }
      }
    }
)


const initialState = {
    sliderData: [] as UserCertificateImage[],
    loadingContainer: true,
    cedulawasValidated: "" as "YES" | "NO" | ""
}

export const verifyCertificateDataSlice = createSlice({
  name: 'verifyCertificateData',
  initialState,
  reducers: {
    removeVerifiedCertificateImageReducer: (state, action: PayloadAction<{ id: string, jobId: string }>) => {
        state.sliderData = state.sliderData.filter((u: UserCertificateImage) => (u.id !== action.payload.id && u.jobId !== action.payload.jobId ))
        return state
    },
    setLoadingVerifyCertificateReducer: (state, action: PayloadAction<boolean>) => {
      state.loadingContainer = action.payload
      return state
    },
    setCedulawasValidatedVerifyCertificateReducer: (state, action: PayloadAction<"YES" | "NO" | "">) => {
      state.cedulawasValidated = action.payload
      return state
    },
    resetverifyCertificateImageReducer: (state) => {
        return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCertificateUsersImagesThunk.fulfilled, (state, action: any) => {
        state.sliderData = action.payload.usersToReturn
        state.loadingContainer = false;
        return state
      }).addCase(getCertificateUsersImagesThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state
      }).addCase(getCertificateUsersImagesThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
    removeVerifiedCertificateImageReducer, 
    resetverifyCertificateImageReducer,
    setLoadingVerifyCertificateReducer,
    setCedulawasValidatedVerifyCertificateReducer
} = verifyCertificateDataSlice.actions

export default verifyCertificateDataSlice.reducer