import { useState, useEffect, memo } from 'react'
import LoadingSpinner from '../../../../LoadingSpinner'
import "./WhereMap.css"

interface WhereMapProps {
    id: string,
    radius?: number, 
    longitude: number, 
    latitude: number
}

const WhereMap = ({ id, radius = 0, longitude, latitude}: WhereMapProps) => {

    //const [loadingMap, setLoadingMap] = useState<boolean>(true)

    useEffect(() => {
/*
        const mapOptions = {
            //backgroundColor: "#f00",
            center: { lat: latitude, lng: longitude},
            zoom: 14 - (Math.ceil(radius / 5))
          };
        loader
        .load()
        .then((google) => {
            const map = document.getElementById(`single-map-${id}`)
            if(map){
                const mapObj = new google.maps.Map(map, mapOptions);
                const marker = new google.maps.Marker({
                    position: { lat: latitude, lng: longitude},
                    //title: "Hi"
                })
                if(radius && !isNaN(radius)){
                    const circle = new google.maps.Circle({
                        strokeColor: "#FF0000",
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: "#FF0000",
                        fillOpacity: 0.35,
                        //map,
                        center: { lat: latitude, lng: longitude},
                        radius: Math.round(radius * 1000),
                    });
                    circle.setMap(mapObj)
                    marker.setMap(mapObj)
                }
            }
        })
        .catch(e => {
            console.log("map failed", e)
        }).finally(() => { 
            //setLoadingMap(false) 
        })
            */
      return () => {
      }
    }, [])
    

    return (
        <>
            <div id={`single-map-${id}`} className="con-view-map">
                    
            </div>
            { 
                //loadingMap && <LoadingSpinner fullPage={false} /> 
            }  
        </>
    )
}
// { loadingMap && <LoadingSpinner fullPage={false} /> }  
export default memo(WhereMap)