import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getUsersForm } from "../../../../utils/requestsUtils/functionsRequests/usersForms/usersFormsFunctions"
import { getOrderedState } from "../../../../utils/redux/reduxUtils"

export type ExtraDataUser = {
    LastEvaluatedKey?: string,
    page: number,
    limit: number,
    bringTotal: boolean,
    stringToSearch?: string
}


export type UserForm = {
    id:             string
    firstName:      string 
    lastName:       string 
    phoneNumber:    string
    mail:           string
    skillId:        string
    skillName:      string
    state:          string
    city:           string
    cityHall:       string
    timestamp:      string
}

type ResponseChunk = {
    results: UserForm[]
    LastEvaluatedKey?: string
    page: number
    limit: number
    bringTotal: boolean
    total: number
}

export const GetUsersFormThunk = createAsyncThunk(
    'manageUsersFormData/GetUsersFormThunk',
    async ({ LastEvaluatedKey, page, limit, bringTotal }: ExtraDataUser): Promise<ResponseChunk> => {
      try { 
        const res = await getUsersForm({ LastEvaluatedKey, limit: limit === -1 ? undefined : limit, bringTotal })
        return {
          results: res.results,
          LastEvaluatedKey: res.LastEvaluatedKey,
          page,
          limit,
          bringTotal,
          total: res.total
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as UserForm[],
          page,
          limit,
          bringTotal,
          total: 0,
          LastEvaluatedKey,
        }
      }
    }
)

export const numberUsersFormByPage: number[] = [ 10, 25, 50, 100, -1 ]

export type ModalsUsersForm = "DeleteUsers" | "DeleteUser" | ""

type InitialState = {
    total: number
    limit: number
    page: number
    usersIds: string[]
    usersToShow: UserForm[]
    LastEvaluatedKey: string
    loadingContainer: boolean
    orderDirection: boolean
    orderColumn: string
    usersGrouped: UserForm[]
    checkboxUsersGrouped: boolean
    modalName: ModalsUsersForm
    userDelete?: UserForm
}

const initialState: InitialState = {
    total: 0,
    limit: numberUsersFormByPage[0],
    page: 1,
    usersIds: [] as string[],
    usersToShow: [],
    LastEvaluatedKey: "",
    loadingContainer: true,
    orderDirection: false,
    orderColumn: "",
    usersGrouped: [],
    checkboxUsersGrouped: false,
    modalName: ""
}

export const manageUsersFormDataSlice = createSlice({
  name: 'manageUsersFormData',
  initialState,
  reducers: {

    deleteUserFormReducer: (state, action: PayloadAction<string>) => {
        const id = action.payload

        state.usersIds = state.usersIds.filter((id: string) => id !== action.payload)
        state.usersToShow = state.usersToShow.filter((user: UserForm) => user.id !== action.payload)
  
        if(id === state.LastEvaluatedKey && state.usersIds.length > 1){
          const position = state.usersIds.indexOf(id)
          state.LastEvaluatedKey = state.usersIds[position-1]
        }
        state.total = state.total - 1

        return state
    },
    orderUserFormsReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
        const { orderColumn, orderDirection  } = action.payload
        /*
        state.bugReportsToShow = state.bugReportsToShow.map((bugReport: BugReportTableItem) => {

        //const bug = UserInfoCache.bugNameCache.get(bugReport.bugId)
        //if(bug?.name)
        //  bugReport.bugName = bug.name

        return JSON.parse( JSON.stringify(bugReport) )

        })*/
        state.usersToShow = getOrderedState({ orderColumn, orderDirection, array: state.usersToShow })
        state.orderColumn = orderColumn
        state.orderDirection = orderDirection
                
        return state
    },
      addUserFormGroupedReducer: (state, action: PayloadAction<UserForm>) => {
        state.usersGrouped.push(action.payload)
        return state
      },
      removeUserFormGroupedReducer: (state, action: PayloadAction<string>) => {
          state.usersGrouped = state.usersGrouped.filter((user: UserForm) => {
              return user.id !== action.payload
          })
          return state
      },
      checkAllCheckboxesManageUsersForm: (state, action: PayloadAction<UserForm[]>) => {
        const users = action.payload
        if(users.length === 0)
            state.checkboxUsersGrouped = false
        else
            state.checkboxUsersGrouped = true
        state.usersGrouped = users
        return state
      },
      openModalDeleteUserFormReducer: (state, action: PayloadAction<UserForm>) => {
        state.modalName = "DeleteUser"
        state.userDelete = action.payload
        return state
      },
      openModalDeleteUsersFormReducer: (state) => {
        state.modalName = "DeleteUsers"
        return state
      },
      resetManageUsersFormGroupedReducer:  (state) => {
        state.checkboxUsersGrouped = false
        state.usersGrouped = []
        return state
      },
      resetManageUsersFormModalReducer:  (state) => {
        state.modalName = ""
        state.userDelete = undefined
        return state
      },
      resetmanageUsersFormReducer: () => {
          return initialState
      }
  },

  extraReducers: (builder) => {
    builder.addCase(GetUsersFormThunk.fulfilled, (state, action: PayloadAction<ResponseChunk>) => {
      const { results,  page, LastEvaluatedKey, limit, bringTotal, total } = action.payload  

      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          state.usersIds[skip+i] = results[i].id
          lastIndex = skip+i
      }

        state.usersIds = state.usersIds.slice(0,lastIndex+1)

        state.usersToShow = results
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey ?? ""
        state.limit = limit ?? 0
        state.total = total

        return state
      }).addCase(GetUsersFormThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetUsersFormThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
    deleteUserFormReducer,
    resetmanageUsersFormReducer,
    orderUserFormsReducer,
    addUserFormGroupedReducer,
    removeUserFormGroupedReducer,
    checkAllCheckboxesManageUsersForm,
    resetManageUsersFormGroupedReducer,
    openModalDeleteUserFormReducer,
    resetManageUsersFormModalReducer,
    openModalDeleteUsersFormReducer
} = manageUsersFormDataSlice.actions

export default manageUsersFormDataSlice.reducer