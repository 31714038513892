import axios, { AxiosError, AxiosRequestConfig } from "axios"
import {
    getUserCredentials,
    adminApiUrl
} from "../../axiosUtils"
import { ProductDB, StatusProduct, TypeProduct } from "../../../../redux/slices/components/Products/ProductsSlice"
import { LastEvaluatedKeyProductsDB } from "../../../../redux/slices/components/ManageProducts/manageProductsDataSlice"

export const getProductDB = (productId: string) => {
    return new Promise((resolve: (value: ProductDB) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/products/${productId}`, config)
            .then((res) => {resolve(res.data.productDB)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/${productId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const getRequestedProducts = () => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/products/requested`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/requested`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type GetValidatedProductsParams = {
    typeProduct:        TypeProduct
    jobId?:              string
    LastEvaluatedKey?:  LastEvaluatedKeyProductsDB
    limit:              number
}

export const getValidatedProducts = (params: GetValidatedProductsParams) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params
            };

            axios.get(`${adminApiUrl}/v1/products/validated`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/validated`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type GetAvailableProductsParams = {
    typeProduct:        TypeProduct
    jobId?:              string
    LastEvaluatedKey?:  LastEvaluatedKeyProductsDB
    limit:              number
}

export const getAvailableProducts = (params: GetAvailableProductsParams) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params
            };

            axios.get(`${adminApiUrl}/v1/products/available`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/available`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


type ProductCreateDBBody = {
    id:             string
    jobId:          string
    typeProduct:    string
    productName:    string
    description:    string
}

export const createProductDB = (body: ProductCreateDBBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.post(`${adminApiUrl}/v1/products`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


type ProductUpdateCommonBody = {
    productName:    string
    status:         StatusProduct
    RFCcode:        string
    ISR:            number
    IVA_GIMMER:     number
    IVA_GIMWORK:    number
    RMPC:           number
}

type ProductUpdateDigitalBody = {
    fileType: string[]
    typeProduct: "DIGITAL"
} & ProductUpdateCommonBody

type ProductUpdatePhysicalBody = {
    typeProduct: "PHYSICAL"
} & ProductUpdateCommonBody

export type ProductUpdateBody = ProductUpdateDigitalBody | ProductUpdatePhysicalBody

export const updtateProductDB = (productId: string, body: ProductUpdateBody | { status: StatusProduct }) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/products/${productId}`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/${productId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updtateProductDBVerification = (productId: string, body: ProductUpdateBody | { status: "REJECTED" }) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/products/${productId}/verification`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/${productId}/verification`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


type ProductCreateGimworkCommonBody = {
    productName:    string
    RFCcode:        string
    ISR:            number
    IVA_GIMMER:     number
    IVA_GIMWORK:    number
    RMPC:           number
}

type ProductCreateGimworkDigitalBody = {
    fileType: string[]
    typeProduct: "DIGITAL"
} & ProductCreateGimworkCommonBody

type ProductCreateGimworkPhysicalBody = {
    typeProduct: "PHYSICAL"
} & ProductCreateGimworkCommonBody

export type ProductCreateGimworkBody = ProductCreateGimworkDigitalBody | ProductCreateGimworkPhysicalBody

export type ProductCreateGimworkDBBody = ProductCreateGimworkBody & { 
    id: string
    jobId: string
    description: string 
}

export const createProductGimworkDB = (body: ProductCreateGimworkDBBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.post(`${adminApiUrl}/v1/products/gimwork`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/gimwork`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const deleteProductDB = (productId: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.delete(`${adminApiUrl}/v1/products/${productId}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/${productId}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export const getLinkToPutPhysicalImage = (productId: string) => {
    return new Promise((resolve: (value: string) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.put(`${adminApiUrl}/v1/products/file/${productId}/physical-img`, {}, config)
            .then((res) => {resolve(res.data.link)})
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/products/file/${productId}/physical-img`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 
