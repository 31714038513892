import React, { useMemo } from 'react'
import { Message } from '../../../../../../utils/dbModels'
import "./MessagesList.css"
import MessageUserReport from './MessageUserReport/MessageUserReport'
import { UserReportType } from '../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'

type MessagesListProps = {
    messages: Message[]
    type: UserReportType
    tabUsers: number
    indexTab: number
}

const MessagesList = ({ messages, type, tabUsers, indexTab }: MessagesListProps) => {
    //console.log(" ")
    //console.log("type", type)

    const requestByUserType = useSelector((state: RootState) => state.manageUserReports.userReport.requestByUserType)
    const requestById = useSelector((state: RootState) => state.manageUserReports.userReport.requestBy)
    const personInvolvedId = useSelector((state: RootState) => state.manageUserReports.userReport.personsInvolved)[0]
    const requestByImgUrl = useSelector((state: RootState) => state.manageUserReports.userRequestBy?.imgUri)
    const personInvolvedImgUrl = useSelector((state: RootState) => state.manageUserReports.userInvolved?.imgUri)
    const supportImgUri = useSelector((state: RootState) => state.auth.user.imgUrl)

    /*
    const supportId = useSelector((state: RootState) => state.auth.user.id)

    const indexLastMessageSupport = useMemo(() => { 
        let currentIndex = -1
        for(let i=0; i<messages.length; i++){
            if(messages[i].userID !== requestById && messages[i].userID !== personInvolvedId){
                currentIndex = i
            }
        }
        return currentIndex
    }, [messages])

        //console.log("indexLastMessageSupport", indexLastMessageSupport)
*/

    const gimGimmer = type === "RequestBy" 
                                ? (requestByUserType === "Gim" ? "Gim" : "Gimmer")
                                : (requestByUserType !== "Gim" ? "Gim" : "Gimmer")

    return (
        <div className="message-list">
            {
                messages.map((message, index) => {
                    return(
                        <MessageUserReport 
                            checkView={indexTab === 2 && (type === "RequestBy" ? tabUsers === 0 : tabUsers === 1) && !message.viewed} 
                            type={type} 
                            userId={type === "RequestBy" ? requestById : personInvolvedId}
                            message={message} 
                            key={index} 
                            gimGimmer={gimGimmer}
                            isFirstGroupMessage={message.isFirstGroupMessage}
                            requestImgUrl={requestByImgUrl ?? ""}
                            personInvolvedUrl={personInvolvedImgUrl ?? ""}
                            supportImgUri={supportImgUri}
                        />
                    )
                })
            }
        </div>
    )
}

/*
,
    (prevProps: MessagesListProps, nextProps: MessagesListProps) => {
        return true
    }
*/

export default React.memo( MessagesList )