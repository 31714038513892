import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react'
import { listPhoneCodeInfo, PhoneCodeInfo} from '../../../utils/staticVariables/staticVariables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside';
import "./PhoneInputMask.css"
import { PhoneNumber } from '../../../redux/slices/components/SignUp/SignUpSlice';
import { useTranslation } from 'react-i18next';

type PhoneInputMaskProps = {
    phoneNumber: PhoneNumber
    setPhoneNumber: React.Dispatch<React.SetStateAction<PhoneNumber>>
    setError?: React.Dispatch<React.SetStateAction<string>>
}


const PhoneInputMask = ({
    phoneNumber,
    setPhoneNumber,
    setError, 
}: PhoneInputMaskProps) => {

    const { t } = useTranslation()

    const [phoneCodeSelected, setPhoneCodeSelected] = useState( listPhoneCodeInfo.find((l) => l.code === phoneNumber.code) ?? listPhoneCodeInfo[0])
    const [showList, setShowList] = useState(false)
    const [excludeIds] = useState(["open-phone-list"])

    const numberZeros = (format: string) => {
		return format.replace(/[^0]/g, '').length;
    }

    const existsError = (valueFiltered: string, format: string) => {
        if(valueFiltered.replace(/[\D]/g, "").length !== numberZeros(format))
            return true
        return false
    }

    const changeZerosByNumbers = (value: string) => {
        //console.log(" ")
        //console.log("format", phoneCodeSelected.format)
        let newValue = ""
        let sumOneToIndexWhenSpaceCharacterAppears = 0
        for(let i=0; i<value.length; i++){
            //console.log("i", i, phoneCodeSelected.format[i])
            if(phoneCodeSelected.format[i + sumOneToIndexWhenSpaceCharacterAppears] === "0")
                newValue += value[i]
            else{
                newValue += ` ${value[i]}`
                sumOneToIndexWhenSpaceCharacterAppears++
            }
            //console.log("newValue", newValue)
        }

        return newValue
    }


    const onChange = (value: string) => {
        //console.log("value", value)
        const valueFiltered = value.replace(/[\D]/g, "")
        //console.log("valueFiltered 1 ", valueFiltered)
        if(valueFiltered.length <= numberZeros(phoneCodeSelected.format) ){
            const newValue = changeZerosByNumbers(valueFiltered)
            //console.log("newValue", newValue, phoneCodeSelected.format)
            setPhoneNumber({ phoneNumber: newValue, code: phoneCodeSelected.code })

            if(!setError)
                return 
            
            if(existsError(valueFiltered, phoneCodeSelected.format))
                setError(t("SignUp:phoneScreen:label_05") ?? "")
            else{
                setError("")
            }
        }

    }



    const closeList = useCallback(
      () => {
            setShowList(false)
      },
      [],
    )
    
    const onSelectPhone = (phone: PhoneCodeInfo) => {
        setPhoneCodeSelected(phone)
        setPhoneNumber({...phoneNumber, code: phone.code })

        const valueFiltered = phoneNumber.phoneNumber.replace(/[\D]/g, "")
        if(setError){
            if( existsError(valueFiltered, phone.format) ){
                if(setError)
                    setError(t("SignUp:phoneScreen:label_05") ?? "")
            }else{
                setError("") 
            }
        }

    }

    const openList = () => {
        setShowList(true)
    }


    return (
        <div className="flex flex-d-column">

 
            <div className="flex flex-d-column mt-40">
                <label htmlFor="Phone number" className="fs-16 mb-4">{t("SignUp:phoneScreen:label_07")}</label>

                <div className="input-login flex flex-ai-center">
                    <div id="open-phone-list" onClick={openList} className="flex">
                        <img className="flag-phone-img ml-10" src={`./assets/flags/${phoneCodeSelected.urlName}`} alt={`${phoneCodeSelected.urlName}_flag`} />
                        <div className="center ml-4">
                            <FontAwesomeIcon 
                                icon={ solid("chevron-down") }
                                className="fs-8"
                            />
                        </div>
                    </div>
                    <p className="fs-15 ml-6 mb-2">{phoneCodeSelected.code}</p>
                    <input 
                        //type={"number"} 
                        id="login-password-3" 
                        name="phone" 
                        value={phoneNumber.phoneNumber}
                        onChange={(e) => onChange(e.target.value)}
                        className="fs-16 p-6 border-none flex-1 outline-none"
                        placeholder={`${phoneCodeSelected.format}`}
                    />

                </div>
            </div>

            {
                showList &&
                <ClickOutside onPress={closeList} exclude={excludeIds}>
                    <div className="p-relative">
                        <div className="p-absolute list-phone-input">
                            {
                                listPhoneCodeInfo.filter((p: PhoneCodeInfo) =>  p.code !== phoneCodeSelected.code).map((phone: PhoneCodeInfo) =>{
                                    return(
                                        <div key={phone.code} onClick={() => onSelectPhone(phone)} className="flex flex-ai-center item-phone-input pointer">
                                            <img className="flag-phone-img" src={`./assets/flags/${phone.urlName}`} alt={`${phone.urlName}_flag`} />
                                            <p className="fs-12 ml-10">
                                                {phone.name}
                                            </p>
                                            <p className="fs-12 c-manatee ml-6">
                                                {phone.code}
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </ClickOutside>
            }

        </div>
    )
}

export default React.memo( PhoneInputMask )