import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getAllJobsCategories } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions'
import { EditCategoryModalData, EditJobModalData } from './managerJobsModalsSlice'


export interface SortEvent {
  countryName: string,
  sortByCategoryName: string
}

interface LinkedJob {

}

export type OptionalJobInputs = {

    fixe?: {
        price:  number
        gimIVA: number
        gimmerIVA: number
        gimworkIVA: number
        ISR: number
        RMP: number
    },
    transportCosts?: {
        price:  number
        gimIVA: number
        gimmerIVA: number
        gimworkIVA: number
        ISR: number
        RMP: number
    },
    tips?: {
        tip:  number
        gimIVA: number
        gimmerIVA: number
        gimworkIVA: number
        ISR: number
        RMP: number
    },
    product?: {
        gimIVA:   number
        gimmerIVA: number
        gimworkIVA: number
        RMPC: number
    }
}

export type PriceInputs = {
  price: number
  malusGim: number
  malusGimmer: number
  malusShareGimmer: number
  malusShareGim: number
  IVA: number
  timeTransaction: {
    gimIVA: number
    gimmerIVA: number
    gimworkIVA: number
    ISR: number
    RMP: number
  },
}

export type DayInputs = PriceInputs & OptionalJobInputs

export type NightInputs = PriceInputs & {
    nightHours: {
      startTime:  string
      endTime:    string
  },
} & OptionalJobInputs

export type PricingInformation =  {
  day: DayInputs
  night?: NightInputs
}

export interface JobCategoryJob {
    id: string
    //categoryId: string
    active: boolean
    imgKey: string

    isCertifReq: boolean
    isGimmable: boolean
    isPool: boolean
    jobName: string
    linkedJobs: LinkedJob[]

    pricingInformation: PricingInformation
    RFCcode: string
}

export type JobCategoryJobWhole =  JobCategoryJob & { categoryId: string }

export interface CategoryJob {
    id: string
    categoryName: string
    country: string
    imgKey: string
    isPrincipal: boolean
    photoKey: string
    jobs: JobCategoryJob[]
    //jobs: string[]
    //jobsData: JobCategoryJob[]
}



export interface ManagerJobData {
    categoryJobs: CategoryJob[],
    countryNames: string[],
    orderJobs: SortEvent[],
    loading: boolean
}


const orderCategoryJobsByCurrentState = (categoryJobs: CategoryJob[], orderJobs: SortEvent[]) => {
  let newArrayOrdered: CategoryJob[] = []
  for(const orderEvent of orderJobs){
    const categoriesGroupedByCountry = categoryJobs.filter((c:CategoryJob) => c.country === orderEvent.countryName)
    if(orderEvent.sortByCategoryName === "asc")
      newArrayOrdered = newArrayOrdered.concat(categoriesGroupedByCountry.sort((a: CategoryJob, b: CategoryJob) => a.categoryName>b.categoryName ? 1 : -1))
    else
      newArrayOrdered = newArrayOrdered.concat(categoriesGroupedByCountry.sort((a: CategoryJob, b: CategoryJob) => a.categoryName<=b.categoryName ? 1 : -1))
  }
  return newArrayOrdered
}


export const getAllJobsCategoriesChunk = createAsyncThunk(
    'managerJobData/getAllJobsCategoriesChunk',
    async (thunkAPI) => {

       try {
          const items = await getAllJobsCategories(true);

          const categories = items.sort((a: CategoryJob, b: CategoryJob) => a.categoryName>b.categoryName ? 1 : -1);

          const uniqueCountries = categories
                                    .map((c: CategoryJob) => c.country)
                                    .filter((value: CategoryJob, index: number, self: CategoryJob[]) => 
                                        self.indexOf(value) === index
                                    ).sort();
          
          const orderJobs: SortEvent[] = []
          for(const country of uniqueCountries){
            orderJobs.push({ countryName: country, sortByCategoryName: "asc" })
          }

          return {
            countryNames: uniqueCountries,
            jobs: categories, 
            orderJobs
          }
       } catch (error) {
        console.log("error", error)
        return {
          countryNames: [],
          jobs: [], 
          orderJobs: [] as SortEvent[]
        }
       }

    }
  )


const initialState = {
    categoryJobs: [] as CategoryJob[],
    countryNames: ["France", "Mexico"],
    orderJobs: [] as SortEvent[],
    loading: true,
}

export const managerJobDataSlice = createSlice({
  name: 'managerJobData',
  initialState,
  reducers: {

    updateJobsByCountryReducer: (state, action: PayloadAction<CategoryJob[]>) => {
      return { ...state, orderByCategoryName: "asc", categoryJobs: action.payload };
    },


    removeCategoryReducer: (state, action: PayloadAction<string>) => {
      return { ...state, categoryJobs: state.categoryJobs.filter((c: CategoryJob) => c.id !== action.payload) };
    },

    removeJobReducer: (state, action: PayloadAction<{categoryId: string, jobId: string}>) => {
      state.categoryJobs = state.categoryJobs.map((c: CategoryJob) => {
          if(c.id === action.payload.categoryId){
              c.jobs = c.jobs.filter((job: JobCategoryJob) => job.id !== action.payload.jobId)
              //c.jobs = c.jobs.filter((jobId: string) => jobId !== action.payload.jobId)
              return c;
          }
          return c
      })
      return state
    },
    
    activateJobReducer: (state, action: PayloadAction<{categoryId: string, jobId: string, newActivationState: boolean}>) => {
      state.categoryJobs = state.categoryJobs.map((c: CategoryJob) => {
          if(c.id === action.payload.categoryId){
              c.jobs = c.jobs.map((job: JobCategoryJob) => {
                if(job.id === action.payload.jobId)
                  return {...job, active: action.payload.newActivationState}
                return job
              })
              return c;
          }
          return c
      })
      return state
    },
    orderByCategoryNameReducer: (state, action: PayloadAction<SortEvent>) => {
      const newState = {...state}
      let countryJobsToSort = newState.categoryJobs.filter((c: CategoryJob)=> c.country === action.payload.countryName)
      let otherJobsToSort = newState.categoryJobs.filter((c: CategoryJob)=> c.country !== action.payload.countryName)

      if(action.payload.sortByCategoryName === "asc"){
        countryJobsToSort = countryJobsToSort.sort((a: CategoryJob, b: CategoryJob) => a.categoryName>b.categoryName ? 1 : -1);
        
        const orderJobs: SortEvent[] = []
        for(const country of newState.orderJobs){
          if(country.countryName === action.payload.countryName)
              orderJobs.push({ countryName: country.countryName, sortByCategoryName: "asc" })
          else
            orderJobs.push({ countryName: country.countryName, sortByCategoryName: country.sortByCategoryName })
        }

        newState.orderJobs = orderJobs;
      }
      else{
        countryJobsToSort = countryJobsToSort.sort((a: CategoryJob, b: CategoryJob) => a.categoryName<=b.categoryName ? 1 : -1);
        
        const orderJobs: SortEvent[] = []
        for(const country of newState.orderJobs){
          if(country.countryName === action.payload.countryName)
              orderJobs.push({ countryName: country.countryName, sortByCategoryName: "des" })
          else
            orderJobs.push({ countryName: country.countryName, sortByCategoryName: country.sortByCategoryName })
        }

        newState.orderJobs = orderJobs;
      }


      newState.categoryJobs = otherJobsToSort.concat(countryJobsToSort)
      return newState;
    },

    updateEditedCategoryReducer: (state, action: PayloadAction<EditCategoryModalData>) => {
      state.categoryJobs = state.categoryJobs.map((categoryJob: CategoryJob) => {
        const categoryUpdated = action.payload;
        if(categoryJob.id === categoryUpdated.categoryId){
          return {
            ...categoryJob, 
            country: categoryUpdated.country,
            categoryName: categoryUpdated.categoryName,
            imgKey: categoryUpdated.imgKey,
            photoKey: categoryUpdated.photoKey
          }
        }
        return categoryJob
      })
      const orderedCategoryJobs = orderCategoryJobsByCurrentState(state.categoryJobs, state.orderJobs)
      state.categoryJobs = orderedCategoryJobs
      return state
    },

    updateEditedJobReducer: (state, action: PayloadAction<EditJobModalData>) => {
      
      const jobUpdated = action.payload.job;

      state.categoryJobs = state.categoryJobs.map((categoryJob: CategoryJob) => {

          if(categoryJob.id === action.payload.categoryId){

              categoryJob.jobs = categoryJob.jobs.map((job: JobCategoryJob) => {
                
                  if(job.id === jobUpdated.id)
                      return jobUpdated
                  return job
              })
              return categoryJob
          }
          return categoryJob
      })
      const orderedCategoryJobs = orderCategoryJobsByCurrentState(state.categoryJobs, state.orderJobs)
      state.categoryJobs = orderedCategoryJobs
      return state
    },
    updateEditedJobsGroupedByCategory: (state, action: PayloadAction<{ categoryId: string, jobsGrouped: JobCategoryJob[]}>)  => {
      const index = state.categoryJobs.findIndex(( categoryJob: CategoryJob) => categoryJob.id === action.payload.categoryId)
      const categoryJob = state.categoryJobs[index]
      categoryJob.jobs = categoryJob.jobs.map((job: JobCategoryJob) => {
        const jobMatched = action.payload.jobsGrouped.find((jobEdited: JobCategoryJob) => job.id === jobEdited.id )
        return jobMatched ? {...job, ...jobMatched} : job
      })
      state.categoryJobs[index] = categoryJob
      return state
    },
    deleteJobsGroupedByCategory: (state, action: PayloadAction<{ categoryId: string, jobsGrouped: { id: string; }[]}>)  => {
      const index = state.categoryJobs.findIndex(( categoryJob: CategoryJob) => categoryJob.id === action.payload.categoryId)
      const categoryJob = state.categoryJobs[index]
      categoryJob.jobs = categoryJob.jobs.filter((job: JobCategoryJob) => {
        const jobMatched = action.payload.jobsGrouped.find((jobEdited: { id: string; }) => job.id === jobEdited.id )
        return jobMatched ? false : true
      })
      /*
      categoryJob.jobs = categoryJob.jobs.filter((jobId: string) => {
        const jobMatched = action.payload.jobsGrouped.find((jobEdited: { id: string; }) => jobId === jobEdited.id )
        return jobMatched ? false : true
      })*/
      state.categoryJobs[index] = categoryJob
      return state
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllJobsCategoriesChunk.fulfilled, (state, action: any) => {
      state.categoryJobs = action.payload.jobs
      state.countryNames = action.payload.countryNames
      state.orderJobs = action.payload.orderJobs;
      state.loading = false
      return state
    })
    .addCase(getAllJobsCategoriesChunk.pending, (state, action: any) => {
      state.loading = true
      return state
    }).addCase(getAllJobsCategoriesChunk.rejected, (state, action: any) => {
      const failState = {...initialState}
      failState.loading = false
      return failState
    })
    
  },
});

// Action creators are generated for each case reducer function
export const {  
    updateJobsByCountryReducer, 
    orderByCategoryNameReducer,
    removeCategoryReducer,
    updateEditedCategoryReducer,
    removeJobReducer,
    activateJobReducer,
    updateEditedJobReducer,
    updateEditedJobsGroupedByCategory,
    deleteJobsGroupedByCategory,
} = managerJobDataSlice.actions

export default managerJobDataSlice.reducer