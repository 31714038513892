import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { LanguageLabel } from '../../../redux/slices/authSlice'


const useGetJobName = (id?: string) => {

    const { i18n } = useTranslation()

    const jobNamesile = useSelector((state: RootState) => state.global.jobsNamesFile)

    if(!id)
        return "NO ID"

    const language: LanguageLabel = i18n.language as LanguageLabel

    if(jobNamesile)
        return jobNamesile[id]?.[language] ?? "No name found"
    return ""
}

export default useGetJobName