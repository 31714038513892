import { RootState, useAppDispatch } from '../../../redux/store/store';
import { setNewCategoryModalState } from '../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import "./HeaderManageJobs.css"
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const HeaderManageJobs = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const accessCreate = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Category", id_Action: "Create" })

    const handleAddCategory = () => {
        dispatch( setNewCategoryModalState() )
    }

    const goSimulator = () => {
        navigate(`/simulator`)
    }

    return (
        <div className="flex flex-jc-sb flex-ai-center">
            
            <p className="title-screen">
                {t("General:label_063")}
            </p>

            <div className="flex flex-ai-center flex-1">
                {
                    accessCreate &&
                    <button 
                        className="btn-beside-title-screen"
                        onClick={handleAddCategory}
                    >
                        <FontAwesomeIcon icon={regular("plus")} className="fs-18-vh" />
                        <p className="text-btn-title-screen">{t("General:label_064")}</p>
                    </button> 
                }
                <button 
                    className="btn-beside-title-screen"
                    onClick={goSimulator}
                >
                    <p className="text-btn-title-screen">{"Simulator"}</p>
                </button> 
                
            </div>
        </div>
    )
}

export default React.memo( HeaderManageJobs )