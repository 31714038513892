import { faChevronRight } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type ButtonScreenHelpProps = {
    text: string
    onPress: () => void
}

const ButtonScreenHelp = ({ text, onPress }: ButtonScreenHelpProps) => {
    return (
        <button onClick={onPress} className="flex flex-ai-center flex-jc-sb width-100 pointer" style={{ height: "4.6vh" }}>
            <p className="fs-18-vh color-text ml-10">{text}</p>
            <FontAwesomeIcon icon={faChevronRight} className="fs-18-vh color-text mr-10" />
        </button>
    )
}

export default ButtonScreenHelp