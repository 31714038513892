import { useState } from 'react';
import { CategoryJob } from '../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import ContentInnerImage from './ContentInnerImage/ContentInnerImage';
import "./CategoryDropdown.css"
import TableDropdown from './TableDropdown/TableDropdown'
import React from 'react';

interface JobDropdownProps {
    categoryJob: CategoryJob
}

const JobDropdown = React.memo(({categoryJob}:JobDropdownProps) => {
    //console.log("categoryJob", categoryJob)
    const [dropdownStatus, setDropdownStatus] = useState<string | null>(null);

    return (
        <div className="container-bar-table">
            <div 
                className="container-job-dropdown-bar"
                /* with animation
                className={`container-job-dropdown-bar 
                    ${dropdownStatus === "open" && "container-job-dropdown-bar-opened br-bar-jobs" }
                    ${dropdownStatus === "close" ?  "container-job-dropdown-bar-opened-reverse" : ""}
                `}
                */
            >
                {/*
                <div 
                    className="container-img-dropdown"
                    style={{ "backgroundImage": `url("${S3DomainGimwork_categories_logo}${job.imgKey}")`} }
                >
                </div>
    */}
                <div className="container-black-layer"></div>

                <ContentInnerImage 
                    dropdownStatus={dropdownStatus} 
                    setDropdownStatus={setDropdownStatus}
                    categoryJob={categoryJob}
                />

            </div>

            <TableDropdown 
                jobs={categoryJob.jobs} 
                dropdownStatus={dropdownStatus} 
                categoryId={categoryJob.id}
                categoryName={categoryJob.categoryName}
            />

        </div>
    )
})

export default JobDropdown