import React, { CSSProperties, useEffect, useState } from 'react'
import { getProfilePictureByUserId } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { userImageCache } from '../../../utils/cache/imgUserCache'

type UserImageProps = {
    userId: string
    sizeImg: number
    unit: string
    size?: "small" | "mid" | ""
    style?: CSSProperties
}

const UserImage = ({ userId, size = "small", sizeImg, unit }: UserImageProps) => {
    
    const [imgUrl, setImgUrl] = useState("")

    useEffect(() => {

        const getData = async() => {

            try {
                const urlCached = await userImageCache.getUrl(userId, size)
                setImgUrl(urlCached)

            } catch (error) {
                
            }
        }

        getData()

    }, [])
    

    return (
        <img 
            style={{
                width: `${sizeImg}${unit}`,
                height: `${sizeImg}${unit}`,
            }}
            className={"circle"} 
            src={imgUrl} 
            alt="user" 
            onError={(e) => { 
                (e.target as HTMLImageElement).src = "/user.svg" }
            }
        />
    )
}

export default React.memo( UserImage )