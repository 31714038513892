import React, { useState } from 'react'
import SignInMethods from '../SignInMethods/SignInMethods'
import FormInputsLogin from '../FormInputsLogin/FormInputsLogin'
import { useTranslation } from 'react-i18next'

type LoginProcessProps = {
    nextStep: (index: number) => void
    showSignMethods: boolean
    setShowSignMethods: React.Dispatch<React.SetStateAction<boolean>>
}

const LoginProcess = ({ showSignMethods, setShowSignMethods, nextStep }: LoginProcessProps) => {

    const { t } = useTranslation()

    return (
        <>
            <img className="gimwork-img" src="./assets/images/GimworkLogo.svg" alt="Gimwork_logo" />
            <br />
            <br />
            <p className="fs-46 fw-600">
                {t("SignIn:label_01")}
            </p>

            <p className="fs-26 fw-700 mb-50" style={{ marginTop: "10vh" }}>
                {t("SignIn:label_02")}
            </p>

            {
                showSignMethods ?
                <SignInMethods setShowSignMethods={setShowSignMethods} />
                : <FormInputsLogin nextStep={nextStep} setShowSignMethods={setShowSignMethods} />
            }
        </>
    )
    }

export default LoginProcess