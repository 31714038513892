import React from 'react'
import "./ToggleButton.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-solid-svg-icons'

type ToggleButtonProps = {
    id: string
    checked: boolean
    onChange: (id: string) => void
}
// press icon makes clickOutside closes, it need to be under an auxiliar div
const ToggleButton = ({ id, checked, onChange }: ToggleButtonProps) => {
    return (
        <div className={`toggle ${checked ? "toggle-gim-bg" : ""}`}>
            <input className="toggle-checkbox " onChange={() => onChange(id)} checked={checked} type="checkbox" id={id} />
            <label  className="toggle-checkbox-label center p-relative" htmlFor={id}>
                <div className="p-absolute center " style={{ zIndex: 2 }}>
                    { checked && <FontAwesomeIcon  icon={faCheck} className="color-gim fs-10"  /> }
                </div>
                <div className="p-absolute center bg-transparent width-100 height-100" id="icon-toggle" style={{ zIndex: 3 }}>
                    
                </div>
            </label>
        </div>
    )
}

export default React.memo( ToggleButton )