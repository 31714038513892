import { Message } from "../dbModels"
import { MessageType } from "./ChatFunctions"
import { UpdateMessageStateDetails } from "./types"

export type OnNewMessageOnRoom ={
    chatRoom: {
        chatRoomUsers: {
            nextToken: null
        }
        createdAt: string
        id: string
        lastMessage: {
            chatRoomID: string
            content: string
            createdAt: string
            id: string
            information: any
            otherUserID: string
            type: MessageType
            updatedAt: string
            userID: string
            viewed: boolean
        },
        lastMessageID: string
        messages: {
            nextToken: null
        },

        updatedAt: string
    }
    chatRoomID: string
    content: string
    createdAt: string
    id: string
    information: any
    otherUser: any // it comes as null
    otherUserID: string
    type: MessageType
    updatedAt: string
    user:{
        chatRoomUser: {
            nextToken: null
        }
        createdAt: string
        description: string // it comes as null
        id: string
        imageUri: string
        name: string
        status: any
        updatedAt: string
    },
    userID: string
    viewed: boolean
}

export const messageSendUserReportToMessage = (message: OnNewMessageOnRoom ): Message => {
    return {
        id: message.id,
        __typename: message.type,
        chatRoomID: message.chatRoomID,
        content: message.content,
        information: message.information,
        createdAt: message.createdAt,
        updatedAt: message.updatedAt,
        userID: message.userID,
        viewed: message.viewed
    }
}

export const messageSeenUserReportToMessage = (message:  UpdateMessageStateDetails): Message => {
    return {
        id: message.id,
        __typename: message.type,
        chatRoomID: message.chatRoomID,
        content: message.content,
        information: message.information,
        createdAt: message.createdAt,
        updatedAt: message.updatedAt,
        userID: message.userID,
        viewed: message.viewed
    }
}