import React from 'react'
import "./ButtonSettingsNavBar.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/pro-solid-svg-icons'
import ClickOutside from '../../UIcomponents/ClickOutside/ClickOutside'

type ButtonSettingsNavBarProps = {
    handleList: () => void
    imgUrl?: string
    showList: boolean
    closeList: () => void
    children: React.ReactNode
}

const ButtonSettingsNavBar = ({ handleList, imgUrl, showList, closeList, children }: ButtonSettingsNavBarProps) => {
     return (
        <div 
            id="settings-button"
            onClick={handleList}
            className="con-white-profile pointer p-relative white-bg flex flex-jc-sb flex-ai-center border-radius-3"
        >
            <FontAwesomeIcon icon={faBars} className="color-text fs-22-vh ml-8" />


            <img 
                className="circle img-settings ml-10 mr-6" 
                src={imgUrl ? imgUrl : "/user.svg"}
                onError={(e) => { 
                    (e.target as HTMLImageElement).src = "/user.svg" }
                }
                alt={"User"} 
            />
            {
                showList &&
                <ClickOutside onPress={closeList} exclude={["settings-button"]} >
                    {children}
                </ClickOutside>
            }

        </div>
    )
}

export default React.memo( ButtonSettingsNavBar )