import React from 'react'
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { useSelector } from 'react-redux';
import AddProductDBModal from './AddProductDBModal/AddProductDBModal';
import { ModalNameRequestProducts, resetRequestProductsDBModalsReducer } from '../../../redux/slices/components/RequestProducts/requestProductsDataSlice';
import RequestProductDBModal from './RequestProductDBModal/RequestProductDBModal';

const RequestProductsModals = () => {
    const dispatch = useAppDispatch()
    const modalName = useSelector((state: RootState) => state.requestProductsData.modalName);
  
    const handleClickOutsideModal = (e: any) => {
        const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickOutsideModal && e.target.id === "darkened-background-modal")
            dispatch( resetRequestProductsDBModalsReducer() )
    }
  
    return (
      <>
        {
          modalName &&
          <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
              <div id={"background-aux-modal"}>
                  <ProductsRequestSelectModal modalName={modalName} />
              </div>
          </div>
        }
      </>
    )
}

interface ProductsRequestSelectModalProps {
    modalName: ModalNameRequestProducts
  }
  
const ProductsRequestSelectModal = ({ modalName }: ProductsRequestSelectModalProps) => {

    switch(modalName){
      case "AddProductDBModal":
        return (<AddProductDBModal />)
      case "RequestProductDBModal":
          return (<RequestProductDBModal />)
      default:
          return(
              <h1>Error modals</h1>
          )
    }
    
}

export default React.memo( RequestProductsModals )