import { faPlus, faVideo } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Colors } from '../../../utils/colors/colors'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import Webcam from "react-webcam";
import WebCamID from '../../../components/WebCamID/WebCamID'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { putIdImgByUserId, uploadedUserIdImage } from '../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'

const VideoIdScreen = () => {

    const navigate = useNavigate()

    const userId = useSelector((state: RootState) => state.auth.user.id)

    const [image, setImage] = useState<File | null>(null)
    const [urlPreviewImage, setUrlPreviewImage] = useState("")

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState("")

    const onNext = useCallback(
        () => {
            navigate("/select-profile")
        },
        [],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const onFinishRecord = useCallback(
        (blob: Blob) => {
            setLoading(true)
            putIdImgByUserId("Video_id")
            .then((url) => {
                const options = { headers: { 'Content-Type': "image/*, video/*" } };
                axios.put(url, blob, options)
                .then(() => {
                    uploadedUserIdImage(userId)
                    .then(() => {
                        onNext()
                    }).catch(() => {
                        
                    }).finally(() => {
                        setLoading(false)
                    })
  
                }).catch(() => {
                    setLoading(false)
                })
            }).catch(() => {
                setLoading(false)
            })
        },
        [],
    )
    

    const setImageUploaded = async() => {

        if(error)
            setError("")

        const fileImage = (document.getElementById("sign-up-profile-picture") as HTMLInputElement)?.files
        if(fileImage?.length)
        {
            const file = fileImage[0]

            setImage(file)

            const fReader = new FileReader();
            fReader.readAsDataURL(file);
            fReader.onloadend = function(event){
                if(typeof event.target?.result === "string"){
                    setUrlPreviewImage(event.target?.result)
                }
            }
        }
    }
    

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                   
                    <p className="fs-36-vh c-text" style={{ marginBottom: "2.2vh" }}>{"Upload your video ID"}</p>

                    <p className="fs-22-vh c-text" style={{ marginBottom: "4.4vh" }}>{"Help us to secure our community"}</p>

                    <WebCamID onFinishRecord={onFinishRecord} />

                    <div className="p-relative">
                        <p className="p-absolute color-error fs-12 mt-6">
                            {error}
                        </p>
                    </div>

                    <p className="fs-18-vh c-text" style={{ marginTop: "4.4vh" }}>{"Upload the front and back of your ID Card, Passepport or Drive licence."}</p>
                </div>

                { ImageSignUp["Id"] }
            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={"Skip"} 
                />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }

        </div>
    )
}

export default VideoIdScreen