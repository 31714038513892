import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import React from 'react';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { checkAllCheckboxesManageUsersForm, GetUsersFormThunk, numberUsersFormByPage } from '../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice';
import { getPaginatedData } from '../../../../utils/strings/strings';

const PaginationUsersFormTable = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    
    const usersIds = useSelector((state: RootState) => state.manageUsersForm.usersIds);
    const usersToShow = useSelector((state: RootState) => state.manageUsersForm.usersToShow);
    const loadingContainer = useSelector((state: RootState) => state.manageUsersForm.loadingContainer);
    const LastEvaluatedKey = useSelector((state: RootState) => state.manageUsersForm.LastEvaluatedKey);
    const page = useSelector((state: RootState) => state.manageUsersForm.page);
    const limit  = useSelector((state: RootState) => state.manageUsersForm.limit);
    const total = useSelector((state: RootState) => state.manageUsersForm.total);

    const paginate = (direction: string) => {
        
        if(loadingContainer)
            return
        
        if(direction === "forward" && LastEvaluatedKey){
            dispatch( GetUsersFormThunk({ page: page + 1, limit, LastEvaluatedKey, bringTotal: true }) )
        }
        else if(direction === "backward" && page !== 1){
            if(page === 2){
                dispatch(   GetUsersFormThunk({ page: page - 1, limit, LastEvaluatedKey: "", bringTotal: true }) )
            }else{
                const lastKeyId = usersIds[((page - 2) * limit) - 1]
                dispatch( GetUsersFormThunk({ page: page - 1, limit, LastEvaluatedKey: lastKeyId, bringTotal: true }) )
            }
        }
        dispatch( checkAllCheckboxesManageUsersForm( [] ) )
    }

    const changeNumberOfPages = (newNumberOfPages: number) => {

        const totalOffersUntilNow = (page - 1) * limit + usersToShow.length 
        if(newNumberOfPages > totalOffersUntilNow){
            // bring page 1 with same type
            dispatch( GetUsersFormThunk({ page: 1, limit: newNumberOfPages, bringTotal: true}) )
        }else{
            dispatch( GetUsersFormThunk({ page: 1, limit: newNumberOfPages, bringTotal: true}) )
            /*
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            */
        }
    }



    return (
        <div className="con-row-pagination flex flex-jc-end flex-ai-center mr-20 ff-roboto">
         
            <div className="flex">
                {
                    numberUsersFormByPage.map((numberPages: number, index: number) => {
                        return (
                            <button 
                                key={index}
                                disabled={numberPages === limit}
                                className={`fs-12 fw-500 mr-12 ${numberPages === limit ? "c-black" : "disable-color pointer"}`}
                                onClick={() => { 
                                        changeNumberOfPages(numberPages)
                                }}
                            >
                                {numberPages === -1 ? "All" : numberPages}
                            </button>
                        )
                    })
                }
                <p className="fw-500 fs-12 ml-10">
                    {`${getPaginatedData(page, limit, total)} ${t("General:label_267")} ${total}`}
                </p>
            </div>

            <div className="flex flex-ai-center ml-18">
                <button
                    onClick={() => paginate("backward")} className={`fs-18 mr-12 ${page === 1 ? "disable-color" : "c-black pointer"}`}
                     disabled={page === 1 ? true : false} 
                >
                    <FontAwesomeIcon icon={solid("angle-left")}  />
                </button>
                <p className="fs-13 fw-700">{page}</p>
                <button 
                    onClick={() => paginate("forward")}  className={`fs-18 ml-12 ${LastEvaluatedKey ?  "c-black pointer" : "disable-color"}`} 
                    disabled={!LastEvaluatedKey ? true : false}
                >
                    <FontAwesomeIcon icon={solid("angle-right")}  />
                </button>
            </div>

        </div>
    )
}

export default React.memo( PaginationUsersFormTable )