import "./ConfirmButton.css"

type ConfirmButtonProps = {
    text: string,
    width?: string
    disabled?: boolean
    onPress?: (e: any) => void
}

const ConfirmButton = ({ text, width, disabled, onPress }:ConfirmButtonProps) => {
  return (
    <button 
        type="submit" 
        onClick={onPress}
        className={`${disabled ? "confirm-btn-modal-disabled" : "confirm-btn-modal pointer"} fs-15-vh`} style={{ "width": width ? width : "14rem", opacity: disabled ? 0.7 : 1 }}
    >
        { text }
    </button>
  )
}

export default ConfirmButton