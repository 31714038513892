import HeaderManageReportsScreen from '../../../../components/ManageReportsScreen/HeaderManageReportsScreen/HeaderManageReportsScreen'
import HeaderTableManageReports from '../../../../components/ManageReportsScreen/HeaderTableManageReports/HeaderTableManageReports'
import ReportRows from '../../../../components/ManageReportsScreen/ReportRows/ReportRows'
import PaginationReportTableRow from '../../../../components/ManageReportsScreen/PaginationReportTableRow/PaginationReportTableRow'
import ButtonsUserReports from '../../../../components/ManageReportsScreen/ButtonsUserReports/ButtonsUserReports'
import FiltersManageReports from '../../../../components/ManageReportsScreen/FiltersManageReports/FiltersManageReports'
import TabBarUserReports from '../../../../components/ManageReportsScreen/TabBarUserReports/TabBarUserReports'


const ManageReportsScreen = () => {

    return (
        <div className="flex flex-d-column height-100 p-relative">
            <HeaderManageReportsScreen />
            <div className="flex flex-jc-sb mt-28">
                <ButtonsUserReports />
                <FiltersManageReports />
            </div>
            <TabBarUserReports />
            <HeaderTableManageReports />
            <ReportRows />
            <PaginationReportTableRow />
        </div>
    )
}

export default ManageReportsScreen