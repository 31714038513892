import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type CronometerProps = {
    onFinishCronometer: () => void
}

const Cronometer = ({ onFinishCronometer }: CronometerProps) => {

    const { t } = useTranslation()

    const [seconds, setSeconds] = useState(30)

    useEffect(() => {
      
        if(seconds > 0){
            setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000);
        }else{
            onFinishCronometer()
        }

      
    }, [seconds])
    

    return (
        <div>
            <p className="fs-16-vh text-color">0:{seconds < 10 ? `0${seconds}` : seconds} {` ${t("SignUp:confirmEmailScreen:label_07")}`}</p>
        </div>
    )
}

export default React.memo( Cronometer )