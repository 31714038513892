

export enum INE {
    C = "c",
    D = "d",
    E = "e"
}

type INE_C = {
    claveElector: string
    numEmision: string
    ocr: string
}

type INE_D = {
    cic: string
    ocr: string
}

type INE_E = {
    cic: string
    idCiudadano: string
}

export type BodyINE_C = { modelo: INE.C } & INE_C
export type BodyINE_D = { modelo: INE.D } & INE_D
export type BodyINE_E = { modelo: INE.E } & INE_E

export type Body_INE = BodyINE_C | BodyINE_D | BodyINE_E

export type FormINEProps = {
    onSubmit: (body: Body_INE) => void
}