import "./FieldJustRead.css"

interface IFieldJustRead {
    field: string,
    value: string
}


const FieldJustRead = ({field, value}:IFieldJustRead) => {

  return (
    <div className="mt-24" >
        <p className="fs-14 fw-600">{field}</p>
        <p className="fs-14 mt-4">{value}</p>
    </div>
  )
}

export default FieldJustRead