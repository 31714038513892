import React, { useState } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store/store';
import CancelButton from '../../../UIcomponents/Modals/CancelButton/CancelButton';
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import "./ActivationJobModal.css"
import { activateJob } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions';
import { activateJobReducer } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import LoadingSpinner from '../../../LoadingSpinner';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { useTranslation } from 'react-i18next';



const ActivationJobModal = () => {

  const { t } = useTranslation();
  const dispatch = useAppDispatch()
  const { newActivationState, categoryId, categoryName, jobId, jobName } = useSelector((state: RootState) => state.managerJobModals.ActivateJobModal);
  const [askInput, setAskInput] = useState<boolean>(false)
  const [loading, setLoading] = useState(false);

  const handleAskMeAgain = () => {
      setAskInput(!askInput)
  }

  const handleSubmitActivation = (e: any) => {
    e.preventDefault();
    setLoading(true)
    activateJob(categoryId, jobId, {newActivationState})
    .then((res) => {
      if(askInput)
          localStorage.setItem("dontAskAgainActivation", JSON.stringify(askInput))
      dispatch(activateJobReducer({
        categoryId, jobId, newActivationState
      }))
      dispatch(resetValueFromState())
    })
    .catch((error) => {console.log("error", error)})
    .finally(() => { setLoading(false)})
  }

  const closeModal = () => {
      dispatch(resetValueFromState())
  }

  return (
    <div className="blank-wrapper-modals">
        <TitleModal title={t("General:label_010")} />
        <DescriptionModal description={`
          ${t("General:label_086")} ${newActivationState ? t("General:label_089") : t("General:label_090")}
           ${jobName} ${t("General:label_087")} ${categoryName} ${t("General:label_088")} ?
        `} />
        <form onSubmit={handleSubmitActivation} className="con-ask-check-btns flex flex-jc-sb flex-ai-center">
          <div className="flex flex-ai-center">
            <input  
                id="ask" 
                name="ask" 
                type="checkbox" 
                onChange={handleAskMeAgain}
                checked={askInput}
            />
            <label className="fw-500 label-ask" htmlFor="ask" >{t("General:label_091")}</label>
          </div>
          <div className="align-btns-right">
              <CancelButton onPress={closeModal} />
              <ConfirmButton text={t("General:label_016")} />
          </div>
        </form>
        { loading && <LoadingSpinner fullPage={true} /> }
    </div>
  )
}

export default ActivationJobModal