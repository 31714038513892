import  React, { useState } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import FormRequestProductDBModal from './FormRequestProductDBModal/FormRequestProductDBModal';

const RequestProductDBModal = () => {

    return (
        <div className="blank-wrapper-modals flex flex-d-column p-relative" style={{ width: "41.2rem", minHeight: "44rem" }}>

            <FormRequestProductDBModal />
  
        </div>
    )
}

export default React.memo( RequestProductDBModal )