import React, { useEffect, useState } from 'react'
import { RootState, useAppDispatch } from '../../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import "./ChatTesterDeveloper.css"
import { addMessageBugReportReducer } from '../../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import { MessageBugReport } from '../../../../../../utils/dbModels'
import { v4 as uuidv4 } from 'uuid';
import UserImage from '../../../../../UIcomponents/UserImage/UserImage'
import moment from 'moment'
import { sendMessageBugReport } from '../../../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'


const ChatTesterDeveloper = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [messageText, setMessageText] = useState("")

    const messages = useSelector((state: RootState) => state.manageBugReportsData.bugReport.messages) 
    const reportId = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)
    const testerAssigned = useSelector((state: RootState) => state.manageBugReportsData.bugReport.testerAssigned)
    const userAssigned = useSelector((state: RootState) => state.manageBugReportsData.bugReport.userAssigned)
    const status = useSelector((state: RootState) => state.manageBugReportsData.bugReport.status)
    const myUserId = useSelector((state: RootState) => state.auth.user.id)

    const myUserIsTester = myUserId === testerAssigned
    const myUserIsDeveloper = myUserId === userAssigned
    const userBelongsChat = (myUserIsTester || myUserIsDeveloper)

    useEffect(() => {
      
    
        return () => {
        
        }
    }, [messages?.length])
    

    const onSendMessage = () => {

        const newMessage: MessageBugReport = {
            id: uuidv4(),
            messageText,
            createdAt: new Date().toISOString(),
            viewed: false,
            userId: myUserId
        }
        dispatch( addMessageBugReportReducer(newMessage) )
        setMessageText("")
        sendMessageBugReport(reportId, messageText)
        .then(() => {

        }).catch(() => {

        })

    }

    if(!testerAssigned || !userAssigned){
        return (<></>)
    }

    return (
        <div className="flex flex-d-column height-100">

            <div className="flex-1">
            {
                messages?.map((message) => {
                    const messageAtLeft = userBelongsChat
                                            ? message.userId !== myUserId
                                            : message.userId === testerAssigned 

                    return (    
                        <div key={message.id} className={`aux-con-message-report mt-10 flex ${(messageAtLeft) ? "message-left-bug" : "message-right-bug"}`}>
                                
                            <div className="con-message-img">
                                {
                                    messageAtLeft && message.isFirstGroupMessage &&
                                    <UserImage userId={messageAtLeft ? testerAssigned : userAssigned} sizeImg={4} unit={"vh"} />
                                }
                            </div>
                            
                            <div className="con-message-date-bug">
                                <div className={`message-user-report-bug border-radius-6 pr-20 pl-20 center color-text ${messageAtLeft ? "gimmer-bg" : "white-bg"}`} >
                                    <p className={`fs-13 fw-500 ${messageAtLeft ? "c-white" : "color-text" }`} >{message.messageText}</p>
                                </div>
                                <div className="flex flex-ai-center mt-2">
                                    <p className={`flex-1 fs-11 color-text ${messageAtLeft ? "text-align-left" : "text-align-right" }` }>
                                        {moment(message.createdAt).fromNow()}
                                    </p>
                                    {
                                        !messageAtLeft && message.viewed &&
                                        <UserImage userId={messageAtLeft ? testerAssigned : userAssigned} sizeImg={4.8} unit={"vh"} />
                                    }
                                </div>
                            </div>
                
                        
                            
                            <div className="con-message-img-bug flex flex-jc-end">
                                {
                                    !messageAtLeft && message.isFirstGroupMessage &&
                                    <UserImage userId={messageAtLeft ? testerAssigned : userAssigned} sizeImg={4} unit={"vh"} />
                                }
                
                            </div>
                        
                        </div>
                    )
                })
            }
            </div>

            {
                (myUserId === testerAssigned || myUserId === userAssigned) && (status === "PENDING" || status === "TESTING") &&
                <div className="pl-20 pr-20 ">
                    <div className="write-message-bug border-radius-6 white-bg mb-8 mt-8 flex flex-ai-center">

                        <input 
                            className="fs-14 input-write-message-bug flex-1"
                            placeholder={t("Reports:user:label_15") ?? ""}
                            value={messageText}
                            onChange={(e) => {
                                setMessageText(e.target.value)
                            }}
                        />

                        <button 
                            className="pointer"
                            onClick={onSendMessage}
                            style={{ opacity: messageText ? 1 : 0.5 }}
                            disabled={ messageText ? false : true }
                        >
                            <FontAwesomeIcon icon={solid("paper-plane-top")} className={`fs-20 color-gimmer`} />
                        </button>

                    </div>
                </div>
            }

        </div>
    )
}

export default React.memo( ChatTesterDeveloper )