import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { JobCategoryJob } from "../../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice";
import { checkAllCheckboxesJobs, removeAllCheckboxesJobs } from "../../../../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice";
import { useAppDispatch } from "../../../../../../../redux/store/store";
import "./HeaderTableJobs.css"
import React from "react";
import useCheckAccess from "../../../../../../hooks/useCheckAccess/useCheckAccess";

interface HeaderTableJobsProps {
  jobs: JobCategoryJob[],
  categoryId: string,
  categoryName: string
}

const HeaderTableJobs = React.memo(({ jobs, categoryId, categoryName }: HeaderTableJobsProps) => {
  
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const accessDisplayActivateJob = useCheckAccess({ id_Category: "Manage_Jobs", id_Policy: "Activate_Deactivate", id_Action: "Display" })

  const [selectAll, setSelectAll] = useState<boolean>(false);

  useEffect(() => {

    if(selectAll){
        const jobsGrouped = jobs.map((job: JobCategoryJob) => {
            return  {
              ...job,
              categoryId,
              categoryName
            }
        })
      dispatch(checkAllCheckboxesJobs({ jobs: jobsGrouped }))
    }
    else
      dispatch(removeAllCheckboxesJobs({ categoryId }))
    
  }, [selectAll])
  

  return (
    <div className="con-header-table-jobs flex fs-12 fw-500 width-100">
        <div className="item1 center text-align-center">
            <input 
                className="pointer"
                type="checkbox" 
                onChange={() => {
                  setSelectAll(!selectAll)
                }}
                checked={selectAll} 
            />
        </div>
        { accessDisplayActivateJob && <div className="item2 center text-align-center">{t("General:label_070")}</div> }
        <div className="item3 center text-align-center">{t("General:label_071")}</div>
        <div className="item4 flex flex-ai-center text-align-center">{t("General:label_072")}</div>
        <div className="item5 center text-align-center">{t("General:label_073")}</div>
        <div className="item6 center text-align-center">{t("General:label_074")}</div>
        <div className="item7 center text-align-center">{t("General:label_075")}</div>
        <div className="item8 center text-align-center">{t("ManageJobs:label_02")}</div>
        <div className="item9 center text-align-center">{t("ManageJobs:label_03")}</div>
        <div className="item10 center text-align-center">{t("ManageJobs:label_04")}</div>
        <div className="item11 center text-align-center">{t("ManageJobs:label_05")}</div>
        <div className="item12 center text-align-center">{t("ManageJobs:label_12")}</div>
        <div className="item13 center text-align-center">{t("ManageJobs:label_11")}</div>
        <div className="item14 center text-align-center">{t("General:label_080")}</div>
        <div className="item15"></div>
    </div>
  )
})

export default HeaderTableJobs