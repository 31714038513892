import React from 'react';
import "./PreviewImage.css"

interface urlPreviewImageProps {
    imgLink: string,
    width?: string,
    height?: string
}

const PreviewImage = ({ imgLink, width = "100%", height = "100%" }: urlPreviewImageProps) => {

    return (
    <>
        {
            imgLink ?
                <div 
                    style={{ 
                        "backgroundImage":  `url("${imgLink}")`,
                        width,
                        height 
                    }}  
                    className="preview-img-uploaded img-preview">    
                </div>
            :
                <div 
                    style={{
                        width,
                        height 
                    }}
                    className="preview-img-uploaded no-img-preview">
                </div>
        }
    </>
  )
}

export default PreviewImage