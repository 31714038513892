import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type ArrowGoBackButtonProps = {
    goBack: () => void
} 

const ArrowGoBackButton = ({ goBack }: ArrowGoBackButtonProps) => {
    return (
        <button type="button" className="pointer" onClick={goBack}>
            <FontAwesomeIcon icon={solid("arrow-left")} className="color-text fs-22" />
        </button>
    )
}

export default React.memo( ArrowGoBackButton )