import React from 'react'
import RowCategoryRole from './RowCategoryRole/RowCategoryRole'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import { Data_Rol_Table } from '../../../../redux/slices/components/ManageRoles/manageRolesSlice'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'

const ContentTableRoles =() => {

    const data = useSelector((state: RootState) => state.manageRoles.data)
    const accessCreateRole = useCheckAccess({ id_Category: "Manage_Roles", id_Policy: "Roles", id_Action: "Edit" })

    return (
        <div className="p-relative flex-1" style={{ height: "70vh"}}>
            <div className="ff-roboto aux-scroll" >
                {
                    data.map((category: Data_Rol_Table, index: number) => {
                        return (
                            <RowCategoryRole 
                                key={category.id_Category}
                                category={category}
                                enableEdit={accessCreateRole}
                            />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( ContentTableRoles )