import React from 'react'
import { RootState } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { PolicyIds } from '../../../utils/roles'

const useCheckAccess = ({ id_Category, id_Policy, id_Action }: PolicyIds) => {

    const policies = useSelector((state: RootState) => state.auth.user.roleData.policies)

    const policy = policies.find(p => 
        p.id_Category === id_Category &&
        p.id_Policy === id_Policy &&
        p.id_Action === id_Action
    )

    if(policy)
        return true

    return false
}

export default useCheckAccess