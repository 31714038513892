import React, { useState } from 'react'
import "./SettingsList.css"
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import "./SettingsList.css"
import { signOut } from 'aws-amplify/auth'
import LoadingSpinner from '../../LoadingSpinner'

type Option = {
    text: string
    link: string
}

type SettingsListProps = {
    isPublic: boolean
}

const SettingsList = ({ isPublic }:SettingsListProps) => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [options] = useState<Option[]>(
        isPublic ?
        [
            { text: t("Profile:settings:label_08"), link: "login" },
            { text: t("Profile:settings:label_09"), link: "login" },
        ]
        :
        [
        { text: t("Profile:settings:label_01"), link: "my-profile" },
        //{ text: t("Profile:settings:label_02"), link: "messages" },
        { text: t("Profile:settings:label_03"), link: "notifications" },
        { text: t("Profile:settings:label_04"), link: "my-history" },
        { text: t("Profile:settings:label_05"), link: "my-shop" },
        { text: t("Profile:settings:label_06"), link: "help" },
        { text: t("Profile:settings:label_07"), link: "logout" }
    ])

    const onPress = (link: string) => {
        if(link === "logout")
            closeSession()
        else{
            navigate(`/${link}`)
        }
    }

    const closeSession = () => {

        setLoading(true)
        signOut()
        .then(() => {
            window.location.href = process.env.REACT_APP_DOMAIN_URL ?? ""
        }).catch(() => {
            //alert("General:Error logout")
        }).finally(() => {
            setTimeout(() => {
                setLoading(false)  
            }, 1500); 
        })
      }

    return (
        <div className="con-list-settings p-absolute white-bg border-radius- pt-3 pb-2 border-radius-1">
            {
                options.map((option) => {
                    return(
                        <button
                            key={option.text}
                            onClick={() => onPress(option.link)}
                            className="pt-8 pl-10 pointer flex width-100 setting-btn white-bg"
                        >
                            <p className="fs-11 color-text text-align-left width-100 fs-16-vh">{option.text}</p>
                        </button>
                    )
                })
            }

            {
                loading && <LoadingSpinner fullPage={true} />
            }
        </div>
    )
}

export default React.memo( SettingsList )