import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserReportTableItem } from './manageReportsDataSlice';



const initialState = {
  userReport: {} as UserReportTableItem,
  show: false
}

export const manageDetailedUserReportSlice = createSlice({
  name: 'manageDetailedUserReport',
  initialState,
  reducers: {
    setDetailedUserReportReducer: (state, action: PayloadAction<{ userReport: UserReportTableItem }>) => {
        state.userReport = action.payload.userReport
        state.show = true    
        return state
    },
    resetDetailedUserReportReducer: (state) => {
        return initialState
    }
  },
});

// Action creators are generated for each case reducer function
export const {  
    setDetailedUserReportReducer, 
    resetDetailedUserReportReducer
} = manageDetailedUserReportSlice.actions

export default manageDetailedUserReportSlice.reducer