import React from 'react'

const Error404 = () => {
  return (
    <div className="container-full-white">
        <h1>Page not found</h1>
    </div>
  )
}

export default Error404