import React from 'react'
import { useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import { resetMarketplaceModalsReducer } from '../../../../redux/slices/components/Marketplace/marketplaceDataSlice';
import FormModalAddProduct from './FormModalAddProduct/FormModalAddProduct';

const ModalAddProduct = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();
  
    const closeModal = () => {
      dispatch(resetMarketplaceModalsReducer())
    }
  
    return (
      <div className="blank-wrapper-modals flex flex-d-column p-relative">
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <TitleModal title={t("Marketplace:label_02")} />
          <FormModalAddProduct /> 
      </div>
    )
}

export default ModalAddProduct