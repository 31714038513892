import React, { useEffect, useState } from 'react'
import { getCostPriceForTransaction, getCostPriceForTransactionPublic } from '../../../utils/requestsUtils/functionsRequests/transactions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'

type PriceProps = {
    otherUserID: string,
    type: 'gim' | 'gimmer'
    jobId: string
    categoryName: string
    estimatedTime?: string
    cssClasses: string
    initText?: string
    finalText?: string
    getPrice?: (price: number) => void 
}

const Price = ({
    otherUserID,
    type,
    jobId,
    categoryName,
    estimatedTime, 
    cssClasses,
    initText,
    finalText, 
    getPrice
}: PriceProps) => {

    const [price, setPrice] = useState<number>()

    const isLogged = useSelector((state: RootState) => state.auth.isLogged)

    const duration = !estimatedTime
    ? 'PT1H'
    : estimatedTime[estimatedTime.length - 1] === 'h'
        ?
        'PT' + estimatedTime.toLocaleUpperCase()
        :
        'PT' + estimatedTime.toLocaleUpperCase() + 'M' 

    useEffect(() => {

        const getData = async() => {

            try {
                const getPriceFunction = isLogged ? getCostPriceForTransaction : getCostPriceForTransactionPublic 
                const price = await getPriceFunction(
                    otherUserID,
                    type,
                    jobId,
                    categoryName,
                    duration
                )
                setPrice(price)
                if(getPrice)
                    getPrice(price)
            } catch (error) {
                
            }
        }

        getData()

    }, [])

    
    

    return (
        <p className={cssClasses}>{`${initText ?? ""}${price ?? ""}${finalText ?? ""}`}</p>
    )
}

export default React.memo( Price )