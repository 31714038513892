import React from 'react'
import "../buttonsLogin.css"
import { useTranslation } from 'react-i18next'

type ButtonSignUpProps = {
    onPress: () => void
}

const ButtonSignUp = ({ onPress }: ButtonSignUpProps) => {

    const { t } = useTranslation()

    return (
        <button type="button" onClick={onPress} className="login-button flex flex-ai-center flex-jc-c signup-button">
            <p className="fs-18 c-white">
                {t("SignIn:label_06")}
            </p>
        </button>
    )
}

export default React.memo( ButtonSignUp )