import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import "./WhereButtonsBar.css"
import { GimGimmer } from '../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { TypeOffers } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { StageProcess } from '../../../redux/slices/components/HomeOffers/homeOffersSlice'
import { setStageProcessRequestReducer, TabNavigatorStage } from '../../../redux/slices/components/RequestProcess/requestProcessSlice'

const widthOption = [10.8, 41.4, 70.3]

type WhereButtonsBarProps = {
    type: TypeOffers
}

const WhereButtonsBar = ({ type }: WhereButtonsBarProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [leftLine, setLeftLine] = useState<number>(widthOption[0])
    const [indexTab, setIndexTab] = useState(0)

    const tabNavigatorStage = useSelector((state: RootState) => state.requestProcess.tabNavigatorStage)
    const slotToRequest = useSelector((state: RootState) => state.requestProcess.slotToRequest)
    const placeSelected = useSelector((state: RootState) => state.requestProcess.placeSelected)
    // case without animation
    const staticIndex = tabNavigatorStage === TabNavigatorStage.WHERE 
                            ? 0
                            : tabNavigatorStage === TabNavigatorStage.WHEN
                                ? 1
                                : 2

    const setTab = (index: number) => {

        if(index === indexTab)
            return

        const smallLine = document.getElementById("small-line-where") 

        if(smallLine){

            const newLeft = widthOption[index]

            smallLine.animate([
                { left: `${newLeft}%` },
                //{ left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            if(index === 0)
                dispatch( setStageProcessRequestReducer(TabNavigatorStage.WHERE) )
            else if(index === 1)
                dispatch( setStageProcessRequestReducer(TabNavigatorStage.WHEN) )
            else
                dispatch( setStageProcessRequestReducer(TabNavigatorStage.RESUME) )
            setLeftLine(newLeft)
            setIndexTab(index)
        }
    }

    useEffect(() => {
        
        setTab(staticIndex)
        
    
    }, [staticIndex])
    

    return (
        <div className="p-relative flex flex-jc-sb con-where-btns-bar">

            <div className="flex con-where-btn-bar flex-jc-sb flex-ai-center">
                <div 
                    className={`option-tab-where ${indexTab === 0 ? "" : "pointer"}`}  
                    onClick={() => setTab(0)}
                >
                    <p className={`fs-20-vh text-align-center ${indexTab === 0  ? "color-text" : "color-manatee"}`}>{"Where"}</p>
                    <div id="0-option" className="option-line-where"></div>
                </div>
                
                <div 
                    className={`option-tab-where ${(indexTab === 1 || !placeSelected) ? "" : "pointer"}`}  
                    onClick={() => setTab(1)}
                >
                    <p className={`fs-20-vh text-align-center ${indexTab === 1  ? "color-text" : "color-manatee"}`}>{"When"}</p>
                    <div id="1-option" className="option-line-where"></div>
                </div>

                <div 
                    className={`option-tab-where ${(indexTab === 2 || !slotToRequest) ? "" : "pointer"}`}  
                    onClick={() => setTab(2)}
                >
                    <p className={`fs-20-vh text-align-center ${indexTab === 2  ? "color-text" : "color-manatee"}`}>{"Resume"}</p>
                    <div id="2-option" className="option-line-where"></div>
                </div>
            </div>

            <div 
                id="small-line-where" 
                style={{ left: `${leftLine.toString()}%` }} 
                //style={{ left: `${widthOption[staticIndex]}%` }}
                className={`small-line-where ${type === "Task" ? "gim-bg" : "gimmer-bg"} ${indexTab < 0 ? "visibility-hidden" : ""}`}
            ></div>

        </div>
    )
}

export default React.memo( WhereButtonsBar )