import React, { useEffect, useRef, useState } from 'react'
import { getUserCredentials } from '../../utils/requestsUtils/axiosUtils'
import { useSelector } from 'react-redux'
import { RootState } from '../../redux/store/store'
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { ActionBugReport } from '../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice';
import { ValidationValueBugReport } from '../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions';
import { useNavigate } from 'react-router-dom';
import "./MainSocket.css"

type MessageSocket = {
    reportId: string
    action: ActionBugReport
    value?: ValidationValueBugReport 
}

type NotificationSocket = {
    title: string
    body: string
    reportId: string
}

const MainSocket = () => {

    const navigate = useNavigate()

    const socket = useRef<WebSocket>()
    const userId = useSelector((state: RootState) => state.auth.user.id)
    const authorizationToken = useSelector((state: RootState) => state.auth.user.authorizationToken)

    const [messages, setMessages] = useState<NotificationSocket[]>([])

    useEffect(() => {
      
        const connectSocket = async() => {

            let token = ""
            try {
                const session = await getUserCredentials()
                token = session.idToken;
            } catch (error) {
                return
            }

            let connectionId = ""
            //const socket = new WebSocket("wss://mnauksfzm3.execute-api.eu-west-3.amazonaws.com/production?authorizationKey=6rPo2M7431xC")

            

            socket.current = new WebSocket(`wss://a55qvn5117.execute-api.us-west-1.amazonaws.com/dev?authorizationToken=${authorizationToken}`)

            //console.log("connected")
            socket.current.onopen = function(event: Event) {
                //alert("[open] Connection established");
               
                socket.current?.send(JSON.stringify({"action": "registerGroupUserBug", userId}));
                //alert(`Message sent ${device_id}`)
                //socket.current.send({"body":{"action": "onMessage", "data": "hi"} });
            };
    



            socket.current.onclose = function(event: Event) {
                //alert("[open] Connection established");
                //console.log("CLOSE", new Date().toISOString(), userId)
                connectSocket()
                //socket.current.send(JSON.stringify({"action": "returnConnectionId"}));
                //alert(`Message sent ${device_id}`)
                //socket.current.send({"body":{"action": "onMessage", "data": "hi"} });
            };
            



            /*
            socket.current.addEventListener('message', (event) => {
                alert(`[message 1] Data received from server: ${event.data} id: ${event.lastEventId}`);
                console.log('Message from server ', event.data);
                const modal = document.getElementById('test');
                modal.innerHTML = "Message Received 1";
            });
            */
            

            
            socket.current.onmessage = function(event: MessageEvent) {
                const addNotif = (notification: NotificationSocket) => {
                    setMessages(m => [...m, notification])
                }
                try {
                    const message = JSON.parse(event.data);
                    //console.log(" ")
                    //console.log("message", message)
                    if(message?.type)
                    {
                        const type = message.type;

                        if(message.type === "sendMessageToGroupUsersBugReport"){
                            const { action, reportId, value }: MessageSocket = message.data

                            if(action === "TAKE"){
                                addNotif({ title: "A developer took a bug", body: "", reportId })
                            }else if(action === "SEND_VERIFY"){
                                addNotif({ title: "A developer send to verify a bug", body: "", reportId })  
                            }else if(action === "VALIDATION"){
                                addNotif({ title: "A Tester opened a bug", body: "", reportId })  
                            }else if(action === "VERIFY"){
                                if(value === "ACCEPTED"){
                                    addNotif({ title: "A Tester closes a bug", body: "", reportId })  
                                }else if(value === "REJECTED"){
                                    addNotif({ title: "A Tester rejected a bug", body: "", reportId })  
                                }
                            }
                        }

                    }
            
            
                } catch (error) {
                    console.log("error", error);
                    alert("Error: try again")
                }
            
            };
            
        
            socket.current.onerror = function(error) {
                //alert(`[error]`);
            };
        }

        connectSocket()


    }, [])



    const onDeleteNotif = (index: number) => {
        const newMessages = messages.filter((m, indexM) => indexM !== index)
        setMessages( newMessages )
    }

    const onGoReport = (idReport: string, index: number) => {
        const newMessages = messages.filter((m, indexM) => indexM !== index)
        setTimeout(() => {
            setMessages( newMessages )
        }, 100);
        navigate(`/manage-bug-reports/${idReport}`)
    }
    
    return (
        <>
            {
                messages.map((message, index) => {
                    return (
                        <div 
                            key={index}
                            className="border-radius-3 flex notif-socket"
                            style={{
                                position: "fixed",

                                right: "5rem",
                                top: `${8 +(12 * index)}rem`, // 

                            }}
                        >
                            <div
                                onClick={() => onGoReport(message.reportId, index)}
                                className="flex-1 height-100 pointer p-12"
                            >
                                <p className="fs-14">{message.title}</p>
                            </div>

                            <div className="flex flex-d-column">
                                <button className="pointer mt-12 mr-10" onClick={() => onDeleteNotif(index)}>
                                    <FontAwesomeIcon className="color-text fs-20" icon={faTimes} />
                                </button>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default React.memo( MainSocket )