import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import LoadingSpinner from '../../../LoadingSpinner'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import { deleteUserFormReducer, resetManageUsersFormModalReducer } from '../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice'
import { deleteUserForm } from '../../../../utils/requestsUtils/functionsRequests/usersForms/usersFormsFunctions'

const DeleteUserFormModal = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const user = useSelector((state: RootState) => state.manageUsersForm.userDelete)
    const [loading, setLoading] = useState(false);


    const handleSubmit = (e: any) => {
        e.preventDefault();

        if(!user)
            return

        setLoading(true)

        deleteUserForm(user.id).then((res) => {
            dispatch( deleteUserFormReducer(user.id) )
            dispatch(resetManageUsersFormModalReducer())
        }).
        catch(() => {

        })
        .finally(() => { setLoading(false)})
    }

    const handleClose = () => {
        dispatch(resetManageUsersFormModalReducer())
    }

    if(!user){
        return (
            <></>
        )
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>
            <TitleModal title={t("General:label_010")} />
            <DescriptionModal description={`${t("General:label_140")} ${user.firstName} ${user.lastName} ${t("General:label_141")}`} />
            
            <div className="flex flex-jc-end mt-40">
                <button onClick={handleClose} type="button" className="cancel-btn-modal pointer">
                    {t("General:label_015")}
                </button>
                <ConfirmButton text={t("General:label_016")} />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default React.memo( DeleteUserFormModal )