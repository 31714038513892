import React, { useState } from 'react'
import { StatusReportBug } from '../../../../utils/dbModels'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { acceptRejectReportBug, sendToVerifyReportBug, takeReportBug, updateStatusAppBugWeb, ValidationValueBugReport, verifyReportBug } from '../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'
import { addHistoryItemBugReport, setEndDateIncidentBugReportReducer, setNewStatusBugReportReducer, updateStatusBugReportReducer, updateTesterAssignedBugReportReducer, updateUserAssignedBugReportReducer } from '../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import LoadingSpinner from '../../../LoadingSpinner'
import "./ButtonsBugReport.css"
import { useTranslation } from 'react-i18next'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'
import { useNavigate } from 'react-router-dom'

const ButtonsBugReport = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)

    const myUserId = useSelector((state: RootState) => state.auth.user.id)

    const idBugReport = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)
    const priority = useSelector((state: RootState) => state.manageBugReportsData.bugReport.priority)
    const status = useSelector((state: RootState) => state.manageBugReportsData.bugReport.status)

    const testerAssigned = useSelector((state: RootState) => state.manageBugReportsData.bugReport.testerAssigned)
    const userAssigned = useSelector((state: RootState) => state.manageBugReportsData.bugReport.userAssigned)

    const accessEditTester = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Edit" })
    const accessEditDeveloper = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Edit" })

    const handleStatus = (value: ValidationValueBugReport) => {
        setLoading(true)
        acceptRejectReportBug(idBugReport, priority, value)
        .then(() => {
            const newStatus = value === "ACCEPTED" ? "OPENED" : "CLOSED"
            dispatch( setNewStatusBugReportReducer({ newStatus }) )
            dispatch( updateStatusBugReportReducer({ id: idBugReport, status: newStatus }) )
            dispatch( updateTesterAssignedBugReportReducer( myUserId ) )
            if(value === "ACCEPTED")
                dispatch( addHistoryItemBugReport({ userId: myUserId, date: new Date().toISOString(), event: "ACCEPT" }) )
            else
                navigate(-1)
            //dispatch( setEndDateIncidentBugReportReducer() )
        })
        .catch(() => {
        }).finally(() => { setLoading(false)})
    }

    const handleTakeBug = () => {
        setLoading(true)
        takeReportBug(idBugReport)
        .then(() => {
            const newStatus = "PENDING"
            dispatch( setNewStatusBugReportReducer({ newStatus }) )
            dispatch( updateStatusBugReportReducer({ id: idBugReport, status: newStatus }) )
            dispatch( updateUserAssignedBugReportReducer(myUserId) )
            dispatch( addHistoryItemBugReport({ userId: myUserId, date: new Date().toISOString(), event: "ASSIGNED" }) )
        })
        .catch(() => {
        }).finally(() => { setLoading(false)})
    }


    const sendToVerify = () => {
        setLoading(true)
        sendToVerifyReportBug(idBugReport)
        .then(() => {
            const newStatus = "TESTING"
            dispatch( setNewStatusBugReportReducer({ newStatus }) )
            dispatch( updateStatusBugReportReducer({ id: idBugReport, status: newStatus }) )
            dispatch( addHistoryItemBugReport({ userId: myUserId, date: new Date().toISOString(), event: "READY_FOR_TESTING" }) )
        })
        .catch(() => {
        }).finally(() => { setLoading(false)})
    }

    const sendFinalVerification = (value: ValidationValueBugReport) => {
        setLoading(true)
        verifyReportBug(idBugReport, value)
        .then(() => {
            const newStatus = value === "ACCEPTED" ? "CLOSED" : "PENDING"
            dispatch( setNewStatusBugReportReducer({ newStatus }) )
            dispatch( updateStatusBugReportReducer({ id: idBugReport, status: newStatus }) )
            dispatch( addHistoryItemBugReport({ userId: myUserId, date: new Date().toISOString(), event: value === "ACCEPTED" ? "CLOSED" : "FIX_REJECTED" }) )
            //dispatch( setEndDateIncidentBugReportReducer() )
        })
        .catch(() => {
        }).finally(() => { setLoading(false)})
    }

    return (
        <div className="flex con-buttons-bug-reports p-relative width-100">

            {
                accessEditTester && status === "UPLOADED" &&
                <button 
                    onClick={() => handleStatus("REJECTED")} 
                    className="btn-move-bug-report ban-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_06")}
                </button>
            }

            {
                accessEditTester && status === "UPLOADED" &&
                <button 
                    onClick={() => handleStatus("ACCEPTED")} 
                    className="btn-move-bug-report accept-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_04")}
                </button>
            }

            {
                accessEditDeveloper && status === "OPENED" &&
                <button 
                    onClick={handleTakeBug} 
                    className="btn-move-bug-report accept-btn fw-600 fs-13"
                >
                    {"Take the bug"}
                </button>
            }

            {
                accessEditDeveloper && status === "PENDING" && (userAssigned === myUserId) &&
                <button 
                    onClick={sendToVerify} 
                    className="btn-move-bug-report accept-btn fw-600 fs-13"
                >
                    {"Closed"}
                </button>
            }

            {
                accessEditTester && status === "TESTING" && (testerAssigned === myUserId) &&
                <button 
                    onClick={() => sendFinalVerification("REJECTED")} 
                    className="btn-move-bug-report ban-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_06")}
                </button>
            }

            {
                accessEditTester && status === "TESTING" && (testerAssigned === myUserId) &&
                <button 
                    onClick={() => sendFinalVerification("ACCEPTED")} 
                    className="btn-move-bug-report accept-btn fw-600 fs-13"
                >
                    {t("Reports:bug:label_04")}
                </button>
            }

            {
                status === "CLOSED" &&
                <div className="center width-100">
                    <p className="fw-700 fs-18 color-error">{"BUG CLOSED"}</p>
                </div>
            }
            
            { loading && <LoadingSpinner fullPage={false} /> }
        </div>
    )
}

export default React.memo( ButtonsBugReport )