import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getIdUsersImages, uploadedUserIdImage } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions';


export type UserIdImage = {
    id: string, 
    firstName: string,
    lastName: string,
    countryName: string
}
  
export const getIdUsersImagesThunk = createAsyncThunk(
    'verifyIdData/getIdUsersImagesThunk',
    async () => {
      try {
        const { usersToReturn, LastEvaluatedKey} = await getIdUsersImages()
        return {
            usersToReturn: usersToReturn as UserIdImage[],
            LastEvaluatedKey: LastEvaluatedKey
        }
        
      }catch (error) {
        console.log("error", error)
        return {
          usersToReturn: [] as UserIdImage[],
          LastEvaluatedKey: null
      }
      }
    }
)


export const undoIdImageThunk = createAsyncThunk(
    'verifyIdData/undoIdImageThunk',
    async (user: UserIdImage) => {
      try { 
        await uploadedUserIdImage(user.id)
        return {
            user
        }
      } catch (error) {
        console.log("error", error)
        return {} as UserIdImage;
      }
    }
)

const initialState = {
    sliderData: [] as UserIdImage[],
    lastUserVerified: {} as UserIdImage,
    loadingContainer: true
}

export const verifyIdDataSlice = createSlice({
  name: 'verifyIdData',
  initialState,
  reducers: {

    removeVerifiedIdImageReducer: (state, action: PayloadAction<{ id: string, user: UserIdImage }>) => {
        state.lastUserVerified = action.payload.user;
        state.sliderData = state.sliderData.filter((u: UserIdImage) => u.id !== action.payload.user.id)
        return state
    },
    setLoadingVerifyIdReducer: (state, action: PayloadAction<boolean>) => {
      state.loadingContainer = action.payload
      return state
    },
    resetVerifyIdImageReducer: (state) => {
        return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIdUsersImagesThunk.fulfilled, (state, action: any) => {
        state.sliderData = action.payload.usersToReturn
        state.loadingContainer = false;
        return state
      }).addCase(getIdUsersImagesThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state
      }).addCase(getIdUsersImagesThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      }).addCase(undoIdImageThunk.fulfilled, (state, action: any) => {
        state.sliderData.unshift(action.payload.user)
        state.lastUserVerified = {} as UserIdImage;
        state.loadingContainer = false;
        return state
      }).addCase(undoIdImageThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(undoIdImageThunk.rejected, (state, action: any) => {
        state.loadingContainer = false
        return state
      })
  },

});

// Action creators are generated for each case reducer function
export const {  
    removeVerifiedIdImageReducer, 
    resetVerifyIdImageReducer,
    setLoadingVerifyIdReducer,
} = verifyIdDataSlice.actions

export default verifyIdDataSlice.reducer