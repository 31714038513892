import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useTranslation } from 'react-i18next'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { PriorityReport } from '../../../../../utils/dbModels'
import { updateBugReport } from '../../../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions'
import { setNewPriorityBugReportReducer, updatePriorityBugReportReducer  } from '../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import "./PriorityDropdown.css"

const PriorityDropdown = React.memo(() => {

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const [openList, setOpenList] = useState<boolean>(false)
    const [currentPriority, setCurrentPriority] = useState<string>("")
    const [loading, setLoading] = useState(false)

    const { id, priority } = useSelector((state: RootState) => state.manageBugReportsData.bugReport)


    useEffect(() => {
        switch (priority) {
            case "HIGH":
                // @ts-expect-error
                setCurrentPriority(t("General:label_168"))
            break;
            case "MEDIUM":
                // @ts-expect-error
                setCurrentPriority(t("General:label_169"))
            break;
            case "LOW":
                // @ts-expect-error
                setCurrentPriority(t("General:label_170"))
            break;
            default:
                break;
        }
    
    }, [priority])
    
    const setNewPriority = (newPriority: PriorityReport) => {
        
        dispatch(setNewPriorityBugReportReducer({ newPriority }))
        dispatch(updatePriorityBugReportReducer({ id: id, priority: priority }))
        setOpenList(false)
    }

    const onClick = () => {
        if(loading)
            return
        setOpenList(!openList)
    }

    return (
        <div
            onClick={onClick} 
            className={`p-relative priority-dropdown width-100`}
        >
            <div className={`flex flex-ai-center border-radius-3 pl-10 pr-10 priority-dropdown ${openList ? "border-blue" : "border-black"} pointer`}>
                {
                    loading ?
                    <div className="p-relative height-100 flex flex-1 flex-ai-center mr-10 ml-10 mr-8">
                        <div className="loading-spinner-mini"></div>
                    </div>
                    :
                    <p className="fs-12 fw-500 three-dot flex-1">{currentPriority}</p>
                }
                <FontAwesomeIcon icon={solid("triangle")} className={`fs-6 ${openList ? "" : "rotate-180"}`} />
            </div>


            {
                openList &&
                <div className="p-absolute dropdown-priority width-100 border-radius-2 z-index-1">
                    {
                        priority !== "HIGH" &&
                        <div
                            onClick={() => setNewPriority("HIGH")}  
                            className="flex flex-ai-center pl-10 pr-10 priority-dropdown-row pointer"
                        >
                            <p className="fs-12 fw-500 three-dot">{t("General:label_168")}</p>
                        </div>
                    }
                    {
                        priority !== "MEDIUM" &&
                        <div
                            onClick={() => setNewPriority("MEDIUM")}  
                            className="flex flex-ai-center pl-10 pr-10 priority-dropdown-row pointer"
                        >
                            <p className="fs-12 fw-500 three-dot">{t("General:label_169")}</p>
                        </div>
                    }
                                        {
                        priority !== "LOW" &&
                        <div
                            onClick={() => setNewPriority("LOW")}  
                            className="flex flex-ai-center pl-10 pr-10 priority-dropdown-row pointer"
                        >
                            <p className="fs-12 fw-500 three-dot">{t("General:label_170")}</p>
                        </div>
                    }
                </div>
            }
        </div>
    )
})

export default PriorityDropdown