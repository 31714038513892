
import { LastEvaluatedKeyBugReports } from "../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice"
import { PriorityReport, StatusReportBug } from "../../../../dbModels"
import { 
    getUserCredentials,
    adminApiUrl
} from "../../../axiosUtils"
import axios, { AxiosError, AxiosRequestConfig } from "axios"

type GetBugReportsBody = {
    LastEvaluatedKey?: string,
    limit: number,
    bringTotal: boolean
    status?: StatusReportBug
}

export const getBugReports = (body: GetBugReportsBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
                params: body
            };
            axios.get(`${adminApiUrl}/v1/reports/app-bug`, config)
            .then((res) => { 
                resolve(res.data)
            })
            .catch((error: AxiosError) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const getBugReportById = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.get(`${adminApiUrl}/v1/reports/app-bug/${id}`, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const updateBugReportSeenWeb = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/seen`, { }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

type UpdateBugReport = {
    //requestBy?: "string", 
    //subject?: "string", 
    //status?: StatusReport, 
    priority?: PriorityReport,
    //title?: "string", 
    //description?: "string"
}
export const updateBugReport = (id: string, body: UpdateBugReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };
            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
}



export const updateStatusAppBugWeb = (id: string, status: StatusReportBug) => {
    return new Promise((resolve: (value: any) => void, reject) => {

        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/status`, { status }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/status`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export type ValidationValueBugReport = "ACCEPTED" | "REJECTED"

export const acceptRejectReportBug = (id: string, priority: PriorityReport, value: ValidationValueBugReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/tester`, { priority, action: "VALIDATION", value }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/tester`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



export const verifyReportBug = (id: string, value: ValidationValueBugReport) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/tester`, { action: "VERIFY", value }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/tester`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const takeReportBug = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/developer`, { action: "TAKE" }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/developer`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 


export const sendToVerifyReportBug = (id: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.patch(`${adminApiUrl}/v1/reports/app-bug/${id}/developer`, { action: "SEND_VERIFY" }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/developer`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 



export const sendMessageBugReport = (id: string, messageText: string) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.post(`${adminApiUrl}/v1/reports/app-bug/${id}/message`, { messageText }, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug/${id}/message`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 

export type CreateReportBugBody = {
	id: string;
	subject: string;
	title: string;
	description: string;
};

export const createBugReport = (body: CreateReportBugBody) => {
    return new Promise((resolve: (value: any) => void, reject) => {
        getUserCredentials()
        .then((session) => {
            const token = session.idToken;
            const config: AxiosRequestConfig = { 
                headers: { Authorization: `Bearer ${token}`, },
            };

            axios.post(`${adminApiUrl}/v1/reports/app-bug`, body, config)
            .then((res) => {resolve(res.data)})
            .catch((error) => { 
                console.log("Error: ", `${adminApiUrl}/v1/reports/app-bug`, error); 
                reject(error) 
            })
        }).catch((error: any) => {
            reject(error)
        })
    })
} 