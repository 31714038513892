import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Colors } from '../../../utils/colors/colors'
import { useNavigate } from 'react-router-dom'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import ArrowGoBackSignUp from '../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../components/UIcomponents/NextButton/NextButton'
import TextPasswordRule from '../../../components/UIcomponents/TextPasswordRule/TextPasswordRule'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { setPasswordSignUp } from '../../../redux/slices/components/SignUp/SignUpSlice'
import { useTranslation } from 'react-i18next'

const PasswordScreen = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const firstName = useSelector((state: RootState) => state.signUp.data.firstName)
    const lastName = useSelector((state: RootState) => state.signUp.data.lastName)
    const passwordRedux = useSelector((state: RootState) => state.signUp.data.password)

    const [password, setPassword] = useState(passwordRedux)
    const [passwordConfirm, setPasswordConfirm] = useState(passwordRedux)

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const [rulesPassword, setRulesPassword] = useState({ length: false, number: false, capitalize: false })
    const [error, setError] = useState("")

    useEffect(() => {
      
        if(!firstName || !lastName)
            navigate("/login")
      
    }, [])

    const onNext = useCallback(
        () => {
            dispatch( setPasswordSignUp({ password }) )
            navigate("/phone-number")
        },
        [password],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const onChangeHandler = (value: string) => {
        setPassword(value);
    
        const regexLength = new RegExp('^(?=.{8,})');
        const resLength = regexLength.test(value);

        const regexNum = new RegExp('^(?=.*[0-9])');
        const resnum = regexNum.test(value);

        const regexCap = new RegExp('^(?=.*[A-Z])(?=.*[a-z])');
        const resCap = regexCap.test(value);

        setRulesPassword({
            length: resLength,
            number: resnum,
            capitalize: resCap
        })
    
        if (error) {
            setError('');
        }
    };

    const onChangeHandlerConfirm = (value: string) => {
        setPasswordConfirm(value);
    
        if(value !== password)
            setError(t("SignUp:passwordScreen:label_01") ?? "")
        else{
            if(error)
                setError("")
        }
    };
  

    return (
        <div className="width-100 height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center ml-10" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">{t("SignUp:passwordScreen:label_02")}</p>


                    <div 
                        className="flex"
                        style={{
                            width: "100%",
                            borderBottomWidth: "1px",
                            borderBottomColor: Colors.TEXT,
                            borderBottomStyle: "solid",
                            marginTop: "4.4vh"
                        }}
                    >
                        <input 
                            type={showPassword ? "text" : "password"} 
                            id="login-password-4" 
                            name="password" 
                            value={password}
                            onChange={(e) => {
                                onChangeHandler(e.target.value)
                            }}
                            className="fs-16 p-6 border-none outline-none flex-1"
                        />
                        <button type="button" onClick={() => setShowPassword(!showPassword)} className="pointer">
                            {
                                showPassword ?
                                <FontAwesomeIcon 
                                    icon={ solid("eye-slash") }
                                    className="fs-12"
                                />
                                :
                                <FontAwesomeIcon 
                                    icon={ solid("eye") }
                                    className="fs-12"
                                />
                            }
                        </button>
                    </div>

                    <TextPasswordRule approved={rulesPassword.length} text={t("SignUp:passwordScreen:label_03")} /> 
                    <TextPasswordRule approved={rulesPassword.number} text={t("SignUp:passwordScreen:label_04")} /> 
                    <TextPasswordRule approved={rulesPassword.capitalize} text={t("SignUp:passwordScreen:label_05")} /> 

                    <p className="p-absolute color-error fs-12 mt-6">
                        {error}
                    </p>

                    <div 
                        className="flex"
                        style={{
                            width: "100%",
                            borderBottomWidth: "1px",
                            borderBottomColor: Colors.TEXT,
                            borderBottomStyle: "solid",
                            marginTop: "4.4vh"
                        }}
                    >
                        <input 
                            type={showPasswordConfirm ? "text" : "password"} 
                            id="login-password-5" 
                            name="password" 
                            value={passwordConfirm}
                            onChange={(e) => {
                                onChangeHandlerConfirm(e.target.value)
                            }}
                            className="fs-16 p-6 border-none outline-none flex-1"
                        />
                        <button type="button" onClick={() => setShowPasswordConfirm(!showPasswordConfirm)} className="pointer">
                            {
                                showPasswordConfirm ?
                                <FontAwesomeIcon 
                                    icon={ solid("eye-slash") }
                                    className="fs-12"
                                />
                                :
                                <FontAwesomeIcon 
                                    icon={ solid("eye") }
                                    className="fs-12"
                                />
                            }
                        </button>
                    </div>

                    
                </div>

                <div className="mr-20">
                    { ImageSignUp["Password"] }
                </div>
            </div>


            <div className="flex flex-jc-sb ml-10" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:passwordScreen:label_06")} 
                    disabled={!rulesPassword.length || !rulesPassword.number || !rulesPassword.capitalize || password !== passwordConfirm }
                />
            </div>


            
        </div>
    )
}

export default PasswordScreen