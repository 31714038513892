
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import FormEditJobModal from './FormEditJobModal/FormEditJobModal';

const EditJobModal = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(resetValueFromState())
  }
    //const job = useSelector((state: RootState) => state.managerJobModals.EditJobModal.job);  
    const { categoryId, job } = useSelector((state: RootState) => state.managerJobModals.EditJobModal); 
    return (
      <div className="blank-wrapper-modals flex flex-d-column height-job-modal-with-scroll p-relative" style={{ width: "96vw", height: "96vh" }}>
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
            <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-16-vh"/> 
          </button>
          <TitleModal title={`${t("General:label_121")} ${job.jobName} ${t("General:label_122")}`} />
            <FormEditJobModal 
                job={job}
                categoryId={categoryId}
            />
      </div>
    )
}

export default EditJobModal