import "./MyHistoryScreen.css"
import TabBarMyHistory from '../../../../components/MyHistory/TabBarMyHistory/TabBarMyHistory'
import { GetHistoryThunk } from "../../../../redux/slices/components/MyHistory/myHistoryDataSlice"
import { useEffect } from "react"
import { useAppDispatch } from "../../../../redux/store/store"
import HeaderTableMyHistory from "../../../../components/MyHistory/HeaderTableMyHistory/HeaderTableMyHistory"
import HistoryRows from "../../../../components/MyHistory/HistoryRows/HistoryRows"
import { useTranslation } from "react-i18next"

const MyHistoryScreen = () => {

  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    
      dispatch( GetHistoryThunk({ type: "All" }) )

  }, [])
  
  return (
    <div className="container-my-history height-100">
        <p className="my-history-title">{t("History:label_01")}</p>
        <TabBarMyHistory />
        <HeaderTableMyHistory />
        <HistoryRows />
    </div>
  )
}

export default MyHistoryScreen