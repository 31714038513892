import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { GetOfferByUsersThunk, GetOffersThunk, numberOffersByPage } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import "./PaginationRow.css"
import { useNavigate } from 'react-router-dom';
import { getPaginatedData } from '../../../utils/strings/strings';
import { useTranslation } from 'react-i18next';

const PaginationRow = () => {
    
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    
    const { offersToShow, loadingContainer, offersIds, page, type, limit, total, stringToSearch, indexTab } = useSelector((state: RootState) => state.manageOffersData);
    const offersLength = offersToShow.length;

    const isLastPage = total - (limit * page) <= 0 ? true : false 
    //console.log("total - (limit * page)", total,  (limit * page))
    
    const paginate = (direction: string) => {
        
        if(loadingContainer)
            return
        
        if(direction === "forward"){
            navigate(`/manage-offers?limit=${limit}&page=${page + 1}&type=${type}`)
            /*
            if(stringToSearch)
                dispatch(GetOfferByUsersThunk({ page: page + 1, limit: 100, bringTotal: true,  stringToSearch}))
            else
                dispatch(GetOffersThunk({ type, page: page + 1, limit, bringTotal: true }))
            */
        }
        else if(direction === "backward" && page !== 1){
            navigate(`/manage-offers?limit=${limit}&page=${page - 1}&type=${type}`)
            /*
            if(page === 2){
                dispatch(GetOffersThunk({ type, page: page - 1, limit, bringTotal: true }))
            }else{
                const lastKeyId = offersIds[((page - 2) * limit) - 1]
                dispatch(GetOffersThunk({ type, page: page - 1, limit, bringTotal: true }))
            }
            */
        }

    }

    const changeNumberOfPages = (newNumberOfPages: number) => {

        const totalOffersUntilNow = (page - 1) * limit + offersToShow.length 

        if(newNumberOfPages > totalOffersUntilNow){
            // bring page 1 with same type
            //dispatch(GetOffersThunk({ type, page: 1, limit: newNumberOfPages, bringTotal: true}))
            navigate(`/manage-offers?limit=${newNumberOfPages}&page=${1}&type=${type}`)
        }else{
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            navigate(`/manage-offers?limit=${newNumberOfPages}&page=${newPage}&type=${type}`)
            //dispatch(GetOffersThunk({ type, page: 1, limit: newNumberOfPages, bringTotal: true}))
            /*
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            */
        }
    }

    const changeNumberOfPagesSearchBar = (newNumberOfPages: number) => {
        
        if(!stringToSearch)
            return

        const totalOffersUntilNow = (page - 1) * limit + offersToShow.length 

        if(newNumberOfPages > totalOffersUntilNow){
            // bring page 1 with same type
            dispatch(GetOfferByUsersThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch }))
        }else{
            
            dispatch(GetOfferByUsersThunk({ page: 1, limit: newNumberOfPages, bringTotal: true, stringToSearch}))
            /*
            const newPage = Math.ceil(totalOffersUntilNow / newNumberOfPages)
            const newLastEvaluatedKey = offersToShow[newNumberOfPages - 1].id
            dispatch(GetOffersThunk({ type, page: newPage, limit: newNumberOfPages, LastEvaluatedKey: newLastEvaluatedKey, bringTotal: false }))
            */
        }
    }

    return (
        <div className="con-row-pagination flex flex-jc-sb flex-ai-center width-100">
            
            <div className=""></div>
            
            <div className="flex flex-ai-center">
                <button 
                    onClick={() => paginate("backward")} 
                    className={`fs-24 mr-12 ${(page === 1)?  "disable-color" : "c-black pointer"}`}
                    disabled={(page === 1 || loadingContainer ) ? true : false} 
                >
                    <FontAwesomeIcon icon={solid("arrow-left-long")}  />
                </button>
                <p className="fs-15 fw-700">{page}</p>
                <button 
                    onClick={() => paginate("forward")}  
                    className={`fs-24 ml-12 ${( isLastPage) ?  "disable-color" : "c-black pointer"}`}
                    disabled={( isLastPage || loadingContainer ) ? true : false}
                >
                    <FontAwesomeIcon icon={solid("arrow-right-long")}  />
                </button>
            </div>
            
            <div className="flex">
                {
                    numberOffersByPage.map((numberPages: number, index: number) => {
                        return (
                            <button 
                                key={index}
                                disabled={numberPages === limit || loadingContainer}
                                className={`fs-14 fw-500 mr-12 ${numberPages === limit ? "c-black" : "disable-color pointer"}`}
                                onClick={() => { 
                                    if(indexTab > 0)
                                        changeNumberOfPages(numberPages) 
                                    else
                                        changeNumberOfPagesSearchBar(numberPages)
                                }}
                            >
                                {numberPages}
                            </button>
                        )
                    })
                }
                <p className="fw-500 fs-14 ml-10">
                    {`${getPaginatedData(page, limit, total)} ${t("General:label_267")} ${total}`}
                </p>
            </div>

        </div>
    )
}

export default PaginationRow