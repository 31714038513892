import React, { useEffect } from 'react'
import { useAppDispatch } from '../../../redux/store/store'
import LeftSideBugReport from './LeftSideBugReport/LeftSideBugReport'
import RightSideBugReport from './RightSideBugReport/RightSideBugReport'
import { resetDetailedBugReportReducer } from '../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess'

const DetailedBugReportScreen = () => {

    const dispatch = useAppDispatch()

    const accessDisplayTester = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Tester", id_Action: "Display" })
    const accessDisplayDeveloper = useCheckAccess({ id_Category: "Manage_Reports", id_Policy: "Bug_Reports_Developer", id_Action: "Display" })

    useEffect(() => {
        return () => {
            dispatch( resetDetailedBugReportReducer() )
        }
    }, [])
    
    if(!accessDisplayTester && !accessDisplayDeveloper){
        return (
            <div className="p-absoulte width-100 height-100 center">
                <p className="fs-20 fw-600 color-text">{"You can access to this content"}</p>
            </div>
        )
    }
    
    return (
        <div className="aux-scroll flex width-100 ff-poppins z-index-1" style={{ zIndex: 1, backgroundColor: "white" }}>
            <LeftSideBugReport />
            <RightSideBugReport />
        </div>
    )
}

export default React.memo( DetailedBugReportScreen )