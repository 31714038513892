import React, { useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import SearcherBar from '../../UIcomponents/SearcherBar/SearcherBar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { showRequestProductDBModalReducer } from '../../../redux/slices/components/RequestProducts/requestProductsDataSlice'
import "./HeaderProductRequest.css"

const HeaderProductRequest = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const searchBarRef = useRef()

    const typeProduct = useSelector((state: RootState) => state.requestProductsData.typeProduct)

    const searchSubmit = useCallback(
        () => {
            
        },
        [],
    )
    
    const handleRequestProduct = () => {
        dispatch(showRequestProductDBModalReducer())
    }


    return (
        <div className="flex flex-ai-center">
            <p className="title-screen">{t("ManageProducts:request:label_01")}</p>
            <SearcherBar style={{ flex:1  }} ref={searchBarRef} searchSubmit={searchSubmit} placeholder={t("General:label_029")} />

            <button 
                className="btn-request-product border-color-text border-radius-6 flex flex-ai-center ml-20 pl-12 pr-12 pointer"
                onClick={handleRequestProduct}
            >
                <FontAwesomeIcon icon={regular("plus")} className="fs-18-vh" />
                <p className="fs-20-vh color-text ml-10">{typeProduct === "DIGITAL" ? t("ManageProducts:request:label_03") : t("ManageProducts:request:label_02")}</p>
            </button> 
            
        </div>
    )
}

export default React.memo( HeaderProductRequest )