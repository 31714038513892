import { createSlice, PayloadAction, createAsyncThunk  } from '@reduxjs/toolkit'
import { getOrderedState } from '../../../../utils/redux/reduxUtils'
import { ProductDB, StatusProduct, TypeProduct } from '../Products/ProductsSlice'
import { getValidatedProducts } from '../../../../utils/requestsUtils/functionsRequests/products/productsFunctions'

export type LastEvaluatedKeyProductsDB = {
    id: string
    typeProduct?: TypeProduct
    status?: StatusProduct
    userId:   string
}

export type ExtraDataProducts = {
    typeProduct: TypeProduct
    LastEvaluatedKey?: LastEvaluatedKeyProductsDB,
    jobId?: string
    page: number,
    limit: number,
}


export const GetProductsDBThunk = createAsyncThunk(
    'manageProductsDataSlice/GetProductsDBThunk',
    async ({ typeProduct, LastEvaluatedKey, page, limit, jobId }: ExtraDataProducts) => {
      try { 
        const params = {
            jobId,
            typeProduct,
            limit, 
            LastEvaluatedKey
        }

        const res = await getValidatedProducts(params)

        return {
          results: res.items as ProductDB[],
          LastEvaluatedKey: res.LastEvaluatedKey,
          typeProduct,
          page,
          limit,
          total: res.total,
          jobId
        }
      } catch (error) {
        console.log("error", error)
        return {
          results: [] as ProductDB[],
          typeProduct,
          page,
          limit,
          total: 0,
          jobId: undefined
        }
      }
    }
)


export const numberProductsByPage: number[] = [10, 25, 50, 100 ]

export type ModalNameProducts = "AddProductGimworkDBModal" | "EditProductDBModal" | "DeleteProductDBModal" | ""

type InitialState = {
    stringToSearch:         string
    total:                  number
    indexTab:               number
    limit:                  number
    page:                   number
    typeProduct:            TypeProduct
    productsIds:            LastEvaluatedKeyProductsDB[]
    productsToShow:         ProductDB[]
    LastEvaluatedKey?:      LastEvaluatedKeyProductsDB
    loadingContainer:       boolean
    orderColumn:            string
    orderDirection:         boolean
    jobId?:                 string
    EditProductDBModal:     ProductDB
    DeleteProductDBModal:   ProductDB
    modalName:              ModalNameProducts
}

const initialState: InitialState = {
    stringToSearch: "",
    total: 0,
    indexTab: 1,
    limit: numberProductsByPage[0],
    page: 1,
    typeProduct: "DIGITAL",
    productsIds: [],
    productsToShow: [],
    LastEvaluatedKey: undefined,
    loadingContainer: true,
    orderColumn: "",
    orderDirection: false,
    jobId: undefined,
    EditProductDBModal: {} as ProductDB,
    DeleteProductDBModal: {} as ProductDB,
    modalName: ""
}

export const manageProductsDataSlice = createSlice({
  name: 'manageProductsData',
  initialState,
  reducers: {
    removeDeletedProductReducer: (state, action: PayloadAction<string>) => {
      const id  = action.payload
  
      if(id === state.LastEvaluatedKey?.id && state.productsIds.length > 1){
        //const position = state.productsIds.indexOf(id)
        let position = -1
        state.productsIds.forEach((key, index) => {
            if(key.id === id)
                position = index
        })
        if(position > -1)
          state.LastEvaluatedKey = state.productsIds[position-1]
      }
      state.productsIds = state.productsIds.filter((product: LastEvaluatedKeyProductsDB) => product.id !== id)
      state.productsToShow = state.productsToShow.filter((product: ProductDB) => product.id !== id)
      state.total = state.total - 1
      return state
    },
    changeIndexTabProductsReducer: (state, action: PayloadAction<{ indexTab: number }>) => {
      state.productsIds = []
      state.indexTab = action.payload.indexTab
      return state
    },
    addProductCreatedGimworkReducer: (state, action: PayloadAction<ProductDB>) => {
      state.productsToShow.unshift( action.payload )
      return state
    },
    editProductReducer: (state, action: PayloadAction<ProductDB>) => {
      const productDB = action.payload
      state.productsToShow = state.productsToShow.map((product) => {
          if(productDB.id === product.id)
              return productDB
          else 
              return product
      })
      return state
    },
    showEditProductDBModalReducer: (state, action: PayloadAction<ProductDB>) => {
        state.modalName = "EditProductDBModal"
        state.EditProductDBModal = action.payload
        return state
    },
    showAddProductGimworkDBModalReducer: (state) => {
        state.modalName = "AddProductGimworkDBModal"
        return state
    },
    showDeleteProductDBModalReducer: (state, action: PayloadAction<ProductDB>) => {
        state.modalName = "DeleteProductDBModal"
        state.DeleteProductDBModal = action.payload
        return state
    },
    resetProductsDBModalsReducer: (state) => {
        state.EditProductDBModal = {} as ProductDB
        state.DeleteProductDBModal = {} as ProductDB
        state.modalName = ""
        return state
    },
    orderProductsReducer: (state, action: PayloadAction<{orderColumn: string, orderDirection: boolean}>) => {
      const { orderColumn, orderDirection  } = action.payload
      state.productsToShow = getOrderedState({ orderColumn, orderDirection, array: state.productsToShow })
      state.orderColumn = orderColumn
      state.orderDirection = orderDirection
      return state
    },
    resetManageProductsReducer: () => {
      return initialState
    }
  },

  extraReducers: (builder) => {
    builder.addCase(GetProductsDBThunk.fulfilled, (state, action: PayloadAction<ExtraDataProducts & { total: number, results: ProductDB[] }>) => {
      const { results, typeProduct, page, LastEvaluatedKey, limit, total } = action.payload  

      let lastIndex = 0
      for(let i=0; i < results.length; i++){
          const skip = state.limit * (page-1)
          //state.productsIds[skip+i] = results[i].id
          lastIndex = skip+i
      }

        state.productsIds = state.productsIds.slice(0,lastIndex+1)
        state.productsToShow = results
        state.typeProduct = typeProduct
        state.loadingContainer = false
        state.page = page
        state.LastEvaluatedKey = LastEvaluatedKey
        state.limit = limit ?? 0
        state.total = total
        state.stringToSearch = ""
        return state
      }).addCase(GetProductsDBThunk.pending, (state, action: any) => {
        state.loadingContainer = true
        return state     
      }).addCase(GetProductsDBThunk.rejected, (state, action: any) => {
        const failState = {...initialState}
        failState.loadingContainer = false
        return failState
      })
      
  },

});

// Action creators are generated for each case reducer function
export const {  
  showAddProductGimworkDBModalReducer,
  removeDeletedProductReducer,
  changeIndexTabProductsReducer,
  resetManageProductsReducer,
  orderProductsReducer,
  showEditProductDBModalReducer,
  showDeleteProductDBModalReducer,
  resetProductsDBModalsReducer,
  editProductReducer,
  addProductCreatedGimworkReducer
} = manageProductsDataSlice.actions

export default manageProductsDataSlice.reducer