

import React, { useEffect } from 'react'                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
import LeftSideUserReport from './LeftSideUserReport/LeftSideUserReport'
import RightSideUserReport from './RightSideUserReport/RightSideUserReport'
import ErrorMessageReport from './ErrorMessageReport/ErrorMessageReport'
import MessageAfterMoveBackUp from './MessageAfterMoveBackUp/MessageAfterMoveBackUp'
import MessageAfterAcceptRefuse from './MessageAfterAcceptRefuse/MessageAfterAcceptRefuse'

type DetailedUserReportScreenProps = {

}

const DetailedUserReportScreen = React.memo(({  }: DetailedUserReportScreenProps) => {
    

    useEffect(() => {

        return () => {

        }
    }, [])
    
    //aux-scroll
    return (
        <div className="flex width-100 ff-poppins height-100">
            <LeftSideUserReport />
            <RightSideUserReport />
            <ErrorMessageReport />
            <MessageAfterMoveBackUp />
            <MessageAfterAcceptRefuse />
        </div>
    )
})

export default React.memo( DetailedUserReportScreen )