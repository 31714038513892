import React, { useEffect, useLayoutEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useParams } from 'react-router-dom';
import { GetUserReportByIdThunk, resetDetailedUserReportReducer, setLoadingUserReportReducer } from '../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import DetailedUserReportScreen from '../../../../../components/ManageReportsScreen/DetailedUserReportScreen/DetailedUserReportScreen'
import { updateAssignmentWeb } from '../../../../../utils/requestsUtils/functionsRequests/reports/user/reportsFunctions';
import { AddUserToChatSupport } from '../../../../../utils/chats/ChatFunctions';

const UserReportScreen = () => {

    const dispatch = useAppDispatch()

    const loadingIndividualPage = useSelector((state: RootState) => state.manageUserReports.loadingIndividualPage)
    const idReportBrought = useSelector((state: RootState) => state.manageUserReports.userReport.id)

    const { id  } = useParams();

    useLayoutEffect(() => {


        if(id){
            dispatch( setLoadingUserReportReducer(true) )
            updateAssignmentWeb(id)
            .then(({ STATUS_OPERATION }: { STATUS_OPERATION: "NEW_ASSIGNMENT" | "CHANGED_ASSIGNMENT" }) => {
                //AddUserToChatSupport
                dispatch( GetUserReportByIdThunk({ id }) )
                if(STATUS_OPERATION === 'CHANGED_ASSIGNMENT'){
                
                
                }
            })
            .catch(() => {
                dispatch( GetUserReportByIdThunk({ id }) )
            })
            
        }
    
        return () => {
            dispatch(resetDetailedUserReportReducer())
        };
    }, [])

    

    return (
        <div className="flex flex-d-column height-100 p-relative" style={{ }}>
            {
                loadingIndividualPage ?
                <LoadingSpinner fullPage={false} /> 
                : (
                    idReportBrought ?
                    <DetailedUserReportScreen />
                    : 
                    <div className="center">
                        <p className="fs-28 fw-500">Report not found</p>
                    </div>
                )

            }
        </div>
    )
}

export default UserReportScreen