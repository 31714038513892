import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getAvailableRolesChunk } from '../../../redux/slices/components/ManageUsers/manageUsersModalsSlice';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess';
import { ModalsUsersForm, resetManageUsersFormModalReducer } from '../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice';
import DeleteUserFormModal from './DeleteUserFormModal/DeleteUserFormModal';
import DeleteMultipleUserFormModal from './DeleteMultipleUserFormModal/DeleteMultipleUserFormModal';

type ManagerUsersSelectModalProps = {
    modalName: ModalsUsersForm
}

const ManageUsersFormModals = () => {

    const dispatch = useAppDispatch()

    const modalName = useSelector((state: RootState) => state.manageUsersForm.modalName);

    const handleClickOutsideModal = (e: any) => {
        const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickOutsideModal && e.target.id === "darkened-background-modal")
            dispatch(resetManageUsersFormModalReducer())
    }
  
    return (
      <>
        {
          modalName &&
          <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
            <div id={"background-aux-modal"}>
                <ManagerUsersSelectModal modalName={modalName} />
            </div>
          </div>
        }
      </>
    )
}

const ManagerUsersSelectModal = ({ modalName }: ManagerUsersSelectModalProps) => {
  
      switch(modalName){
        case "DeleteUser":
            return (<DeleteUserFormModal />)
        case "DeleteUsers":
            return (<DeleteMultipleUserFormModal />)
        default:
            return(
              <h1>Error modals</h1>
            )
      }
  }

export default ManageUsersFormModals