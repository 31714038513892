import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import "./TabsMarketplace.css"
import React from 'react'
import { changeIndexTabReducerHistory } from '../../../redux/slices/components/MyHistory/myHistoryDataSlice'
import { changeIndexTabMarketplaceReducer } from '../../../redux/slices/components/Marketplace/marketplaceDataSlice'

type TabOfferOption = { index: number }

const colorsAnimation = ["gray", "blue", "red"]
const typesOffers = ["all", "Job", "Task"]


const TabsMarketplace = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    //const navigate = useNavigate()

    //const [searchParams] = useSearchParams();

    const [leftLine, setLeftLine] = useState<number>(0)

    const marginBetweenOptions = 1; // rem
    const widthOption = 15; // rem

    const indexTab = useSelector((state: RootState) => state.marketplace.indexTab);

    useEffect(() => {
        if(indexTab < 0)
            setLeftLine(0)
        else if(indexTab === 2)
            setLeftLine((1) * (widthOption+marginBetweenOptions))
    }, [indexTab])
    

    const setTab = ({ index }: TabOfferOption) => {

        if(index === indexTab)
            return

        const smallLine = document.getElementById("small-line") 

        if(smallLine){

            let remsToMove = 0
            if(indexTab > 0)
                remsToMove = ((index - indexTab) * (widthOption+marginBetweenOptions) );
            else
                remsToMove = ((index - 1) * (widthOption+marginBetweenOptions) ); // case after search bar

            smallLine.animate([
                { left: `${leftLine}rem` },
                { left: `${remsToMove + leftLine}rem` }
            ], {
                fill: "forwards",
                duration: 300,
                iterations: 1
            });
            setLeftLine(remsToMove + leftLine)
            dispatch( changeIndexTabMarketplaceReducer(index) )
            //navigate(`/manage-offers?limit=${limit}&page=${1}&type=${typesOffers[index] === "Task" ? "Task" : "Job"}`)
            //dispatch(GetOffersThunk({ type: typesOffers[index] === "Task" ? "Task" : "Job", page: 1, limit, bringTotal: true }))
        }
    }

    return (
        <div id="container-tab" className="con-all-options-tab-marketplace">
            
            <div 
                className={`option-tab-tab-marketplace ${indexTab === 1 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 1 })}
            >
                <p className={`text-option-tab-tab-marketplace ${indexTab === 1  ? "italic text-tab-blue-tab-marketplace" : ""}`}>{t("Marketplace:label_18")}</p>
                <div id="1-option" className="option-line-tab-marketplace"></div>
            </div>
            
            <div 
                className={`option-tab-tab-marketplace ${indexTab === 2 ? "" : "pointer"}`}  
                onClick={() => setTab({ index: 2  })}
            >
                <p className={`text-option-tab-tab-marketplace ${indexTab === 2  ? "italic text-tab-red-tab-marketplace" : ""}`}>{t("Marketplace:label_19")}</p>
                <div id="2-option" className="option-line-tab-marketplace"></div>
            </div>

                <div 
                    id="small-line" 
                    style={{ left: `${leftLine.toString()}rem` }} 
                    className={`small-line-tab-marketplace ${colorsAnimation[indexTab]}-line-tab-marketplace ${indexTab < 0 ? "visibility-hidden" : ""}`}
                ></div>
            
            <div className="whole-line-tab-marketplace"></div>

        </div>
    )
}

export default React.memo( TabsMarketplace )