
type HorizontalSeparatorProps = {
    distance: string
}

const HorizontalSeparator = ({
    distance
}: HorizontalSeparatorProps) => {
    return (
        <div style={{ height: ".1rem", width: distance }}></div>
    )
}

export default HorizontalSeparator