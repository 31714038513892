import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { orderMarketplaceReducer } from '../../../../redux/slices/components/Marketplace/marketplaceDataSlice';
import ArrowColumn from '../../../UIcomponents/ArrosColumn/ArrowColumn';
import "./HeaderTableMarketplace.css"
import "../TableMarketplace.css"

const HeaderTableMarketplace = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const orderDirection = useSelector((state: RootState) => state.marketplace.orderDirection)
    const orderColumn = useSelector((state: RootState) => state.marketplace.orderColumn)
    const indexTab = useSelector((state: RootState) => state.marketplace.indexTab)

    const orderArray = useCallback(
            ({ newOrderDirection, newOrderColumn }: {newOrderDirection: boolean, newOrderColumn: string}) => {  
                dispatch(orderMarketplaceReducer({  orderDirection: newOrderDirection, orderColumn: newOrderColumn }))
            },
            [],
    )


    return (
        <div className="flex con-header-table-marketplace ff-roboto">


                
            <div className="marketplace-item1 flex flex-ai-center fs-14 mt-4 c-manatee">
                <ArrowColumn
                    columnText={t("Marketplace:label_03")}
                    orderColumn={orderColumn} 
                    columnName={"jobName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>

            <div className="marketplace-item2 flex flex-ai-center fs-14 mt-4 c-manatee center">
                <ArrowColumn
                    columnText={t("Marketplace:label_04")}
                    orderColumn={orderColumn} 
                    columnName={"jobName"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="marketplace-item3 flex flex-ai-center fs-14 mt-4 c-manatee center">
                {
                    indexTab === 2 &&
                    <ArrowColumn 
                        columnText={t("Marketplace:label_05")}
                        orderColumn={orderColumn} 
                        columnName={"startTime"}  
                        orderDirection={orderDirection} 
                        orderArray={orderArray}
                    />
                }
            </div>
            
            <div className="marketplace-item4 flex flex-ai-center fs-13 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("Marketplace:label_06")}
                    orderColumn={orderColumn} 
                    columnName={"startTime"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="marketplace-item5 center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("Marketplace:label_07")}
                    orderColumn={orderColumn} 
                    columnName={"startTime"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="marketplace-item6 center fs-14 mt-4 c-manatee">
                <ArrowColumn 
                    columnText={t("Marketplace:label_08")}
                    orderColumn={orderColumn} 
                    columnName={"startTime"}  
                    orderDirection={orderDirection} 
                    orderArray={orderArray}
                />
            </div>
            
            <div className="marketplace-item7 center fs-13 mt-4 c-manatee">
                {indexTab === 1 ? t("Marketplace:label_09") : t("Marketplace:label_10")}
            </div>
            
            <div className="marketplace-item8 center fs-13 mt-4 c-manatee">
                {t("Marketplace:label_11")}
            </div>

        
        </div>
    )
    
}

export default React.memo( HeaderTableMarketplace )