import React, { useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam';
import "./WebCamID.css"

type WebCamIDProps = {
    onFinishRecord: (blob: Blob) => void
}

const WebCamID = ({ onFinishRecord }: WebCamIDProps) => {
    const webcamRef = useRef<Webcam>(null);
    const mediaRecorderRef = useRef<MediaRecorder>(null)
    const [capturing, setCapturing] = React.useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);

    const [error, setError] = useState("")
  
    const handleStartCaptureClick = React.useCallback(() => {
        try {
          if(webcamRef.current !== null){

            //@ts-expect-error
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
              mimeType: "video/webm"
            });
            mediaRecorderRef.current.addEventListener(
              "dataavailable",
              handleDataAvailable
            );
            mediaRecorderRef.current.start();
            setCapturing(true);
        }
        } catch (error) {
            console.log("ERROR", error) 
            setError("You don't have a camera in this device")
        }
    }, [webcamRef, setCapturing, mediaRecorderRef]);
  
    const handleDataAvailable = React.useCallback(
      ({ data }: any) => {
        console.log("data.size", data.size)
        if (data.size > 0) {
          setRecordedChunks((prev) => prev.concat(data));
        }
      },
      [setRecordedChunks]
    );
  
    const handleStopCaptureClick = React.useCallback(() => {
      //@ts-expect-error
      mediaRecorderRef.current.stop();
      setCapturing(false);
      console.log("stop")
      //handleDownload()
    }, [mediaRecorderRef, webcamRef, setCapturing, recordedChunks]);
  
    const handleDownload = React.useCallback(() => {
      if (recordedChunks.length) {
        const blob = new Blob(recordedChunks, {
          type: "video/webm"
        });

        onFinishRecord(blob)

        /*
        const url = URL.createObjectURL(blob);
        let a = document.createElement("a");
        document.body.appendChild(a);
        //@ts-expect-error
        a.style = "display: none";
        a.href = url;
        a.download = "react-webcam-stream-capture.webm";
        a.click();
        window.URL.revokeObjectURL(url);
        setRecordedChunks([]);
        */
      }
    }, [recordedChunks]);

    useEffect(() => {
      
      console.log("use effect")
      if (recordedChunks.length > 0) {
        const blob = new Blob(recordedChunks, {
          type: "video/webm"
        });

        onFinishRecord(blob)
      }
    
      return () => {
        
      }
    }, [recordedChunks])

    
  
    return (
      <div>
        <Webcam audio={false} ref={webcamRef} width={240} />
        <div className="center mt-10">
          {
              capturing ? (
                  <button 
                      className="button-record border-radius-3 dark-gray-bg pointer" 
                      onClick={handleStopCaptureClick}
                  >
                      <p className="fs-14 c-white fw-500">Stop</p>
                  </button>
              ) : (
                <button 
                    className="button-record border-radius-3 dark-gray-bg pointer" 
                    onClick={handleStartCaptureClick}
                >
                    <p className="fs-14 c-white fw-500">Start</p>
                </button>

          )}
        </div>
        {recordedChunks.length > 0 && (
          <button onClick={handleDownload}></button>
        )}
            <div className="p-relative mt-6">
                <p className="p-absolute color-error fs-12 mt-6">
                    {error}
                </p>
            </div>
      </div>
    );
  };
  
export default React.memo( WebCamID )