import { useLayoutEffect, useState } from 'react'
import DetailedBugReportScreen from '../../../../../components/ManageBugReportScreen/DetailedBugReportScreen/DetailedBugReportScreen'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import LoadingSpinner from '../../../../../components/LoadingSpinner'
import { useNavigate, useParams } from 'react-router-dom';
import { GetBugReportByIdThunk, resetDetailedBugReportReducer } from '../../../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice'
import MessageBugReportComponent from '../../../../../components/ManageBugReportScreen/DetailedBugReportScreen/MessageBugReportComponent/MessageBugReportComponent'

const BugReportScreen = () => {

    const dispatch = useAppDispatch()

    const loadingIndividualPage = useSelector((state: RootState) => state.manageBugReportsData.loadingIndividualPage)
    const idReportBrought = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)

    const { id } = useParams();

    useLayoutEffect(() => {


        if(id){
            dispatch( GetBugReportByIdThunk({ id }) )
        }
    
        return () => {
            dispatch(resetDetailedBugReportReducer())
        };
    }, [id])


    if (loadingIndividualPage) 
        return (
            <div className="width-100 height-100 p-relative">
                <LoadingSpinner fullPage={false} /> 
            </div>
        )

    if(!idReportBrought)
        return (
            <div className="center height-100">
                <p className="fs-28 fw-500">Report not found</p>
            </div>
        )

    return (
        <div className="flex height-100 p-relative">
            <DetailedBugReportScreen />
            <MessageBugReportComponent />
        </div>
    )
}

export default BugReportScreen