import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';


type IdImageProps = {
    url: string
    onClick: () => void
}

const IdImage = ({
    url,
    onClick
}: IdImageProps) => {
    return (
        <div onClick={onClick} className="p-relative id-img-view pointer">
        <img
            className="img-slider-id p-absolute" 
            src={url}
            
        />
            <div className="id-img-hover center">
                <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} className="fs-30 icon-expand-id-img" />
            </div>

        </div>
    )
}

export default React.memo( IdImage )