import { useTranslation } from "react-i18next";
import { ProductDB } from "../../../../redux/slices/components/Products/ProductsSlice";
import { RootState, useAppDispatch } from "../../../../redux/store/store";
import React, { useState } from "react";
import { checkErrorsWhenSubmit, ValuesForm } from "../../../../utils/verifications/Forms";
import LoadingSpinner from "../../../LoadingSpinner";
import { useSelector } from "react-redux";
import FormAddProductDBModal from "./FormAddProductDBModal/FormAddProductDBModal";

const AddProductDBModal = () => {
    
    const productDB = useSelector((state: RootState) => state.requestProductsData.AddProductDBModal);

    return (
      <div className="blank-wrapper-modals flex flex-d-column p-relative" style={{ width: "41.2rem", height: "62rem" }}>
          {
              productDB &&
              <FormAddProductDBModal 
                  product={productDB}
              />
          }
      </div>
    )
}

export default React.memo( AddProductDBModal )