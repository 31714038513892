import { i18n } from "i18next"
import React from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setNotificationAsViewed } from "../../../../redux/slices/authSlice"

type DefaultNotificationprops =
    {
        "id": string,
        "viewed": boolean

        "t": any,
        i18n: i18n;
        setNotificationAsViewed(id: string): void
    }




class DefaultNotification extends React.PureComponent<DefaultNotificationprops> {
    constructor(props: DefaultNotificationprops) {
        super(props)
        //    this.onPress = this.onPress.bind(this)
    }

    override componentDidMount() {
        if (!this.props.viewed) {
            this.props.setNotificationAsViewed(this.props.id);
        }
    }



    override render(): React.ReactNode {


        return null


    }
}


const mapsStateprops = () => {
    return {}
}

const mapsDispatchprops = {
    setNotificationAsViewed
}


export default connect(mapsStateprops, mapsDispatchprops)(withTranslation()(DefaultNotification))