import moment from 'moment-timezone';
import 'moment/locale/es'
import 'moment/locale/fr'
import DateReload from '../../../../DateReload/DateReload';
import { convertPythonTimestamp } from '../../../../../../utils/dates/dates';
interface ReceivedTimeNotificationProps {
	date: number
}

export const ReceivedTimeNotification = ({ date }: ReceivedTimeNotificationProps) => {
	return (
		<DateReload
			date={convertPythonTimestamp(date ?? 0)} 
			type={"NOTIFICATION"}
			style={{
				color: '#92959D',
			}}
		/>
	)
}

