import React from 'react'
import { Country, S3DomainGimwork_country_flags_w80 } from '../../../../utils/staticVariables/staticVariables'
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { useAppDispatch } from '../../../../redux/store/store'
import useCheckAccess from '../../../hooks/useCheckAccess/useCheckAccess'
import { setDeleteContractModalState, setDeleteContractState, setEditContractModalState, setEditContractState } from '../../../../redux/slices/components/Rules/rulesSlice'
import "./RowRules.css"

type RowRulesProps = {
    countryName: string
    contract: string
    index: number
}

const RowRules = ({ countryName, contract, index }: RowRulesProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const accessEdit = useCheckAccess({ id_Category: "Rules", id_Policy: "Contracts", id_Action: "Edit" })
    const accessDelete = useCheckAccess({ id_Category: "Rules", id_Policy: "Contracts", id_Action: "Delete" })

    const editContract = () => {
        dispatch( setEditContractModalState({
            countryName: countryName as Country,
        }) )
    }

    const deleteContract = () => {
        dispatch( setDeleteContractModalState({
            countryName: countryName as Country
        }) )
    }

    return (
        <div className="flex flex-d-column" style={{ marginTop: `${index * 2.5 + 3}rem` }}>
            <div className="flex flex-ai-center">
                <div 
                    className="img-flag-dropdown"
                    style={{ backgroundImage: `url("${S3DomainGimwork_country_flags_w80}${countryName}.png")` }}>
                </div>
                <p className="fw-600 fs-20 ml-16 ff-roboto">{countryName}</p>
            </div>

            <div className="flex flex-ai-center width-100 dark-gray-bg height-rules-item mt-14" >
                <div className="flex item1-rules">
                    <FontAwesomeIcon icon={regular("scroll-old")} className={"c-white fs-18 ml-20"} /> 
                    <p className="fs-16 c-white ml-10">{"GIM Legal Contract"}</p>
                </div>
                <div className="flex flex-1"></div>
                {
                    accessEdit &&
                    <button onClick={editContract} className="pointer">
                        <FontAwesomeIcon icon={solid("pen")} className={"c-white fs-18 mr-20"} /> 
                    </button>
                }

                {
                    accessDelete &&
                    <button onClick={deleteContract} className="pointer">
                        <FontAwesomeIcon icon={solid("trash")} className={"c-white fs-18 mr-20"} /> 
                    </button>
                }
            </div>


        </div>
    )
}

export default React.memo( RowRules )