import React, { useState } from 'react'
import "./CardUserReport.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import LoadingSpinner from '../../../../../../LoadingSpinner'
import { GimGimmer } from '../../../../../../../utils/requestsUtils/functionsRequests/offers/offersFunctions'
import { useTranslation } from 'react-i18next'

type CardUserReportProps = {
    typeReport: "Requested_By" | "User_Involved"
    name: string
    gimStars: string
    gimmerStars: string
    email: string
    imgUrl: string
    type: GimGimmer
    loading: boolean
}

type UserData = {
    name: string
    gimStars: string
    gimmerStars: string
    email: string
}

const CardUserReport = ({ 
    typeReport,
    type,
    name,
    gimStars,
    gimmerStars,
    email,
    imgUrl,
    loading
 }: CardUserReportProps) => {     
    
    const { t } = useTranslation()


    return (
        <div className="card-support border-radius-6">
    
            <div className="p-relative">

                { loading && <div  className="pb-14 pt-14" > <LoadingSpinner fullPage={false} backgroundColor='#fff' />  </div> }

                <p className="fs-12 c-oslo-gray">{t(`Reports:user:${typeReport}`)}</p>

                <div className="flex mt-20">

                    <img 
                        alt="user"
                        src={imgUrl ?? "/user.svg"} 
                        className="img-6rem-circle" 
                        onError={(e) => {
                            //(e.target as HTMLImageElement).src = "/user.svg" }
                            //setImgUri("/user.svg" )
                        }}
                    />

                    <div className="ml-16">
                        <div className="center" style={{ height: "6rem" }}>
                            <p className={`fs-15 fw-500  ${type === "Gim" ? "color-gim" : "color-gimmer"}`} >{name}</p>
                        </div>
                        <div className="flex">
                            <div className="">
                                <div className="flex flex-ai-center">
                                    <p className="fs-13 color-text">{gimStars}</p>
                                    <FontAwesomeIcon icon={solid("star")} className="fs-14 color-gim" />
                                </div>
                                <p className="fs-13 color-text">{"Gim"}</p>
                            </div>

                            <div className="ml-40">
                                <div className="flex flex-ai-center">
                                    <p className="fs-13 color-text">{gimmerStars}</p>
                                    <FontAwesomeIcon icon={solid("star")} className="fs-14 color-gimmer" />
                                </div>
                                <p className="fs-13 color-text">{"Gimmer"}</p>
                            </div>
                        </div>    
                    </div>       

                </div>         

                <p className="fs-13 color-text mt-6">{`Email: ${email}`}</p>
            </div>

        </div>
    )
}

export default React.memo( CardUserReport )