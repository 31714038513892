import React from 'react'
import "./TransactionsCard.css"
import TransactionItem from './TransactionItem/TransactionItem'
import moment from 'moment'
import { HistoryTransaction } from '../../../../utils/dbModels/HistoryTransaction'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import { getS3ImgUri } from '../../../../utils/staticVariables/staticVariables'
import { useTranslation } from 'react-i18next'

type TransactionsCardProps = {
    lastFourTransactions: HistoryTransaction[]
}

const TransactionsCard = ({ lastFourTransactions }: TransactionsCardProps) => {

    const { t } = useTranslation()
    const userId = useSelector((state: RootState) => state.auth.user.id)

    return (
        <div className='transactions-card mt-30'>
            <p className="fs-14 fw-700">{t("Profile:transaction:label_06")}</p>
            <div className="flex mt-20">

                {
                    lastFourTransactions.map((h: HistoryTransaction) => {
                        return(
                        <TransactionItem
                            key={h.id}
                            date={h.schedule.startTime}
                            jobName={h.skill.jobName}
                            money={h.gimProfit?.toFixed(2) ?? 0}
                            state={h.state}
                            imgKey={`${getS3ImgUri(h.skill.imgKey)}_100`}
                            type={userId === h.gimId ? "Gim" : "Gimmer"}
                        />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default React.memo( TransactionsCard )