import React, { useState } from 'react'
import "./ButtonsMoveReport.css"
import { RootState, useAppDispatch } from '../../../../../../redux/store/store'
import { useSelector } from 'react-redux'
import { banUserButtonPressedReducer, openIncidentReducer, setAfterAcceptRefuseMessageReducer, setAfterMoveBackUp, setErrorMessageSupportChanged, setLoadingUserReportReducer } from '../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import { moveBackUpWeb, openIncidentWeb, updateAcceptRefuseUserReport } from '../../../../../../utils/requestsUtils/functionsRequests/reports/user/reportsFunctions'
import LoadingSpinner from '../../../../../LoadingSpinner'
import { AdminRole, getHierarchyNumberOfSupport } from '../../../../../../utils/verifications/strings'
import { useTranslation } from 'react-i18next'

const ButtonsMoveReport = () => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)

    const myUserId = useSelector((state: RootState) => state.auth.user.id)
    const userAssigned = useSelector((state: RootState) => state.manageUserReports.userReport.userAssigned)
    const hierarchySupport = useSelector((state: RootState) => state.manageUserReports.userReport.support)

    const openIncident = useSelector((state: RootState) => state.manageUserReports.userReport.openIncident)
    const id = useSelector((state: RootState) => state.manageUserReports.userReport.id)

    const roleId = useSelector((state: RootState) => state.auth.user.roleId)

    const hierarchyRole = getHierarchyNumberOfSupport(roleId)

    const onOpenIncident = () => {
        setLoading(true)
        openIncidentWeb(id)
        .then(({ newMovement }) => {
            dispatch( openIncidentReducer(newMovement) )
        }).catch(() => {
            dispatch( setErrorMessageSupportChanged(true) )
        }).finally(() => {
            setLoading(false)
        })

    }

    const onMoveBackUp = async() => {
        //await dispatch( MoveBackUpThunk({ id, timestamp }) )
        setLoading(true)
        moveBackUpWeb(id)
        .then(() => {
            //alert("General:Report moved back up succesfully")
            //navigate(-1)
            dispatch( setAfterMoveBackUp(true) )
        }).catch(() => {
            dispatch( setErrorMessageSupportChanged(true) )
        }).finally(() => {
            setLoading(false)
        })

        /*
        if(currentSupport === "Support_1")
            dispatch( changeSupportReducer("Support_2") )
        else if(currentSupport === "Support_2")
            dispatch( changeSupportReducer("Support_3") )
        else if(currentSupport === "Support_3")
            dispatch( changeSupportReducer("Support_Admin") )
        */
    }

    const onBanUser = () => {
        dispatch( banUserButtonPressedReducer() )
    }

    const acceptReport = () => {
        setLoading(true)
        updateAcceptRefuseUserReport(id, "ACCEPTED")
        .then(() => {
            dispatch( setAfterAcceptRefuseMessageReducer() )
        }).catch(() => {

        }).finally(() => {
            setLoading(false)
        })
    }

    const refuseReport = () => {
        setLoading(true)
        updateAcceptRefuseUserReport(id, "DECLINED")
        .then(() => {
            dispatch( setAfterAcceptRefuseMessageReducer() )
        }).catch(() => {
            
        }).finally(() => {
            setLoading(false)
        })
    }

    if(loading) return  <div className="center" style={{ height: "10rem", width: "100%" }}><LoadingSpinner fullPage={false} /> </div>

    if(myUserId !== userAssigned && roleId !== AdminRole) return(<></>)

    return(
        <div className="center mt-50">

            <button 
                onClick={refuseReport}
                className="btn-move-report refuse-btn fw-600 fs-13"
            >
                {t("Reports:user:label_09")}
            </button>

            <button 
                onClick={acceptReport}
                className="btn-move-report accept-btn fw-600 fs-13"
            >
                {t("Reports:user:label_10")}
            </button>

            {
                !openIncident && hierarchyRole > 1 &&
                <button 
                    onClick={onOpenIncident} 
                    className="btn-move-report open-incident-btn fw-600 fs-13"
                >
                    {t("Reports:user:label_11")}
                </button>
            }

            {
                hierarchySupport !== 4 &&
                <button 
                    onClick={onMoveBackUp}
                    className="btn-move-report back-up-btn fw-600 fs-13"
                >
                    {t("Reports:user:label_12")}
                </button>
            }
            
            {
                (hierarchySupport === 3 || hierarchySupport === 4 ) &&
                <button 
                    onClick={onBanUser}
                    className="btn-move-report ban-btn fw-600 fs-13"
                >
                    {t("Reports:user:label_13")}
                </button>
            }
        </div>
    )
}

export default React.memo( ButtonsMoveReport )