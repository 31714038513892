import React, { useCallback, useState } from 'react'
import ArrowGoBackButton from '../../../../../../components/UIcomponents/ArrowGoBackButton/ArrowGoBackButton'
import { useNavigate } from 'react-router-dom'
import MyCheckboxCircular from '../../../../../../components/UIcomponents/MyCheckboxCircular/MyCheckboxCircular'
import { useTranslation } from 'react-i18next'

const DeletionReasonScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [optionSelected, setOptionSelected] = useState("")
    const [options] = useState([
        t("Profile:deleteAccount:label_11"),
        `${t("Profile:deleteAccount:label_12")} 01`,
        `${t("Profile:deleteAccount:label_12")} 02`,
        `${t("Profile:deleteAccount:label_12")} 03`,
        `${t("Profile:deleteAccount:label_12")} 04`,
        t("Profile:deleteAccount:label_13")
    ])
    const [textAreaValue, setTextAreaValue] = useState("")

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )

    const onChange = useCallback(
        (option: string) => {
            setOptionSelected(option)
        },
    [])

    const next = () => {
        navigate(`/my-profile/confirm-deletion?option=${optionSelected}&description=${textAreaValue}`)
    }

    return (
        <div className="pl-10 width-50">
            <ArrowGoBackButton goBack={goBack} />
            <p className="fs-20 fw-600 color-text mt-30">{t("Profile:deleteAccount:label_01")}</p>
            <p className="fs-14 color-text mt-8 mb-20">{t("Profile:deleteAccount:label_14")}</p>
            {
                options.map((option, index) => {
                    return(
                        <div key={index} className="flex flex-ai-center mb-10">
                            <MyCheckboxCircular 
                                value={option}
                                valueCheckBox={option === optionSelected}
                                onChange={onChange}
                                size={"1.6rem"}
                                sizeInner={".8rem"}
                                backgroundColor={"#FF2121"}
                                borderThickness="2px"
                                borderColor="#3A4153"
                            />
                            <p className="fs-13 color-text ml-8">{option}</p>
                        </div>
                    )
                })
            }

            <p className="fs-13 color-text  mt-20">{t("Profile:deleteAccount:label_15")}</p>
        
            <textarea 
                className="athens-gray-bg border-radius-3 width-100 mt-20 p-8 fs-13 color-text resize-none"
                style={{ height: "12rem" }}
                value={textAreaValue}
                onChange={(e) => setTextAreaValue(e.target.value)}
            />


            <div className="flex flex-jc-end mt-20">
                <button 
                    onClick={next} 
                    disabled={!optionSelected}
                    type="button" 
                    className={`dark-gray-bg border-radius-3 center pointer ${!optionSelected ? "disable-btn" : ""}`}
                    style={{ width: "15rem", height: "3.7rem" }}
                >
                    <p className="fs-15 fw-500 c-white">{`${t("Profile:deleteAccount:label_16")}...`}</p>
                </button>
            </div>
        </div>
    )
}

export default DeletionReasonScreen