import React from 'react'
import "./NextButton.css"

type NextButtonProps = {
    onPress: () => void
    text: string
    disabled?: boolean 
}

const NextButton = ({ onPress, text, disabled  }: NextButtonProps) => {
    return (
        <button 
            disabled={disabled} 
            onClick={onPress} 
            className={`border-radius-3 button-signup-next ${disabled ? "disable-btn" : "pointer dark-gray-bg"}`} 
        >
            <p className="c-white fs-20-vh">{text}</p>
        </button>
    )
}

export default NextButton