
import React from 'react'
import { WorkPreference } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import "./WhereIconsSeparated.css"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseUser, faLaptop, faWalking } from '@fortawesome/pro-solid-svg-icons'
import { Colors } from '../../../utils/colors/colors'

type WhereIconsSeparatedProps = {
    workPreference: WorkPreference
    size: number
    unit: string
    backgroundColor?: string
}

const WhereIconsSeparated = ({ workPreference, size, unit, backgroundColor = Colors.TEXT }: WhereIconsSeparatedProps) => {

    const { outside, inside, remote } = workPreference

    const sizeBg = `${size}${unit}`
    const sizeIcon = `${size*0.5}${unit}`

    return (
        <div className="flex">

            {
                remote &&
                <div className="wp-icon-1 center circle c-white dark-gray-bg" style={{ width: sizeBg, height: sizeBg, backgroundColor }}>
                    <FontAwesomeIcon icon={faLaptop} style={{ fontSize: sizeIcon }} />
                </div>
            }

            {
                inside.address &&
                <div className="wp-icon-2 center circle c-white dark-gray-bg" style={{ width: sizeBg, height: sizeBg, backgroundColor }}>
                    <FontAwesomeIcon icon={faHouseUser} style={{ fontSize: sizeIcon }} />
                </div>
            }

            {
                outside.address &&
                <div className="center circle c-white dark-gray-bg" style={{ width: sizeBg, height: sizeBg, backgroundColor }}>
                    <FontAwesomeIcon icon={faWalking} style={{ fontSize: sizeIcon }} />                
                </div>
            }

        </div>
    )
}

export default React.memo( WhereIconsSeparated )