import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { GetUserByIdThunk, GetUsersByStringThunk, GetUsersThunk } from '../../../../redux/slices/components/ManageUsers/manageUsersDataSlice'
import { resetManageUsersModalsReducer } from '../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { deleteUser } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { isUUID } from '../../../../utils/verifications/uuid/uuid'
import LoadingSpinner from '../../../LoadingSpinner'
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal'
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'

const DeleteUserModal = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const { id, firstName, lastName } = useSelector((state: RootState) => state.manageUsersModals.deleteUserModal)
    const { usersIds, limit, stringToSearch, page } = useSelector((state: RootState) => state.manageUsersData)
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true)

        deleteUser(id).then((res) => {
            //dispatch(removeDeletedUserReducer({ id }))
            let lastKey = ""
            if(page>1)
                lastKey = usersIds[((page - 1) * limit) - 1]
            
            if(stringToSearch){
                if(isUUID(stringToSearch)){
                    //dispatch(GetUserByIdThunk({ id: stringToSearch }))
                }else{
                    dispatch(GetUsersByStringThunk({ LastEvaluatedKey: lastKey, page, limit, bringTotal: true, stringToSearch}))
                }
            }
            else
                dispatch(GetUsersThunk({ LastEvaluatedKey: lastKey, page, limit, bringTotal: true}))

            dispatch(resetManageUsersModalsReducer())
        }).finally(() => { setLoading(false)})
    }

    const handleClose = () => {
        dispatch(resetManageUsersModalsReducer())
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>
            <TitleModal title={t("General:label_010")} />
            <DescriptionModal description={`${t("General:label_140")} ${firstName} ${lastName} ${t("General:label_141")}`} />
            
            <div className="flex flex-jc-end mt-40">
                <button onClick={handleClose} type="button" className="cancel-btn-modal pointer">
                    {t("General:label_015")}
                </button>
                <ConfirmButton text={t("General:label_016")} />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default DeleteUserModal