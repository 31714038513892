import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import GenericNotification from "../GenericNotification";
import { Colors } from "../../../../utils/colors/colors";
import { NotificationProps } from "../../FactoryNotification/FactoryNotification";


interface IdVerificationNotificationProps extends NotificationProps {
	item: {
		id: string, 
		notifMsg: string, 
		timestamp: number, 
		viewed: boolean,
		notifData: {
			approved: string, 
			receivedFromType: string
		},
	}
}

const IdVerificationNotification = ({ item, clickable, onSetNotificationAsView }: IdVerificationNotificationProps ) => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	//const  { openIdModal } = useSelector((state: STORE_TYPE)=>state.modalsState.idModal)
	const { id, notifMsg, timestamp, viewed, notifData: { approved } } = item
	const description = approved === "true" ? t("Notifications:index:label_40") : t("Notifications:index:label_41")

	const touchNotification =  useCallback(
	  () => {
		if(approved !== "true" ){
			//dispatch(showIdModalAction(!openIdModal))
		}
		if(!viewed)
			onSetNotificationAsView(id)
	  },
	  [],
	)
	
	return(
		<GenericNotification 
			touchNotification={touchNotification} 
			
			id={id}
			timestamp={timestamp}
			viewed={clickable ? viewed : true}
			description={description}
			title={approved === "true" ? t("Notifications:index:label_13") : t("Notifications:index:label_39")  }

			icon={approved === "true" ? "IDverified" : "IDinvalid" }
			color={ approved === "true" ? Colors.flagGreen : Colors.secondaryColor }
		/>
	)
}


export default React.memo( IdVerificationNotification )