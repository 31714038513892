import React, { useEffect, useState } from 'react'
import "./ButtonsUserReports.css"
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../redux/store/store'
import { getHierarchyNumberOfSupport } from '../../../utils/verifications/strings'
import { GetUsersReportsThunk } from '../../../redux/slices/components/ManageReports/manageReportsDataSlice'

const ButtonsUserReports = () => {

    const dispatch = useAppDispatch()

    const totalCounterSupports = useSelector((state: RootState) => state.manageUserReports.totalCounterSupports)
    const limit = useSelector((state: RootState) => state.manageUserReports.limit);
    const roleId = useSelector((state: RootState) => state.auth.user.roleId)

    const openIncident = useSelector((state: RootState) => state.manageUserReports.openIncident);
    const gimGimmerAll = useSelector((state: RootState) => state.manageUserReports.gimGimmerAll);
    const userId = useSelector((state: RootState) => state.manageUserReports.userId);
    const supportSent = useSelector((state: RootState) => state.manageUserReports.supportSent);
    const justSupportLevel = useSelector((state: RootState) => state.manageUserReports.justSupportLevel);

    const hierarchyRole = getHierarchyNumberOfSupport(roleId)

    const handleGetReports = (support: number) => {
        dispatch(GetUsersReportsThunk({ 
            page: 1, 
            limit, 
            bringTotal: true, 
            bringAllSupportsCounter: true, 
            gimGimmerAll,
            openIncident,
            userId,
            supportSent: support,
            justSupportLevel: true
        }))
    }

    return (
        <div className="flex ml-16">

            {
                hierarchyRole >= 1 &&
                <div className="p-relative">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterSupports?.Support_1 ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports(1)} className="btn-pending fs-14 fw-600 pr-16 pl-16 border-radius-6">Support 1</button>
                </div>
            }

            {
                hierarchyRole >= 2 &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterSupports?.Support_2 ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports(2)} className="btn-pending fs-14 fw-600 pr-16 pl-16 border-radius-6">Support 2</button>
                </div>
            }

            {
                hierarchyRole >= 3 &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterSupports?.Support_3 ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports(3)} className="btn-pending fs-14 fw-600 pr-16 pl-16 border-radius-6">Support 3</button>
                </div>
            }

            {
                hierarchyRole >= 4 &&
                <div className="p-relative ml-16">
                    <div className="p-absolute error-bg pt-3 pb-3 pl-6 pr-6 border-radius-8" style={{ right: "-1rem", top: "-1rem" }}>
                        <p className="c-white fw-600 fs-11" >{totalCounterSupports?.Support_Admin ?? 0}</p>
                    </div>
                    <button onClick={() => handleGetReports(4)} className="btn-pending fs-14 fw-600 pr-16 pl-16 border-radius-6">Admin</button>
                </div>
            }


        </div>
    )
}

export default React.memo( ButtonsUserReports )