interface DescriptionModalProps {
    description: string
}

const DescriptionModal = ({ description }:DescriptionModalProps) => {
  return (
    <p className="fs-16 mt-10 color-oslo">{ description }</p>
  )
}

export default DescriptionModal