import { useEffect, useState } from "react"
import "./HomeScreen.css"
import { getOffersPublic, GimGimmer } from "../../utils/requestsUtils/functionsRequests/offers/offersFunctions"
import ContainerOfferCards from "../../components/Home/ContainerOfferCards/ContainerOfferCards"
import { RootState, useAppDispatch } from "../../redux/store/store"
import { GetOffersHomeThunk } from "../../redux/slices/components/HomeOffers/homeOffersSlice"
import { useSearchParams } from "react-router-dom"
import { isUUID } from "../../utils/verifications/uuid/uuid"
import { canBeInteger } from "../../utils/verifications/strings"
import TwoSelectorBarGimGimmer from "../../components/Home/TwoSelectorBarGimGimmer/TwoSelectorBarGimGimmer"
import SearchSelectors from "../../components/Home/SearchSelectors/SearchSelectors"
import { getAllJobsCategoriesChunk } from "../../redux/slices/components/ManagerJobs/managerJobDataSlice"
import { isValidISO8601 } from "../../utils/dates/dates"
import moment from "moment"
import { useSelector } from "react-redux"
import ButtonOrder from "../../components/Home/ButtonOrder/ButtonOrder"

type HomScreenProps = {

}

const HomeScreen = ({  }: HomScreenProps ) => {

    const dispatch = useAppDispatch()

    const [searchParams] = useSearchParams();

    const optionSelectedWhere = useSelector((state: RootState) => state.homeOffers.optionSelectedWhere)

    const remote = searchParams.get("remote")
    useEffect(() => {

        const pageParam = searchParams.get("page") ?? "1"
        const limitParam = searchParams.get("limit") ?? "10"
        const jobIdParam = searchParams.get("jobId") ?? undefined
        const startTimeParam = searchParams.get("startTime") ?? undefined
        const endTimeParam = searchParams.get("endTime") ?? undefined
        const typeParam = searchParams.get("type") ?? "Gim"
        const outsideParam = searchParams.get("outside") ?? undefined
        const insideParam = searchParams.get("inside") ?? undefined
        const remoteParam = searchParams.get("remote") ?? undefined

        let type: GimGimmer = "Gim"
        if(typeParam === "Gimmer")
            type = "Gimmer"

        let page = 10
        if( canBeInteger(pageParam) )
            page = Number(pageParam)

        let limit = 10
        if( canBeInteger(limitParam) )
            limit = Number(limitParam)
        
        let jobId = undefined
        if( jobIdParam && isUUID(jobIdParam) )
            jobId = jobIdParam

        let startTime = undefined
        if( startTimeParam && isValidISO8601(startTimeParam))
            startTime = startTimeParam

        let endTime = undefined
        if( endTimeParam && isValidISO8601(endTimeParam))
            endTime = endTimeParam
        else if(startTime){
            endTime = moment(startTime).endOf("day").toISOString()
        }

        let outside = undefined
        try {
            if( outsideParam )
                outside = JSON.parse( outsideParam )
        } catch (error) {
            
        }

        let inside = undefined
        try {
            if( insideParam )
                inside = JSON.parse( insideParam )
        } catch (error) {
            
        }

        let remote = undefined
        try {
            if( remoteParam )
                remote = Boolean( JSON.parse( remoteParam ) )
        } catch (error) {
            
        }

        dispatch( GetOffersHomeThunk({ type, page, limit, jobId, when: { startTime: startTime ?? "", endTime }, outside, inside, remote }) )
        dispatch( getAllJobsCategoriesChunk() )
      
    }, [
        searchParams.get("type"), 
        searchParams.get("jobId"), 
        searchParams.get("limit"), 
        searchParams.get("page"), 
        searchParams.get("startTime"), 
        searchParams.get("endTime"), 
        searchParams.get("outside"),
        searchParams.get("inside"),
        remote
    ])


    const initialSide = searchParams.get("type") ? 
                            (searchParams.get("type") === "Gim" ? "left" : "right") 
                            : "left"
    return (
        <div className="container-home-screen p-relative">
            <div className="aux-scroll">
                { 
                // loadingAuthUser && <LoadingSpinner fullPage={true} />
                }
                <div className="center mt-20 mb-20">
                    <SearchSelectors />
                </div>
                <div className="mb-50 mt-40 flex flex-jc-sb flex-ai-center">
                    <TwoSelectorBarGimGimmer initialSide={initialSide} />
                    <ButtonOrder />
                </div>
                {
                    <ContainerOfferCards />
                }
            </div>
        </div>
    )
}

export default HomeScreen