import React, { useEffect, useState } from 'react'
import { ProductInfo } from '../../../../utils/chats/ChatFunctions'
import { getProfilePictureByUserId } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux/store/store'
import { useTranslation } from 'react-i18next'
import "./ScrollProductCard.css"
import { ProductDB } from '../../../../redux/slices/components/Products/ProductsSlice'
import { getMinimalUserInfoData, S3DomainGimwork_public_files, UserInfoCache, UserInfoCacheType } from '../../../../utils/staticVariables/staticVariables'
import { getLinkToDownloadFileProduct, getLinkToDownloadFileProductOtherUser } from '../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions'
import useGetJobName from '../../../hooks/useGetJobName/useGetJobName'

type ScrollProductCardProps = {
    product: ProductDB
}

const ScrollProductCard = ({ product }: ScrollProductCardProps) => {

    const { t } = useTranslation()

    const [showExpandedIdImage, setShowExpandedIdImage] = useState<boolean>(false);
    const [cerImgUrl, setCerImgUrl] = useState<string>("")
    const [profileImgeUrl, setProfileImgeUrl] = useState<string>("")
    const [userName, setUserName] = useState("")

    const [loading, setLoading] = useState(false)

    const [imagePhysicalProductUrl, setImagePhysicalProductUrl] = useState("")

    const jobName = useGetJobName(product.jobId)


    useEffect(() => {

        const getData = () => {
            const user = UserInfoCache.userNameCache.get(product.userId)

            if(user?.name){
                  setUserName(user.name)
              }else{
                getMinimalUserInfoData(product.userId)
                .then((userInfo: UserInfoCacheType) => {
                    UserInfoCache.addNameInfo(product.userId, { name: userInfo.name, phoneNumber: userInfo.phoneNumber, mail: userInfo.mail, gimmerScore: userInfo.gimmerScore, gimScore: userInfo.gimScore })
                    setUserName(userInfo.name)
                }).catch(() => {})
            }

            getProfilePictureByUserId(product.userId, "small")
            .then((url) => {
                setProfileImgeUrl(url)
            }).catch(() => {
    
            })

            getLinkToDownloadFileProductOtherUser(product.userId, product.id, product.productName, "Marketplace")
            .then((url) => {
                setImagePhysicalProductUrl(url)
            }).catch(() => {
    
            })
          
        }

        getData()

    }, [])

    return (
        <div className="scroll-product-card flex width-100">

            <div className="file-img-card">
                {
                    product.typeProduct === "PHYSICAL" ?
                        <img 
                            src={`${S3DomainGimwork_public_files }/${product.userId}/${product.id}/physical_img`}
                            alt="" 
                            style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        />
                    :
                    <div className="width-100 height-100 file-image-scroll-product center">
                        <p className="fs-28-vh fs-700">{"File"}</p>
                    </div>
                }
            </div>

            <div className="flex flex-d-column flex-jc-sb ml-16">
                
                <div className="">
                    <p className="fs-18-vh fw-600 text-3-points-two-lines">{product.productName}</p>
                    <p className="fs-16-vh mt-4 text-3-points">{jobName}</p>
                </div>

                <div className="flex flex-ai-center">
                    <img src={profileImgeUrl} className="circle" style={{ width: "5.5vh", height: "5.5vh" }} alt="user" />  
                    <div className="ml-10">
                        <p className="fs-18-vh fw-600 text-3-points-two-lines">{`${userName}`}</p>
                        <p className="fs-16-vh mt-4">{"From: México"}</p>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ScrollProductCard