import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

type StateColumnManageJobsProps = {
    state: boolean
}

const StateColumnManageJobs = ({ state }: StateColumnManageJobsProps) => {
    return (
        state
        ? <FontAwesomeIcon icon={solid("check")} className={"c-black"} />
        : <FontAwesomeIcon icon={regular("times")} className={"c-manatee"} />
    )
}

export default React.memo( StateColumnManageJobs )