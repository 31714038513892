import React from 'react'
import SelectorBarRequestProducts from './SelectorBarRequestProducts/SelectorBarRequestProducts'


const ContainerSelectorRequestProduct = () => {

    return (
        <div className="flex flex-ai-center flex-jc-sb">
            <SelectorBarRequestProducts />
        </div>
    )
}

export default React.memo( ContainerSelectorRequestProduct )