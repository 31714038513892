import React from 'react'

type LoadingSpinnerProps = {
  fullPage: boolean,
  backgroundColor?: string,
  spinnerColor?: string
  mini?: boolean
}

const LoadingSpinner = ({ fullPage, backgroundColor = "#ffffffaa", mini }: LoadingSpinnerProps) => {
  return (

        <div 
            className={`center ${fullPage ? "container-full-page-spinner" : "container-fit-spinner"}`}
            style={{ backgroundColor, zIndex: 2, top: 0, left: 0 }}
          >
            <div className={ mini ? "loading-spinner-mini" : "loading-spinner"} >
            </div>
        </div>
  )
}

export default React.memo( LoadingSpinner )