import React from 'react'
import ButtonBack from '../../ManageAllOffers/DetailedOfferScreen/ButtonBack/ButtonBack'
import LeftHistoryTransaction from './LeftHistoryTransaction/LeftHistoryTransaction'
import RightHistoryTransaction from './RightHistoryTransaction/RightHistoryTransaction'
import "./HistoryTransaction.css"
import { RootState } from '../../../redux/store/store'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const HistoryTransaction = () => {

    const { t } = useTranslation()

    const historyId = useSelector((state: RootState) => state.myHistoryData.historyTransaction.id)

    return (
        <div className="con-history-transaction">
            <p className="my-history-title mb-10">{t("History:label_01")}</p>
            <ButtonBack />
            {
                historyId ? 
                <div className="flex">
                    <LeftHistoryTransaction />
                    <RightHistoryTransaction />
                </div>
                :
                <div className="flex center height-100">
                    <p className="fs-24">{t("History:label_10")}</p>
                </div>
            }
        </div>
    )
}

export default React.memo( HistoryTransaction )