import React, { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '../../../../../redux/store/store';
import { resetManagerJobsGroupedDataSlice } from '../../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice';
import { getAllJobsCategoriesChunk } from '../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import { useSelector } from 'react-redux';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import SimulatorJobs from '../../../../../components/ManagerJobs/SimulatorJobs/SimulatorJobs';

const SimulatorScreen = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [searchParams] = useSearchParams();

    const loading = useSelector((state: RootState) => state.managerJobData.loading);

    useEffect(() => {
        // Get a specific parameter value
        const jobIdParam = searchParams.get("jobId") ?? ""
        const countryParam = searchParams.get("country") ?? ""

        dispatch(getAllJobsCategoriesChunk())

        return () => {
            dispatch(resetManagerJobsGroupedDataSlice())
        }

    }, [searchParams.get("jobId"), searchParams.get("country")])



    return (
        <div>
            <SimulatorJobs />
            { loading && <LoadingSpinner fullPage={false} backgroundColor="#fff" /> }
        </div>
    )
}

export default SimulatorScreen