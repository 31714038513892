import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import "./ChatHistoryTesterDeveloper.css"
import ChatTesterDeveloper from './ChatTesterDeveloper/ChatTesterDeveloper'
import HistoryTesterDeveloper from './HistoryTesterDeveloper/HistoryTesterDeveloper'

const ChatHistoryTesterDeveloper = () => {

    const { t } = useTranslation()
    const [tabSelected, setTabSelected] = useState<"history" | "chat">("history")

    return (
        <div className="height-100 width-100" style={{ height: "44vh" }}>

            <div className="flex mt-14 width-100">
                <div
                    onClick={() => setTabSelected("history")}
                    className={`tab-info-user-report-bug athens-gray-bg p-relative mr-8 ${tabSelected !== "history" ? "pointer opacity-5" : ""}`}
                >
                    <p className="fs-13 fw-700">{"Historial"}</p>
                    
                    { tabSelected === "history" && <div className="line-aux-tab width-100 p-absolute athens-gray-bg"></div> }

                </div>

                <div 
                    onClick={() => setTabSelected("chat")}
                    className={`tab-info-user-report-bug athens-gray-bg p-relative ${tabSelected === "history" ? "pointer opacity-5" : ""}`}
                >
                    <p className="fs-13 fw-700">{"Chat"}</p>
                    { tabSelected !== "history" && <div className="line-aux-tab-bug width-100 p-absolute athens-gray-bg"></div> }
                </div>
            </div>


            <div className="width-100 athens-gray-bg container-content-tab-bug height-100" >
                {
                    tabSelected === "history" ?

                        <HistoryTesterDeveloper />
                        :
                        <ChatTesterDeveloper />
                }
            </div>

        </div>
    )
}

export default React.memo( ChatHistoryTesterDeveloper )