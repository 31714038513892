import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal'
import "./AddNewJobModal.css"
import FormAddNewJobModal from './FormAddNewJobModal/FormAddNewJobModal';
import React from 'react';


const AddNewJobModal = () => {
  
  const dispatch = useAppDispatch()
  const { t } = useTranslation();

  const closeModal = () => {
    dispatch(resetValueFromState())
  }
  const categoryId = useSelector((state: RootState) => state.managerJobModals.AddNewJobModal.categoryId);
  const categoryName = useSelector((state: RootState) => state.managerJobModals.AddNewJobModal.categoryName);
  const imgKey = useSelector((state: RootState) => state.managerJobModals.AddNewJobModal.imgKey);

  return (
    <div className="blank-wrapper-modals flex flex-d-column p-relative" style={{ width: "96vw", height: "96vh" }}>

        <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
            <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
        </button>

        <TitleModal title={`${t("General:label_092")} ${categoryName} ${t("General:label_093")}`} />


        <FormAddNewJobModal 
            categoryId={categoryId}
            imgKey={imgKey}  
        /> 

    </div>
  )
}

export default React.memo( AddNewJobModal )