import React, { useCallback } from 'react'
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import FormDeleteContractModal from './FormDeleteContractModal/FormDeleteContractModal';
import { useSelector } from 'react-redux';
import { resetModalsValueFromRulesState } from '../../../../redux/slices/components/Rules/rulesSlice';

const DeleteContractModal = () => {
    const dispatch = useAppDispatch()
    const { t } = useTranslation();

    const contractInfo = useSelector((state: RootState) => state.rules.DeleteContractModalState);
  
    const closeModal = useCallback(
      () => {
          dispatch(resetModalsValueFromRulesState())
      },
      [],
    )
  
  
    return (
      <div className="blank-wrapper-modals flex flex-d-column p-relative" >
          <button onClick={closeModal} className="p-absolute close-modal-icon pointer">
              <FontAwesomeIcon  icon={solid("times")} className="c-manatee fs-14"/> 
          </button>
          <div className="centerpr-10">
              <TitleModal title={`${"Are you sure you want to delete the contract of "} ${contractInfo.countryName}`} />
          </div>
          <FormDeleteContractModal closeModal={closeModal} countryName={contractInfo.countryName} />
      </div>
    )
}

export default DeleteContractModal