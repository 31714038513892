// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { requestNotificationPermission } from "../Permissions/permissions";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const PERMISSION_NOTIFICATIONS_DENIED = "PERMISSION_NOTIFICATIONS_DENIED"

export const messaging = getMessaging(app);

export const getTokenFirebase = () => {
    return new Promise((resolve: (value: string) => void, reject) => {
        //requestNotificationPermission()
        //.then(() => {
            getToken(messaging, {vapidKey: process.env.REACT_APP_VAPID_KEY})
            .then((currentToken) => {
                if (currentToken) {
                    // Send the token to your server and update the UI if necessary
                    // ...
                    resolve(currentToken)
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    // ...
                    reject("REQUEST_PERMISSION_NOTIFICATION_FIRST")
                }
            }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
                if(err.code === "messaging/permission-blocked"){
                    resolve("")
                    return
                }
                reject(err)
                // ...
            })
        //}).catch((error) => {
          console.log("requestNotificationPermission")
            //reject(error)
        //})
    })
}


export const setupNotifications = async () => {
    try {
      // Request permission for notifications
      const permission = await Notification.requestPermission();
      
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        // Get the FCM token
        const token = await getToken(messaging);
        console.log('FCM Token:', token);
      } else {
        console.log('Notification permission denied.');
      }
      // Handle foreground notifications
      onMessage(messaging, (payload) => {
        console.log('Foreground Message:', payload);
        // Handle the notification or update your UI
      });
    } catch (error) {
      console.error('Error setting up notifications:', error);
    }
  };


  export const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
  });