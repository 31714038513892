import React from 'react'
import "./BlockRemote.css"
import BlockType from '../BlockType/BlockType'
import RemoteImage from '../../../UIcomponents/RemoteImage/RemoteImage'

const BlockRemote = () => {
    return (
        <div className="remote-block">
            <BlockType type="remote">
                <p className="fs-18-vh ml-10">{"Remote"}</p>
            </BlockType>

            <div className="flex-1">
            <RemoteImage />
            </div>
        </div>
    )
}

export default React.memo( BlockRemote )