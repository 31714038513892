import React, { useCallback, useEffect } from 'react'
import ArrowGoBackSignUp from '../../../../components/SignUp/ArrowGoBackSignUp/ArrowGoBackSignUp'
import NextButton from '../../../../components/UIcomponents/NextButton/NextButton'
import { useNavigate } from 'react-router-dom'
import { getAllJobsCategories } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions'
import { Colors } from '../../../../utils/colors/colors'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CategoryJob } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { S3DomainGimwork_categories_logo } from '../../../../utils/staticVariables/staticVariables'
import { RootState, useAppDispatch } from '../../../../redux/store/store'
import { selectCategoryJobs, setCategoryJobs } from '../../../../redux/slices/components/SignUp/SignUpSlice'
import { useSelector } from 'react-redux'
import ModalSelectJob from '../../../../components/SignUp/SkillGim/SelectSkill/ModalSelectJob/ModalSelectJob'
import SearchBarSkills from '../../../../components/SignUp/SkillGim/SelectSkill/SearchBarSkills/SearchBarSkills'
import { useTranslation } from 'react-i18next'

const SelectSkillScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const categories = useSelector((state: RootState) => state.signUp.categoryJobs)

    const onNext = useCallback(
        () => {
            navigate("/welcome")
        },
        [],
    )

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )
    
    useEffect(() => {
      
        getAllJobsCategories(true)
        .then((categories) => {
            dispatch( setCategoryJobs( categories ) )
        }).catch(() => {

        })
        
    }, [])


    const selectCategory = (id: string) => {
        dispatch( selectCategoryJobs(id) )
    }

    window.onpopstate = (event) => {
        console.log(
            `location: ${document.location}, state: ${JSON.stringify(event.state)}`,
          );
    }

    return (
        <div className="width-100  height-100 flex flex-d-column flex-jc-sb" style={{ marginBottom: "0px" }}>

            <div className="flex flex-d-column flex-1" style={{ marginTop: "11.5vh" }}>

                <p className="fs-36-vh c-text">{t("SignUp:selectSkillScreen:label_01")}</p>

                <p className="fs-18-vh c-text" style={{ marginTop: "1.4vh" }}>{t("SignUp:selectSkillScreen:label_02")}</p>

                <div className="flex flex-jc-sb" style={{ marginTop: "4.6vh" }}>
                    <p className="fs-20-vh c-text">{t("SignUp:selectSkillScreen:label_03")}</p>
                    <SearchBarSkills />
                </div>

                <div className="flex" style={{ flexWrap: "wrap", marginTop: "4vh", height: "29vh" }}>
                    {
                        categories.map((category: CategoryJob) => {
                            return(
                                <div 
                                    key={category.id}
                                    onClick={() => selectCategory(category.id)}
                                    className="pointer shadow-box-select-skill flex flex-d-column mr-6 ml-10"
                                    style={{
                                        width: "16.4%",
                                        height: "12.2vh",
                                        backgroundColor: "#3A41531F"
                                    }}
                                >
                                    <img 
                                        src={`${S3DomainGimwork_categories_logo}${category.id}/categoryImg_200`}
                                        style={{
                                            height: "81%",
                                            width: "100%", 
                                            objectFit: "cover"
                                        }}
                                    />
                                    <div className="center flex-1">
                                        <p className="fs-14-vh text-3-points color-text mt-1">{category.categoryName}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <div className="center" >
                    <button
                        style={{
                            border: `1px solid ${Colors.Gim}`,
                            width: "35rem",
                            height: "4.1vh"
                        }}
                        className="border-radius-3 pointer"
                        onClick={() => selectCategory("All")}
                    >
                        <p className="fs-18-vh color-gim">{t("SignUp:selectSkillScreen:label_04")}</p>
                    </button>
                </div>

            </div>

            <div className="flex flex-jc-sb" style={{ marginBottom: "12.2vh" }}>
                <ArrowGoBackSignUp onPress={goBack} />

                <NextButton 
                    onPress={onNext} 
                    text={t("SignUp:selectSkillScreen:label_05")} 
                />
            </div>

            <ModalSelectJob />

        </div>
    )
}

export default SelectSkillScreen