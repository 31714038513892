import axios, { AxiosError } from 'axios';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';

//axios.defaults.withCredentials = true
//axios.defaults.headers.common["authkey"] = process.env.REACT_APP_AUTHKEY;

export const urlSocketAuth = process.env.REACT_APP_URL_WEBSOCKET

//export const domainUrl = "https://localhost:3000"
export const domainUrl = process.env.REACT_APP_DOMAIN_URL

//export const adminApiUrl = "https://o2smy05t2k.execute-api.eu-west-3.amazonaws.com/test/v1"
export const adminApiUrl = process.env.REACT_APP_API

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //console.log("Error Interceptor 403", error)
      if (error?.response?.data?.message === "User is not authorized to access this resource with an explicit deny") {
        // execute your function here
        window.location.href = `${domainUrl}/403`
      }
      return Promise.reject(error);
    }
);

export type UserCredentials = {
	idToken: string
	userId: string
}


export const getUserCredentials = (forceRefresh?: boolean): Promise<UserCredentials> => {
	return new Promise((resolve, reject) => {
        getCurrentUser()
        .then((result) => {
            fetchAuthSession({ forceRefresh: forceRefresh ?? false })
            .then((session) => {
                //console.log("session", session)
                //console.log("session.tokens.idToken", session.tokens?.idToken)
                //console.log("session.tokens.accessToken", session.tokens?.accessToken)
                //console.log("session.tokens.idToken str", session.tokens?.idToken?.toString())
                //console.log("session.tokens.accessToken str", session.tokens?.accessToken.toString())

                if (session.tokens && session.tokens.idToken) {
                    //console.log("token", session.tokens.idToken.toString())
                    resolve({ idToken: session.tokens.idToken.toString(), userId: result.userId })
                } else {
                    reject('TOKENS NOT PRESENT')
                }
            }).catch((ERROR: AxiosError) => {
                console.error('UNAUTHENTICATED USER')
                console.error("fetchAuthSession", ERROR)
                reject(ERROR)
            })
        }).catch((ERROR: any) => {
            console.error('UNAUTHENTICATED USER')
            console.error("Error getCurrentUser", ERROR)
            reject(ERROR)
        });

	})

}

// /v1/user
export const GetUser = (userId: string) => axios.get(`${adminApiUrl}/v1/user/${userId}`)



// offers

export const GetOffersByUsers = (params: any) => axios.get(`${adminApiUrl}/v1/offers/search-by-users`, { params })

export default axios;

