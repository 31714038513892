import React from 'react'
import { DetailsSchedule, TypeOffers } from '../../../redux/slices/components/ManageOffers/manageOffersDataSlice'
import { orderSchedulesByStartDate } from '../../../utils/dates/dates'
import moment from 'moment'
import { capitalizeAllWords } from '../../../utils/strings/strings'
import "./ScheduleResume.css"

type ScheduleResumeProps = {
    type: TypeOffers
    detailsSchedule: DetailsSchedule[]
}

const ScheduleResume = ({ type, detailsSchedule }: ScheduleResumeProps) => {

    const orderSchedule = orderSchedulesByStartDate(detailsSchedule)

    const groupedDates: { [key: string]: DetailsSchedule[] } = {}
    
    orderSchedule.forEach((date) => {
        const isoDate = moment( date.start ).startOf("day").toISOString()
        if( groupedDates[isoDate] ){
            groupedDates[isoDate].push(date)
        }else{
            groupedDates[isoDate] = [ date ]
        }
    })

    return (
        <div className="width-100 mb-30">
            {
                Object.keys(groupedDates).map((key) => {
                    return(
                        <div key={key} className="">
                            <p className="fs-16">{capitalizeAllWords( moment(key).format('dddd, MMMM Do YYYY') )}</p>
                            <div className="flex flex-wrap mt-14 mb-14">
                            {
                                groupedDates[key].map((date) => {
                                    return(
                                        <div key={date.id} className={`${type === "Task" ? "gim-bg" : "gimmer-bg"} border-radius-3 slot-resume center mr-10`}>
                                            <p className="fs-14 c-white">{`${moment(date.start).format('hh:mm')} - ${moment(date.end).format('hh:mm')}`}</p>
                                        </div>
                                    )
                                })
                            }
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default React.memo( ScheduleResume )