import React, { useState, useEffect } from 'react';
import { messaging, onMessageListener } from '../firebase/firebase';
import { onMessage } from 'firebase/messaging';
import { useAppDispatch } from '../../redux/store/store';
import { NotificationUser, setAddNotification } from '../../redux/slices/authSlice';
//import { Toaster, toast } from 'react-hot-toast';


function NotificationComponent() {

    const dispatch = useAppDispatch()

  /*
    const [notification, setNotification] = useState({ title: '', body: '' });
    useEffect(() => {
        //requestPermission();
        const unsubscribe = onMessageListener()
        .then((payload: any) => {

            
            setNotification({
              title: payload?.notification?.title,
              body: payload?.notification?.body,
            });
            
            
            
            toast.success(`${payload?.notification?.title}: ${payload?.notification?.body}`, {
              duration: 60000, 
              position: 'top-right', //section of the browser page
            });

        });
        return () => {
          unsubscribe.catch((err) => console.log('failed: ', err));
        };
    }, []);
*/
    useEffect(() => {
        onMessage(messaging, (payload: any) => {
            console.log('Message received. ', payload);
            const notification: NotificationUser = {
                id:         payload.data.notifId,
                notifData:  payload.data,
                notifMsg:   payload.data.message,
                timestamp:  0,
                viewed:     false
            }
            dispatch( setAddNotification(notification) )
        });
    }, [])
    //console.log("11111111111111111", process.env.REACT_APP_BROADCAST_CHANNEL)
    useEffect(() => {
        const bc = new BroadcastChannel("test_channel");
        
        bc.onmessage = (event) => {
            //console.log("BroadcastChannel", event)
            const notifData = event.data.data
            const notification: NotificationUser = {
                id:         notifData.notifId,
                notifData:  notifData,
                notifMsg:   notifData.message,
                timestamp:  0,
                viewed:     false
            }
            dispatch( setAddNotification(notification) )
        };
    }, [])
    
    

    return (
        <>
        </>
    );                                                                             
}
export default React.memo( NotificationComponent );