import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import ImageSignUp from '../../../components/SignUp/ImageSignUpProcess/ImageSignUpProcess'
import { useTranslation } from 'react-i18next'

const WelcomeScreen = () => {

    const { t } = useTranslation()
    const navigate = useNavigate()


    const onNext = () => {
        //navigate("/my-profile")
        window.location.href = `${process.env.REACT_APP_DOMAIN_URL}/my-profile`
    }

    const goBack = useCallback(
        () => {
            navigate(-1)
        },
        [],
    )
    

    return (
        <div className="width-100 height-100 pr-40 pl-40" style={{ marginBottom: "0px" }}>

            <div className="flex flex-jc-sb flex-ai-center" style={{ marginTop: "11.5vh" }}>
                <div className="" style={{ width: "35%" }}>

                    <p className="fs-36-vh c-text">{t("SignUp:welcomeScreen:label_01")}</p>


                    <p className="fs-22-vh c-text" style={{ marginTop: "2.2vh" }}>{t("SignUp:welcomeScreen:label_02")}</p>

                    <p className="fs-22-vh c-text" style={{ marginTop: "2.2vh" }}>{t("SignUp:welcomeScreen:label_03")}</p>
                    
                    <button
                        style={{
                            height: "6.4vh",
                            marginTop: "7.4vh"
                        }}
                        className="dark-gray-bg border-radius-3 width-100 pointer"
                        onClick={onNext}
                    >
                        <p className="c-white fs-26-vh">{t("SignUp:welcomeScreen:label_04")}</p>
                    </button>

                </div>

                { ImageSignUp["Welcome"] }
            </div>

        </div>
    )
}

export default WelcomeScreen