import React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store/store'
import UserImage from '../../../../../UIcomponents/UserImage/UserImage'
import moment from 'moment'
import "./HistoryTesterDeveloper.css"

const HistoryTesterDeveloper = () => {

    const history = useSelector((state: RootState) => state.manageBugReportsData.bugReport.history) 

    const strings = {
        ACCEPT: { title: "Accept bug", description: "has been assigned to this bug since XXX" }, 
        ASSIGNED: { title: "Bug assigned", description: "has taken the bug since XXX" },
        READY_FOR_TESTING: { title: "Ready for testing", description: "has fixed the bug." },
        FIX_REJECTED: { title: "Fix rejected", description: "did not accept the finalization of the bug." },
        CLOSED: { title: "Bug closed", description: "has approve the closure of the bug." }, 
    }

    return (
        <div className="p-relative height-100">
            <div className="aux-scroll hide-scroll">
                <div className="flex flex-d-column height-100 mt-14">
                    <div className="flex-1 con-history-cards">
                        {
                            history?.map((item) => {
                                const format = moment(item.date).format('D MMMM YYYY');
                                return(
                                    <div key={item.date} className="flex white-bg history-bug-card border-radius-3 mt-10">
                                        <UserImage userId={item.userId} sizeImg={3.5} unit="vh" />
                                        <div className="ml-8">
                                            <p className="fs-16 fw-700">{strings[item.event].title}</p>
                                            <p className="fs-12 mt-4 mb-4">{`${strings[item.event].description.replace(/XXX/, format)}`}</p>
                                            <p className="fs-10 c-manatee">{moment(item.date).fromNow()}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo( HistoryTesterDeveloper )