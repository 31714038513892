import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { getAvailableRolesChunk, resetManageUsersModalsReducer } from '../../../redux/slices/components/ManageUsers/manageUsersModalsSlice';
import { RootState, useAppDispatch } from '../../../redux/store/store';
import DeleteMultipleUserModal from './DeleteMultipleUserModal/DeleteMultipleUserModal';
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';
import EditMultipleUsersRolesModal from './EditMultipleUsersRolesModal/EditMultipleUsersRolesModal';
import EditUserRoleModal from './EditUserRoleModal/EditUserRoleModal';
import SendMessageUserModal from './SendMessageUserModal/SendMessageUserModal';
import useCheckAccess from '../../hooks/useCheckAccess/useCheckAccess';

type ManagerUsersSelectModalProps = {
    modalName: string
}

const ManageUsersModals = () => {
    const dispatch = useAppDispatch()

    const modalName = useSelector((state: RootState) => state.manageUsersModals.modalName);
    const accessEditUserRole = useCheckAccess({ id_Category: "Manage_Users", id_Policy: "Users", id_Action: "Edit" })

    useEffect(() => {
      
      if(accessEditUserRole)
          dispatch( getAvailableRolesChunk() )

    }, [])
    

    const handleClickOutsideModal = (e: any) => {
        const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickOutsideModal && e.target.id === "darkened-background-modal")
            dispatch(resetManageUsersModalsReducer())
    }
  
    return (
      <>
        {
          modalName &&
          <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
            <div id={"background-aux-modal"}>
                <ManagerUsersSelectModal modalName={modalName} />
            </div>
          </div>
        }
      </>
    )
}

const ManagerUsersSelectModal = ({ modalName }: ManagerUsersSelectModalProps) => {
  
      switch(modalName){
        case "DeleteUserModal":
            return (<DeleteUserModal />)
        case "EditUserRoleModal":
            return (<EditUserRoleModal />)
        case "SendMessageUserModal":
            return (<SendMessageUserModal />)
        case "DeleteMultipleUserModal":
            return (<DeleteMultipleUserModal />)
        case "EditMultipleUserRoleModal":
            return (<EditMultipleUsersRolesModal />)
        default:
            return(
              <h1>Error modals</h1>
            )
      }
  }

export default ManageUsersModals