import React from 'react'
import { RootState, useAppDispatch } from '../../../redux/store/store';
import { useSelector } from 'react-redux';
import ModalAddRole from './ModalAddRole/ModalAddRole';
import ModalRemoveRole from './ModalRemoveRole/ModalRemoveRole';
import { openCloseModalRoleReducer } from '../../../redux/slices/components/ManageRoles/manageRolesSlice';

const ManageRolesModals = () => {

    const dispatch = useAppDispatch()
    const modalRole = useSelector((state: RootState) => state.manageRoles.modalRole);
  
    const handleClickOutsideModal = (e: any) => {
        const clickOutsideModal = document.getElementById("background-aux-modal")?.contains(e.target);
        if(!clickOutsideModal && e.target.id === "darkened-background-modal"){
            dispatch( openCloseModalRoleReducer("") )
        }
    }
  
    return (
      <>
        {
            modalRole &&
            <div onClick={(e) => { handleClickOutsideModal(e) }} id={"darkened-background-modal"} className="background-gray center">
                <div id={"background-aux-modal"}>
                    {
                        modalRole === "Add" && <ModalAddRole />
                        || modalRole === "Remove" && <ModalRemoveRole />
                    }
                </div>
            </div>
        }
      </>
    )
}

export default ManageRolesModals