import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../redux/store/store";
import LoadingSpinner from "../../LoadingSpinner";
import { BugReportTableItem, updateSeenBugReportReducer } from "../../../redux/slices/components/ManageBugReports/manageBugReportsDataSlice";
import "./BugReportRows.css"
import BugReportRow from "./BugReportRow/BugReportRow";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { updateBugReportSeenWeb } from "../../../utils/requestsUtils/functionsRequests/reports/app-bug/appBugFunctions";

const BugReportRows = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();

    const bugReportsToShow = useSelector((state: RootState) => state.manageBugReportsData.bugReportsToShow);
    const loadingContainer = useSelector((state: RootState) => state.manageBugReportsData.loadingContainer);

    const clickOnOffer = useCallback(
      (id: string) => {
          //dispatch( setDetailedBugReportReducer({ bugReport }) )
          navigate(`/manage-bug-reports/${id}`)
          updateBugReportSeenWeb( id )
          .then(() => {
              dispatch( updateSeenBugReportReducer({ id: id}) )
          }).catch((error) => {
              console.log("error", error)
          })
          //dispatch(setDetailedOfferReducer({bugReport, imgUrl: userImg}))
      },
        [],
    )
    

    return (
      <div className="container-bug-reports-rows flex flex-1 flex-d-column ff-roboto">
        <div className="aux-scroll-bug-reports">

              { loadingContainer && <LoadingSpinner fullPage={false} /> }
              
              {
                  
                  bugReportsToShow.map((bugReport: BugReportTableItem, index: number) => {
                      return(
                        <BugReportRow
                            key={bugReport.id}
                            bugReport={bugReport}
                            index={index}
                            onPress={clickOnOffer}
                        />
                      )
                  })
                  
              }

          </div>
      </div>
    )
}

export default BugReportRows