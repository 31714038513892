import React, { useEffect, useState } from 'react'
import ListCardsTransactionHistory, { TransactionDate } from '../ListCardsTransactionHistory/ListCardsTransactionHistory'
import { getTransactionHistory } from '../../../utils/requestsUtils/functionsRequests/transactions/history'
import { orderTransactions } from '../../../utils/arrays/transactions'
import { useNavigate } from 'react-router-dom'

const CardsTransactionsHistoryAssistance = () => {

    const navigate = useNavigate()

    const [transactions, setTransactions] = useState<TransactionDate[]>([])

    useEffect(() => {

        const newDate = new Date();
        newDate.setDate(newDate.getDate() - 30);

        const getData = () => {
            getTransactionHistory("All", 100, newDate.toISOString())
            .then(({ items }) => {
                setTransactions( orderTransactions(items) )
            }).catch(() => {

            })
        }

        getData()
    
    }, [])

    const onPressCard = (id: string) => {
        navigate(`/report-transaction/${id}`)
    }

    return (
        <ListCardsTransactionHistory transactions={transactions} onPressCard={onPressCard} />
    )
}

export default React.memo( CardsTransactionsHistoryAssistance )