import React, { useCallback } from "react"
import { activateJobReducer, JobCategoryJob } from '../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import "./TableDropdown.css"
import { RootState, useAppDispatch } from '../../../../../../redux/store/store';
import { useSelector } from 'react-redux';
import JobRow from './JobRow/JobRow';
import BottomJobsRow from './BottomJobsRow/BottomJobsRow';
import HeaderTableJobs from "./HeaderTableJobs/HeaderTableJobs";
import { setActivateJobState, setDeleteJobState, setEditJobState } from "../../../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice";
import { activateJob } from "../../../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions";
import { resetValueFromStateManagerJobsTables } from "../../../../../../redux/slices/components/ManagerJobs/managerJobsTables";
import { JobGrouped } from "../../../../../../redux/slices/components/ManagerJobs/managerJobsGroupedDataSlice";

interface TableDropdownProps {
    dropdownStatus: string | null,
    jobs: JobCategoryJob[],
    categoryId: string,
    categoryName: string
}

const TableDropdown = React.memo(({categoryId, categoryName, jobs, dropdownStatus}: TableDropdownProps) => {

  const dispatch = useAppDispatch()

  const jobsGrouped = useSelector((state: RootState) => state.managerJobsGroupedData.jobsGrouped);
  const jobsMultipleGroupedByCategory = jobsGrouped.filter((job: JobGrouped) => job.categoryId === categoryId)

  const handleDeleteJob = (jobId: string) => {
      dispatch(setDeleteJobState({
        jobId,
        categoryId, 
      }))
  }

  const handleEditJob = (job: JobCategoryJob, categoryId: string) => {
      dispatch(setEditJobState({
          categoryId,
          job
      }))
  }

  const handleActivateJob = useCallback(
      (job: JobCategoryJob, categoryId: string) => {
          const dontAskAgain = localStorage.getItem("dontAskAgainActivation")
          if(dontAskAgain && JSON.parse(dontAskAgain)){
              const newActivationState = !job.active
              activateJob(categoryId, job.id, { newActivationState })
              .then((res) => {
                dispatch(activateJobReducer({
                  categoryId, jobId: job.id, newActivationState
                }))
                dispatch(resetValueFromStateManagerJobsTables())
              })
              .finally(() => { })
          }else{
              dispatch(setActivateJobState({ 
                  newActivationState: !job.active,
                  categoryId,
                  categoryName,
                  jobId: job.id,
                  jobName: job.jobName 
              }))
          }

      },
      [],
  )
  

  
  //console.log("TableDropdown", jobsGrouped)
  return (
    <div 
        className={`container-animate-table flex flex-d-column
            ${dropdownStatus === "open" && "height-opened" }
            ${dropdownStatus === "close" &&  "height-closed"}
        `}
        /*
            with animation
            className={`container-animate-table flex flex-d-column
                ${dropdownStatus === "open" && "container-job-dropdown-table-opened pd-con-table" }
                ${dropdownStatus === "close" &&  "container-job-dropdown-table-opened-reverse"}
            `}
        */
    >
      {
        dropdownStatus === "open" &&
          <div className="container-border-table-jobs flex flex-d-column">
            <HeaderTableJobs
                jobs={jobs}
                categoryId={categoryId}
                categoryName={categoryName}
            />
            <div id={`container-rows-${categoryId}`} className="con-scroll-rows-job-table">
                {
                  //<div  className="con-scroll-rows-job-table-aux">
                      
                          jobs.map((job: JobCategoryJob) => {
                              const jobMatched = jobsMultipleGroupedByCategory.some((jobGrouped: JobGrouped) => jobGrouped.id === job.id)
                              return (
                                  <JobRow 
                                      key={job.id} 
                                      checkboxValue={jobMatched ? true : false} 
                                      job={job} 
                                      categoryId={categoryId} 
                                      categoryName={categoryName}
                                      handleDeleteJob={handleDeleteJob}
                                      handleEditJob={handleEditJob}
                                      handleActivateJob={handleActivateJob}
                                  />
                              )
                          })
                      
                  //</div>
                }

            </div>
        </div>
      }
      <BottomJobsRow jobsSelected={jobsMultipleGroupedByCategory.length} categoryId={categoryId} categoryName={categoryName} />
    </div>
  )


})

export default TableDropdown