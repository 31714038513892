import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GetUserByIdThunk, GetUsersByStringThunk, GetUsersThunk, ManageUser, removeMultipleDeletedUserReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersDataSlice";
import { resetManageUsersModalsReducer } from "../../../../redux/slices/components/ManageUsers/manageUsersModalsSlice";
import { RootState, useAppDispatch } from "../../../../redux/store/store";
import { deleteMultipleUsers } from "../../../../utils/requestsUtils/functionsRequests/user/userFunctions";
import { isUUID } from "../../../../utils/verifications/uuid/uuid";
import LoadingSpinner from "../../../LoadingSpinner";
import ConfirmButton from "../../../UIcomponents/Modals/ConfirmButton/ConfirmButton";
import DescriptionModal from "../../../UIcomponents/Modals/DescriptionModal/DescriptionModal";
import TitleModal from "../../../UIcomponents/Modals/TitleModal/TitleModal";
import { deleteUsersForm } from "../../../../utils/requestsUtils/functionsRequests/usersForms/usersFormsFunctions";
import { deleteUserFormReducer, resetManageUsersFormGroupedReducer, resetManageUsersFormModalReducer, UserForm } from "../../../../redux/slices/components/ManageUsersForm/ManageUsersFormDataSlice";

const DeleteMultipleUserFormModal = () => {

    const dispatch = useAppDispatch()
    const { t } = useTranslation()
    const usersGrouped = useSelector((state: RootState) => state.manageUsersForm.usersGrouped)

    const [loading, setLoading] = useState(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true)
        const usersIds = usersGrouped.map((user: UserForm) => user.id)
        deleteUsersForm(usersIds)
        .then((res) => {

            usersIds.forEach((id) => {
                dispatch( deleteUserFormReducer(id) )
            })

            dispatch( resetManageUsersFormGroupedReducer() )
            dispatch( resetManageUsersFormModalReducer() )

        }).finally(() => { setLoading(false)})

    }

    const handleClose = () => {
        dispatch( resetManageUsersFormModalReducer() )
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>
                <TitleModal title={t("General:label_010")} />
                <DescriptionModal description={`${t("General:label_140")} ${usersGrouped.length} ${t("General:label_141")}`} />
                
                <p className="fs-16 fw-500 mt-20 mb-4">
                    {t("General:label_149")}
                </p>

                {
                    usersGrouped.map((user: UserForm) => {
                        return(
                            <p
                                key={user.id}
                                className="fs-14 fw-500 mt-8 mb-8"
                            >
                                {`- ${user.firstName} ${user.lastName} ( ${user.mail} )`}
                            </p>
                        )
                    })
                }

                <div className="flex flex-jc-end mt-40">
                    <button onClick={handleClose} type="button" className="cancel-btn-modal pointer">
                        {t("General:label_015")}
                    </button>
                    <ConfirmButton text={t("General:label_016")} />
                </div>

                { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default React.memo( DeleteMultipleUserFormModal )