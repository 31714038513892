import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../../redux/store/store'
import { getProfilePictureByUserId, getUserDataById } from '../../../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { setUserReportReducer } from '../../../../../../redux/slices/components/ManageReports/manageReportsDataSlice'
import CardUserReport from './CardUserReport/CardUserReport'

const CardsUserReport = () => {

    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(true)

    const requestByUserType = useSelector((state: RootState) => state.manageUserReports.userReport.requestByUserType)

    const requestByUserId = useSelector((state: RootState) => state.manageUserReports.userReport.requestBy)
    const personInvolveduserId = useSelector((state: RootState) => state.manageUserReports.userReport.personsInvolved)[0]

    const requestByUser = useSelector((state: RootState) => state.manageUserReports.userRequestBy)
    const personInvolveduser = useSelector((state: RootState) => state.manageUserReports.userInvolved)

    useEffect(() => {
      
        const getData = async() => {
            const promises = [
                getUserDataById(requestByUserId, { mail: 1, gimmerScore: 1, gimScore: 1, firstName: 1, lastName: 1 }),
                getProfilePictureByUserId(requestByUserId, "small"),
                getUserDataById(personInvolveduserId, { mail: 1, gimmerScore: 1, gimScore: 1, firstName: 1, lastName: 1 }),
                getProfilePictureByUserId(personInvolveduserId, "small")
            ]

            const res = await Promise.all(promises)
            const userRequestBy = res[0]
            const userInvolved = res[2]

            dispatch( 
                setUserReportReducer({
                    type: "RequestBy",
                    user: {
                        id: requestByUserId,
                        firstName: userRequestBy?.firstName,
                        lastName: userRequestBy?.lastName,
                        gimStars: userRequestBy.gimScore.toFixed(2),
                        gimmerStars: userRequestBy.gimmerScore.toFixed(2),
                        email: userRequestBy.mail,
                        imgUri: res[1]
                    }
                }) 
            )

            dispatch( 
                setUserReportReducer({
                    type: "UserInvolved",
                    user: {
                        id: personInvolveduserId,
                        firstName: userInvolved?.firstName,
                        lastName: userInvolved?.lastName,
                        gimStars: userInvolved.gimScore.toFixed(2),
                        gimmerStars: userInvolved.gimmerScore.toFixed(2),
                        email: userInvolved.mail,
                        imgUri: res[3]
                    }
                }) 
            )

            setLoading(false)
    
        }

        getData()
    
    }, [])


    return (
        <div className="center mt-24 width-100 white-bg">
            <CardUserReport 
                typeReport="Requested_By" 
                type={requestByUserType} 
                name={`${requestByUser?.firstName} ${requestByUser?.lastName}`}
                gimStars={requestByUser?.gimStars ?? ""}
                gimmerStars={requestByUser?.gimmerStars ?? ""}
                email={requestByUser?.email ?? ""}
                imgUrl={requestByUser?.imgUri ?? ""}
                loading={loading}
            />
            <div className="ml-24"></div>
            <CardUserReport  
                typeReport="User_Involved" 
                type={requestByUserType === "Gim" ? "Gimmer" : "Gim"}  
                name={`${personInvolveduser?.firstName} ${personInvolveduser?.lastName}`}
                gimStars={personInvolveduser?.gimStars ?? ""}
                gimmerStars={personInvolveduser?.gimmerStars ?? ""}
                email={personInvolveduser?.email ?? ""}
                imgUrl={personInvolveduser?.imgUri ?? ""}
                loading={loading}
            />
        </div>
    )
}

export default React.memo( CardsUserReport )