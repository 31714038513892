import React, { useCallback, useState } from 'react'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import "./GraphicEarned.css"
import { ChartIncomes } from '../../StatisticsUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ClickOutside from '../../../../UIcomponents/ClickOutside/ClickOutside';

type GraphicEarnedProps ={
    chartIncomes: ChartIncomes[]
}

const GraphicEarned = ({ chartIncomes }: GraphicEarnedProps) => {

    const [showBigGraphic, setShowBigGraphic] = useState(false)

    const onPressOutside = useCallback(
        () => {
            setShowBigGraphic(false) 
        },
        [],
    )
    

    return (
        <div className="item-graphic p-relative pointer">
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={chartIncomes}
                  margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="nameMonth" />
                  <YAxis />
                  <Tooltip />
                  <Area type="monotone" dataKey="amount" stroke="#8884d8" fill="#8884d8" />
                </AreaChart>
            </ResponsiveContainer>
            
            <div className="p-absolute graphic-earned-view width-100 height-100">
                
                <div 
                    onClick={() => setShowBigGraphic(true)} 
                    className="graphic-earned-view-hover center width-100 height-100"
                    id="open-earned-graphic"
                >
                    <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} className="fs-30 icon-expand-earned" />
                </div>
                
            </div>

            {
                showBigGraphic &&
                <div className="background-gray center">
                    <ClickOutside onPress={onPressOutside} exclude={["open-earned-graphic"]}>
                        <div className="black-modal-big-graphic">
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                  width={500}
                                  height={400}
                                  data={chartIncomes}
                                  margin={{
                                      top: 10,
                                      right: 30,
                                      left: 0,
                                      bottom: 0,
                                  }}
                                >
                                  <CartesianGrid strokeDasharray="3 3" />
                                  <XAxis dataKey="nameMonth" />
                                  <YAxis />
                                  <Tooltip />
                                  <Area type="monotone" dataKey="amount" stroke="#8884d8" fill="#8884d8" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                    </ClickOutside>

                    <div className="center p-absolute" style={{ bottom: 0 }}>
                        <div className="center circle mt-20 pointer p-absolute con-icon-close-graphic-earned"> 
                            <FontAwesomeIcon icon={regular("times")} className="fs-30 c-white" />
                        </div>
                    </div>
                </div>
            }
              
        </div>
    )
}

export default React.memo( GraphicEarned )