import { useTranslation } from "react-i18next"
import "./ImgFormats.css"

const ImgFormats = () => {
  const { t } = useTranslation()
  return (
    <p className="text-img-allowed">{t("General:label_120")}</p>
  )
}

export default ImgFormats