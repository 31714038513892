import React, { useCallback, useState } from 'react'
import { verifyEmailExists } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions'
import { MyYup } from '../../../../utils/verifications/myYup'
import { ValuesForm } from '../../../../utils/verifications/Forms'
import { useTranslation } from 'react-i18next'
import TitleInBorderForInput from '../../../UIcomponents/TitleInBorderForInput/TitleInBorderForInput'
import ArrowGoBackButton from '../../../UIcomponents/ArrowGoBackButton/ArrowGoBackButton'
import "./CheckEmail.css"
import { AxiosError } from 'axios'
import LoadingSpinner from '../../../LoadingSpinner'
import { resetPassword } from 'aws-amplify/auth'

type CheckEmailProps = {
    goBack: (index: number) => void
    nextStep: (index: number) => void
    email: string
    setEmail: React.Dispatch<React.SetStateAction<string>>
}

const CheckEmail = ({ goBack, nextStep, email, setEmail }: CheckEmailProps) => {

    const { t, i18n } = useTranslation();

    const [inputValues, setInputValues] = useState<ValuesForm>({
        email: { error: "", value: email, touched: false, checkValue: (x: string | number, dv?: boolean) => new MyYup(x, i18n.language, dv).isRequired().isEmail()},
    });  

    const [error, setError] = useState("")

    const [loading, setLoading] = useState(false)


    const handleSubmit = (e: any) => {

        e.preventDefault()

        let existsError = false;
        for(const nameInput in inputValues){
            if(checkErrorsWhenSubmit(nameInput))
                existsError = true
        }
        setInputValues({...inputValues})

        if(existsError)
            return

        setLoading(true)

        verifyEmailExists(inputValues["email"].value)
        .then(() => {
            resetPassword({ username: inputValues["email"].value.replace("@", "_") })
            .then((res) => {
                setEmail(inputValues["email"].value)
                nextStep(2)
            }).catch(() => {
                setError("Error in cognito server")
            }).finally(() => {
                setLoading(false)
            })
        }).catch((error: AxiosError) => {
            console.log("Error", error.response?.data)
            if(error.response?.data === "MAIL_NOT_FOUND")
                setError("The email does not exists")
            else if(error.response?.data === "USER_REGISTERED_BY_FEDERATIVE")
                setError("You cant reset passwords for Google or Apple accounts")
            else
                setError("Error server")

            setLoading(false)
        })
    }

    const checkErrors = (e: any) => {
        if(error)
            setError("")
        const value = e.target.value
        const result = inputValues[e.target.name].checkValue(value)
        if(result.errorMsg)
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: result.errorMsg}})
        else
            setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], value, error: "" }})
    }
    

    const checkErrorsWhenSubmit = (nameInput: string): boolean => {
        const value = inputValues[nameInput].value
        const result = inputValues[nameInput].checkValue(value)
        if(result.errorMsg){
            inputValues[nameInput] = {...inputValues[nameInput], value, error: result.errorMsg}
            return true
        }else{
            inputValues[nameInput] = {...inputValues[nameInput], value, error: ""}
            return false
        }
    }

    const GoBack = useCallback(
        () => {
            goBack(0)
        },
        [],
    )
    

    return (
        <form className="con-forgot-password">
            <div className="flex">
                <ArrowGoBackButton goBack={GoBack} />
            </div>
            <p className="fs-26 color-text fw-700 mt-30">{"Forgot password?"}</p>
            <p className="fs-13 color-text mt-12">{
                "Enter the email associated with your account and we will help you create a new password"
            }</p>

            <p className="fs-14 color-text fw-700 mt-40">{"Email"}</p>

            <div className="p-relative flex flex-d-column width-100 mt-6 mb-50">
                <div className=" p-relative padding-to-show-error">
                    <input
                        id="email"
                        name="email"
                        type="text"
                        placeholder={"example@email.com"}
                        className="input-forgot-password fs-16 input-without-focus"
                        onChange={(e: any) => {
                            checkErrors(e)
                        }}
                        value={inputValues["email"].value}
                        onFocus={(e:any) => { setInputValues({...inputValues, [e.target.name]: {...inputValues[e.target.name], touched: true}})  }}
                    />
                    { inputValues["email"].error && (
                        <p className="p-absolute error-input-txt" style={{ bottom: "-.8rem" }}>{inputValues["email"].error  || ""}</p>
                    )}
                </div>
                <p className="p-absolute error-input-txt" style={{ bottom: "-1.8rem" }}>{error}</p>
            </div>

            {
                loading ?
                <div className="center width-100 p-relative" style={{ height: "8rem", }}>
                    <LoadingSpinner fullPage={false} />
                </div>
                :
                <button 
                    onClick={handleSubmit} 
                    className="dark-gray-bg center width-100 btn-next-forgot-password" 
                    type="submit"
                >
                    <p className="fs-15 c-white">{"Next"}</p>
                </button>
            }


        </form>
    )
}

export default React.memo( CheckEmail )