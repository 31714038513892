import { useTranslation } from 'react-i18next'

interface YourStatisticsIdProps {
    imagesRemaining: string
}

const YourStatisticsId = ({ imagesRemaining }:YourStatisticsIdProps) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-d-column flex-ai-center">
        <p className="your-statistics-profile-title fw-500">{t("General:label_058")}</p>
        <button className="remaining-profiles-imgs-btn flex flex-d-column flex-ai-center">
            <span className="fs-28 fw-500 mb-4">{ imagesRemaining }</span>
            <span className="fs-16">{t("General:label_059")}</span>
        </button>
    </div>
  )
}

export default YourStatisticsId