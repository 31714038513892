import { FaCheck } from 'react-icons/fa'

type MyCheckboxProps = {
    onChange: any,
    value: boolean,
    size?: string,
    noSelectedBackgroundColor?: string
    backgroundColor?: string,
    iconColor?: string,
    iconSize?: string,
    borderThickness?: string,
    borderColor?: string,
    borderRadius?: string
}

const MyCheckbox = ({ onChange, value,
    size = "1rem", backgroundColor = "black", iconColor = "white",
    iconSize = ".6rem", borderThickness = "1px", borderColor = "#999",
    borderRadius = ".3rem", noSelectedBackgroundColor = "transparent"  }: MyCheckboxProps) => {

  return (
    <div style={{
        width: size,
        height: size,
        border: `${borderThickness} solid ${borderColor}`,
        borderRadius,
        cursor: "pointer",
        backgroundColor: value ? backgroundColor : noSelectedBackgroundColor,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden" 
    }}
        onClick={onChange}
    >
        { value && <FaCheck style={{  color: iconColor, fontSize: iconSize  }} /> }
    </div>
  )
}

export default MyCheckbox