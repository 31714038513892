import React from 'react'
import { useTranslation } from 'react-i18next'
import CardAssignedBugReport from './CardAssignedBugReport/CardAssignedBugReport'
import { RootState } from '../../../../redux/store/store'
import { useSelector } from 'react-redux'
import "./RightSideBugReport.css"
import ChatHistoryTesterDeveloper from './ChatHistoryTesterDeveloper/ChatHistoryTesterDeveloper'

const RightSideBugReport = () => {

    const { t } = useTranslation()

    const myUserId = useSelector((state: RootState) => state.auth.user.id)
    const userAssignedId = useSelector((state: RootState) => state.manageBugReportsData.bugReport.userAssigned)
    const testerAssigned = useSelector((state: RootState) => state.manageBugReportsData.bugReport.testerAssigned)
    const reportId = useSelector((state: RootState) => state.manageBugReportsData.bugReport.id)
    const datesIncident = useSelector((state: RootState) => state.manageBugReportsData.bugReport.datesIncident)
    const status = useSelector((state: RootState) => state.manageBugReportsData.bugReport.status)

    const onSelect = (id: string) => {

    }

    return (
        <div className="pl-20 pb-10 mb-10 mt-80 con-user-cards-bugs" style={{ width: "47%", paddingLeft: "5.7%" }}>
            { 
                testerAssigned && status !== "UPLOADED" &&
                <CardAssignedBugReport 
                    id={testerAssigned} 
                    isMyUserAssigned={myUserId === testerAssigned} 
                    datesIncident={datesIncident} 
                    reportId={reportId}
                    isTester={true}
                /> 
            }
            { 
                userAssignedId && 
                <CardAssignedBugReport 
                    id={userAssignedId} 
                    isMyUserAssigned={myUserId === userAssignedId} 
                    datesIncident={datesIncident} 
                    reportId={reportId}
                    isTester={false}
                /> 
            }

            {
                status !== "UPLOADED" &&
                <div className="flex mt-30">
                    <ChatHistoryTesterDeveloper />
                </div>
            }
        </div>
    )
}

export default React.memo( RightSideBugReport )