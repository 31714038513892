import React, { useState } from 'react'
import DescriptionModal from '../../../../UIcomponents/Modals/DescriptionModal/DescriptionModal'
import CancelButton from '../../../../UIcomponents/Modals/CancelButton/CancelButton'
import ConfirmButton from '../../../../UIcomponents/Modals/ConfirmButton/ConfirmButton'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from '../../../../LoadingSpinner'
import { ProductType, resetMarketplaceModalsReducer, setDeleteMarketplaceProductReducer, setDeleteProductModalReducer } from '../../../../../redux/slices/components/Marketplace/marketplaceDataSlice'
import { deleteProductMarketplace } from '../../../../../utils/requestsUtils/functionsRequests/marketplace/marketplaceFunctions'
import { useAppDispatch } from '../../../../../redux/store/store'
import { setDeleteMarketplaceProductUserReducer } from '../../../../../redux/slices/authSlice'

type FormModalDeleteProductProps = {
    id: string
    productName: string
    type: ProductType
}

const FormModalDeleteProduct = ({ id, productName, type }:FormModalDeleteProductProps) => {

    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const [loading, setLoading] = useState(false)
    const [askInput, setAskInput] = useState<boolean>(false)

    const handleAskMeAgain = () => {
        setAskInput(!askInput)
    }


    const handleSubmit = (e: any) => {
        e.preventDefault();
        setLoading(true)

        deleteProductMarketplace(id)
        .then((res) => {
            if(askInput)
                localStorage.setItem("dontAskAgainDeleteProduct", JSON.stringify(!askInput))
            dispatch( 
                setDeleteMarketplaceProductReducer({
                    id,
                    type
                }) 
            )
            dispatch( 
                setDeleteMarketplaceProductUserReducer({
                    id,
                    type
                }) 
            )
            dispatch(resetMarketplaceModalsReducer())
        })
        .catch((error) => {console.log("error", error)})
        .finally(() => { setLoading(false)})
        
    }

    const closeModal = () => {
        dispatch(resetMarketplaceModalsReducer())
    }

    return (
        <div>
            <DescriptionModal description={t("Marketplace:label_21")} />
            <form onSubmit={handleSubmit} className="con-ask-check-btns flex flex-jc-sb flex-ai-center">
            <div className="flex flex-ai-center">
                <input  
                    id="ask" 
                    name="ask" 
                    type="checkbox" 
                    onChange={handleAskMeAgain}
                    checked={askInput}
                />
                <label className="fw-500 label-ask" htmlFor="ask" >{t("General:label_091")}</label>
            </div>
            <div className="align-btns-right">
                <CancelButton  onPress={closeModal} />
                <ConfirmButton text={t("General:label_016")} />
            </div>
            </form>
            { loading && <LoadingSpinner fullPage={true} /> }
        </div>
    )
}

export default FormModalDeleteProduct