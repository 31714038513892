import React from 'react'
import ListChatsSide from '../../../../components/MySessions/ListChatsSide/ListChatsSide'
import ChatSide from '../../../../components/MySessions/ChatSide/ChatSide'

const MySessionsScreen = () => {
    return (
        <div className="flex">
            <ListChatsSide />
            <ChatSide />
        </div>
    )
}

export default MySessionsScreen