import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { JobCategoryJob } from '../../../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import "./MenuJobRow.css"
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import ClickOutside from '../../../../../../UIcomponents/ClickOutside/ClickOutside';

interface MenuJobRowProps {
    btnId: string
    job: JobCategoryJob,
    categoryName: string,
    categoryId: string,
    topMenu: boolean
    accessDeleteJob: boolean
    accessEditJob: boolean
    accessEditActivateJob: boolean
    accessLinkJob: boolean
    handleEditJob: (job: JobCategoryJob, categoryId: string) => void
    handleActivateJob: (job: JobCategoryJob, categoryId: string) => void
    handleDeleteJob: (jobId: string) => void
    setShowMenu: Dispatch<SetStateAction<boolean>>
}
  
const MenuJobRow = ({ 
    btnId,
    topMenu, 
    job, 
    categoryId, 
    categoryName, 
    accessDeleteJob,
    accessEditJob,
    accessEditActivateJob,
    accessLinkJob,
    handleEditJob,
    handleActivateJob,
    handleDeleteJob,
    setShowMenu
 }: MenuJobRowProps) => {

    const { t } = useTranslation();

    const handleLinkToOther = () => {
        
    }

    

    useEffect(() => {
    
        const handleOutsideClick = (e: any) => {
            setTimeout(() => {
                //setShowMenu(false)
            }, 50);
        }
        document.body.addEventListener('click', handleOutsideClick, true); // handle the capturing phase of an event
      
        return () => {
          document.body.removeEventListener('click', handleOutsideClick, true);
        }
    }, [])

    const onClose = useCallback(
        () => {
            setShowMenu(false)
        },
        [],
    )
    

      // elements with no-select class wont activate or deactivate the select status of the row when you click on them
    return (
        <div className="con-menu fs-12" style={{ top: true ? "-7rem" : "2.4rem" }}>
            <ClickOutside onPress={onClose} exclude={[btnId]} >
                <div className="" >
                    {
                        accessEditJob &&
                        <button onClick={() => { handleEditJob(job, categoryId); onClose() }} className="option-menu flex flex-ai-center pointer p-8 no-select">
                            <FontAwesomeIcon icon={solid("pen")} className="no-select" /> 
                            <p className="ml-10 mt-2 no-select">{t("General:label_068")}</p>
                        </button> 
                    } 
                    {
                        accessDeleteJob &&
                        <button
                            id={`${job.id}-delete-modal`}
                            onClick={() => { handleDeleteJob(job.id); onClose() }}
                            className='option-menu flex flex-ai-center pointer p-8 no-select'
                        >
                            <FontAwesomeIcon icon={regular("trash")} className="no-select" />
                            <p className="ml-10 mt-2 no-select">{t("ManageJobs:label_15")}</p>
                        </button>
                    }
                    {
                        accessEditActivateJob &&
                        <button onClick={() => { handleActivateJob(job, categoryId); onClose() }} className="option-menu flex flex-ai-center pointer p-8 no-select">
                            <FontAwesomeIcon icon={solid("plug")} className="no-select"/> 
                            <p className="ml-10 mt-2 no-select">{job.active ? t("General:label_084") : t("General:label_083")}</p>
                        </button>  
                    }
                    <button onClick={handleLinkToOther} className="option-menu flex flex-ai-center pointer p-8 no-select">
                        <FontAwesomeIcon icon={solid("link-simple")} className="no-select" /> 
                        <p className="ml-10 mt-2 no-select">{t("General:label_085")}</p>
                    </button>                        
                </div>
            </ClickOutside>
        </div>
    )
}

export default React.memo( MenuJobRow )