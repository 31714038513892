import { useState, useEffect, useCallback } from 'react';
import { RootState, useAppDispatch } from '../../../../redux/store/store';
import { removeVerifiedCertificateImageReducer, setCedulawasValidatedVerifyCertificateReducer, setLoadingVerifyCertificateReducer, UserCertificateImage } from "../../../../redux/slices/components/VerifyCertificate/VerifyCertificateDataSlice";
import "./SliderCertificateCard.css"
import { BodyCedula, checkCedulaRequest, getCertificateImgByJobId, getProfilePictureByUserId, verifyUserCerImage } from '../../../../utils/requestsUtils/functionsRequests/user/userFunctions';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import FormCedula from '../FormCedula/FormCedula';
import LoadingSpinner from '../../../LoadingSpinner';
import { useSelector } from 'react-redux';

interface SliderCertificateProps {
  user: UserCertificateImage,
  index: number,
  lengthUsers: number
  editable: boolean
}


const SliderCertificateCard = ({ user, index, editable }: SliderCertificateProps) => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch()

    const cedulawasValidated = useSelector((state: RootState) => state.VerifyCertificateData.cedulawasValidated)

    const [showExpandedIdImage, setShowExpandedIdImage] = useState<boolean>(false);
    const [cerImgUrl, setCerImgUrl] = useState<string>("")
    const [profileImgeUrl, setProfileImgeUrl] = useState<string>("")

    const [loading, setLoading] = useState(false)


    useEffect(() => {
        getCertificateImgByJobId(user.id, user.jobId)
        .then((url) => {
            setCerImgUrl(url)
        })

        getProfilePictureByUserId(user.id, "mid")
        .then((url) => {
            setProfileImgeUrl(url)
        })
 
    }, [])
    

    const validPicture = () => {
        dispatch(setLoadingVerifyCertificateReducer(true))
        verifyUserCerImage(user.id, user.jobId, { newVerifiedState: true })
        .then((res) => {
            dispatch(removeVerifiedCertificateImageReducer({id: user.id, jobId: user.jobId}))
            dispatch( setCedulawasValidatedVerifyCertificateReducer("") )
        }).finally(() => { dispatch(setLoadingVerifyCertificateReducer(false)) })
    }
    
    const inValidPicture = () => {
        dispatch(setLoadingVerifyCertificateReducer(true))
        verifyUserCerImage(user.id, user.jobId, { newVerifiedState: false })
        .then((res) => {
            dispatch(removeVerifiedCertificateImageReducer({id: user.id, jobId: user.jobId}))
            dispatch( setCedulawasValidatedVerifyCertificateReducer("") )
        }).finally(() => { 
            dispatch(setLoadingVerifyCertificateReducer(false)) 
        })
    }

    return (
        <div id={`slider-profile-img-${index.toString()}`} className="con-cer-img-card p-relative" >

            <div className="card-cer-img flex">

                <div 
                    className="cer-img-view pointer" 
                    style={{ "backgroundImage":  `url(${cerImgUrl})` }}
                    onClick={() => setShowExpandedIdImage(true)}
                >
                    <div className="cer-img-hover center">
                        <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} className="fs-30 icon-expand-id-img" />
                    </div>
                </div>

                {
                    showExpandedIdImage &&
                    <div className="fixed-all-screen background-dark center flex-d-column">
                        <div 
                            className="cer-img-expanded" 
                            style={{ "backgroundImage":  `url(${cerImgUrl})`}}
                        ></div>
                        <div onClick={() => setShowExpandedIdImage(false)} className="center circle mt-20 pointer con-icon-close-cer-img"> 
                            <FontAwesomeIcon icon={regular("times")} className="fs-18 c-white" />
                        </div>
                    </div>
                }
                
                <div className="con-data-card-cer-img flex flex-d-column flex-jc-c flex-1 ml-30 p-relative">

                    <div className="mb-10">
                        <p className="fs-18-vh fw-600">{user.jobName}</p>
                        <p className="fs-16-vh fw-500 mt-4">{user.categoryName}</p>
                    </div>

                    <div className="con-img-name-country-cer flex  flex-ai-center">
                        <div className="img-profile-cer-card mr-8" style={{ "backgroundImage":  `url(${profileImgeUrl})`}}></div>
                        <div className="con-profile-cer-name-country flex flex-d-column" >
                            <p className="fw-600 fs-18-vh mb-4">{`${user.firstName} ${user.lastName}`}</p>
                            <p className="fw-500 mt-4 fs-16-vh">{`${t("General:label_055")}: ${user.countryName}`}</p>
                        </div>
                    </div>

                    {
                        editable &&
                        <div className="center mt-30">

                            <button
                                className="btn-slider-card-cer fw-600 fs-15  btn-slider-card-invalid-cer pointer mr-6 color-persian-red"
                                onClick={inValidPicture}
                            >
                                {t("General:label_057")}
                            </button>


                            {
                                cedulawasValidated === "YES" &&
                                <button
                                    className="btn-slider-card-cer fw-600 fs-15 btn-slider-card-valid-cer pointer ml-6 color-green-pass"
                                    onClick={validPicture}
                                >
                                    {t("General:label_056")}
                                </button>
                            }

                        </div>
                    }

                    { loading && <LoadingSpinner fullPage={false} /> }
                </div>

            </div>

        </div>
    )
}

export default SliderCertificateCard