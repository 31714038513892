import "./BottomRowTableUsers.css"
import GroupedUsersSide from "./GroupedUsersSide/GroupedUsersSide";
import PaginationUserTable from "./PaginationUserTable/PaginationUserTable";

const BottomRowTableUsers = () => {

  return(
    <div className="con-bottom-row-table-users flex flex-ai-center flex-jc-sb">
        <GroupedUsersSide />
        <PaginationUserTable />
    </div>
  )

}

export default BottomRowTableUsers