import React, { useMemo } from 'react'
import ClickOutside from '../../../../UIcomponents/ClickOutside/ClickOutside'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '../../../../../redux/store/store'
import { CategoryJob, JobCategoryJob } from '../../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import { Colors } from '../../../../../utils/colors/colors'
import useGetAddressInfo from '../../../../hooks/useGetAddressInfo/useGetAddressInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Coords, DefaultPosition, requestUserPosition } from '../../../../../utils/maps/locations'
import { setAddSkill } from '../../../../../redux/slices/components/SignUp/SignUpSlice'
import { useNavigate } from 'react-router-dom'
import useGetAllJobNames from '../../../../hooks/useGetAllJobNames/useGetJobName'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import "./ListSearchSkills.css"

type ListSearchSkillsProps = {
    textSearchBar: string
    onCloseModal: () => void
}

const ListSearchSkills = ({ textSearchBar, onCloseModal }: ListSearchSkillsProps) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const categoryJobs = useSelector((state: RootState) => state.signUp.categoryJobs)
    const jobsAdded = useSelector((state: RootState) => state.signUp.jobsAdded)

    const jobsNames = useGetAllJobNames()
    console.log("jobsNames list", jobsNames)
    const jobCategoriesFiltered: CategoryJob[] = useMemo(() => {
        const jobCategories = categoryJobs.map((category) => {
            const jobs = category.jobs.filter((job) => {
                if(!textSearchBar)
                    return false
                return jobsNames[job.id]?.indexOf(textSearchBar) > - 1
            })
            return {
                ...category,
                jobs
            }

        })
        return jobCategories.filter((category) => category.jobs.length > 0)
    }, [textSearchBar,categoryJobs])

    const [ address ] = useGetAddressInfo()


    const addJob = (category: CategoryJob, job: JobCategoryJob) => {

        const obj = {
            categoryId: category.id,
            categoryName: category.categoryName,
            imgKey: job.imgKey,
            isCertifReq: job.isCertifReq,
            isJobSelected: true,
            isUploaded: false,
            isVerified: false,
            jobId: job.id,
            jobName: job.jobName,
            photoKey: category.photoKey,
        }

        requestUserPosition()
        .then((coords: Coords) => {
            dispatch( 
                setAddSkill({
                    ...obj,
                    workPreference: {
                        inside: {
                            address: address,
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                        },
                        outside: {
                            address: address,
                            latitude: coords.latitude,
                            longitude: coords.longitude,
                            radius: 5
                        },
                        remote: true
                    }
                }) 
            )
        }).catch((error) => {
            console.log("Error: ", error)
            dispatch( 
                setAddSkill({
                    ...obj,
                    workPreference: {
                        inside: {
                            address: DefaultPosition.address,
                            latitude: DefaultPosition.latitude,
                            longitude: DefaultPosition.longitude,
                        },
                        outside: {
                            address: DefaultPosition.address,
                            latitude: DefaultPosition.latitude,
                            longitude: DefaultPosition.longitude,
                            radius: 5
                        },
                        remote: true
                    }
                }) 
            )
        }).finally(() => {
            setTimeout(() => {
                onCloseModal()
                navigate("/gim-skill/select-location")
            }, 10);
        })
        
    }


    return (
        <ClickOutside onPress={onCloseModal} exclude={["input-search-bar-skills"]}>
            {
                jobCategoriesFiltered.map((category) => {
                    return(
                        <div
                            key={category.id}
                            className=""
                        >
                            <div className="dark-gray-bg center width-100 con-category-skill" style={{ height: "3vh" }}>
                                
                                <p className="fs-20-vh c-white">{jobsNames[category.id]}</p>

                            </div>

                            <div className="flex flex-d-column" style={{ padding: ".5vh 1.5vw 1.4vh .5vw" }}>
                                {
                                    category.jobs.map((job: JobCategoryJob) => {
                                        const isJobSelected = jobsAdded.find((j) => j.jobId === job.id)
                                        return(
                                            <div
                                                key={job.id}
                                                className="flex flex-ai-center" 
                                                style={{ 
                                                    marginTop: ".8vh", 
                                                    marginBottom: ".8vh",
                                                    color: isJobSelected ? Colors.PictonBlue : Colors.TEXT,
                                                    cursor: isJobSelected ? "auto" : "pointer"
                                                    }}
                                                onClick={isJobSelected ? () => {} : () => addJob(category, job)}
                                            >
                                                <FontAwesomeIcon icon={faCircleCheck} className="fs-18-vh" />
                                                <p className="fs-20-vh ml-10">{jobsNames[job.id]}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    )
                })
            }
        </ClickOutside>
    )
}

export default React.memo( ListSearchSkills )