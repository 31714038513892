import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import "./ButtonBack.css"
import { useNavigate } from 'react-router-dom'
import React from 'react'


const ButtonBack = () => {
    const { t } = useTranslation();

    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <button onClick={goBack} className="back-offer-detailed fs-14-vh center pointer mt-4 mb-4">
            <FontAwesomeIcon icon={solid("arrow-left")}/>
            <span className="fw-500 ml-4">{t("General:label_009")}</span>
        </button>
    )
}

export default React.memo( ButtonBack )