import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux/store/store'
import { LanguageLabel } from '../../../redux/slices/authSlice'


const useGetAllJobNames: () => { [key: string]: string } = () => {

    const { i18n } = useTranslation()

    const jobNamesFile = useSelector((state: RootState) => state.global.jobsNamesFile)

    const language: LanguageLabel = i18n.language as LanguageLabel

    if(jobNamesFile){
        const obj: { [key: string]: string } = {}
        for(const key in jobNamesFile){
            obj[key] = jobNamesFile[key]?.[language]
        }
        return obj
    }
    return {}
}

export default useGetAllJobNames