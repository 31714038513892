
import React from 'react'
import { CategoryJob, SortEvent } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice'
import HeaderJobDropdowns from './HeaderJobDropdowns/HeaderJobDropdowns'
import JobDropdown from './JobDropdown/CategoryDropdown'
import "./JobDropdownsByCountry.css"

interface JobDropdownByCountryProps {
  countryName: string,
  categoriesJobs: CategoryJob[],
  orderJobs: SortEvent[]
}

const JobDropdownsByCountry = React.memo(({ countryName, categoriesJobs, orderJobs }: JobDropdownByCountryProps) => {
    return (
      <div className="container-jobdropdown-country">
          <HeaderJobDropdowns 
              countryName={countryName} 
              orderJob={orderJobs.find((o: SortEvent) => o.countryName === countryName)}
          />
          {
            categoriesJobs.map((job: CategoryJob, index: number) => (
                <JobDropdown 
                    key={job.id}
                    categoryJob={job}
                />
            ))
          }
      </div>
    )
})

export default JobDropdownsByCountry