import { useNavigate } from 'react-router';
import "./ErrorScreen.css"
interface IErrorScreenProps {
    errorMessage: string,
    loginAgain: boolean
}

const ErrorScreen = ({errorMessage, loginAgain}:IErrorScreenProps) => {

    const navigate = useNavigate();

    const goToAnotherSiteSite = () => {
        if(loginAgain)
            navigate("/login")
        else   
            navigate("/my-profile");
    }

  return (
    <div className="container-error-message">
        <div className="container-data-error">
            <h1 className="message-error-text">{errorMessage}</h1>
            <button
                className="btn-go-gimwork"
                onClick={goToAnotherSiteSite}
            >
                {
                    loginAgain ? "Login Again" : "Go to Panel"
                }
            </button>
        </div>
    </div>
  )
}

export default ErrorScreen