import moment from "moment"
import { isDateDifferenceGreaterThan } from "../dates/dates"
import { getProfilePictureByUserId } from "../requestsUtils/functionsRequests/user/userFunctions"

type UserImageInfoCache = {
    url: string
    date: string
}

class UserImageCache{
    userImageCache = new Map<string, UserImageInfoCache>()

    addUrl(id: string, url: string) {
        const date = moment().toISOString()
        this.userImageCache.set(id, { date, url })
    }

    async getUrl(id: string, size: "small" | "mid" | "" = "small"): Promise<string> {
        const date = moment().toISOString()
        const obj = this.userImageCache.get(id)
        if(isDateDifferenceGreaterThan(date, 3600) || !obj){
            try {
                const url = await getProfilePictureByUserId(id, size)
                return url
            } catch (error) {
                return ""
            }
        }else{
            return obj?.url
        }
    }
}

export const userImageCache = new UserImageCache()