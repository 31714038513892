import React, { ChangeEvent, useMemo } from 'react'
import { ValuesForm } from '../../../../../utils/verifications/Forms'
import RowInputs, { DataInputRowJob } from '../RowInputs/RowInputs'
import TitleCheckboxInputs from '../TitleCheckboxInputs/TitleCheckboxInputs'
import { useTranslation } from 'react-i18next'

type RowTransportCostsProps = {
    inputValues: ValuesForm
    isDay: boolean
    valueCheckBox: boolean
    onChangeCheckbox?: (value: boolean) => void
    onChange: (newValue: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onFocus: (name: string) => void
}



const RowTransportCosts = ({ inputValues, isDay, valueCheckBox, onChangeCheckbox, onChange, onFocus }: RowTransportCostsProps) => {

    const { t } = useTranslation()

    const dayData: DataInputRowJob[] = useMemo(() => [
        { name: "transportCostsPrice_Day",           placeholder: t("ManageJobs:label_08"),     width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsGimIVA_Day",          placeholder: "Gim IVA",                    width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsGimmerIVA_Day",       placeholder: "Gimmer IVA",                 width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsGimworkIVA_Day",      placeholder: "Gimwork IVA",                width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsISR_Day",             placeholder: "ISR",                        width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsRMP_Day",             placeholder: "RMP",                        width: "13%",   type: "text",   step: undefined },
    ], []);

    const nightData: DataInputRowJob[] = useMemo(() => [
        { name: "transportCostsPrice_Night",           placeholder: t("ManageJobs:label_08"),   width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsGimIVA_Night",          placeholder: "Gim IVA",                  width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsGimmerIVA_Night",       placeholder: "Gimmer IVA",               width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsGimworkIVA_Night",      placeholder: "Gimwork IVA",              width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsISR_Night",             placeholder: "ISR",                      width: "13%",   type: "text",   step: undefined },
        { name: "transportCostsRMP_Night",             placeholder: "RMP",                      width: "13%",   type: "text",   step: undefined },
    ], []);
    
    return (
        <RowInputs 
            inputValues={inputValues}
            inputData={isDay ? dayData : nightData}
            onChange={onChange}
            onFocus={onFocus}
            color="#FFF3CD"
        >
            <TitleCheckboxInputs 
                title={t("ManageJobs:label_05")}
                color="#FFF3CD"
                valueCheckBox={valueCheckBox}
                onChangeCheckbox={onChangeCheckbox}
            />
        </RowInputs>
    )
}

export default RowTransportCosts