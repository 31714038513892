import  { useState } from 'react';
import { useAppDispatch } from '../../../../redux/store/store';
import { RootState } from '../../../../redux/store/store';
import { useSelector } from 'react-redux';
import { deleteJob } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/JobFunctions';
import TitleModal from '../../../UIcomponents/Modals/TitleModal/TitleModal';
import DescriptionModal from '../../../UIcomponents/Modals/DescriptionModal/DescriptionModal';
import ConfirmButton from '../../../UIcomponents/Modals/ConfirmButton/ConfirmButton';
import CancelButton from '../../../UIcomponents/Modals/CancelButton/CancelButton';
import LoadingSpinner from '../../../LoadingSpinner';
import { removeJobReducer } from '../../../../redux/slices/components/ManagerJobs/managerJobDataSlice';
import { resetValueFromState } from '../../../../redux/slices/components/ManagerJobs/managerJobsModalsSlice';
import "./DeleteJobModal.css"
import { useTranslation } from 'react-i18next';
import { deleteJobFromJobsNamesFile } from '../../../../utils/requestsUtils/functionsRequests/jobCategories/jobCategoriesFunctions';
import { deleteJobNameFileReducer } from '../../../../redux/slices/components/Global/GlobalSlice';
import useGetJobName from '../../../hooks/useGetJobName/useGetJobName';



const DeleteJobModal = () => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const jobId = useSelector((state: RootState) => state.managerJobModals.DeleteJobModal.jobId);
    const categoryId = useSelector((state: RootState) => state.managerJobModals.DeleteJobModal.categoryId);
    const [loading, setLoading] = useState<boolean>(false);

    const categoryName = useGetJobName(categoryId)
    const jobName = useGetJobName(jobId)


    const handleSubmit = async(e :any) => {
        e.preventDefault();
        setLoading(true)

        try {
            //await deleteJob(jobId)
            await deleteJob(categoryId, jobId)
            await deleteJobFromJobsNamesFile([jobId])

            dispatch( deleteJobNameFileReducer(jobId) )
            dispatch(removeJobReducer({ categoryId, jobId}))
        } catch (error) {
            console.log("error", error)
            alert("General:Error")
        }finally{
            setLoading(false)
            dispatch(resetValueFromState())
        }
        
    }

    const closeModal = () => {
        dispatch(resetValueFromState())
    }

    return (
        <div className="blank-wrapper-modals">
            <form onSubmit={handleSubmit}>
            
            <TitleModal title={t("General:label_010")} />
            <DescriptionModal description={`${t("General:label_107")}
                ${jobName} ${t("General:label_108")} ${categoryName}?`} 
            />
            <br />
            <br />
            <br />
            <div className="flex flex-jc-end">
                <CancelButton onPress={closeModal} />
                <ConfirmButton text={t("General:label_016")} />
            </div>

            { loading && <LoadingSpinner fullPage={true} /> }
            </form>
        </div>
    )
}

export default DeleteJobModal